import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MapDelegateHelperService } from './map-delegate-helper.service';
import { WarehouseStoreService } from '../../../_core/stores/warehouse/warehouse-store.service';

import { IDelegate, IDelegateEntity, ISelectableItem } from '../model/delegate.model';
import { IWarehouses } from '../../../_core/models/warehouse.model';

@Injectable({
  providedIn: 'root',
})
export class DelegateWarehousesService implements IDelegate {
  public getResult$!: Observable<ISelectableItem[]>;

  constructor(private mapDelegateHelperService: MapDelegateHelperService, private warehouseStoreService: WarehouseStoreService) {}

  public get(_shortName: boolean = false, getParams?: object): void {
    this.warehouseStoreService.getWarehouses(getParams ? getParams : {});

    this.getResult$ = this.warehouseStoreService.warehouses$.pipe(
      map((items: IWarehouses | null) => this.mapDelegateHelperService.mapEntity(items?.items as IDelegateEntity[])),
    );
  }

  public reset(): void {
    this.warehouseStoreService.resetWarehouses();
  }
}
