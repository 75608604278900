import { ChangeDetectionStrategy, Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'tlp-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
})
export class SwitchComponent implements ControlValueAccessor {
  @Input() public isSwitchOn: boolean = false;
  @Input() public label!: string;

  private onChange = (_: boolean) => {};

  public onToggleSwitch(): void {
    this.isSwitchOn = !this.isSwitchOn;
    this.onChange(this.isSwitchOn);
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(): void {}

  public writeValue(value: boolean): void {
    if (value) {
      this.isSwitchOn = value;
      this.onChange(this.isSwitchOn);
    }
  }
}
