import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'tlp-input-group',
  templateUrl: './input-group.component.html',
  styleUrls: ['./input-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputGroupComponent {
  @Input() public text: string | undefined = undefined;
}
