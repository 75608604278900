import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ApiCityService } from '../../api/api-city.service';
import { ICities, ICityApiParams } from '../../models/city.model';
import { eCityActions } from './city.actions';

@Injectable()
export class CityEffects {
  constructor(private actions$: Actions, private apiCityService: ApiCityService) {}

  public getCities$: Observable<
    { type: eCityActions; cities: ICities | null } | { type: eCityActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eCityActions.CITY_GET),
      switchMap((payload: { type: string; params: ICityApiParams }) =>
        this.apiCityService.getCities(payload.params).pipe(
          map(cities => ({
            type: eCityActions.CITY_GET_SUCCESS,
            cities,
          })),
          catchError((error: HttpErrorResponse) => {
            return of({ type: eCityActions.CITY_GET_FAILURE, error });
          }),
        ),
      ),
    ),
  );

  public getUserCities$: Observable<
    { type: eCityActions; userCities: ICities | null } | { type: eCityActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eCityActions.CITY_USER_GET),
      switchMap((payload: { type: string; params: ICityApiParams }) =>
        this.apiCityService.getUserCities(payload.params).pipe(
          map(userCities => ({
            type: eCityActions.CITY_USER_GET_SUCCESS,
            userCities,
          })),
          catchError((error: HttpErrorResponse) => {
            return of({ type: eCityActions.CITY_USER_GET_FAILURE, error });
          }),
        ),
      ),
    ),
  );
}
