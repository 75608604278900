import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rubCurrency',
})
export class RubCurrencyPipe implements PipeTransform {
  public transform(value: number | string): string {
    if (value) {
      const num = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1 `);
      return `${num} ₽`;
    }
    return '₽';
  }
}
