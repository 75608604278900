import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { ButtonModule } from '../button/button.module';
import { PaginatorComponent } from './component/paginator/paginator.component';

@NgModule({
  declarations: [PaginatorComponent],
  imports: [CommonModule, IconSpriteModule, ButtonModule],
  exports: [PaginatorComponent],
})
export class PaginatorModule {}
