import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ITeam, ITeamApiParams } from '../../models/team.model';

export enum eTeamActions {
  TEAM_GET = '[Admin: Team] get team',
  TEAM_GET_SUCCESS = '[Admin: Team] get team success',
  TEAM_GET_FAILURE = '[Admin: Team] get team failure',

  TEAM_REQUESTS_GET = '[Admin: Team] get team requests',
  TEAM_REQUESTS_GET_SUCCESS = '[Admin: Team] get team requests success',
  TEAM_REQUESTS_GET_FAILURE = '[Admin: Team] get team requests failure',

  TEAM_APPROVE_REQUEST = '[Admin: Team] approve request',
  TEAM_APPROVE_REQUEST_SUCCESS = '[Admin: Team] approve request success',
  TEAM_APPROVE_REQUEST_FAILURE = '[Admin: Team] approve request failure',

  TEAM_REJECT_REQUEST = '[Admin: Team] reject request',
  TEAM_REJECT_REQUEST_SUCCESS = '[Admin: Team] reject request success',
  TEAM_REJECT_REQUEST_FAILURE = '[Admin: Team] reject request failure',

  TEAM_DELETE_MEMBER = '[Admin: Team] delete member',
  TEAM_DELETE_MEMBER_SUCCESS = '[Admin: Team] delete member success',
  TEAM_DELETE_MEMBER_FAILURE = '[Admin: Team] delete member failure',

  TEAM_RESET = '[Admin: Team] reset team',
  TEAM_REQUESTS_RESET = '[Admin: Team] reset team requests',
  TEAM_RESET_STATE = '[Admin: Team] reset team state',
}

export const getTeam = createAction(eTeamActions.TEAM_GET, props<{ params: ITeamApiParams }>());
export const getTeamSuccess = createAction(eTeamActions.TEAM_GET_SUCCESS, props<{ team: ITeam }>());
export const getTeamFailure = createAction(eTeamActions.TEAM_GET_FAILURE, props<{ error: HttpErrorResponse }>());

export const getTeamRequests = createAction(eTeamActions.TEAM_REQUESTS_GET, props<{ params: ITeamApiParams }>());
export const getTeamRequestsSuccess = createAction(eTeamActions.TEAM_REQUESTS_GET_SUCCESS, props<{ teamRequests: ITeam }>());
export const getTeamRequestsFailure = createAction(eTeamActions.TEAM_REQUESTS_GET_FAILURE, props<{ error: HttpErrorResponse }>());

export const approveTeamRequest = createAction(
  eTeamActions.TEAM_APPROVE_REQUEST,
  props<{ id: string; params: { teamApiParams: ITeamApiParams; teamRequestsApiParams: ITeamApiParams } }>(),
);
export const approveTeamRequestSuccess = createAction(eTeamActions.TEAM_APPROVE_REQUEST_SUCCESS);
export const approveTeamRequestFailure = createAction(eTeamActions.TEAM_APPROVE_REQUEST_FAILURE, props<{ error: HttpErrorResponse }>());

export const rejectTeamRequest = createAction(eTeamActions.TEAM_REJECT_REQUEST, props<{ id: string; params: ITeamApiParams }>());
export const rejectTeamRequestSuccess = createAction(eTeamActions.TEAM_REJECT_REQUEST_SUCCESS);
export const rejectTeamRequestFailure = createAction(eTeamActions.TEAM_REJECT_REQUEST_FAILURE, props<{ error: HttpErrorResponse }>());

export const deleteTeamMember = createAction(eTeamActions.TEAM_DELETE_MEMBER, props<{ id: string; params: ITeamApiParams }>());
export const deleteTeamMemberSuccess = createAction(eTeamActions.TEAM_DELETE_MEMBER_SUCCESS);
export const deleteTeamMemberFailure = createAction(eTeamActions.TEAM_DELETE_MEMBER_FAILURE, props<{ error: HttpErrorResponse }>());

export const resetTeam = createAction(eTeamActions.TEAM_RESET);
export const resetTeamRequests = createAction(eTeamActions.TEAM_REQUESTS_RESET);
export const resetTeamState = createAction(eTeamActions.TEAM_RESET_STATE);
