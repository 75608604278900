<label class="switch-container">
     <div *ngIf="label"
          class="switch__label">
          {{label}}
     </div>
     <div class="switch"
          [class.switch--on]="isSwitchOn"
          tabindex="0">
          <input type="checkbox" 
                 class="switch__value"  
                 [checked]="isSwitchOn"
                 [attr.aria-label]="label" 
                 [value]="isSwitchOn"
                 (click)="onToggleSwitch()">
     </div>
</label>



   