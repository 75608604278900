import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_CONFIG, IApiModel } from '../models/api.model';
import { IApiParams } from '../models/http.model';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorsService } from './http-errors.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private readonly apiURL: string;

  constructor(@Inject(API_CONFIG) config: IApiModel, private httpClient: HttpClient, private httpErrorsService: HttpErrorsService) {
    this.apiURL = config.BASE_API_URL + config.BASE_API_PREFIX;
  }

  public get apiUrl(): string {
    return this.apiURL;
  }

  public getHeaders(multipart: boolean = false): HttpHeaders {
    const httpHeaders: HttpHeaders = new HttpHeaders();
    const contentType: string = multipart ? 'multipart/form-data' : 'application/json';

    httpHeaders.set('Content-Type', contentType);
    return httpHeaders;
  }

  public httpRequest<T>(
    method: 'get' | 'post' | 'put' | 'delete',
    url: string,
    data?: object,
    multipart?: boolean,
    query?: HttpParams,
  ): Observable<T> {
    const params: IApiParams = {
      path: url,
      data,
      query,
    };
    return this.httpClient
      .request<T>(method, `${this.apiURL}/${params.path}`, {
        body: params.data,
        headers: this.getHeaders(multipart),
        params: params.query,
      })
      .pipe(
        tap(res => res),
        catchError<T, Observable<T>>(err =>
          this.httpErrorsService.handleError(`${method.toUpperCase()} error on path ${params.path}`, err),
        ),
      );
  }
}
