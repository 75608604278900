import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractApiMethodsService } from '../abstract-api-methods.service';
import { IListingAdvertsApiParams, IListingApiParams, IListingBookingsApiParams, IListing } from '../../models/listing.model';

@Injectable()
export class ApiListingService extends AbstractApiMethodsService {
  public getUsersListing(params: IListingApiParams): Observable<IListing | null> {
    return this.httpPost(params, 'adm/listing/user');
  }

  public getOrganizationsListing(params: IListingApiParams): Observable<IListing | null> {
    return this.httpPost(params, 'adm/listing/organization');
  }

  public getWarehousesListing(params: IListingApiParams): Observable<IListing | null> {
    return this.httpPost(params, 'adm/listing/warehouse');
  }

  // TODO вероятно необходимо переделать на Зоны хранения
  public getAdvertsListing(params: IListingAdvertsApiParams): Observable<IListing | null> {
    return this.httpPost(params, 'adm/listing/advrt');
  }

  public getBookingsListing(params: IListingBookingsApiParams): Observable<IListing | null> {
    return this.httpPost(params, 'adm/listing/reserve');
  }
}
