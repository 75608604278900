import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { pairwise } from 'rxjs/operators';
import { decomposeTime, getParsedDecomposedTime } from '../../../_core/utils/dates.utils';

export type tInputCounterTypes = 'default' | 'timer';

@Component({
  selector: 'tlp-input-counter',
  templateUrl: './input-counter.component.html',
  styleUrls: ['./input-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputCounterComponent implements OnInit, OnDestroy {
  @Input() public control: AbstractControl | null = null;
  @Input() public inputCounterType: tInputCounterTypes = 'default';
  @Input() public allowNegativeValue: boolean = false;

  public hasErrors: boolean = false;
  public disabled: boolean = false;

  private controlValueSub?: Subscription | null = null;
  private controlStatusSub?: Subscription | null = null;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.disabled = !!this.control?.disabled;

    this.controlValueSub = this.control?.valueChanges.subscribe(() => {
      if (this.disabled && this.control?.status !== 'DISABLED') {
        this.disabled = false;
        this.cdr.markForCheck();
      }
    });

    this.controlStatusSub = this.control?.statusChanges?.pipe(pairwise()).subscribe(([prevStatus, nextStatus]: [string, string]) => {
      if (prevStatus !== nextStatus) {
        this.hasErrors = nextStatus === 'INVALID';
        this.disabled = nextStatus === 'DISABLED';
        this.cdr.markForCheck();
      }
    });
  }

  public ngOnDestroy(): void {
    this.controlValueSub?.unsubscribe();
    this.controlStatusSub?.unsubscribe();
  }

  public changeAmount(inc: boolean): void {
    if (!this.control || this.disabled) {
      return;
    }

    switch (this.inputCounterType) {
      case 'default':
        let value: number = Number(this.control.value);
        value = inc ? value + 1 : value - 1;
        this.control.setValue(!this.allowNegativeValue && value < 0 ? '0' : value.toString());
        break;
      case 'timer':
        const time: string | null = this.getChangedTime(inc);
        if (time) {
          this.control.setValue(time);
        }
        break;
      default:
        return;
    }
  }

  /* tslint:disable: no-magic-numbers */
  private getChangedTime(inc: boolean): string | null {
    const decomposedTime = decomposeTime(this.control?.value);

    let hours: number = getParsedDecomposedTime(decomposedTime);
    const minutes: number = (getParsedDecomposedTime(decomposedTime, false) + (inc ? 1 : -1)) % 60;

    if (minutes < 0) {
      hours = hours ? (hours - 1) % 24 : 0;
    } else if (!minutes && inc) {
      hours = (hours + 1) % 24;
    }

    return `${this.prependSingleDigits(hours)}:${minutes < 0 ? 59 : this.prependSingleDigits(minutes)}`;
  }
  /* tslint:enable: no-magic-numbers */

  private prependSingleDigits(value: number): string {
    return (value < 10 ? '0' : '') + value;
  }
}
