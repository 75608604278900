import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IWarehousesCatalog, IWarehousesCatalogApiParams, IWarehousesCatalogItem } from '../../models/warehouses-catalog.model';

export enum eWarehousesCatalogActions {
  WAREHOUSES_CATALOG_GET = '[Warehouses catalog] get advert board',
  WAREHOUSES_CATALOG_GET_SUCCESS = '[Warehouses catalog] get advert board success',
  WAREHOUSES_CATALOG_GET_FAILURE = '[Warehouses catalog] get advert board failure',

  WAREHOUSES_CATALOG_ITEM_GET = '[Warehouses catalog] get advert board item details',
  WAREHOUSES_CATALOG_ITEM_GET_SUCCESS = '[Warehouses catalog] get advert board item details success',
  WAREHOUSES_CATALOG_ITEM_GET_FAILURE = '[Warehouses catalog] get advert board item details failure',

  WAREHOUSES_CATALOG_RESET = '[Warehouses catalog] reset advert board',
  WAREHOUSES_CATALOG_ITEM_RESET = '[Warehouses catalog] reset advert board item',
  WAREHOUSES_CATALOG_STATE_RESET = '[Warehouses catalog] reset advert board state',
}

export const getWarehousesCatalog = createAction(
  eWarehousesCatalogActions.WAREHOUSES_CATALOG_GET,
  props<{ params: IWarehousesCatalogApiParams }>(),
);
export const getWarehousesCatalogSuccess = createAction(
  eWarehousesCatalogActions.WAREHOUSES_CATALOG_GET_SUCCESS,
  props<{ warehousesCatalog: IWarehousesCatalog }>(),
);
export const getWarehousesCatalogFailure = createAction(
  eWarehousesCatalogActions.WAREHOUSES_CATALOG_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getWarehousesCatalogItem = createAction(eWarehousesCatalogActions.WAREHOUSES_CATALOG_ITEM_GET, props<{ id: string }>());
export const getWarehousesCatalogItemSuccess = createAction(
  eWarehousesCatalogActions.WAREHOUSES_CATALOG_ITEM_GET_SUCCESS,
  props<{ warehousesCatalogItem: IWarehousesCatalogItem }>(),
);
export const getWarehousesCatalogItemFailure = createAction(
  eWarehousesCatalogActions.WAREHOUSES_CATALOG_ITEM_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const resetWarehousesCatalog = createAction(eWarehousesCatalogActions.WAREHOUSES_CATALOG_RESET);
export const resetWarehousesCatalogItem = createAction(eWarehousesCatalogActions.WAREHOUSES_CATALOG_ITEM_RESET);
export const resetWarehousesCatalogState = createAction(eWarehousesCatalogActions.WAREHOUSES_CATALOG_STATE_RESET);
