import { IApiPagination } from '../models/pagination.model';

export const PAGINATOR_MIN_SIZE: number = 20;
export const PAGINATOR_MID_SIZE: number = 40;
export const PAGINATOR_MAX_SIZE: number = 60;

export const INITIAL_PAGINATION: IApiPagination = {
  limit: 20,
  offset: 0,
};
