import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './component/button/button.component';

import { IconSpriteModule } from 'ng-svg-icon-sprite';

@NgModule({
  declarations: [ButtonComponent],
  imports: [CommonModule, IconSpriteModule],
  exports: [ButtonComponent],
})
export class ButtonModule {}
