import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ForgotEffects } from './forgot.effects';
import { ForgotReducer } from './forgot.reducer';
import { ApiForgotService } from '../../api/api-forgot.service';
import { ForgotStoreService } from './forgot-store.service';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('forgot', ForgotReducer), EffectsModule.forFeature([ForgotEffects])],
  providers: [ApiForgotService, ForgotStoreService],
})
export class ForgotStoreModule {}
