import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input, HostListener, ElementRef, ViewChild } from '@angular/core';
import { objectsEqual } from '../../../../_core/utils/compare.utils';

@Component({
  selector: 'tlp-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  // tslint:disable-next-line: prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DrawerComponent {
  @ViewChild('contentWrapper') public contentWrapper!: ElementRef;

  @Input() public containerSelector!: string;
  @Input() public drawerWidth: number = 660;
  @Output() public drawerOpened: EventEmitter<void> = new EventEmitter<void>();
  @Output() public drawerClosed: EventEmitter<void> = new EventEmitter<void>();

  public isOpen: boolean = false;

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    if (objectsEqual(event.target, this.contentWrapper.nativeElement)) {
      this.close();
    }
  }

  public open(): void {
    this.isOpen = true;
    this.drawerOpened.emit();
  }

  public close(): void {
    this.isOpen = false;
    this.drawerClosed.emit();
  }
}
