import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { RegisterReducer } from './register.reducer';
import { RegisterEffects } from './register.effects';
import { ApiRegisterService } from '../../api/api-register.service';
import { RegisterStoreService } from './register-store.service';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('register', RegisterReducer), EffectsModule.forFeature([RegisterEffects])],
  providers: [ApiRegisterService, RegisterStoreService],
})
export class RegisterStoreModule {}
