import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ButtonModule } from '../_ui/button/button.module';
import { ContentBlockModule } from '../_ui/content-block/content-block.module';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { LoadingModule } from '../_ui/directives/loading/loading.module';
import { NavigationListModule } from '../navigation-list/navigation-list.module';

import { HomeHeaderComponent } from './component/home-header/home-header.component';
import { HomeMenuComponent } from './component/home-menu/home-menu.component';

@NgModule({
  declarations: [HomeHeaderComponent, HomeMenuComponent],
  imports: [CommonModule, RouterModule, ButtonModule, ContentBlockModule, IconSpriteModule, LoadingModule, NavigationListModule],
  exports: [HomeHeaderComponent, HomeMenuComponent],
})
export class HomeHeaderModule {}
