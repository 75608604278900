import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PopupComponent } from '../../popup/component/popup/popup.component';

export type confirmPopupContentPositions = 'center' | 'left' | 'right';

@Component({
  selector: 'tlp-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss'],
  // tslint:disable-next-line:prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ConfirmPopupComponent {
  @Input() public title: string = 'Совершить операцию?';
  @Input() public subtitle!: string;
  @Input() public description!: string;
  @Input() public buttonsPosition: confirmPopupContentPositions = 'left';
  @Input() public titlePosition: confirmPopupContentPositions = 'left';
  @Input() public confirmButtonTitle: string = 'Да, совершить';
  @Input() public cancelButtonTitle: string = 'Нет';
  @Input() public containerSelector!: string;
  @Input() public isNestedInDrawer: boolean = false;
  @Input() public size: number = 350;

  @ViewChild('popup') private popupRef!: PopupComponent;

  @Output() public confirm: EventEmitter<void> = new EventEmitter<void>();
  @Output() public cancel: EventEmitter<void> = new EventEmitter<void>();

  public onConfirm(): void {
    this.confirm.emit();
  }

  public onCancel(): void {
    this.cancel.emit();
  }

  public open(): void {
    this.popupRef.open();
  }

  public close(): void {
    this.popupRef.close();
  }
}
