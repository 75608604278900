import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export interface ISwitchTabsItem<T = string> {
  title: string;
  value: T;
  hidden?: boolean;
}

export type switchTabsActiveItems = string[];

@Component({
  selector: 'tlp-switch-tabs',
  templateUrl: './switch-tabs.component.html',
  styleUrls: ['./switch-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchTabsComponent {
  @Input() public items: ISwitchTabsItem[] = [];
  @Input() public activeItems: switchTabsActiveItems = [];
  @Input() public theme: 'primary' | 'secondary' | 'secondary-dark' = 'primary';

  @Output() public changeTab: EventEmitter<string> = new EventEmitter<string>();

  public onTabClick(item: ISwitchTabsItem): void {
    this.changeTab.emit(item.value);
  }
}
