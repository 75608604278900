import { IHttpRequestState } from '../../models/http.model';
import { IOrganization, IOrganizationDocuments, IOrganizationInfo, IOrganizations } from '../../models/organization.model';

export interface IOrganizationStoreState {
  organizations: IOrganizations | null;
  organization: IOrganization | null;
  createdOrganizationId: string | null;
  organizationGeneral: IOrganizationInfo | null;
  organizationDocuments: IOrganizationDocuments | null;
  organizationsLoadingState: IHttpRequestState | null;
  organizationLoadingState: IHttpRequestState | null;
  organizationPublishingState: IHttpRequestState | null;
  organizationUpdatingState: IHttpRequestState | null;
}

export const InitOrganizationState: IOrganizationStoreState = {
  organizations: null,
  organization: null,
  createdOrganizationId: null,
  organizationGeneral: null,
  organizationDocuments: null,
  organizationsLoadingState: null,
  organizationLoadingState: null,
  organizationPublishingState: null,
  organizationUpdatingState: null,
};
