import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LoginEffects } from './login.effects';
import { LoginReducer } from './login.reducer';
import { ApiLoginService } from '../../api/api-login.service';
import { LoginStoreService } from './login-store.service';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('login', LoginReducer), EffectsModule.forFeature([LoginEffects])],
  providers: [ApiLoginService, LoginStoreService],
})
export class LoginStoreModule {}
