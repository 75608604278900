import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IAuth, IRegisterUser } from '../../models/auth.model';

export enum eRegisterActions {
  REGISTER = '[Register] register action',
  REGISTER_SUCCESS = '[Register] register success',
  REGISTER_FAILURE = '[Register] register failure',

  REGISTER_CONFIRM = '[Register] confirm register',
  REGISTER_CONFIRM_SUCCESS = '[Register] confirm register success',
  REGISTER_CONFIRM_FAILURE = '[Register] confirm register failure',

  REGISTER_SEND_CONFIRM_EMAIL = '[Register] send register confirm email',
  REGISTER_SEND_CONFIRM_EMAIL_SUCCESS = '[Register] send register confirm emai success',
  REGISTER_SEND_CONFIRM_EMAIL_FAILURE = '[Register] send register confirm emai failure',

  REGISTER_STATE_RESET = '[Register] register state reset',
}

export const register = createAction(eRegisterActions.REGISTER, props<{ user: IRegisterUser }>());
export const registerSuccess = createAction(eRegisterActions.REGISTER_SUCCESS);
export const registerFailure = createAction(eRegisterActions.REGISTER_FAILURE, props<{ error: HttpErrorResponse }>());

export const registerConfirm = createAction(eRegisterActions.REGISTER_CONFIRM, props<{ token: string }>());
export const registerConfirmSuccess = createAction(eRegisterActions.REGISTER_CONFIRM_SUCCESS, props<{ authData: IAuth }>());
export const registerConfirmFailure = createAction(eRegisterActions.REGISTER_CONFIRM_FAILURE, props<{ error: HttpErrorResponse }>());

export const sendRegisterConfirmEmail = createAction(eRegisterActions.REGISTER_SEND_CONFIRM_EMAIL, props<{ email: string }>());
export const sendRegisterConfirmEmailSuccess = createAction(eRegisterActions.REGISTER_SEND_CONFIRM_EMAIL_SUCCESS);
export const sendRegisterConfirmEmailFailure = createAction(
  eRegisterActions.REGISTER_SEND_CONFIRM_EMAIL_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const resetRegisterState = createAction(eRegisterActions.REGISTER_STATE_RESET);
