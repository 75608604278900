<tlp-content-block (click)="toggleItemDetails(true)">
  <div class="card">
    <div class="card__main"
         [style.grid-template-columns]="gridTemplateColumns || 'repeat(auto-fit, minmax(90px, 1fr))'"
    >
      <div class="card__field" *ngFor="let field of item.fields">
        <ng-container *ngIf="field.images && field.images[0]; else noPhoto">
          <div class="card__image-container card__image-container--small">
            <img class="card__image"
                 [src]="field.images[0].file.path"
                 [alt]="field.images[0].file.name">
          </div>
        </ng-container>
        <ng-template #noPhoto>
          <div *ngIf="field.emptyImageIcon" class="card__image-container card__image-container--small card__image-container--empty">
            <svg-icon-sprite [src]="'./assets/sprites/sprite.svg#ic-' + field.emptyImageIcon" [width]="'20px'" [height]="'20px'"></svg-icon-sprite>
          </div>
        </ng-template>

        <h3 class="card__field-heading">{{ field.heading }}</h3>
        <h4 class="card__field-subheading" *ngIf="field.subheading">{{ field.subheading }}</h4>
        <p class="card__field-text" *ngIf="field.text">{{ field.text }}</p>
      </div>
      <div class="card__field card__field--status" *ngIf="item.status?.length">
        <tlp-status-note
            class="card__status"
            *ngFor="let statusItem of item.status"
            [statusName]="statusItem?.system_name"
            [text]="statusItem?.title || statusItem?.title_short"
        ></tlp-status-note>
      </div>
      <div class="card__field card__field--button" *ngIf="toggleItemContentButton">
        <tlp-button
          theme="link"
          [iconRight]="isCollapsed ? 'down' : 'up'"
          (click)="$event.stopPropagation(); handleItemContentToggle()"
        >{{ toggleItemContentButton }}</tlp-button>
      </div>
      <div class="card__field card__field--actions" *ngIf="item.actions && item.actions.length">
        <ng-container *ngIf="item.actions[item.actions.length - 1].type !== 'whitespace'; else whitespace">
          <span class="cell-divider"></span>
          <div class="card__button-item" *ngFor="let action of item.actions">
            <tlp-button
              theme="link"
              [isIconButton]="true"
              [disabled]="action.disabled || false"
              [tlpTooltip]="action.tooltip || null"
              (click)="$event.stopPropagation(); action.callback(item.id)"
            >
              <svg-icon-sprite [src]="'./assets/sprites/sprite.svg#ic-' + action.type" class="card__button-icon" [width]="'16px'" [height]="'16px'"></svg-icon-sprite>
              <span class="card__button-text" *ngIf="action.name">{{ action.name }}</span>
            </tlp-button>
          </div>
        </ng-container>
        <ng-template #whitespace>&nbsp;</ng-template>
      </div>
    </div>

    <div class="card__details" *ngIf="!isCollapsed && toggleItemContentButton" (click)="$event.stopPropagation()">
      <ng-content></ng-content>
    </div>

    <ng-container *ngIf="!isCollapsed && item.collapsedFields?.length">
      <div class="card__details" *ngFor="let collapsedField of item.collapsedFields">
        <p class="card__details-text">
          <b *ngIf="collapsedField.heading">{{ collapsedField.heading }}</b>
          <span [innerHTML]="collapsedField.text"></span>
        </p>
      </div>
    </ng-container>

    <div class="card__expand-button" *ngIf="item.collapsedFields?.length && !toggleItemContentButton">
      <tlp-button
          theme="link"
          [iconRight]="isCollapsed ? 'down' : 'up'"
          (click)="$event.stopPropagation(); toggleItemDetails()"
      >{{ isCollapsed ? 'Подробнее' : 'Скрыть'}}</tlp-button>
    </div>
  </div>
</tlp-content-block>
