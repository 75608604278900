<div class="radio-buttons"
    *ngIf="(items || (delegate?.getResult$ | async) ) as radios"
    [ngClass]="['radio-buttons--' + theme]"
    [class.radio-buttons--disabled]="!selectable"
>
  <div class="radio-buttons__item radio-item"
       *ngFor="let radio of radios"
       [class.radio-item--active]="radio.value === value"
       [tlpTooltip]="infoTip"
       [tooltipPlacement]="infoTipPlacement"
       (click)="selectItem(radio)"
  >
    <div class="radio-item__checkmark"></div>
    <span>{{ radio.name }}</span>
  </div>
</div>
