import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { WarehousesCatalogEffects } from './warehouses-catalog.effects';
import { WarehousesCatalogReducer } from './warehouses-catalog.reducer';
import { ApiWarehousesCatalogService } from '../../api/api-warehouses-catalog.service';
import { WarehousesCatalogStoreService } from './warehouses-catalog-store.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('warehouses-catalog', WarehousesCatalogReducer),
    EffectsModule.forFeature([WarehousesCatalogEffects]),
  ],
  providers: [ApiWarehousesCatalogService, WarehousesCatalogStoreService],
})
export class WarehousesCatalogStoreModule {}
