import {
  Component,
  forwardRef,
  Input,
  ChangeDetectionStrategy,
  OnInit,
  ViewEncapsulation,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, ValidationErrors, FormControl } from '@angular/forms';
import { InputMask } from '../../../_core/const/input-mask.const';
import { ISelectableItem } from '../../delegate/model/delegate.model';
import { COUNTRY_PHONE_CODES, DEFAULT_COUNTRY_CODE, DEFAULT_COUNTRY_PHONE_CODE } from 'src/app/_core/const/country-codes.const';

@Component({
  selector: 'tlp-input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPhoneComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputPhoneComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class InputPhoneComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {
  @Input() public setCountryCode: string | undefined = undefined;
  @Input() public placeholder: string = '';
  @Input() public required: boolean = false;
  @Input() public disabled: boolean = false;

  public formControl!: FormControl | null;
  public isDisabled: boolean = false;
  public mask!: string;
  public countryCode!: string;
  public availableCountryCodes: ISelectableItem[];

  constructor(private cdr: ChangeDetectorRef) {
    this.availableCountryCodes = COUNTRY_PHONE_CODES.map(countryCode => ({
      name: `+${countryCode.code}`,
      value: countryCode.code,
      icon: countryCode.icon,
    }));
    this.availableCountryCodes.sort((firstValue, secondValue) => secondValue.value.toString().length - firstValue.value.toString().length);
    this.mask = DEFAULT_COUNTRY_PHONE_CODE?.mask || '0*';
    this.countryCode = DEFAULT_COUNTRY_PHONE_CODE?.code || DEFAULT_COUNTRY_CODE;
  }

  public ngOnInit(): void {
    if (this.setCountryCode !== undefined) {
      this.countryCode = this.setCountryCode;
      this.mask = this.getMask(this.countryCode);
    }
    this.formControl = new FormControl();
  }

  public ngOnDestroy(): void {
    this.formControl = null;
  }

  private onChange = (_: number | string) => {};
  private onTouched = () => {};

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  public writeValue(outsideValue: number | string): void {
    let value = outsideValue ? outsideValue.toString() : '';
    if (value) {
      const countryCode = COUNTRY_PHONE_CODES.find(code => outsideValue.toString().match(new RegExp(`^${code.code}`)));
      if (countryCode) {
        value = value.replace(countryCode.code, '');
        this.countryCode = countryCode.code;
        this.mask = countryCode.mask || InputMask.phone;
      }
    }

    this.formControl?.setValue(value, { emitEvent: false });
    this.cdr.detectChanges();

    this.onTouched();
    this.onChange(outsideValue);
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.formControl?.disable();
      this.isDisabled = true;
    } else {
      this.formControl?.enable();
      this.isDisabled = false;
    }

    this.cdr.detectChanges();
  }

  public onValueChange(event: KeyboardEvent | FocusEvent): void {
    const value = (event.target as HTMLInputElement).value;
    this.onTouched();
    this.onChange(this.countryCode + value);
  }

  public validate(): ValidationErrors | null {
    if (!this.formControl?.value && this.required) {
      return {
        required: true,
      };
    }
    if (this.countryCode === '' && this.required) {
      return {
        invalidCountryCode: true,
      };
    }
    if (this.formControl?.errors?.mask) {
      return {
        invalidPhoneFormat: true,
      };
    }
    return null;
  }

  public selectCountryCode(countryCode: Event): void {
    if (!countryCode) {
      return;
    }

    if (countryCode.toString() !== '' && countryCode.toString() !== this.countryCode) {
      this.countryCode = countryCode.toString();
      this.mask = this.getMask(this.countryCode);
      this.formControl?.setValue('', { emitEvent: false });
      this.cdr.detectChanges();

      this.onChange(this.countryCode + this.formControl?.value);
    }
  }

  private getMask(countryCode: string): string {
    return COUNTRY_PHONE_CODES.find(code => countryCode === code.code)?.mask || InputMask.phone;
  }
}
