import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmPopupComponent } from './component/confirm-popup.component';
import { ButtonModule } from '../button/button.module';
import { PopupModule } from '../popup/popup.module';

@NgModule({
  declarations: [ConfirmPopupComponent],
  imports: [CommonModule, ButtonModule, PopupModule],
  exports: [ConfirmPopupComponent],
})
export class ConfirmPopupModule {}
