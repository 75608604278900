import { INavLink } from '../models/navigation.model';

export const adminNavLinks: INavLink[] = [
  { title: 'Компании', icon: 'ic-company', href: '/profile/admin/organizations' },
  { title: 'Пользователи', icon: 'ic-users', href: '/profile/admin/users' },
  { title: 'Склады', icon: 'ic-warehouse', href: '/profile/admin/warehouses' },
  { title: 'Брони', icon: 'ic-reservations', href: '/profile/admin/booking' },
  { title: 'Логи', icon: 'ic-list', href: '/profile/admin/logs' },
];
export const sbNavLinks: INavLink[] = [
  { title: 'Компании', icon: 'ic-company', href: '/profile/admin/organizations' },
  { title: 'Пользователи', icon: 'ic-users', href: '/profile/admin/users' },
  { title: 'Склады', icon: 'ic-warehouse', href: '/profile/admin/warehouses' },
  { title: 'Брони', icon: 'ic-reservations', href: '/profile/admin/booking' },
  { title: 'Логи', icon: 'ic-list', href: '/profile/admin/logs' },
];
export const dslNavLinks: INavLink[] = [
  { title: 'Компании', icon: 'ic-company', href: '/profile/admin/organizations' },
  { title: 'Пользователи', icon: 'ic-users', href: '/profile/admin/users' },
  { title: 'Склады', icon: 'ic-warehouse', href: '/profile/admin/warehouses' },
  { title: 'Брони', icon: 'ic-reservations', href: '/profile/admin/booking' },
  { title: 'Логи', icon: 'ic-list', href: '/profile/admin/logs' },
];
export const csNavLinks: INavLink[] = [
  { title: 'Компании', icon: 'ic-company', href: '/profile/admin/organizations' },
  { title: 'Пользователи', icon: 'ic-users', href: '/profile/admin/users' },
  { title: 'Склады', icon: 'ic-warehouse', href: '/profile/admin/warehouses' },
  { title: 'Брони', icon: 'ic-reservations', href: '/profile/admin/booking' },
  { title: 'Логи', icon: 'ic-list', href: '/profile/admin/logs' },
];

export const holderNavLinks: INavLink[] = [
  { title: 'Склады', icon: 'ic-warehouse', href: '/profile/warehouses' },
  { title: 'Брони', icon: 'ic-reservations', href: '/profile/booking' },
];
export const renterNavLinks: INavLink[] = [];
