import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { IDictionaryApiParams } from '../../models/dictionary.model';
import { IDictionaryItems } from '../../models/dictionary.model';

export enum eDictionaryActions {
  DICTIONARY_ACCOUNTING_CONTROL_SYSTEMS_GET = '[Dictionary] get accounting control systems dictionary',
  DICTIONARY_ACCOUNTING_CONTROL_SYSTEMS_GET_SUCCESS = '[Dictionary] get accounting control systems dictionary success',
  DICTIONARY_ACCOUNTING_CONTROL_SYSTEMS_GET_FAILURE = '[Dictionary] get accounting control systems dictionary failure',

  DICTIONARY_ACCOUNTING_SYSTEM_TYPE_GET = '[Dictionary] get accounting system type dictionary',
  DICTIONARY_ACCOUNTING_SYSTEM_TYPE_GET_SUCCESS = '[Dictionary] get accounting system type dictionary success',
  DICTIONARY_ACCOUNTING_SYSTEM_TYPE_GET_FAILURE = '[Dictionary] get accounting system type dictionary failure',

  DICTIONARY_CLASSIFICATION_GET = '[Dictionary] get classification dictionary',
  DICTIONARY_CLASSIFICATION_GET_SUCCESS = '[Dictionary] get classification dictionary success',
  DICTIONARY_CLASSIFICATION_GET_FAILURE = '[Dictionary] get classification dictionary failure',

  DICTIONARY_CRANAGE_TYPES_GET = '[Dictionary] get cranage types dictionary',
  DICTIONARY_CRANAGE_TYPES_GET_SUCCESS = '[Dictionary] get cranage types dictionary success',
  DICTIONARY_CRANAGE_TYPES_GET_FAILURE = '[Dictionary] get cranage types dictionary failure',

  DICTIONARY_ELECTRICITY_GRID_TYPE_GET = '[Dictionary] get electricity grid type dictionary',
  DICTIONARY_ELECTRICITY_GRID_TYPE_GET_SUCCESS = '[Dictionary] get electricity grid type dictionary success',
  DICTIONARY_ELECTRICITY_GRID_TYPE_GET_FAILURE = '[Dictionary] get electricity grid type dictionary failure',

  DICTIONARY_FIRE_EXT_SYSTEM_TYPE_GET = '[Dictionary] get fire ext system type dictionary',
  DICTIONARY_FIRE_EXT_SYSTEM_TYPE_GET_SUCCESS = '[Dictionary] get fire ext system type dictionary success',
  DICTIONARY_FIRE_EXT_SYSTEM_TYPE_GET_FAILURE = '[Dictionary] get fire ext system type dictionary failure',

  DICTIONARY_FLOOR_COVERING_GET = '[Dictionary] get floor covering dictionary',
  DICTIONARY_FLOOR_COVERING_GET_SUCCESS = '[Dictionary] get floor covering dictionary success',
  DICTIONARY_FLOOR_COVERING_GET_FAILURE = '[Dictionary] get floor covering dictionary failure',

  DICTIONARY_HEATING_SYSTEM_TYPE_GET = '[Dictionary] get heating system type dictionary',
  DICTIONARY_HEATING_SYSTEM_TYPE_GET_SUCCESS = '[Dictionary] get heating system type dictionary success',
  DICTIONARY_HEATING_SYSTEM_TYPE_GET_FAILURE = '[Dictionary] get heating system type dictionary failure',

  DICTIONARY_LOADING_UNLOADING_METHODS_GET = '[Dictionary] get loading unloading methods dictionary',
  DICTIONARY_LOADING_UNLOADING_METHODS_GET_SUCCESS = '[Dictionary] get loading unloading methods dictionary success',
  DICTIONARY_LOADING_UNLOADING_METHODS_GET_FAILURE = '[Dictionary] get loading unloading methods dictionary failure',

  DICTIONARY_LOADING_UNLOADING_STRUCT_TYPES_GET = '[Dictionary] get loading unloading struct types dictionary',
  DICTIONARY_LOADING_UNLOADING_STRUCT_TYPES_GET_SUCCESS = '[Dictionary] get loading unloading struct types dictionary success',
  DICTIONARY_LOADING_UNLOADING_STRUCT_TYPES_GET_FAILURE = '[Dictionary] get loading unloading struct types dictionary failure',

  DICTIONARY_LOADING_UNLOADING_TECH_TYPES_GET = '[Dictionary] get loading unloading tech types dictionary',
  DICTIONARY_LOADING_UNLOADING_TECH_TYPES_GET_SUCCESS = '[Dictionary] get loading unloading tech types dictionary success',
  DICTIONARY_LOADING_UNLOADING_TECH_TYPES_GET_FAILURE = '[Dictionary] get loading unloading tech types dictionary failure',

  DICTIONARY_OFFICE_FACILITY_TYPES_GET = '[Dictionary] get office facility types dictionary',
  DICTIONARY_OFFICE_FACILITY_TYPES_GET_SUCCESS = '[Dictionary] get office facility types dictionary success',
  DICTIONARY_OFFICE_FACILITY_TYPES_GET_FAILURE = '[Dictionary] get office facility types dictionary failure',

  DICTIONARY_PARKING_TYPE_GET = '[Dictionary] get parking type dictionary',
  DICTIONARY_PARKING_TYPE_GET_SUCCESS = '[Dictionary] get parking type dictionary success',
  DICTIONARY_PARKING_TYPE_GET_FAILURE = '[Dictionary] get parking type dictionary failure',

  DICTIONARY_STORAGE_FORMAT_GET = '[Dictionary] get storage format dictionary',
  DICTIONARY_STORAGE_FORMAT_GET_SUCCESS = '[Dictionary] get storage format dictionary success',
  DICTIONARY_STORAGE_FORMAT_GET_FAILURE = '[Dictionary] get storage format dictionary failure',

  DICTIONARY_STORAGE_SYSTEM_GET = '[Dictionary] get storage system dictionary',
  DICTIONARY_STORAGE_SYSTEM_GET_SUCCESS = '[Dictionary] get storage system dictionary success',
  DICTIONARY_STORAGE_SYSTEM_GET_FAILURE = '[Dictionary] get storage system dictionary failure',

  DICTIONARY_STORAGE_SYSTEM_PUBLIC_GET = '[Dictionary] get storage system public dictionary',
  DICTIONARY_STORAGE_SYSTEM_PUBLIC_GET_SUCCESS = '[Dictionary] get storage system public dictionary success',
  DICTIONARY_STORAGE_SYSTEM_PUBLIC_GET_FAILURE = '[Dictionary] get storage system public dictionary failure',

  DICTIONARY_TAXATION_TYPE_GET = '[Dictionary] get taxation type dictionary',
  DICTIONARY_TAXATION_TYPE_GET_SUCCESS = '[Dictionary] get taxation type dictionary success',
  DICTIONARY_TAXATION_TYPE_GET_FAILURE = '[Dictionary] get taxation type dictionary failure',

  DICTIONARY_TEMPERATURE_MODE_GET = '[Dictionary] get temperature mode dictionary',
  DICTIONARY_TEMPERATURE_MODE_GET_SUCCESS = '[Dictionary] get temperature mode dictionary success',
  DICTIONARY_TEMPERATURE_MODE_GET_FAILURE = '[Dictionary] get temperature mode dictionary failure',

  DICTIONARY_UNIT_GET = '[Dictionary] get unit dictionary',
  DICTIONARY_UNIT_GET_SUCCESS = '[Dictionary] get unit dictionary success',
  DICTIONARY_UNIT_GET_FAILURE = '[Dictionary] get unit dictionary failure',

  DICTIONARY_UNIT_DISTANCE_GET = '[Dictionary] get unit distance dictionary',
  DICTIONARY_UNIT_DISTANCE_GET_SUCCESS = '[Dictionary] get unit distance dictionary success',
  DICTIONARY_UNIT_DISTANCE_GET_FAILURE = '[Dictionary] get unit distance dictionary failure',

  DICTIONARY_UNIT_MASS_GET = '[Dictionary] get unit mass dictionary',
  DICTIONARY_UNIT_MASS_GET_SUCCESS = '[Dictionary] get unit mass dictionary success',
  DICTIONARY_UNIT_MASS_GET_FAILURE = '[Dictionary] get unit mass dictionary failure',

  DICTIONARY_UNIT_SQUARE_GET = '[Dictionary] get unit square dictionary',
  DICTIONARY_UNIT_SQUARE_GET_SUCCESS = '[Dictionary] get unit square dictionary success',
  DICTIONARY_UNIT_SQUARE_GET_FAILURE = '[Dictionary] get unit square dictionary failure',

  DICTIONARY_UNIT_TEMPERATURE_GET = '[Dictionary] get unit temperature dictionary',
  DICTIONARY_UNIT_TEMPERATURE_GET_SUCCESS = '[Dictionary] get unit temperature dictionary success',
  DICTIONARY_UNIT_TEMPERATURE_GET_FAILURE = '[Dictionary] get unit temperature dictionary failure',

  DICTIONARY_STATE_RESET = '[Dictionary] reset dictionary state',
  DICTIONARY_LOADING_STATE_RESET = '[Dictionary] reset dictionary loading state',

  DICTIONARY_LEGAL_TYPE_GET = '[Dictionary] get legal type dictionary',
  DICTIONARY_LEGAL_TYPE_GET_SUCCESS = '[Dictionary] get legal type dictionary success',
  DICTIONARY_LEGAL_TYPE_GET_FAILURE = '[Dictionary] get legal type dictionary failure',

  DICTIONARY_PARTNER_TYPE_GET = '[Dictionary] get partner type dictionary',
  DICTIONARY_PARTNER_TYPE_GET_SUCCESS = '[Dictionary] get partner type dictionary success',
  DICTIONARY_PARTNER_TYPE_GET_FAILURE = '[Dictionary] get partner type dictionary failure',
}

export const getAccountingControlSystemsDictionary = createAction(
  eDictionaryActions.DICTIONARY_ACCOUNTING_CONTROL_SYSTEMS_GET,
  props<{ params: IDictionaryApiParams | undefined }>(),
);
export const getAccountingControlSystemsDictionarySuccess = createAction(
  eDictionaryActions.DICTIONARY_ACCOUNTING_CONTROL_SYSTEMS_GET_SUCCESS,
  props<{ accountingControlSystems: IDictionaryItems }>(),
);
export const getAccountingControlSystemsDictionaryFailure = createAction(
  eDictionaryActions.DICTIONARY_ACCOUNTING_CONTROL_SYSTEMS_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getAccountingSystemTypeDictionary = createAction(
  eDictionaryActions.DICTIONARY_ACCOUNTING_SYSTEM_TYPE_GET,
  props<{ params: IDictionaryApiParams | undefined }>(),
);
export const getAccountingSystemTypeDictionarySuccess = createAction(
  eDictionaryActions.DICTIONARY_ACCOUNTING_SYSTEM_TYPE_GET_SUCCESS,
  props<{ accountingSystemType: IDictionaryItems }>(),
);
export const getAccountingSystemTypeDictionaryFailure = createAction(
  eDictionaryActions.DICTIONARY_ACCOUNTING_SYSTEM_TYPE_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getClassificationDictionary = createAction(
  eDictionaryActions.DICTIONARY_CLASSIFICATION_GET,
  props<{ params: IDictionaryApiParams | undefined }>(),
);
export const getClassificationDictionarySuccess = createAction(
  eDictionaryActions.DICTIONARY_CLASSIFICATION_GET_SUCCESS,
  props<{ classification: IDictionaryItems }>(),
);
export const getClassificationDictionaryFailure = createAction(
  eDictionaryActions.DICTIONARY_CLASSIFICATION_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getCranageTypesDictionary = createAction(
  eDictionaryActions.DICTIONARY_CRANAGE_TYPES_GET,
  props<{ params: IDictionaryApiParams | undefined }>(),
);
export const getCranageTypesDictionarySuccess = createAction(
  eDictionaryActions.DICTIONARY_CRANAGE_TYPES_GET_SUCCESS,
  props<{ cranageTypes: IDictionaryItems }>(),
);
export const getCranageTypesDictionaryFailure = createAction(
  eDictionaryActions.DICTIONARY_CRANAGE_TYPES_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getElectricityGridTypeDictionary = createAction(
  eDictionaryActions.DICTIONARY_ELECTRICITY_GRID_TYPE_GET,
  props<{ params: IDictionaryApiParams | undefined }>(),
);
export const getElectricityGridTypeDictionarySuccess = createAction(
  eDictionaryActions.DICTIONARY_ELECTRICITY_GRID_TYPE_GET_SUCCESS,
  props<{ electricityGridType: IDictionaryItems }>(),
);
export const getElectricityGridTypeDictionaryFailure = createAction(
  eDictionaryActions.DICTIONARY_ELECTRICITY_GRID_TYPE_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getFireExtSystemTypeDictionary = createAction(
  eDictionaryActions.DICTIONARY_FIRE_EXT_SYSTEM_TYPE_GET,
  props<{ params: IDictionaryApiParams | undefined }>(),
);
export const getFireExtSystemTypeDictionarySuccess = createAction(
  eDictionaryActions.DICTIONARY_FIRE_EXT_SYSTEM_TYPE_GET_SUCCESS,
  props<{ fireExtSystemType: IDictionaryItems }>(),
);
export const getFireExtSystemTypeDictionaryFailure = createAction(
  eDictionaryActions.DICTIONARY_FIRE_EXT_SYSTEM_TYPE_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getFloorCoveringDictionary = createAction(
  eDictionaryActions.DICTIONARY_FLOOR_COVERING_GET,
  props<{ params: IDictionaryApiParams | undefined }>(),
);
export const getFloorCoveringDictionarySuccess = createAction(
  eDictionaryActions.DICTIONARY_FLOOR_COVERING_GET_SUCCESS,
  props<{ floorCovering: IDictionaryItems }>(),
);
export const getFloorCoveringDictionaryFailure = createAction(
  eDictionaryActions.DICTIONARY_FLOOR_COVERING_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getHeatingSystemTypeDictionary = createAction(
  eDictionaryActions.DICTIONARY_HEATING_SYSTEM_TYPE_GET,
  props<{ params: IDictionaryApiParams | undefined }>(),
);
export const getHeatingSystemTypeDictionarySuccess = createAction(
  eDictionaryActions.DICTIONARY_HEATING_SYSTEM_TYPE_GET_SUCCESS,
  props<{ heatingSystemType: IDictionaryItems }>(),
);
export const getHeatingSystemTypeDictionaryFailure = createAction(
  eDictionaryActions.DICTIONARY_HEATING_SYSTEM_TYPE_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getLoadingUnloadingMethodsDictionary = createAction(
  eDictionaryActions.DICTIONARY_LOADING_UNLOADING_METHODS_GET,
  props<{ params: IDictionaryApiParams | undefined }>(),
);
export const getLoadingUnloadingMethodsDictionarySuccess = createAction(
  eDictionaryActions.DICTIONARY_LOADING_UNLOADING_METHODS_GET_SUCCESS,
  props<{ loadingUnloadingMethods: IDictionaryItems }>(),
);
export const getLoadingUnloadingMethodsDictionaryFailure = createAction(
  eDictionaryActions.DICTIONARY_LOADING_UNLOADING_METHODS_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getLoadingUnloadingStructTypesDictionary = createAction(
  eDictionaryActions.DICTIONARY_LOADING_UNLOADING_STRUCT_TYPES_GET,
  props<{ params: IDictionaryApiParams | undefined }>(),
);
export const getLoadingUnloadingStructTypesDictionarySuccess = createAction(
  eDictionaryActions.DICTIONARY_LOADING_UNLOADING_STRUCT_TYPES_GET_SUCCESS,
  props<{ loadingUnloadingStructTypes: IDictionaryItems }>(),
);
export const getLoadingUnloadingStructTypesDictionaryFailure = createAction(
  eDictionaryActions.DICTIONARY_LOADING_UNLOADING_STRUCT_TYPES_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getLoadingUnloadingTechTypesDictionary = createAction(
  eDictionaryActions.DICTIONARY_LOADING_UNLOADING_TECH_TYPES_GET,
  props<{ params: IDictionaryApiParams | undefined }>(),
);
export const getLoadingUnloadingTechTypesDictionarySuccess = createAction(
  eDictionaryActions.DICTIONARY_LOADING_UNLOADING_TECH_TYPES_GET_SUCCESS,
  props<{ loadingUnloadingTechTypes: IDictionaryItems }>(),
);
export const getLoadingUnloadingTechTypesDictionaryFailure = createAction(
  eDictionaryActions.DICTIONARY_LOADING_UNLOADING_TECH_TYPES_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getOfficeFacilityTypesDictionary = createAction(
  eDictionaryActions.DICTIONARY_OFFICE_FACILITY_TYPES_GET,
  props<{ params: IDictionaryApiParams | undefined }>(),
);
export const getOfficeFacilityTypesDictionarySuccess = createAction(
  eDictionaryActions.DICTIONARY_OFFICE_FACILITY_TYPES_GET_SUCCESS,
  props<{ officeFacilityTypes: IDictionaryItems }>(),
);
export const getOfficeFacilityTypesDictionaryFailure = createAction(
  eDictionaryActions.DICTIONARY_OFFICE_FACILITY_TYPES_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getParkingTypeDictionary = createAction(
  eDictionaryActions.DICTIONARY_PARKING_TYPE_GET,
  props<{ params: IDictionaryApiParams | undefined }>(),
);
export const getParkingTypeDictionarySuccess = createAction(
  eDictionaryActions.DICTIONARY_PARKING_TYPE_GET_SUCCESS,
  props<{ parkingType: IDictionaryItems }>(),
);
export const getParkingTypeDictionaryFailure = createAction(
  eDictionaryActions.DICTIONARY_PARKING_TYPE_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getStorageFormatDictionary = createAction(
  eDictionaryActions.DICTIONARY_STORAGE_FORMAT_GET,
  props<{ params: IDictionaryApiParams | undefined }>(),
);
export const getStorageFormatDictionarySuccess = createAction(
  eDictionaryActions.DICTIONARY_STORAGE_FORMAT_GET_SUCCESS,
  props<{ storageFormat: IDictionaryItems }>(),
);
export const getStorageFormatDictionaryFailure = createAction(
  eDictionaryActions.DICTIONARY_STORAGE_FORMAT_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getStorageSystemDictionary = createAction(
  eDictionaryActions.DICTIONARY_STORAGE_SYSTEM_GET,
  props<{ params: IDictionaryApiParams | undefined }>(),
);
export const getStorageSystemDictionarySuccess = createAction(
  eDictionaryActions.DICTIONARY_STORAGE_SYSTEM_GET_SUCCESS,
  props<{ storageSystem: IDictionaryItems }>(),
);
export const getStorageSystemDictionaryFailure = createAction(
  eDictionaryActions.DICTIONARY_STORAGE_SYSTEM_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getStorageSystemDictionaryPublic = createAction(
  eDictionaryActions.DICTIONARY_STORAGE_SYSTEM_PUBLIC_GET,
  props<{ params: IDictionaryApiParams | undefined }>(),
);

export const getStorageSystemDictionaryPublicSuccess = createAction(
  eDictionaryActions.DICTIONARY_STORAGE_SYSTEM_PUBLIC_GET_SUCCESS,
  props<{ storageSystem: IDictionaryItems }>(),
);
export const getStorageSystemDictionaryPublicFailure = createAction(
  eDictionaryActions.DICTIONARY_STORAGE_SYSTEM_PUBLIC_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getTaxationTypeDictionary = createAction(
  eDictionaryActions.DICTIONARY_TAXATION_TYPE_GET,
  props<{ params: IDictionaryApiParams | undefined }>(),
);
export const getTaxationTypeDictionarySuccess = createAction(
  eDictionaryActions.DICTIONARY_TAXATION_TYPE_GET_SUCCESS,
  props<{ taxationType: IDictionaryItems }>(),
);
export const getTaxationTypeDictionaryFailure = createAction(
  eDictionaryActions.DICTIONARY_TAXATION_TYPE_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getTemperatureModeDictionary = createAction(
  eDictionaryActions.DICTIONARY_TEMPERATURE_MODE_GET,
  props<{ params: IDictionaryApiParams | undefined }>(),
);
export const getTemperatureModeDictionarySuccess = createAction(
  eDictionaryActions.DICTIONARY_TEMPERATURE_MODE_GET_SUCCESS,
  props<{ temperatureMode: IDictionaryItems }>(),
);
export const getTemperatureModeDictionaryFailure = createAction(
  eDictionaryActions.DICTIONARY_TEMPERATURE_MODE_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getUnitDictionary = createAction(
  eDictionaryActions.DICTIONARY_UNIT_GET,
  props<{ params: IDictionaryApiParams | undefined }>(),
);
export const getUnitDictionarySuccess = createAction(eDictionaryActions.DICTIONARY_UNIT_GET_SUCCESS, props<{ unit: IDictionaryItems }>());
export const getUnitDictionaryFailure = createAction(eDictionaryActions.DICTIONARY_UNIT_GET_FAILURE, props<{ error: HttpErrorResponse }>());

export const getUnitDistanceDictionary = createAction(
  eDictionaryActions.DICTIONARY_UNIT_DISTANCE_GET,
  props<{ params: IDictionaryApiParams | undefined }>(),
);
export const getUnitDistanceDictionarySuccess = createAction(
  eDictionaryActions.DICTIONARY_UNIT_DISTANCE_GET_SUCCESS,
  props<{ unitDistance: IDictionaryItems }>(),
);
export const getUnitDistanceDictionaryFailure = createAction(
  eDictionaryActions.DICTIONARY_UNIT_DISTANCE_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getUnitMassDictionary = createAction(
  eDictionaryActions.DICTIONARY_UNIT_MASS_GET,
  props<{ params: IDictionaryApiParams | undefined }>(),
);
export const getUnitMassDictionarySuccess = createAction(
  eDictionaryActions.DICTIONARY_UNIT_MASS_GET_SUCCESS,
  props<{ unitMass: IDictionaryItems }>(),
);
export const getUnitMassDictionaryFailure = createAction(
  eDictionaryActions.DICTIONARY_UNIT_MASS_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getUnitSquareDictionary = createAction(
  eDictionaryActions.DICTIONARY_UNIT_SQUARE_GET,
  props<{ params: IDictionaryApiParams | undefined }>(),
);
export const getUnitSquareDictionarySuccess = createAction(
  eDictionaryActions.DICTIONARY_UNIT_SQUARE_GET_SUCCESS,
  props<{ unitSquare: IDictionaryItems }>(),
);
export const getUnitSquareDictionaryFailure = createAction(
  eDictionaryActions.DICTIONARY_UNIT_SQUARE_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getUnitTemperatureDictionary = createAction(
  eDictionaryActions.DICTIONARY_UNIT_TEMPERATURE_GET,
  props<{ params: IDictionaryApiParams | undefined }>(),
);
export const getUnitTemperatureDictionarySuccess = createAction(
  eDictionaryActions.DICTIONARY_UNIT_TEMPERATURE_GET_SUCCESS,
  props<{ unitTemperature: IDictionaryItems }>(),
);
export const getUnitTemperatureDictionaryFailure = createAction(
  eDictionaryActions.DICTIONARY_UNIT_TEMPERATURE_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const resetDictionaryState = createAction(eDictionaryActions.DICTIONARY_STATE_RESET);
export const resetDictionaryLoadingState = createAction(eDictionaryActions.DICTIONARY_LOADING_STATE_RESET);

export const getLegalTypeDictionary = createAction(
  eDictionaryActions.DICTIONARY_LEGAL_TYPE_GET,
  props<{ params: IDictionaryApiParams | undefined }>(),
);
export const getLegalTypeDictionarySuccess = createAction(
  eDictionaryActions.DICTIONARY_LEGAL_TYPE_GET_SUCCESS,
  props<{ legalType: IDictionaryItems }>(),
);
export const getLegalTypeDictionaryFailure = createAction(
  eDictionaryActions.DICTIONARY_LEGAL_TYPE_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getPartnerTypeDictionary = createAction(
  eDictionaryActions.DICTIONARY_PARTNER_TYPE_GET,
  props<{ params: IDictionaryApiParams | undefined }>(),
);
export const getPartnerTypeDictionarySuccess = createAction(
  eDictionaryActions.DICTIONARY_PARTNER_TYPE_GET_SUCCESS,
  props<{ partnerType: IDictionaryItems }>(),
);
export const getPartnerTypeDictionaryFailure = createAction(
  eDictionaryActions.DICTIONARY_PARTNER_TYPE_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);
