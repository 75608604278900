<div class="map">
  <div class="map__address-picker" *ngIf="mode === 'pick' && search">
    <div class="map__suggest map-suggest">
      <div class="map-suggest__icon">
        <svg-icon-sprite [src]="'./assets/sprites/sprite.svg#ic-search'" [width]="'20px'" [height]="'20px'"></svg-icon-sprite>
      </div>
      <input #suggestInput class="map-suggest__suggest" type="text" id="suggest">
      <span class="map-suggest__divider cell-divider"></span>
      <div class="map-suggest__button">
        <tlp-button theme="link" [isIconButton]="true" (click)="resetSuggest()">
          <svg-icon-sprite [src]="'./assets/sprites/sprite.svg#ic-close'" [width]="'20px'" [height]="'20px'"></svg-icon-sprite>
        </tlp-button>
      </div>
    </div>
    <div class="map__object-info" *ngIf="pickedPlacemark && pickedPlacemarkChanged">
      <p class="map__text">{{ pickedPlacemark.address_ym_geocoder }}</p>
      <tlp-button theme="primary" (click)="emitNewAddress(pickedPlacemark)">
        Выбрать этот адрес
      </tlp-button>
    </div>
  </div>
  <ya-map
      [center]="mapConfig.center"
      [state]="mapConfig"
      [zoom]="mapConfig.zoom"
      (yaclick)="setPlacemark($event)"
      (load)="onMapLoad($event)"
  >
    <ng-container *ngIf="mode === 'pick' || mode === 'static-view'; else viewTemplate">
      <ya-placemark
          *ngIf="pickedPlacemark"
          [geometry]="[pickedPlacemark.address_lat, pickedPlacemark.address_lon]"
          [options]="placemarkActiveConfig"
      ></ya-placemark>
    </ng-container>

    <ng-template #viewTemplate>
      <ya-clusterer [options]="clusterConfig">
        <ya-placemark
            *ngFor="let placemark of placemarks"
            [geometry]="[placemark.address_lat, placemark.address_lon]"
            [options]="placemark.isActive ? placemarkActiveConfig : placemarkConfig"
            (yaclick)="handlePlacemarkClick($event, placemark)"
        ></ya-placemark>
      </ya-clusterer>
    </ng-template>
  </ya-map>
</div>
