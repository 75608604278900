import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BookingCalendarService } from '../../service/booking-calendar.service';
import { IBookingCalendarDate } from '../../model/booking-calendar.model';

@Component({
  selector: 'tlp-booking-amount',
  templateUrl: './booking-amount.component.html',
  styleUrls: ['./booking-amount.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingAmountComponent {
  @Input() public calendarDate!: IBookingCalendarDate;
  @Input() public totalAmount!: number | null;
  @Input() public amountUnit: string | null = '';

  constructor(private bookingCalendarService: BookingCalendarService) {}

  public getFreeAmount(date: IBookingCalendarDate): number {
    return this.bookingCalendarService.getFreeAmount(this.totalAmount, date);
  }
}
