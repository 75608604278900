import { ChangeDetectionStrategy, Component, Input, forwardRef, OnInit, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ValidationErrors, NG_VALIDATORS } from '@angular/forms';
import * as moment from 'moment';

import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'tlp-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD.MM.YYYY',
        },
        display: {
          dateA11yLabel: 'LL',
          dateInput: 'DD.MM.YYYY',
          monthYearA11yLabel: 'MMMM YYYY',
          monthYearLabel: 'MMM YYYY',
        },
      },
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerComponent implements ControlValueAccessor, OnInit {
  @Input() public placeholder: string = '';
  @Input() public required: boolean = false;

  public isActive: boolean = false;
  public isDisabled: boolean = false;
  public val: moment.Moment | null = null;
  public dateParseError: boolean = false;

  constructor(private cdr: ChangeDetectorRef) {}

  public onDateChange(event: MatDatepickerInputEvent<moment.Moment>): void {
    this.dateParseError = event.value === null && (event.targetElement as HTMLInputElement).value !== '';
    this.value = event.value as moment.Moment;
  }

  public onChangeIsActive(val: boolean): void {
    this.isActive = val;
  }

  public onBlur(): void {
    this.onChangeIsActive(false);
    this.onChange(this.val);
    this.onTouch(this.val);
  }

  get value(): moment.Moment | null {
    return this.val;
  }

  set value(val: moment.Moment | null) {
    this.val = val;
    this.onChange(this.val);
    this.onTouch(this.val);
    this.cdr.detectChanges();
  }

  public onChange = (_: string | object | null) => {};

  public onTouch = (_: string | object | null) => {};

  public writeValue(value: moment.Moment | null): void {
    this.value = value;
  }

  public setDisabledState(disabled: boolean): void {
    this.isDisabled = disabled;
    this.cdr.detectChanges();
  }

  public registerOnChange(fn: (_: string | object | null) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: (_: string | object | null) => void): void {
    this.onTouch = fn;
  }

  public validate(): ValidationErrors | null {
    return this.dateParseError ? { invalidDate: true } : null;
  }

  public ngOnInit(): void {}
}
