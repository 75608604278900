import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IListingAdvertsApiParams, IListingBookingsApiParams, IListing } from '../../models/listing.model';
import { IListingApiParams } from '../../models/listing.model';
import { IListingLoadingState, IListingStoreState } from './listing.state';

import * as listingActions from './listing.actions';
import * as listingSelectors from './listing.selectors';

@Injectable()
export class ListingStoreService {
  constructor(private listingStore: Store<IListingStoreState>) {}

  get users$(): Observable<IListing | null> {
    return this.listingStore.select(listingSelectors.getUsersListing);
  }

  get organizations$(): Observable<IListing | null> {
    return this.listingStore.select(listingSelectors.getOrganizationsListing);
  }

  get warehouses$(): Observable<IListing | null> {
    return this.listingStore.select(listingSelectors.getWarehousesListing);
  }

  get adverts$(): Observable<IListing | null> {
    return this.listingStore.select(listingSelectors.getAdvertsListing);
  }

  get bookings$(): Observable<IListing | null> {
    return this.listingStore.select(listingSelectors.getBookingsListing);
  }

  get listingLoadingState$(): Observable<IListingLoadingState | null> {
    return this.listingStore.select(listingSelectors.getListingLoadingState);
  }

  public getUsersListing(params: IListingApiParams): void {
    this.listingStore.dispatch(listingActions.getUsersListing({ params }));
  }

  public getOrganizationsListing(params: IListingApiParams): void {
    this.listingStore.dispatch(listingActions.getOrganizationsListing({ params }));
  }

  public getWarehousesListing(params: IListingApiParams): void {
    this.listingStore.dispatch(listingActions.getWarehousesListing({ params }));
  }

  // TODO вероятно необходимо переделать на Зоны хранения
  public getAdvertsListing(params: IListingAdvertsApiParams): void {
    this.listingStore.dispatch(listingActions.getAdvertsListing({ params }));
  }

  public getBookingsListing(params: IListingBookingsApiParams): void {
    this.listingStore.dispatch(listingActions.getBookingsListing({ params }));
  }

  public resetUsersListing(): void {
    this.listingStore.dispatch(listingActions.resetListingState());
  }

  public resetOrganizationsListing(): void {
    this.listingStore.dispatch(listingActions.resetOrganizationsListing());
  }

  public resetWarehousesListing(): void {
    this.listingStore.dispatch(listingActions.resetWarehousesListing());
  }

  public resetAdvertsListing(): void {
    this.listingStore.dispatch(listingActions.resetAdvertsListing());
  }

  public resetBookingsListing(): void {
    this.listingStore.dispatch(listingActions.resetBookingsListing());
  }

  public resetListingLoadingState(): void {
    this.listingStore.dispatch(listingActions.resetListingLoadingState());
  }

  public resetListingState(): void {
    this.listingStore.dispatch(listingActions.resetListingState());
  }
}
