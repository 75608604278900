import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ListingEffects } from './listing.effects';
import { ListingReducer } from './listing.reducer';
import { ApiListingService } from '../../api/admin/api-listing.service';
import { ListingStoreService } from './listing-store.service';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('admin-listing', ListingReducer), EffectsModule.forFeature([ListingEffects])],
  providers: [ListingStoreService, ApiListingService],
})
export class ListingStoreModule {}
