export const INPUT_VALIDATION_MESSAGE = {
  required: 'Обязательное поле',
  nonZero: 'Должны быть указаны все величины',
  notSelected: 'Необходимо выбрать хотя бы один вариант',
  notEnoughSelected: 'Не выбрано необходимое число вариантов',
  moreThanLimitSelected: 'Выбрано слишком много вариантов',
  mask: 'Неверный формат',
  minlength: 'Минимальное количество символов',
  email: 'Некорректный E-mail',
  invalidPassword: 'Не все условия выполнены',
  passwordNotMatch: 'Пароли не совпадают',
  oldPasswordNotMatch: 'Пароль не совпадает с прежним',
  invalidDate: 'Некорректная дата',
  invalidPhoneFormat: 'Некорректный формат номера',
  invalidCountryCode: 'Некорректный код страны',
  invalidBik: 'Некорректный БИК',
  invalidInn: 'Некорректный ИНН',
  invalidKpp: 'Некорректный КПП',
  invalidOgrn: 'Некорректный ОГРН',
  invalidOgrnIP: 'Некорректный ОГРНИП',
  invalidOkpo: 'Некорректный ОКПО',
  invalidFileType: 'Некорректный формат файла',
  invalidCredentials: 'Неверный логин/пароль',
  invalidTimeFormat: 'Неверный формат времени',
};

export const INPUT_PASSWORD_MINLENGTH = 6;
export const INPUT_PASSWORD_LATIN_MINLENGTH = 5;
