import { INotificationsState } from './notifications.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const getNotificationsState = createFeatureSelector<INotificationsState>('notifications');

export const getNotifications = createSelector(getNotificationsState, (state: INotificationsState) => state.notifications);
export const getNotificationsIsLoading = createSelector(getNotificationsState, (state: INotificationsState) => state.isLoading);
export const getNotificationsIsDidLoad = createSelector(getNotificationsState, (state: INotificationsState) => state.isDidLoad);
export const getNotificationsLoadingState = createSelector(getNotificationsState, (state: INotificationsState) => ({
  error: state.error,
  isLoading: state.isLoading,
  isDidLoad: state.isDidLoad,
}));

export const getUnreadCount = createSelector(getNotificationsState, (state: INotificationsState) => state.unreadCount);
