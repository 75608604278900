import { IWeekDay } from '../models/date.model';

/* коэффициент для перевода времени в человекопонятный формат и наоборот
 ** отправка на бэк - делим дату на UNIX_TIME_RATIO
 ** вывод на фронте - умножаем дату на UNIX_TIME_RATIO
 */
export const UNIX_TIME_RATIO: number = 1000;

export const TIME_PARSING_REGEX = /([0-9]{2}):([0-9]{2})/;

export const WEEK_DAYS: IWeekDay[] = [
  { value: 'mon', name: 'Пн', order: 1 },
  { value: 'tue', name: 'Вт', order: 2 },
  { value: 'wed', name: 'Ср', order: 3 },
  { value: 'thu', name: 'Чт', order: 4 },
  { value: 'fri', name: 'Пт', order: 5 },
  { value: 'sat', name: 'Сб', order: 6, weekend: true },
  { value: 'sun', name: 'Вс', order: 7, weekend: true },
];
