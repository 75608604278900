import { IHttpRequestState } from '../../models/http.model';
import { ITeam } from '../../models/team.model';

export interface ITeamStoreState {
  team: ITeam | null;
  teamRequests: ITeam | null;
  isCurrentRequestActive: boolean;
  createTeamRequestState: IHttpRequestState | null;
  cancelCurrentTeamRequestState: IHttpRequestState | null;
  teamLoadingState: IHttpRequestState | null;
  teamRequestsLoadingState: IHttpRequestState | null;
  currentTeamRequestState: IHttpRequestState | null;
}

export const InitTeamState: ITeamStoreState = {
  team: null,
  teamRequests: null,
  isCurrentRequestActive: false,
  createTeamRequestState: null,
  cancelCurrentTeamRequestState: null,
  teamLoadingState: null,
  teamRequestsLoadingState: null,
  currentTeamRequestState: null,
};
