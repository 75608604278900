import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractApiMethodsService } from './abstract-api-methods.service';
import {
  IWarehouses,
  IWarehouseApiParams,
  IWarehouseDocuments,
  IWarehouseExternal,
  IWarehouseExternalUpsert,
  IWarehouseGeneral,
  IWarehouseGeneralUpsert,
  IWarehouseInternal,
  IWarehouseInternalUpsert,
  IWarehouseTabState,
} from '../models/warehouse.model';
import { IWarehouseUpsertConfirmation } from '../../warehouse/warehouse-details-upsert-view/model/warehouse-details-upsert.model';

@Injectable()
export class ApiWarehouseService extends AbstractApiMethodsService {
  public getWarehouses(params: IWarehouseApiParams): Observable<IWarehouses | null> {
    return this.httpPost(params, 'warehouse/index');
  }

  public createWarehouse(data: IWarehouseGeneralUpsert): Observable<{ id: string }> {
    return this.httpPost(data, `warehouse/create`);
  }

  public deleteWarehouse(id: string): Observable<boolean> {
    return this.httpPost({}, `warehouse/${id}/delete`);
  }

  public publishWarehouse(id: string): Observable<boolean> {
    return this.httpPost({}, `warehouse/${id}/publish`);
  }

  public getWarehouseTabState(id: string): Observable<IWarehouseTabState | null> {
    return this.httpPost({}, `warehouse/${id}/tab_states`);
  }

  public getWarehouseGeneral(id: string): Observable<IWarehouseGeneral | null> {
    return this.httpPost({}, `warehouse/${id}/view/general`);
  }

  public updateWarehouseGeneral(data: IWarehouseGeneralUpsert, id: string): Observable<{ id: string }> {
    return this.httpPost(data, `warehouse/${id}/update/general`);
  }

  public getWarehouseExternal(id: string): Observable<IWarehouseExternal | null> {
    return this.httpPost({}, `warehouse/${id}/view/external`);
  }

  public getWarehouseExternalPublic(id: string): Observable<IWarehouseExternal | null> {
    return this.httpPost({}, `advrt-board/${id}/warehouse/external`);
  }

  public updateWarehouseExternal(data: IWarehouseExternalUpsert, id: string): Observable<{ id: string }> {
    return this.httpPost(data, `warehouse/${id}/update/external`);
  }

  public getWarehouseInternal(id: string): Observable<IWarehouseInternal | null> {
    return this.httpPost({}, `warehouse/${id}/view/internal`);
  }

  public getWarehouseInternalPublic(id: string): Observable<IWarehouseInternal | null> {
    return this.httpPost({}, `advrt-board/${id}/warehouse/internal`);
  }

  public updateWarehouseInternal(data: IWarehouseInternalUpsert, id: string): Observable<{ id: string }> {
    return this.httpPost(data, `warehouse/${id}/update/internal`);
  }

  public getWarehouseDocuments(id: string): Observable<IWarehouseDocuments | null> {
    return this.httpPost({}, `warehouse/${id}/view/documents`);
  }

  public updateWarehouseDocuments(formData: FormData, id: string): Observable<{ id: string }> {
    return this.httpPost(formData, `warehouse/${id}/update/documents`, true);
  }

  public uploadWarehouseDocument(formData: FormData, id: string, documentType: string): Observable<boolean> {
    return this.httpPost(formData, `warehouse/${id}/upload-document/${documentType}`, true);
  }

  public deleteWarehouseDocument(id: string, documentType: string, changesConfirmed?: IWarehouseUpsertConfirmation): Observable<boolean> {
    return this.httpPost(changesConfirmed || {}, `warehouse/${id}/delete-document/${documentType}`);
  }

  public preloadWarehousePhotos(formData: FormData): Observable<string[]> {
    return this.httpPost(formData, `warehouse/new_photo`, true);
  }
}
