<ng-container *ngIf="bookingCostParams">
  <tlp-content-block *ngIf="costCalculatorTheme === 'default'; else miniTheme">
    <div class="cost-calculator">
      <div class="cost-calculator__data">
        <div class="cost-calculator__row">
          <span class="cost-calculator__text cost-calculator__text--label">
            {{ costCalculatorMode === 'static' ? 'Цена' : 'Стоимость' }}
          </span>
          <span class="cost-calculator__text cost-calculator__text--bold">
            {{
              bookingCostParams.cost
                ? (bookingCostParams.cost | mask: 'separator' | rubCurrency) + (costCalculatorMode === 'static' ? '/д.' : '/день')
                : costCalculatorMode === 'static'
                ? '0'
                : 'Не указано'
            }}
          </span>
        </div>
        <div class="cost-calculator__row">
          <span class="cost-calculator__text cost-calculator__text--label">Площадь</span>
          <span class="cost-calculator__text">
            {{
              bookingCostParams.amount
                ? (bookingCostParams.amount | mask: 'separator') +
                  ' ' +
                  (bookingCostParams.amount_type?.title_short || bookingCostParams.amount_type_title)
                : costCalculatorMode === 'static'
                ? '0'
                : 'Не указано'
            }}
          </span>
        </div>
        <div class="cost-calculator__row">
          <span class="cost-calculator__text cost-calculator__text--label">
            {{ costCalculatorMode === 'static' ? 'Период' : 'Период брони' }}
          </span>
          <span class="cost-calculator__text">
            {{
              daysCount ? (daysCount | numeralPlurals: ['день', 'дня', 'дней']) : costCalculatorMode === 'static' ? '0' : 'Не рассчитано'
            }}
          </span>
        </div>
      </div>
      <div class="cost-calculator__summary" *ngIf="summaryCost">
        <div class="cost-calculator__row">
          <span class="cost-calculator__text cost-calculator__text--bold-dark">Итого</span>
          <span class="cost-calculator__text cost-calculator__text--bold-dark">
            {{ summaryCost | mask: 'separator' | rubCurrency }}
          </span>
        </div>
      </div>
    </div>
  </tlp-content-block>
  <ng-template #miniTheme>
    <ng-container *ngIf="daysCount && summaryCost">
      <p class="cost-calculator__text cost-calculator__text--sublabel">Итого</p>
      <span class="cost-calculator__text cost-calculator__text--dark">
        {{ daysCount | numeralPlurals: ['день', 'дня', 'дней'] }}
      </span>
      <span class="cost-calculator__text cost-calculator__text--dark">
        за {{ summaryCost | mask: 'separator' | rubCurrency }}
      </span>
    </ng-container>
  </ng-template>
</ng-container>
