import { IHttpRequestState } from '../../models/http.model';
import { IWarehousesCatalog, IWarehousesCatalogItem } from '../../models/warehouses-catalog.model';

export interface IWarehousesCatalogStoreState {
  warehousesCatalog: IWarehousesCatalog | null;
  warehousesCatalogItem: IWarehousesCatalogItem | null;
  warehousesCatalogLoadingState: IHttpRequestState | null;
  warehousesCatalogItemLoadingState: IHttpRequestState | null;
}

export const InitWarehousesCatalogState: IWarehousesCatalogStoreState = {
  warehousesCatalog: null,
  warehousesCatalogItem: null,
  warehousesCatalogLoadingState: null,
  warehousesCatalogItemLoadingState: null,
};
