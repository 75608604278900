import { animate, AUTO_STYLE, group, style } from '@angular/animations';

export const EXPAND_ANIMATION = [
  style({ height: 0, opacity: 0 }),
  group([
    animate(
      '0.4s 0.1s ease',
      style({
        height: AUTO_STYLE,
      }),
    ),
    animate('0.4s ease', style({ opacity: 1 })),
  ]),
];
export const COLLAPSE_ANIMATION = [
  style({ height: AUTO_STYLE, opacity: 1 }),
  group([
    animate(
      '0.4s 0.1s ease',
      style({
        height: 0,
      }),
    ),
    animate('0.4s ease', style({ opacity: 0 })),
  ]),
];

export const APPEAR_ANIMATION = [style({ opacity: 0 }), animate('0.5s ease-in', style({ opacity: 1 }))];
export const DISAPPEAR_ANIMATION = [style({ opacity: 1 }), animate('0.5s ease-out', style({ opacity: 0 }))];

export const TRANSLATE_TOP = [
  style({ transform: 'translateY(50px)', opacity: 0 }),
  group([
    animate(
      '0.3s 0.1s ease',
      style({
        transform: 'translateY(0)',
      }),
    ),
    animate('0.3s ease', style({ opacity: 1 })),
  ]),
];
export const TRANSLATE_BOTTOM = [
  style({ transform: 'translateY(-50px)', opacity: 0 }),
  group([
    animate(
      '0.3s 0.1s ease',
      style({
        transform: 'translateY(0)',
      }),
    ),
    animate('0.3s ease', style({ opacity: 1 })),
  ]),
];
export const TRANSLATE_LEFT = [
  style({ transform: 'translateX(-50px)', opacity: 0 }),
  group([
    animate(
      '0.3s 0.1s ease',
      style({
        transform: 'translateX(0)',
      }),
    ),
    animate('0.3s ease', style({ opacity: 1 })),
  ]),
];
export const TRANSLATE_RIGHT = [
  style({ transform: 'translateX(50px)', opacity: 0 }),
  group([
    animate(
      '0.3s 0.1s ease',
      style({
        transform: 'translateX(0)',
      }),
    ),
    animate('0.3s ease', style({ opacity: 1 })),
  ]),
];
