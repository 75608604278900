import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ICard } from '../../model/card.model';

@Component({
  selector: 'tlp-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  @Input() public gridTemplateColumns: string | undefined;
  @Input() public toggleItemDetailsOnClick: boolean = false;
  @Input() public toggleItemContentButton?: string;
  @Input() public item!: ICard;

  @Output() public customToggleButtonClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isCollapsed: boolean = true;

  public toggleItemDetails(checkToggleFlag: boolean = false): void {
    if (!checkToggleFlag || (checkToggleFlag && this.toggleItemDetailsOnClick)) {
      this.isCollapsed = !this.isCollapsed;
    }
  }

  public handleItemContentToggle(): void {
    this.customToggleButtonClicked.emit(this.isCollapsed);
    this.toggleItemDetails();
  }
}
