import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, OnInit, ElementRef, HostListener } from '@angular/core';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

import { NotificationsStoreService } from '../../_core/stores/notifications/notifications-store.service';
import { IApiPagination } from '../../_core/models/pagination.model';
import { INotifications, INotification } from '../../_core/models/notifications.model';
import { INITIAL_PAGINATION, PAGINATOR_MIN_SIZE } from '../../_core/const/pagination.const';

const UNREAD_COUNT_REQUEST_INTERVAL: number = 10000;

@Component({
  selector: 'tlp-notifications-menu',
  templateUrl: './notifications-menu.component.html',
  styleUrls: ['./notifications-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsMenuComponent implements OnInit, OnDestroy {
  public unreadCount$: Observable<number> = this.notificationsStoreService.unreadCount$;
  public notifications$: Observable<INotifications | null> = this.notificationsStoreService.notifications$;
  public isNotificationsLoading$: Observable<boolean> = this.notificationsStoreService.isNotificationsLoading$;

  public isDropdownShown: boolean;
  public newNotifications: INotification[] = [];
  public oldNotifications: INotification[] = [];

  private notificationsSub: Subscription | null = null;
  private componentDestroyed: Subject<void> = new Subject<void>();

  private itemPerScroll: number = PAGINATOR_MIN_SIZE;
  private pagination: IApiPagination = INITIAL_PAGINATION;

  constructor(private elref: ElementRef, private notificationsStoreService: NotificationsStoreService, private cdr: ChangeDetectorRef) {
    this.isDropdownShown = false;
  }

  @HostListener('document:click', ['$event'])
  public onClick(event: MouseEvent): void {
    if (!this.elref.nativeElement.contains(event.target)) {
      this.setShowDropdown(false);
    }
  }

  public ngOnInit(): void {
    interval(UNREAD_COUNT_REQUEST_INTERVAL)
      .pipe(startWith(0), takeUntil(this.componentDestroyed))
      .subscribe(() => {
        this.notificationsStoreService.getUnreadCount();
      });

    this.notificationsSub = this.notifications$.subscribe((notifications: INotifications | null) => {
      this.newNotifications = [
        ...this.newNotifications,
        ...(notifications !== null ? notifications.items.filter((note: INotification) => note.viewed_at === null) : []),
      ];
      this.oldNotifications = [
        ...this.oldNotifications,
        ...(notifications !== null ? notifications.items.filter((note: INotification) => note.viewed_at !== null) : []),
      ];
      this.cdr.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.notificationsSub?.unsubscribe();
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  public toggleDropdown(): void {
    this.setShowDropdown(!this.isDropdownShown);
  }

  public setShowDropdown(show: boolean): void {
    if (this.isDropdownShown !== show) {
      this.isDropdownShown = show;
      if (this.isDropdownShown) {
        this.newNotifications = [];
        this.oldNotifications = [];
        this.notificationsStoreService.resetNotifications();
        this.pagination = INITIAL_PAGINATION;
        this.getNotifications();
      } else {
        this.notificationsStoreService.resetUnreadCount();
      }
    }
  }

  public getNotifications(): void {
    this.notificationsStoreService.getNotifications(this.pagination);
    this.pagination = {
      limit: this.itemPerScroll,
      offset: (this.pagination.offset || 0) + this.itemPerScroll,
    };
  }
}
