import { createAction, props } from '@ngrx/store';

export enum eLogoutActions {
  LOGOUT = '[Logout] logout action',
  LOGOUT_SUCCESS = '[Logout] logout success',
  LOGOUT_FAILURE = '[Logout] logout failure',
  LOGOUT_STATE_RESET = '[Logout] logout state reset',
}

export const logout = createAction(eLogoutActions.LOGOUT, props<{ returnUrl: string }>());
export const logoutSuccess = createAction(eLogoutActions.LOGOUT_SUCCESS);
export const logoutFailure = createAction(eLogoutActions.LOGOUT_FAILURE, props<{ error: Error }>());
export const resetLogoutState = createAction(eLogoutActions.LOGOUT_STATE_RESET);
