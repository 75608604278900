import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { transition, trigger } from '@angular/animations';
import * as moment from 'moment';

import { BookingCalendarService } from '../../../service/booking-calendar.service';
import { IBookingDate, IBookingDatesFilters } from '../../../../../_core/models/booking.model';
import { IBookingCalendarDate, IExpandedBookingCalendarPeriod, tPeriodDirections } from '../../../model/booking-calendar.model';
import { CALENDAR_DAYS_OF_WEEK, eBookingCalendarDisplayedPeriodsCount } from '../../../const/booking-calendar.const';
import { TRANSLATE_LEFT, TRANSLATE_RIGHT } from '../../../../animation/animation.const';
import { UNIX_TIME_RATIO } from '../../../../../_core/const/date.const';

@Component({
  selector: 'tlp-booking-calendar-expanded',
  templateUrl: './booking-calendar-expanded.component.html',
  styleUrls: ['../../../styles/booking-calendar-shared.component.scss', './booking-calendar-expanded.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('calendarSlider', [
      transition(':enter', []),
      transition(':increment', TRANSLATE_RIGHT),
      transition(':decrement', TRANSLATE_LEFT),
    ]),
  ],
})
export class BookingCalendarExpandedComponent implements OnChanges, OnInit {
  @Input() public amountUnit!: string;
  @Input() public totalAmount!: number | null;
  @Input() public showFreeAmount: boolean = true;
  @Input() public bookingDates!: IBookingDate[] | undefined;
  @Input() public bookingDatesLoading: boolean = false;
  @Input() public bookingDatesLoadingFailure?: boolean;

  @Input() public animated: boolean = true;
  @Input() public leftLimitDate?: number | moment.Moment | null = null;
  @Input() public rightLimitDate?: number | moment.Moment | null = null;
  @Input() public displayedPeriodsCount: number = eBookingCalendarDisplayedPeriodsCount.default;
  @Output() public bookingDatesUpdateRequested: EventEmitter<IBookingDatesFilters> = new EventEmitter<IBookingDatesFilters>();

  public bookingCalendar: IBookingCalendarDate[] | undefined = undefined;
  public calendarDaysOfWeek: string[] = CALENDAR_DAYS_OF_WEEK;
  public displayedPeriods: IExpandedBookingCalendarPeriod[] = [];
  public currentPeriodDirectionHandler: number = 0;

  constructor(private cdr: ChangeDetectorRef, private bookingCalendarService: BookingCalendarService) {
    moment.locale('ru');
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.bookingDates?.currentValue) {
      if (this.bookingCalendar) {
        this.bookingDates = this.bookingCalendarService.getParsedBookingDates(changes.bookingDates.currentValue);
        this.bookingCalendar = this.bookingCalendarService.getUpdatedBookingCalendar(
          this.totalAmount,
          this.bookingCalendar,
          changes.bookingDates.currentValue,
        );
        this.updateDisplayedPeriods();
        this.bookingDatesLoading = false;
      } else {
        this.parseDates(changes.bookingDates?.currentValue);
        this.bookingCalendar = this.bookingCalendarService.getBookingCalendar(
          this.totalAmount,
          this.leftLimitDate || null,
          this.rightLimitDate || null,
          this.bookingDates,
          true,
        );
      }
    }

    if (changes.bookingDatesLoadingFailure?.currentValue) {
      this.bookingDatesLoading = false;
      this.cdr.detectChanges();
    }
  }

  public ngOnInit(): void {
    if (!this.bookingCalendar) {
      this.parseDates();
      this.bookingCalendar = this.bookingCalendarService.getBookingCalendar(
        this.totalAmount,
        this.leftLimitDate || null,
        this.rightLimitDate || null,
        this.bookingDates,
      );
    }
    this.fillDisplayedPeriods();

    const lastDisplayedPeriodDatesLength = this.displayedPeriods[this.displayedPeriods.length - 1]?.dates?.length;
    const lastDisplayedPeriodLastDate = moment(
      this.displayedPeriods[this.displayedPeriods.length - 1]?.dates[lastDisplayedPeriodDatesLength - 1]?.date,
    );
    const firstDisplayedPeriodFirstDate = moment(this.displayedPeriods?.[0].dates?.[0].date);

    if (this.bookingCalendarService.needToUpdateDates(this.bookingDates, firstDisplayedPeriodFirstDate, lastDisplayedPeriodLastDate)) {
      this.requestDatesUpdate(firstDisplayedPeriodFirstDate, lastDisplayedPeriodLastDate);
    }
  }

  public canChangePeriod(direction: tPeriodDirections): boolean {
    switch (direction) {
      case 'left':
        if (!this.leftLimitDate) {
          return true;
        }
        return !moment(this.displayedPeriods?.[0]?.dates[0].date)
          .subtract(1, 'days')
          .isBefore(this.bookingCalendarService.getMomentDate(this.leftLimitDate).format('YYYY-MM-DD'));
      case 'right':
        if (!this.rightLimitDate) {
          return true;
        }
        const rightMonthFirstDate = this.displayedPeriods[this.displayedPeriods.length - 1]?.dates[0]?.date;
        return !moment(rightMonthFirstDate)
          .add(1, 'month')
          .isAfter(this.bookingCalendarService.getMomentDate(this.rightLimitDate || undefined).format('YYYY-MM-DD'));
      default:
        return false;
    }
  }

  public changeDisplayedPeriod(direction: tPeriodDirections): void {
    let firstDateToDisplay: moment.Moment | null;
    switch (direction) {
      case 'left':
        const leftMonthFirstDate = this.displayedPeriods[0]?.dates[0]?.date;
        let previousMonthsDaysCount: number = 0;

        for (let index = 0; index < this.displayedPeriodsCount; index++) {
          previousMonthsDaysCount += moment(leftMonthFirstDate)
            .subtract(index + 1, 'month')
            ?.daysInMonth();
        }

        firstDateToDisplay = moment(leftMonthFirstDate).subtract(previousMonthsDaysCount, 'days');
        if (this.leftLimitDate && firstDateToDisplay.isBefore(this.bookingCalendarService.getMomentDate(this.leftLimitDate))) {
          firstDateToDisplay = this.bookingCalendarService.getMomentDate(this.leftLimitDate);
        }
        break;
      case 'right':
        const rightMonthDatesLength = this.displayedPeriods[this.displayedPeriods.length - 1]?.dates?.length;
        const rightMonthLastDate = this.displayedPeriods[this.displayedPeriods.length - 1]?.dates[rightMonthDatesLength - 1]?.date;

        firstDateToDisplay = moment(rightMonthLastDate).add(1, 'days');
        if (this.rightLimitDate && firstDateToDisplay.isAfter(this.bookingCalendarService.getMomentDate(this.rightLimitDate))) {
          firstDateToDisplay = this.bookingCalendarService.getMomentDate(this.rightLimitDate).subtract(1, 'days');
        }
        break;
      default:
        firstDateToDisplay = this.displayedPeriods[0]?.dates[0].date as moment.Moment;
        break;
    }

    let lastDateToDisplay: moment.Moment | null = moment(firstDateToDisplay).add(this.displayedPeriodsCount, 'month');
    if (this.rightLimitDate && lastDateToDisplay.isAfter(this.bookingCalendarService.getMomentDate(this.rightLimitDate))) {
      lastDateToDisplay = this.bookingCalendarService.getMomentDate(this.rightLimitDate).endOf('month');
    }

    if (this.bookingCalendarService.needToUpdateDates(this.bookingDates, firstDateToDisplay, lastDateToDisplay, direction)) {
      this.requestDatesUpdate(firstDateToDisplay, lastDateToDisplay);
    }

    if (this.bookingCalendar) {
      const firstCalendarDateIndex = this.bookingCalendarService.findSameCalendarDateIndex(firstDateToDisplay, this.bookingCalendar);
      const lastCalendarDateIndex = this.bookingCalendarService.findSameCalendarDateIndex(lastDateToDisplay, this.bookingCalendar);

      if (this.animated) {
        this.currentPeriodDirectionHandler += direction === 'right' ? +1 : -1;
      }
      this.fillDisplayedPeriods(firstCalendarDateIndex, lastCalendarDateIndex);
    }
  }

  public getDisplayedPeriodMainDate(date: string | null): string {
    return this.bookingCalendarService.getDisplayedMainDate(date);
  }

  public getStubDates(length: number): string[] {
    return new Array(length).fill('');
  }

  private requestDatesUpdate(firstDate: moment.Moment, lastDate: moment.Moment): void {
    this.bookingDatesLoading = true;
    this.bookingDatesUpdateRequested.emit({
      rent_from: moment(firstDate.format('YYYY-MM-DD')).valueOf() / UNIX_TIME_RATIO,
      rent_to: moment(lastDate.format('YYYY-MM-DD')).add(1, 'days').valueOf() / UNIX_TIME_RATIO,
    });
    this.cdr.markForCheck();
  }

  private fillDisplayedPeriods(firstCalendarDateIndex?: number, lastCalendarDateIndex?: number): void {
    let currentMainDate: string = (this.bookingCalendar?.[firstCalendarDateIndex ?? 0].date as string) || '';
    this.displayedPeriods = [{ mainDate: currentMainDate, dates: [], weeks: [] }];
    if (this.bookingCalendar) {
      for (
        let index = firstCalendarDateIndex ?? 0;
        index < (lastCalendarDateIndex && lastCalendarDateIndex > 0 ? lastCalendarDateIndex : this.bookingCalendar.length);
        index++
      ) {
        const currentDate: string = (this.bookingCalendar?.[index].date as string) || '';

        if (moment(currentMainDate)?.format('MMMM YYYY') === moment(currentDate)?.format('MMMM YYYY')) {
          this.displayedPeriods[this.displayedPeriods.length - 1]?.dates?.push(this.bookingCalendar[index]);
        } else {
          currentMainDate = currentDate;
          const calendarDate = moment(currentDate).format('YYYY-MM-DD');
          const bookingAmount = this.bookingDates?.find(bookingDate => moment(bookingDate.date).isSame(calendarDate))?.amount;

          this.displayedPeriods[this.displayedPeriods.length] = {
            dates: [
              {
                date: calendarDate,
                amount: bookingAmount ?? 0,
                bookingPercent: this.bookingCalendarService.calculatePercentage(this.totalAmount, bookingAmount ?? 0),
              },
            ],
            mainDate: currentMainDate,
            weeks: [],
          };
        }
      }
    }

    this.displayedPeriods = this.displayedPeriods.slice(0, this.displayedPeriodsCount);
    this.refillDisplayedPeriod();
    this.displayedPeriods = this.displayedPeriods.map(displayedPeriod => ({
      ...displayedPeriod,
      weeks: this.getPeriodWeeks(displayedPeriod.dates),
    }));
  }

  private refillDisplayedPeriod(): void {
    let missingDatesCount: number = moment(this.displayedPeriods[0].dates?.[0]?.date).daysInMonth() - this.displayedPeriods[0].dates.length;
    let currentDate = moment(this.displayedPeriods[0].dates?.[0]?.date);

    if (this.displayedPeriods.length > 1) {
      while (missingDatesCount) {
        --missingDatesCount;
        currentDate = moment(currentDate).subtract(1, 'days');
        const calendarDate = moment(currentDate).format('YYYY-MM-DD');

        this.displayedPeriods[0].dates?.unshift({
          date: calendarDate,
          amount: 0,
          bookingPercent: 0,
          isPastDate: true,
        });
      }
    }

    const displayedPeriodsLength = this.displayedPeriods.length;
    const lastDisplayedPeriodDatesLength = this.displayedPeriods[displayedPeriodsLength - 1]?.dates?.length;

    missingDatesCount =
      moment(this.displayedPeriods[displayedPeriodsLength - 1].dates?.[lastDisplayedPeriodDatesLength - 1]?.date).daysInMonth() -
      lastDisplayedPeriodDatesLength;
    currentDate = moment(this.displayedPeriods[displayedPeriodsLength - 1].dates?.[lastDisplayedPeriodDatesLength - 1]?.date);

    while (missingDatesCount) {
      --missingDatesCount;
      currentDate = moment(currentDate).add(1, 'days');
      const calendarDate = moment(currentDate).format('YYYY-MM-DD');

      this.displayedPeriods[displayedPeriodsLength - 1]?.dates?.push({
        date: calendarDate,
        amount: 0,
        bookingPercent: 0,
      });
    }
  }

  private updateDisplayedPeriods(): void {
    this.displayedPeriods?.forEach((displayedPeriodsItem, index) => {
      displayedPeriodsItem.dates.forEach((displayedPeriodItem, innerIndex) => {
        const sameBookingDateIndex = this.bookingCalendar?.findIndex(calendarItem =>
          moment(calendarItem.date).isSame(displayedPeriodItem.date),
        );
        if (sameBookingDateIndex !== undefined && sameBookingDateIndex !== -1 && this.bookingCalendar![sameBookingDateIndex]) {
          this.displayedPeriods![index].dates![innerIndex] = { ...this.bookingCalendar![sameBookingDateIndex] };
          this.displayedPeriods![index].weeks = this.getPeriodWeeks(this.displayedPeriods![index].dates);
        }
      });
    });
  }

  private getPeriodWeeks(dates: IBookingCalendarDate[]): IBookingCalendarDate[][] {
    const firstDateWeekdayNumber: number = moment(dates[0].date).weekday();
    const periodWeeks: IBookingCalendarDate[][] = [dates.slice(0, 7 - firstDateWeekdayNumber)];

    const restPeriodWeeks: IBookingCalendarDate[][] = dates
      .slice(7 - firstDateWeekdayNumber, dates.length)
      .reduce((resultPeriodWeeks: IBookingCalendarDate[][], bookingCalendarDate) => {
        const currentDate = moment(bookingCalendarDate.date);
        const previousDate = moment(bookingCalendarDate.date).subtract(1, 'days');

        if (currentDate.week() === previousDate.week()) {
          resultPeriodWeeks[resultPeriodWeeks.length - 1]?.push(bookingCalendarDate);
        } else {
          resultPeriodWeeks[resultPeriodWeeks.length] = [bookingCalendarDate];
        }

        return resultPeriodWeeks;
      }, []);

    periodWeeks.push(...restPeriodWeeks);
    return periodWeeks;
  }

  private parseDates(bookingDates?: IBookingDate[] | undefined): void {
    this.bookingDates = this.bookingCalendarService.getParsedBookingDates(bookingDates || this.bookingDates);

    this.leftLimitDate = this.bookingCalendarService.getParsedLeftLimit(this.bookingDates, this.leftLimitDate || null);
    this.rightLimitDate = this.bookingCalendarService.getParsedRightLimit(this.bookingDates, this.leftLimitDate, this.rightLimitDate);
  }
}
