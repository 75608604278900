<ul class="navigation-list">
  <li class="navigation-list__item" *ngIf="!showProfileLink">
    <a [routerLink]="'/profile/user-account'"
       class="navigation-list__link"
       [class.navigation-list__link--dark]="dark"
       [class.navigation-list__link--no-icon]="!showIcons"
       [class.navigation-list__link--active]="isActiveLink('/profile/user-account')"
    >
      <div class="user-profile">
        <svg-icon-sprite
          [src]="'./assets/sprites/sprite.svg#ic-no-avatar'"
          [width]="'20px'"
          [height]="'20px'"
          class="user-profile__avatar"
        ></svg-icon-sprite>
        <div class="user-profile__info" *ngIf="user">
          <div class="user-profile__name">
            {{ user.last_name }} {{ user.first_name[0] }}. {{ user.middle_name ? user.middle_name[0] + '.' : '' }}
          </div>
          <div class="user-profile__role" *ngIf="user.role === 'admin'">
            {{ getRoleName(user.role) }}
          </div>
          <div class="user-profile__organization" *ngIf="user.organization">
            {{ user.organization.title }}
            <div class="user-profile__organization-status">
              <tlp-status-note
                *ngIf="user.organization.verification_status"
                [statusName]="user.organization.verification_status.system_name"
                [text]="user.organization.verification_status.title || user.organization.verification_status.title_short"
              ></tlp-status-note>
            </div>
          </div>
        </div>
      </div>
    </a>
  </li>
  <li class="navigation-list__item" *ngIf="showProfileLink">
    <a [routerLink]="'/profile'"
       class="navigation-list__link"
       [class.navigation-list__link--dark]="dark"
       [class.navigation-list__link--no-icon]="!showIcons"
    >
      <span class="navigation-list__link-text">Профиль</span>
    </a>
  </li>
  <li *ngFor="let link of navLinks; index as i" class="navigation-list__item">
    <a [routerLink]="link.href"
       class="navigation-list__link"
       [class.navigation-list__link--dark]="dark"
       [class.navigation-list__link--no-icon]="!showIcons"
       [class.navigation-list__link--active]="isActiveLink(link.href)"
    >
      <svg-icon-sprite class="navigation-list__link-icon" *ngIf="showIcons" [src]="'./assets/sprites/sprite.svg#' + link.icon" [width]="'20px'" [height]="'20px'"></svg-icon-sprite>
      <span class="navigation-list__link-text">
          {{ link.title }}
      </span>
    </a>
  </li>
</ul>
