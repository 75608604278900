import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { IButton } from '../../model/page-action-button.model';
import { PageActionButtonsService } from '../../service/page-action-buttons.service';

@Component({
  selector: 'tlp-page-action-buttons',
  templateUrl: 'page-action-buttons.component.html',
  styleUrls: ['page-action-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageActionButtonsComponent {
  public buttons: IButton[] = [];

  constructor(public pageActionButtonsService: PageActionButtonsService, private cdr: ChangeDetectorRef) {
    this.pageActionButtonsService.config.subscribe(config => {
      if (this.buttons) {
        this.cdr.markForCheck();
      }

      this.buttons = config ? config.buttons : [];
    });
  }

  public clickButton(button: IButton): void {
    if (button.isActive !== undefined) {
      button.isActive = !button.isActive;
    }
    this.pageActionButtonsService.config.value?.callback({
      buttonName: button.name,
      active: button.isActive,
    });
  }
}
