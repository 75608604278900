import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IApiPagination } from '../../models/pagination.model';
import { INotifications, IUnreadNotificationsCount } from '../../models/notifications.model';

export enum eNotificationsActions {
  NOTIFICATIONS_GET = '[Notifications] get notifications',
  NOTIFICATIONS_GET_SUCCESS = '[Notifications] get notifications success',
  NOTIFICATIONS_GET_FAILURE = '[Notifications] get notifications failure',

  NOTIFICATIONS_GET_UNREAD_COUNT = '[Notifications] get unread notifications count',
  NOTIFICATIONS_GET_UNREAD_COUNT_SUCCESS = '[Notifications] get unread notifications count success',
  NOTIFICATIONS_GET_UNREAD_COUNT_FAILURE = '[Notifications] get unread notifications count failure',

  NOTIFICATIONS_RESET = '[Notifications] reset notifications',
  NOTIFICATIONS_UNREAD_COUNT_RESET = '[Notifications] reset unread notifications count',
}

export const getNotifications = createAction(eNotificationsActions.NOTIFICATIONS_GET, props<{ pagination: IApiPagination }>());
export const getNotificationsSuccess = createAction(
  eNotificationsActions.NOTIFICATIONS_GET_SUCCESS,
  props<{ notifications: INotifications }>(),
);
export const getNotificationsFailure = createAction(eNotificationsActions.NOTIFICATIONS_GET_FAILURE, props<{ error: HttpErrorResponse }>());

export const getUnreadCount = createAction(eNotificationsActions.NOTIFICATIONS_GET_UNREAD_COUNT);
export const getUnreadCountSuccess = createAction(
  eNotificationsActions.NOTIFICATIONS_GET_UNREAD_COUNT_SUCCESS,
  props<{ unreadCount: IUnreadNotificationsCount }>(),
);
export const getUnreadCountFailure = createAction(
  eNotificationsActions.NOTIFICATIONS_GET_UNREAD_COUNT_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const resetNotifications = createAction(eNotificationsActions.NOTIFICATIONS_RESET);
export const resetUnreadCount = createAction(eNotificationsActions.NOTIFICATIONS_UNREAD_COUNT_RESET);
