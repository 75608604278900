import { Action, createReducer, on } from '@ngrx/store';
import { IUsersStoreState, InitUsersState } from './users.state';
import * as usersActions from './users.actions';

const getUsersReducer = createReducer(
  InitUsersState,

  on(usersActions.getUsers, state => ({
    ...state,
    usersLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(usersActions.getUsersSuccess, (state, { users }) => ({
    ...state,
    users,
    usersLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(usersActions.getUsersFailure, (state, { error }) => ({
    ...state,
    usersLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(usersActions.getUser, state => ({
    ...state,
    userLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(usersActions.getUserSuccess, (state, { user }) => ({
    ...state,
    user,
    userLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(usersActions.getUserFailure, (state, { error }) => ({
    ...state,
    userLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(usersActions.createUser, state => ({
    ...state,
    userUpdatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(usersActions.createUserSuccess, state => ({
    ...state,
    userUpdatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(usersActions.createUserFailure, (state, { error }) => ({
    ...state,
    userUpdatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(usersActions.deleteUser, state => ({
    ...state,
    usersLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(usersActions.deleteUserSuccess, state => ({
    ...state,
    usersLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(usersActions.deleteUserFailure, (state, { error }) => ({
    ...state,
    usersLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(usersActions.updateUser, state => ({
    ...state,
    userUpdatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(usersActions.updateUserSuccess, state => ({
    ...state,
    userUpdatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(usersActions.updateUserFailure, (state, { error }) => ({
    ...state,
    userUpdatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(usersActions.resetUsers, state => ({
    ...state,
    users: null,
    usersLoadingState: null,
  })),
  on(usersActions.resetUser, state => ({
    ...state,
    user: null,
    userLoadingState: null,
    userUpdatingState: null,
  })),
  on(usersActions.resetUsersLoadingState, state => ({
    ...state,
    usersLoadingState: null,
  })),
  on(usersActions.resetUserLoadingState, state => ({
    ...state,
    userLoadingState: null,
  })),
  on(usersActions.resetUserUpdatingState, state => ({
    ...state,
    userUpdatingState: null,
  })),
  on(usersActions.resetUsersState, () => ({ ...InitUsersState })),
);

export const UsersReducer = (state: IUsersStoreState, action: Action) => {
  return getUsersReducer(state, action);
};
