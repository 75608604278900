import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { IHttpRequestState } from '../../models/http.model';
import { IUser, IUserPassword } from '../../models/user.model';

import * as userActions from './current-user.actions';
import * as userSelectors from './current-user.selectors';

@Injectable()
export class CurrentUserStoreService {
  constructor(private userStore: Store<IUser>) {}

  get currentUser$(): Observable<IUser | null> {
    return this.userStore.select(userSelectors.getCurrentUser);
  }

  get currentUserRole$(): Observable<string | undefined> {
    return this.userStore.select(userSelectors.getCurrentUserRole);
  }

  get currentUserLoadingState$(): Observable<IHttpRequestState | null> {
    return this.userStore.select(userSelectors.getUserLoadingState);
  }

  get currentUserUpdatingState$(): Observable<IHttpRequestState | null> {
    return this.userStore.select(userSelectors.getUserUpdatingState);
  }

  public getCurrentUser(): void {
    this.userStore.dispatch(userActions.getCurrentUser());
  }

  public updateCurrentUserData(user: IUser): void {
    this.userStore.dispatch(userActions.updateCurrentUserData({ user }));
  }

  public updateCurrentUserPassword(password: IUserPassword): void {
    this.userStore.dispatch(userActions.updateCurrentUserPassword({ password }));
  }

  public setCurrentUserOrganization(organization_id?: string): void {
    this.userStore.dispatch(userActions.setCurrentUserOrganization({ organization_id }));
  }

  public resetCurrentUser(): void {
    this.userStore.dispatch(userActions.resetCurrentUser());
  }
}
