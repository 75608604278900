import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ApiDictionaryService } from '../../api/api-dictionary.service';
import { IDictionaryApiParams } from '../../models/dictionary.model';
import { IDictionaryItems } from '../../models/dictionary.model';
import { eDictionaryActions } from './dictionary.actions';

@Injectable()
export class DictionaryEffects {
  constructor(private actions$: Actions, private apiDictionaryService: ApiDictionaryService) {}

  public getAccountingControlSystemsDictionary$: Observable<
    { type: eDictionaryActions; accountingControlSystems: IDictionaryItems | null } | { type: eDictionaryActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eDictionaryActions.DICTIONARY_ACCOUNTING_CONTROL_SYSTEMS_GET),
      switchMap((payload: { type: string; params: IDictionaryApiParams }) =>
        this.apiDictionaryService.getDictionary('accounting_control_systems', payload.params || { type: ['default'] }).pipe(
          map(accountingControlSystems => ({
            type: eDictionaryActions.DICTIONARY_ACCOUNTING_CONTROL_SYSTEMS_GET_SUCCESS,
            accountingControlSystems,
          })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eDictionaryActions.DICTIONARY_ACCOUNTING_CONTROL_SYSTEMS_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getAccountingSystemTypeDictionary$: Observable<
    { type: eDictionaryActions; accountingSystemType: IDictionaryItems | null } | { type: eDictionaryActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eDictionaryActions.DICTIONARY_ACCOUNTING_SYSTEM_TYPE_GET),
      switchMap((payload: { type: string; params: IDictionaryApiParams }) =>
        this.apiDictionaryService.getDictionary('accounting_system_type', payload.params || { type: ['default'] }).pipe(
          map(accountingSystemType => ({
            type: eDictionaryActions.DICTIONARY_ACCOUNTING_SYSTEM_TYPE_GET_SUCCESS,
            accountingSystemType,
          })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eDictionaryActions.DICTIONARY_ACCOUNTING_SYSTEM_TYPE_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getClassificationDictionary$: Observable<
    { type: eDictionaryActions; classification: IDictionaryItems | null } | { type: eDictionaryActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eDictionaryActions.DICTIONARY_CLASSIFICATION_GET),
      switchMap((payload: { type: string; params: IDictionaryApiParams }) =>
        this.apiDictionaryService.getDictionary('classification', payload.params || { type: ['default'] }).pipe(
          map(classification => ({
            type: eDictionaryActions.DICTIONARY_CLASSIFICATION_GET_SUCCESS,
            classification,
          })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eDictionaryActions.DICTIONARY_CLASSIFICATION_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getCranageTypesDictionary$: Observable<
    { type: eDictionaryActions; cranageTypes: IDictionaryItems | null } | { type: eDictionaryActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eDictionaryActions.DICTIONARY_CRANAGE_TYPES_GET),
      switchMap((payload: { type: string; params: IDictionaryApiParams }) =>
        this.apiDictionaryService.getDictionary('cranage_types', payload.params || { type: ['default'] }).pipe(
          map(cranageTypes => ({
            type: eDictionaryActions.DICTIONARY_CRANAGE_TYPES_GET_SUCCESS,
            cranageTypes,
          })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eDictionaryActions.DICTIONARY_CRANAGE_TYPES_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getElectricityGridTypeDictionary$: Observable<
    { type: eDictionaryActions; electricityGridType: IDictionaryItems | null } | { type: eDictionaryActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eDictionaryActions.DICTIONARY_ELECTRICITY_GRID_TYPE_GET),
      switchMap((payload: { type: string; params: IDictionaryApiParams }) =>
        this.apiDictionaryService.getDictionary('electricity_grid_type', payload.params || { type: ['default'] }).pipe(
          map(electricityGridType => ({
            type: eDictionaryActions.DICTIONARY_ELECTRICITY_GRID_TYPE_GET_SUCCESS,
            electricityGridType,
          })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eDictionaryActions.DICTIONARY_ELECTRICITY_GRID_TYPE_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getFireExtSystemTypeDictionary$: Observable<
    { type: eDictionaryActions; fireExtSystemType: IDictionaryItems | null } | { type: eDictionaryActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eDictionaryActions.DICTIONARY_FIRE_EXT_SYSTEM_TYPE_GET),
      switchMap((payload: { type: string; params: IDictionaryApiParams }) =>
        this.apiDictionaryService.getDictionary('fire_ext_system_type', payload.params || { type: ['default'] }).pipe(
          map(fireExtSystemType => ({
            type: eDictionaryActions.DICTIONARY_FIRE_EXT_SYSTEM_TYPE_GET_SUCCESS,
            fireExtSystemType,
          })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eDictionaryActions.DICTIONARY_FIRE_EXT_SYSTEM_TYPE_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getFloorCoveringDictionary$: Observable<
    { type: eDictionaryActions; floorCovering: IDictionaryItems | null } | { type: eDictionaryActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eDictionaryActions.DICTIONARY_FLOOR_COVERING_GET),
      switchMap((payload: { type: string; params: IDictionaryApiParams }) =>
        this.apiDictionaryService.getDictionary('floor_covering', payload.params || { type: ['default'] }).pipe(
          map(floorCovering => ({
            type: eDictionaryActions.DICTIONARY_FLOOR_COVERING_GET_SUCCESS,
            floorCovering,
          })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eDictionaryActions.DICTIONARY_FLOOR_COVERING_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getHeatingSystemTypeDictionary$: Observable<
    { type: eDictionaryActions; heatingSystemType: IDictionaryItems | null } | { type: eDictionaryActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eDictionaryActions.DICTIONARY_HEATING_SYSTEM_TYPE_GET),
      switchMap((payload: { type: string; params: IDictionaryApiParams }) =>
        this.apiDictionaryService.getDictionary('heating_system_type', payload.params || { type: ['default'] }).pipe(
          map(heatingSystemType => ({
            type: eDictionaryActions.DICTIONARY_HEATING_SYSTEM_TYPE_GET_SUCCESS,
            heatingSystemType,
          })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eDictionaryActions.DICTIONARY_HEATING_SYSTEM_TYPE_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getLoadingUnloadingMethodsDictionary$: Observable<
    { type: eDictionaryActions; loadingUnloadingMethods: IDictionaryItems | null } | { type: eDictionaryActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eDictionaryActions.DICTIONARY_LOADING_UNLOADING_METHODS_GET),
      switchMap((payload: { type: string; params: IDictionaryApiParams }) =>
        this.apiDictionaryService.getDictionary('loading_unloading_methods', payload.params || { type: ['default'] }).pipe(
          map(loadingUnloadingMethods => ({
            type: eDictionaryActions.DICTIONARY_LOADING_UNLOADING_METHODS_GET_SUCCESS,
            loadingUnloadingMethods,
          })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eDictionaryActions.DICTIONARY_LOADING_UNLOADING_METHODS_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getLoadingUnloadingStructTypesDictionary$: Observable<
    | { type: eDictionaryActions; loadingUnloadingStructTypes: IDictionaryItems | null }
    | { type: eDictionaryActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eDictionaryActions.DICTIONARY_LOADING_UNLOADING_STRUCT_TYPES_GET),
      switchMap((payload: { type: string; params: IDictionaryApiParams }) =>
        this.apiDictionaryService.getDictionary('loading_unloading_struct_types', payload.params || { type: ['default'] }).pipe(
          map(loadingUnloadingStructTypes => ({
            type: eDictionaryActions.DICTIONARY_LOADING_UNLOADING_STRUCT_TYPES_GET_SUCCESS,
            loadingUnloadingStructTypes,
          })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eDictionaryActions.DICTIONARY_LOADING_UNLOADING_STRUCT_TYPES_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getLoadingUnloadingTechTypesDictionary$: Observable<
    | { type: eDictionaryActions; loadingUnloadingTechTypes: IDictionaryItems | null }
    | { type: eDictionaryActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eDictionaryActions.DICTIONARY_LOADING_UNLOADING_TECH_TYPES_GET),
      switchMap((payload: { type: string; params: IDictionaryApiParams }) =>
        this.apiDictionaryService.getDictionary('loading_unloading_tech_types', payload.params || { type: ['default'] }).pipe(
          map(loadingUnloadingTechTypes => ({
            type: eDictionaryActions.DICTIONARY_LOADING_UNLOADING_TECH_TYPES_GET_SUCCESS,
            loadingUnloadingTechTypes,
          })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eDictionaryActions.DICTIONARY_LOADING_UNLOADING_TECH_TYPES_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getOfficeFacilityTypesDictionary$: Observable<
    { type: eDictionaryActions; officeFacilityTypes: IDictionaryItems | null } | { type: eDictionaryActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eDictionaryActions.DICTIONARY_OFFICE_FACILITY_TYPES_GET),
      switchMap((payload: { type: string; params: IDictionaryApiParams }) =>
        this.apiDictionaryService.getDictionary('office_facility_types', payload.params || { type: ['default'] }).pipe(
          map(officeFacilityTypes => ({
            type: eDictionaryActions.DICTIONARY_OFFICE_FACILITY_TYPES_GET_SUCCESS,
            officeFacilityTypes,
          })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eDictionaryActions.DICTIONARY_OFFICE_FACILITY_TYPES_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getParkingTypeDictionary$: Observable<
    { type: eDictionaryActions; parkingType: IDictionaryItems | null } | { type: eDictionaryActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eDictionaryActions.DICTIONARY_PARKING_TYPE_GET),
      switchMap((payload: { type: string; params: IDictionaryApiParams }) =>
        this.apiDictionaryService.getDictionary('parking_type', payload.params || { type: ['default'] }).pipe(
          map(parkingType => ({
            type: eDictionaryActions.DICTIONARY_PARKING_TYPE_GET_SUCCESS,
            parkingType,
          })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eDictionaryActions.DICTIONARY_PARKING_TYPE_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getStorageFormatDictionary$: Observable<
    { type: eDictionaryActions; storageFormat: IDictionaryItems | null } | { type: eDictionaryActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eDictionaryActions.DICTIONARY_STORAGE_FORMAT_GET),
      switchMap((payload: { type: string; params: IDictionaryApiParams }) =>
        this.apiDictionaryService.getDictionary('storage_format', payload.params || { type: ['default'] }).pipe(
          map(storageFormat => ({
            type: eDictionaryActions.DICTIONARY_STORAGE_FORMAT_GET_SUCCESS,
            storageFormat,
          })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eDictionaryActions.DICTIONARY_STORAGE_FORMAT_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getStorageSystemDictionary$: Observable<
    { type: eDictionaryActions; storageSystem: IDictionaryItems | null } | { type: eDictionaryActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eDictionaryActions.DICTIONARY_STORAGE_SYSTEM_GET),
      switchMap((payload: { type: string; params: IDictionaryApiParams }) =>
        this.apiDictionaryService.getDictionary('storage_system', payload.params || { type: ['default'] }).pipe(
          map(storageSystem => ({
            type: eDictionaryActions.DICTIONARY_STORAGE_SYSTEM_GET_SUCCESS,
            storageSystem,
          })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eDictionaryActions.DICTIONARY_STORAGE_SYSTEM_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getStorageSystemDictionaryPublic$: Observable<
    { type: eDictionaryActions; storageSystem: IDictionaryItems | null } | { type: eDictionaryActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eDictionaryActions.DICTIONARY_STORAGE_SYSTEM_PUBLIC_GET),
      switchMap((payload: { type: string; params: IDictionaryApiParams }) =>
        this.apiDictionaryService.getDictionaryPublic('storage_system', payload.params || { type: ['default'] }).pipe(
          map(storageSystem => ({
            type: eDictionaryActions.DICTIONARY_STORAGE_SYSTEM_PUBLIC_GET_SUCCESS,
            storageSystem,
          })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eDictionaryActions.DICTIONARY_STORAGE_SYSTEM_PUBLIC_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getTaxationTypeDictionary$: Observable<
    { type: eDictionaryActions; taxationType: IDictionaryItems | null } | { type: eDictionaryActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eDictionaryActions.DICTIONARY_TAXATION_TYPE_GET),
      switchMap((payload: { type: string; params: IDictionaryApiParams }) =>
        this.apiDictionaryService.getDictionary('taxation_type', payload.params || { type: ['default'] }).pipe(
          map(taxationType => ({
            type: eDictionaryActions.DICTIONARY_TAXATION_TYPE_GET_SUCCESS,
            taxationType,
          })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eDictionaryActions.DICTIONARY_TAXATION_TYPE_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getTemperatureModeDictionary$: Observable<
    { type: eDictionaryActions; temperatureMode: IDictionaryItems | null } | { type: eDictionaryActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eDictionaryActions.DICTIONARY_TEMPERATURE_MODE_GET),
      switchMap((payload: { type: string; params: IDictionaryApiParams }) =>
        this.apiDictionaryService.getDictionary('temperature_mode', payload.params || { type: ['default'] }).pipe(
          map(temperatureMode => ({
            type: eDictionaryActions.DICTIONARY_TEMPERATURE_MODE_GET_SUCCESS,
            temperatureMode,
          })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eDictionaryActions.DICTIONARY_TEMPERATURE_MODE_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getUnitDictionary$: Observable<
    { type: eDictionaryActions; unit: IDictionaryItems | null } | { type: eDictionaryActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eDictionaryActions.DICTIONARY_UNIT_GET),
      switchMap((payload: { type: string; params: IDictionaryApiParams }) =>
        this.apiDictionaryService.getDictionary('unit', payload.params || { type: ['default'] }).pipe(
          map(unit => ({
            type: eDictionaryActions.DICTIONARY_UNIT_GET_SUCCESS,
            unit,
          })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eDictionaryActions.DICTIONARY_UNIT_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getUnitDistanceDictionary$: Observable<
    { type: eDictionaryActions; unitDistance: IDictionaryItems | null } | { type: eDictionaryActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eDictionaryActions.DICTIONARY_UNIT_DISTANCE_GET),
      switchMap((payload: { type: string; params: IDictionaryApiParams }) =>
        this.apiDictionaryService.getDictionary('unit', payload.params || { type: ['distance'] }).pipe(
          map(unitDistance => ({
            type: eDictionaryActions.DICTIONARY_UNIT_DISTANCE_GET_SUCCESS,
            unitDistance,
          })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eDictionaryActions.DICTIONARY_UNIT_DISTANCE_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getUnitMassDictionary$: Observable<
    { type: eDictionaryActions; unitMass: IDictionaryItems | null } | { type: eDictionaryActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eDictionaryActions.DICTIONARY_UNIT_MASS_GET),
      switchMap((payload: { type: string; params: IDictionaryApiParams }) =>
        this.apiDictionaryService.getDictionary('unit', payload.params || { type: ['mass'] }).pipe(
          map(unitMass => ({
            type: eDictionaryActions.DICTIONARY_UNIT_MASS_GET_SUCCESS,
            unitMass,
          })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eDictionaryActions.DICTIONARY_UNIT_MASS_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getUnitSquareDictionary$: Observable<
    { type: eDictionaryActions; unitSquare: IDictionaryItems | null } | { type: eDictionaryActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eDictionaryActions.DICTIONARY_UNIT_SQUARE_GET),
      switchMap((payload: { type: string; params: IDictionaryApiParams }) =>
        this.apiDictionaryService.getDictionary('unit', payload.params || { type: ['square'] }).pipe(
          map(unitSquare => ({
            type: eDictionaryActions.DICTIONARY_UNIT_SQUARE_GET_SUCCESS,
            unitSquare,
          })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eDictionaryActions.DICTIONARY_UNIT_SQUARE_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getUnitTemperatureDictionary$: Observable<
    { type: eDictionaryActions; unitTemperature: IDictionaryItems | null } | { type: eDictionaryActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eDictionaryActions.DICTIONARY_UNIT_TEMPERATURE_GET),
      switchMap((payload: { type: string; params: IDictionaryApiParams }) =>
        this.apiDictionaryService.getDictionary('unit', payload.params || { type: ['temperature'] }).pipe(
          map(unitTemperature => ({
            type: eDictionaryActions.DICTIONARY_UNIT_TEMPERATURE_GET_SUCCESS,
            unitTemperature,
          })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eDictionaryActions.DICTIONARY_UNIT_TEMPERATURE_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getLegalTypeDictionary$: Observable<
    { type: eDictionaryActions; legalType: IDictionaryItems | null } | { type: eDictionaryActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eDictionaryActions.DICTIONARY_LEGAL_TYPE_GET),
      switchMap((payload: { type: string; params: IDictionaryApiParams }) =>
        this.apiDictionaryService.getDictionary('legal_type', payload.params || { type: ['default'] }).pipe(
          map(legalType => ({
            type: eDictionaryActions.DICTIONARY_LEGAL_TYPE_GET_SUCCESS,
            legalType,
          })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eDictionaryActions.DICTIONARY_LEGAL_TYPE_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getPartnerTypeDictionary$: Observable<
    { type: eDictionaryActions; partnerType: IDictionaryItems | null } | { type: eDictionaryActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eDictionaryActions.DICTIONARY_PARTNER_TYPE_GET),
      switchMap((payload: { type: string; params: IDictionaryApiParams }) =>
        this.apiDictionaryService.getDictionary('partner_type', payload.params || { type: ['default'] }).pipe(
          map(partnerType => ({
            type: eDictionaryActions.DICTIONARY_PARTNER_TYPE_GET_SUCCESS,
            partnerType,
          })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eDictionaryActions.DICTIONARY_PARTNER_TYPE_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );
}
