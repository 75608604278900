import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwitchTabsComponent } from './component/switch-tabs.component';

@NgModule({
  declarations: [SwitchTabsComponent],
  imports: [CommonModule],
  exports: [SwitchTabsComponent],
})
export class SwitchTabsModule {}
