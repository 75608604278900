import { Component, ChangeDetectionStrategy, forwardRef, Input, ViewEncapsulation, OnInit, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NG_VALIDATORS, ValidationErrors, Validator, FormControl } from '@angular/forms';
import { DelegateFactoryService, eDelegateType } from '../../../delegate/service/delegate-factory.service';
import { IDelegate, ISelectableItem } from '../../../delegate/model/delegate.model';
import { tooltipPlacements } from '../../../directives/tooltip/tooltip.directive';

export type radioButtonThemes = 'primary' | 'simple';

@Component({
  selector: 'tlp-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true,
    },
  ],
})
export class RadioButtonComponent implements ControlValueAccessor, OnInit, Validator {
  @Input() set delegateType(delegateType: eDelegateType | string | undefined) {
    if (!delegateType) {
      return;
    }
    this.delegate = this.selectDelegateFactoryService.getDelegate(delegateType as eDelegateType);
  }
  @Input() public delegate: IDelegate | null = null;
  @Input() public items!: ISelectableItem[] | undefined;
  @Input() public shortItemsName: boolean = false;

  @Input() public required: boolean = false;
  @Input() public selectable: boolean = true;

  @Input() public theme: radioButtonThemes = 'primary';
  @Input() public infoTip: string | null = null;
  @Input() public infoTipPlacement: tooltipPlacements = 'follow';

  // tslint:disable-next-line: no-any
  @Output() public changeValue: EventEmitter<any> = new EventEmitter<any>();

  public formControl!: FormControl;
  public value: string | number | boolean | undefined = undefined;

  private onChange: (_: string | number | boolean | undefined) => void;
  private onTouched: () => void;

  constructor(private selectDelegateFactoryService: DelegateFactoryService) {
    this.onChange = (_: string | number | boolean | undefined) => {};
    this.onTouched = () => {};
  }

  public ngOnInit(): void {
    this.formControl = new FormControl(null);
    this.delegate?.get(this.shortItemsName);
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: string | undefined): void {
    if (value) {
      this.value = value;
      this.formControl.setValue(value);

      const itemFromValue = this.items?.find(i => i.value === value);
      if (itemFromValue) {
        this.selectItem(itemFromValue);
      }

      if (this.delegate) {
        this.delegate.getResult$.subscribe(searchResults => {
          const item = searchResults.find(result => result.value === value);
          if (item) {
            this.selectItem(item);
          }
        });
      }
    }
  }

  public selectItem(item: ISelectableItem): void {
    if (!this.selectable) {
      return;
    }

    this.changeValue.emit(item);
    this.value = item?.value;
    this.onChange(this.value);
    this.onTouched();
  }

  public validate(): ValidationErrors | null {
    return this.formControl.errors;
  }
}
