import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

export type contentBlockTypes = 'default' | 'flat' | 'transparent' | 'stroke';
type arrowLocationTypes = 'top-left' | 'top-right';

@Component({
  selector: 'tlp-content-block',
  templateUrl: './content-block.component.html',
  styleUrls: ['./content-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockComponent {
  @Input() public type: contentBlockTypes = 'default';
  @Input() public padding: string = '0';
  @Input() public minWidth: string = 'auto';
  @Input() public dialog: boolean = false;
  @Input() public arrowLocation: arrowLocationTypes = 'top-right';
}
