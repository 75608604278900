import { Action, createReducer, on } from '@ngrx/store';
import { InitNotificationsState, INotificationsState } from './notifications.state';
import * as notificationsActions from './notifications.actions';

const notificationsReducer = createReducer(
  InitNotificationsState,
  on(notificationsActions.getNotifications, state => ({ ...state, isLoading: true, isDidLoad: false, error: null })),
  on(notificationsActions.getNotificationsSuccess, (state, { notifications }) => ({
    ...state,
    isLoading: false,
    isDidLoad: true,
    notifications,
  })),
  on(notificationsActions.getNotificationsFailure, (state, { error }) => ({ ...state, error, isLoading: false, isDidLoad: false })),

  on(notificationsActions.getUnreadCount, state => ({ ...state, error: null })),
  on(notificationsActions.getUnreadCountSuccess, (state, { unreadCount }) => ({ ...state, unreadCount: unreadCount.count })),
  on(notificationsActions.getUnreadCountFailure, (state, { error }) => ({ ...state, error })),

  on(notificationsActions.resetNotifications, state => ({ ...state, notifications: null })),
  on(notificationsActions.resetUnreadCount, state => ({ ...state, unreadCount: 0 })),
);

export const NotificationsReducer = (state: INotificationsState, action: Action) => {
  return notificationsReducer(state, action);
};
