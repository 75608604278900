import { userRoles } from '../models/user.model';

export const ORGANIZATION_RELATED_ROLES: userRoles[] = ['holder', 'renter'];

export const USER_ROLE_DICTIONARY = {
  admin: 'Администратор',
  holder: 'Арендодатель',
  renter: 'Арендатор',
  sb: 'Служба Безопасности', // СБ = ДКЗ
  dsl: 'Департамент Складской Логистики',
  cs: 'Клиентский сервис',
};
