import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { InputMask } from 'src/app/_core/const/input-mask.const';

@Component({
  selector: 'tlp-input-time',
  templateUrl: './input-time.component.html',
  styleUrls: ['./input-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTimeComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputTimeComponent),
      multi: true,
    },
  ],
})
export class InputTimeComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {
  @Input() public placeholder: string = '';
  @Input() public required: boolean = false;
  @Input() public disabled: boolean = false;

  public formControl!: FormControl | null;
  public isDisabled: boolean = false;
  public mask: string = '';

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.formControl = new FormControl();
    this.mask = InputMask.time;
  }

  public ngOnDestroy(): void {
    this.formControl = null;
  }

  private onChange = (_: number | string) => {};
  private onTouched = () => {};

  public registerOnChange(fn: () => {}): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  public writeValue(outsideValue: number | string | undefined): void {
    if (outsideValue !== undefined) {
      this.formControl?.setValue(outsideValue, { emitEvent: false });
      this.onTouched();
      this.onChange(outsideValue);
      this.cdr.markForCheck();
    }
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.formControl?.disable();
      this.isDisabled = true;
    } else {
      this.formControl?.enable();
      this.isDisabled = false;
    }

    this.cdr.markForCheck();
  }

  public onValueChange(event: KeyboardEvent | FocusEvent): void {
    const value = (event.target as HTMLInputElement).value;
    this.onTouched();
    this.onChange(value);
    this.cdr.markForCheck();
  }

  public validate(): ValidationErrors | null {
    if (this.invalidTimeFormat()) {
      return { invalidTimeFormat: true };
    }

    return this.formControl?.errors || null;
  }

  public get isError(): boolean {
    return !!this.formControl?.errors && this.formControl?.touched;
  }

  private invalidTimeFormat(): boolean {
    return this.formControl?.value ? this.formControl?.errors?.mask || !/\b[0-2][0-9]:[0-5][0-9]\b/.test(this.formControl?.value) : false;
  }
}
