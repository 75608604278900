import { ChangeDetectionStrategy, Component, Input, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'tlp-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CounterComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CounterComponent implements OnInit, ControlValueAccessor {
  @Input() public minValue: number = 1;
  @Input() public maxValue: number = 99;
  @Input() public counterValue: number = 1;

  public isDisabledIncrement!: boolean;
  public isDisabledDecrement!: boolean;
  public propagateChange = (_: string | number | boolean) => {};

  public onChangeValue(): void {
    this.isDisabledIncrement = false;
    this.isDisabledDecrement = false;

    if (this.counterValue >= this.maxValue) {
      this.isDisabledIncrement = true;
      this.counterValue = this.maxValue;
    }

    if (this.counterValue <= this.minValue) {
      this.isDisabledDecrement = true;
      this.counterValue = this.minValue;
    }

    this.propagateChange(this.counterValue);
  }

  public onIncrement(): void {
    this.counterValue++;
    this.onChangeValue();
  }

  public onDecrement(): void {
    this.counterValue--;
    this.onChangeValue();
  }

  public writeValue(value: number | null): void {
    if (value !== null) {
      this.counterValue = value;
      this.propagateChange(this.counterValue);
    }
  }

  public registerOnChange(fn: (_: string | number | boolean) => void): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(): void {}

  public ngOnInit(): void {
    this.onChangeValue();
  }
}
