import {
  Directive,
  Input,
  HostListener,
  ElementRef,
  Renderer2,
  TemplateRef,
  OnDestroy,
  ViewContainerRef,
  EmbeddedViewRef,
} from '@angular/core';

export type tooltipPlacements = 'top' | 'bottom' | 'left' | 'right' | 'follow';

@Directive({
  selector: '[tlpTooltip]',
})
export class TooltipDirective implements OnDestroy {
  // tslint:disable-next-line: no-any
  @Input('tlpTooltip') public tooltip!: string | TemplateRef<any> | null;
  @Input() public tooltipPlacement: tooltipPlacements = 'bottom';
  @Input() public tooltipWidth: string = 'auto';
  @Input() public tooltipDelay: number = 100;
  @Input() public tooltipGeneralOffset: number = 10;
  @Input() public tooltipVerticalOffset: number = 0;
  @Input() public relativeContainer: boolean = false;

  // tslint:disable-next-line: no-any
  private tooltipNode!: HTMLElement | EmbeddedViewRef<any> | null;

  constructor(private el: ElementRef, private renderer: Renderer2, private vcRef: ViewContainerRef) {}

  @HostListener('mouseenter') public onMouseEnter(): void {
    if (!this.tooltipNode && this.tooltip) {
      this.show();
    }
  }

  @HostListener('mousemove', ['$event']) public onMouseMove(event: MouseEvent): void {
    if (this.tooltipNode && this.tooltipPlacement === 'follow') {
      this.setPosition(event);
    }
  }

  @HostListener('mouseleave') public onMouseLeave(): void {
    if (this.tooltipNode) {
      this.hide();
    }
  }

  public ngOnDestroy(): void {
    this.hide();
  }

  private show(): void {
    this.create();
    this.setStyles();
    this.setPosition();
  }

  private hide(): void {
    if (!this.isTemplateRef() && this.tooltipNode) {
      this.renderer.removeClass(this.targetElement, 'tooltip--visible');
      this.renderer.removeChild(document.body, this.tooltipNode);
    }

    this.vcRef.clear();
    this.tooltipNode = null;
  }

  private create(): void {
    if (this.isTemplateRef()) {
      // tslint:disable-next-line: no-any
      this.tooltipNode = this.vcRef.createEmbeddedView(this.tooltip as TemplateRef<any>);
    } else {
      this.tooltipNode = this.renderer.createElement('span');
      this.renderer.appendChild(this.tooltipNode, this.renderer.createText(this.tooltip as string));
      this.renderer.appendChild(document.body, this.tooltipNode);
    }
  }

  private setStyles(): void {
    this.renderer.addClass(this.targetElement, 'tooltip');
    this.renderer.addClass(this.targetElement, `tooltip--${this.tooltipPlacement}`);
    this.renderer.addClass(this.targetElement, 'tooltip--visible');

    this.renderer.setStyle(this.targetElement, '-webkit-transition', `opacity ${this.tooltipDelay}ms`);
    if (this.tooltipWidth !== 'auto') {
      this.renderer.setStyle(this.targetElement, 'max-width', 'unset');
      this.renderer.setStyle(this.targetElement, 'width', this.tooltipWidth);
    }
    this.renderer.setStyle(this.targetElement, '-moz-transition', `opacity ${this.tooltipDelay}ms`);
    this.renderer.setStyle(this.targetElement, '-o-transition', `opacity ${this.tooltipDelay}ms`);
    this.renderer.setStyle(this.targetElement, 'transition', `opacity ${this.tooltipDelay}ms`);
  }

  private setPosition(event?: MouseEvent): void {
    const mousePos = event ? { x: event?.clientX, y: event?.clientY } : null;
    const hostPos = this.el.nativeElement.getBoundingClientRect();
    const tooltipPos = this.targetElement ? this.targetElement.getBoundingClientRect() : null;
    const scrollPos: number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    let top!: number;
    let left!: number;

    if (this.tooltipPlacement === 'top') {
      if (this.relativeContainer) {
        top = tooltipPos ? -tooltipPos.height - this.tooltipGeneralOffset : 0;
        left = (hostPos.width - (tooltipPos ? tooltipPos.width : 0)) / 2;
      } else {
        top = hostPos.top - (tooltipPos ? tooltipPos.height + this.tooltipGeneralOffset : 0);
        left = hostPos.left + (hostPos.width - (tooltipPos ? tooltipPos.width : 0)) / 2;
      }
    }

    if (this.tooltipPlacement === 'bottom') {
      if (this.relativeContainer) {
        top = hostPos.height + this.tooltipGeneralOffset;
        left = (hostPos.width - (tooltipPos ? tooltipPos.width : 0)) / 2;
      } else {
        top = hostPos.bottom + this.tooltipGeneralOffset;
        left = hostPos.left + (hostPos.width - (tooltipPos ? tooltipPos.width : 0)) / 2;
      }
    }

    if (this.tooltipPlacement === 'left') {
      if (this.relativeContainer) {
        top = (hostPos.height - (tooltipPos ? tooltipPos.height : 0)) / 2 + this.tooltipVerticalOffset;
        left = tooltipPos ? -tooltipPos.width - this.tooltipGeneralOffset : 0;
      } else {
        top = hostPos.top + (hostPos.height - (tooltipPos ? tooltipPos.height : 0)) / 2 + this.tooltipVerticalOffset;
        left = hostPos.left - (tooltipPos ? tooltipPos.width : 0) - this.tooltipGeneralOffset;
      }
    }

    if (this.tooltipPlacement === 'right') {
      if (this.relativeContainer) {
        top = (hostPos.height - (tooltipPos ? tooltipPos.height : 0)) / 2 + this.tooltipVerticalOffset;
        left = hostPos.width + this.tooltipGeneralOffset;
      } else {
        top = hostPos.top + (hostPos.height - (tooltipPos ? tooltipPos.height : 0)) / 2 + this.tooltipVerticalOffset;
        left = hostPos.right + this.tooltipGeneralOffset;
      }
    }

    if (this.tooltipPlacement === 'follow' && mousePos) {
      top = mousePos.y;
      left = mousePos.x + this.tooltipGeneralOffset * 2;

      if (this.relativeContainer) {
        top = top - hostPos.top;
        left = left - hostPos.left;
      }
    }

    if (!this.relativeContainer) {
      top = top + scrollPos;
    }

    this.renderer.setStyle(this.targetElement, 'top', `${top}px`);
    this.renderer.setStyle(this.targetElement, 'left', `${left}px`);
  }

  private isTemplateRef(): boolean {
    return this.tooltip instanceof TemplateRef;
  }

  /* tslint:disable: no-any */
  private get targetElement(): any {
    return this.tooltip ? (this.isTemplateRef() ? (this.tooltipNode as EmbeddedViewRef<any>).rootNodes[0] : this.tooltipNode) : null;
  }
  /* tslint:enable: no-any */
}
