import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UsersEffects } from './users.effects';
import { UsersReducer } from './users.reducer';
import { ApiUsersService } from '../../api/admin/api-users.service';
import { UsersStoreService } from './users-store.service';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('admin-users', UsersReducer), EffectsModule.forFeature([UsersEffects])],
  providers: [UsersStoreService, ApiUsersService],
})
export class UsersStoreModule {}
