<div class="booking-calendar">
  <div class="booking-calendar__header">
    <h4 class="booking-calendar__heading" *ngIf="mainDate">
      {{ displayedMainDate }}
    </h4>
    <div class="booking-calendar__hints">
      <div class="booking-calendar__hint booking-calendar__hint--free-space">
        <span class="booking-calendar__label booking-calendar__label--dark">Свободно</span>
      </div>
      <div class="booking-calendar__hint booking-calendar__hint--reserved-space">
        <span class="booking-calendar__label booking-calendar__label--dark">Забронировано</span>
      </div>
    </div>
  </div>
  <div class="booking-calendar__body" [tlpLoading]="bookingDatesLoading" [addLoaderToChild]="false">
    <tlp-button
      class="booking-calendar__button booking-calendar__button--left"
      theme="link"
      *ngIf="canChangePeriod('left')"
      [isIconButton]="true"
      (click)="changeDisplayedPeriod('left')"
    >
      <svg-icon-sprite src="./assets/sprites/sprite.svg#ic-left" width="16px" height="16px"></svg-icon-sprite>
    </tlp-button>
    <tlp-button
      class="booking-calendar__button booking-calendar__button--right"
      theme="link"
      *ngIf="canChangePeriod('right')"
      [isIconButton]="true"
      (click)="changeDisplayedPeriod('right')"
    >
      <svg-icon-sprite src="./assets/sprites/sprite.svg#ic-right" width="16px" height="16px"></svg-icon-sprite>
    </tlp-button>
    <div class="booking-calendar__row" [@calendarSlider]="currentPeriodDirectionHandler">
      <div class="booking-calendar__cell" *ngFor="let calendarDate of displayedPeriod">
        <span class="booking-calendar__date-percentage" [style.height.%]="calendarDate.bookingPercent"></span>
        <span class="booking-calendar__date" [tlpTooltip]="dateTooltipInfo" [relativeContainer]="true">
          {{ calendarDate.date | date: 'd' }}
        </span>

        <ng-template #dateTooltipInfo>
          <div class="booking-calendar__amount-tooltip">
            <tlp-booking-amount
              [calendarDate]="calendarDate"
              [totalAmount]="totalAmount"
              [amountUnit]="amountUnit"
            ></tlp-booking-amount>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
