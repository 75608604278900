import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IHttpRequestState } from '../../models/http.model';
import { IUsersStoreState } from './users.state';

export const getUserStoreState = createFeatureSelector<IUsersStoreState>('admin-users');

export const getUser = createSelector(getUserStoreState, (state: IUsersStoreState) => state.user);
export const getUsers = createSelector(getUserStoreState, (state: IUsersStoreState) => state.users);

export const getUsersLoadingState = createSelector(
  getUserStoreState,
  (state: IUsersStoreState) =>
    ({
      ...state.usersLoadingState,
    } as IHttpRequestState),
);
export const getUserLoadingState = createSelector(
  getUserStoreState,
  (state: IUsersStoreState) =>
    ({
      ...state.userLoadingState,
    } as IHttpRequestState),
);
export const getUserUpdatingState = createSelector(
  getUserStoreState,
  (state: IUsersStoreState) =>
    ({
      ...state.userUpdatingState,
    } as IHttpRequestState),
);
