import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { InputMaskModule } from 'racoon-mask-raw';
import { DatepickerComponent } from './component/datepicker.component';

@NgModule({
  declarations: [DatepickerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    IconSpriteModule,
    InputMaskModule,
  ],
  exports: [DatepickerComponent],
})
export class DatepickerModule {}
