import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { CurrentUserStoreService } from '../../../_core/stores/current-user/current-user-store.service';
import { DictionaryHelperService } from '../../../_core/services/dictionary-helper.service';

import { INavLink } from '../../../_core/models/navigation.model';
import { IUser, userRoles } from '../../../_core/models/user.model';

import { ORGANIZATION_RELATED_ROLES, USER_ROLE_DICTIONARY } from '../../../_core/const/roles.const';
import * as navLinks from '../../../_core/const/navigation.const';

@Component({
  selector: 'tlp-navigation-list',
  templateUrl: './navigation-list.component.html',
  styleUrls: ['./navigation-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationListComponent implements OnInit, OnDestroy {
  @Input() public showIcons: boolean = true;
  @Input() public showProfileLink: boolean = false;
  @Input() public dark: boolean = false;

  public activeLink: string | undefined;
  public user: IUser | null = null;

  private currentUser$: Observable<IUser | null> = this.currentUserStoreService.currentUser$;
  private currentUserRole!: userRoles;
  private currentUserOrganizationId?: string | null;
  private userSub: Subscription | null = null;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private currentUserStoreService: CurrentUserStoreService,
    private dictionaryHelperService: DictionaryHelperService,
  ) {}

  public ngOnInit(): void {
    this.activeLink = this.router.url;

    this.router.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd)).subscribe((result: NavigationEnd) => {
      this.activeLink = result.url;
      this.cdr.detectChanges();
    });

    this.userSub = this.currentUser$.subscribe(currentUser => {
      this.user = currentUser;
      if (currentUser?.role) {
        this.currentUserRole = currentUser.role;
        this.currentUserOrganizationId = currentUser.organization_id;
        this.cdr.detectChanges();
      }
    });
  }

  public ngOnDestroy(): void {
    this.userSub?.unsubscribe();
  }

  public getRoleName(role: userRoles): string {
    return this.dictionaryHelperService.getItemName(USER_ROLE_DICTIONARY, role as string);
  }

  public get navLinks(): INavLink[] {
    if (ORGANIZATION_RELATED_ROLES.includes(this.currentUserRole) && !this.currentUserOrganizationId) {
      return [];
    }

    switch (this.currentUserRole) {
      case 'admin':
        return navLinks.adminNavLinks;
      case 'holder':
        return navLinks.holderNavLinks;
      case 'renter':
        return navLinks.renterNavLinks;
      case 'sb':
        return navLinks.sbNavLinks;
      case 'dsl':
        return navLinks.dslNavLinks;
      case 'cs':
        return navLinks.csNavLinks;
      default:
        return [];
    }
  }

  public isActiveLink(link: string): boolean {
    const matches: RegExpMatchArray | null = this.activeLink && link ? this.activeLink.match(link) : null;
    return matches ? true : false;
  }
}
