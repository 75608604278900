import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

import { ButtonModule } from '../button/button.module';
import { FileDndModule } from '../directives/file-dnd/file-dnd.module';
import { HintListModule } from '../hint-list/hint-list.module';
import { PhotoCarouselModule } from '../photo-carousel/photo-carousel.module';
import { NumeralPluralsPipe } from '../pipes/numeral-plurals.pipe';

import { PhotoUploadComponent } from './component/photo-upload/photo-upload.component';

@NgModule({
  declarations: [PhotoUploadComponent],
  imports: [CommonModule, IconSpriteModule, ButtonModule, FileDndModule, HintListModule, PhotoCarouselModule],
  exports: [PhotoUploadComponent],
  providers: [NumeralPluralsPipe],
})
export class PhotoUploadModule {}
