import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NotificationsEffects } from './notifications.effects';
import { NotificationsReducer } from './notifications.reducer';
import { ApiNotificationsService } from '../../api/api-notifications.service';
import { NotificationsStoreService } from './notifications-store.service';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('notifications', NotificationsReducer), EffectsModule.forFeature([NotificationsEffects])],
  providers: [ApiNotificationsService, NotificationsStoreService],
})
export class NotificationsStoreModule {}
