import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from '../directives/tooltip/tooltip.module';

import { RadioButtonComponent } from './component/radio-button/radio-button.component';

@NgModule({
  declarations: [RadioButtonComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TooltipModule],
  exports: [RadioButtonComponent],
})
export class RadioButtonModule {}
