import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractApiMethodsService } from '../abstract-api-methods.service';
import { ILogs, ILogsApiParams, ILogsAvailableEvent, ILogsAvailableEventsApiParams } from '../../models/logs.model';

@Injectable()
export class ApiLogsService extends AbstractApiMethodsService {
  public getLogs(params: ILogsApiParams): Observable<ILogs | null> {
    return this.httpPost(params, 'adm/log/index');
  }

  public getLogsAvailableEvents(params: ILogsAvailableEventsApiParams): Observable<ILogsAvailableEvent[] | null> {
    return this.httpPost(params, 'adm/log/available-events');
  }
}
