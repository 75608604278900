import { Action, createReducer, on } from '@ngrx/store';
import { InitTeamState, ITeamStoreState } from './team.state';
import * as teamActions from './team.actions';

const teamReducer = createReducer(
  InitTeamState,
  on(teamActions.getTeam, state => ({
    ...state,
    teamLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(teamActions.getTeamSuccess, (state, { team }) => ({
    ...state,
    team,
    teamLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(teamActions.getTeamFailure, (state, { error }) => ({
    ...state,
    teamLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(teamActions.getTeamRequests, state => ({
    ...state,
    teamRequestsLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(teamActions.getTeamRequestsSuccess, (state, { teamRequests }) => ({
    ...state,
    teamRequests,
    teamRequestsLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(teamActions.getTeamRequestsFailure, (state, { error }) => ({
    ...state,
    teamRequestsLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(teamActions.getCurrentTeamRequest, state => ({
    ...state,
    currentTeamRequestState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(teamActions.getCurrentTeamRequestSuccess, (state, { currentTeamRequestResponse }) => ({
    ...state,
    isCurrentRequestActive: currentTeamRequestResponse !== null,
    currentTeamRequestState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(teamActions.getCurrentTeamRequestFailure, (state, { error }) => ({
    ...state,
    currentTeamRequestState: { isLoading: false, isDidLoad: false, error },
  })),

  on(teamActions.createTeamRequest, state => ({
    ...state,
    createTeamRequest: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(teamActions.createTeamRequestSuccess, state => ({
    ...state,
    isCurrentRequestActive: true,
    createTeamRequestState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(teamActions.createTeamRequestFailure, (state, { error }) => ({
    ...state,
    createTeamRequestState: { isLoading: false, isDidLoad: false, error },
  })),

  on(teamActions.cancelCurrentTeamRequest, state => ({
    ...state,
    cancelCurrentTeamRequestState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(teamActions.cancelCurrentTeamRequestSuccess, state => ({
    ...state,
    isCurrentRequestActive: false,
    cancelCurrentTeamRequestState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(teamActions.cancelCurrentTeamRequestFailure, (state, { error }) => ({
    ...state,
    cancelCurrentTeamRequestState: { isLoading: false, isDidLoad: false, error },
  })),

  on(teamActions.approveTeamRequest, state => ({
    ...state,
    teamLoadingState: { isLoading: true, isDidLoad: false, error: null },
    teamRequestsLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(teamActions.approveTeamRequestSuccess, state => ({
    ...state,
    teamLoadingState: { isLoading: false, isDidLoad: true, error: null },
    teamRequestsLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(teamActions.approveTeamRequestFailure, (state, { error }) => ({
    ...state,
    teamLoadingState: { isLoading: false, isDidLoad: true, error: null },
    teamRequestsLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(teamActions.rejectTeamRequest, state => ({
    ...state,
    teamLoadingState: { isLoading: true, isDidLoad: false, error: null },
    teamRequestsLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(teamActions.rejectTeamRequestSuccess, state => ({
    ...state,
    teamLoadingState: { isLoading: false, isDidLoad: true, error: null },
    teamRequestsLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(teamActions.rejectTeamRequestFailure, (state, { error }) => ({
    ...state,
    teamLoadingState: { isLoading: false, isDidLoad: true, error: null },
    teamRequestsLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(teamActions.deleteTeamMember, state => ({
    ...state,
    teamLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(teamActions.deleteTeamMemberSuccess, state => ({
    ...state,
    teamLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(teamActions.deleteTeamMemberFailure, (state, { error }) => ({
    ...state,
    teamLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(teamActions.resetTeam, state => ({ ...state, team: null })),
  on(teamActions.resetTeamRequests, state => ({ ...state, teamRequests: null })),
  on(teamActions.resetTeamState, () => ({ ...InitTeamState })),
);

export const TeamReducer = (state: ITeamStoreState, action: Action) => {
  return teamReducer(state, action);
};
