import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IHttpRequestState } from '../../models/http.model';
import { IRegisterStoreState } from './register.state';
import { IRegisterUser } from '../../models/auth.model';

import * as registerActions from './register.actions';
import * as registerSelectors from './register.selectors';

@Injectable()
export class RegisterStoreService {
  constructor(private registerStore: Store<IRegisterStoreState>) {}

  get registerStoreState$(): Observable<IRegisterStoreState> {
    return this.registerStore.select(registerSelectors.getRegisterStoreState);
  }

  get registerLoadingState$(): Observable<IHttpRequestState | null> {
    return this.registerStore.select(registerSelectors.getRegisterLoadingState);
  }

  public register(user: IRegisterUser): void {
    this.registerStore.dispatch(registerActions.register({ user }));
  }

  public confirmRegister(token: string): void {
    this.registerStore.dispatch(registerActions.registerConfirm({ token }));
  }

  public sendRegisterConfirmEmail(email: string): void {
    this.registerStore.dispatch(registerActions.sendRegisterConfirmEmail({ email }));
  }

  public resetRegisterState(): void {
    this.registerStore.dispatch(registerActions.resetRegisterState());
  }
}
