import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IBookingDate, IBookingDatesFilters } from '../../models/booking.model';
import { IHttpRequestState } from '../../models/http.model';
import { IStorageArea, IStorageAreaUpsert, IStorageAreas, IStorageAreasApiParams } from '../../models/storage-area.model';
import { IStorageAreaStoreState } from './storage-area.state';

import * as storageAreasActions from './storage-area.actions';
import * as storageAreasSelectors from './storage-area.selectors';

@Injectable()
export class StorageAreaStoreService {
  constructor(private storageAreaStore: Store<IStorageAreaStoreState>) {}

  get storageAreas$(): Observable<IStorageAreas | null> {
    return this.storageAreaStore.select(storageAreasSelectors.getStorageAreas);
  }

  get storageArea$(): Observable<IStorageArea | null> {
    return this.storageAreaStore.select(storageAreasSelectors.getStorageArea);
  }

  get storageAreaBookingDates$(): Observable<IBookingDate[] | null> {
    return this.storageAreaStore.select(storageAreasSelectors.getStorageAreaBookingDates);
  }

  get storageAreaPreloadedPhotos$(): Observable<string[] | null> {
    return this.storageAreaStore.select(storageAreasSelectors.getStorageAreaPreloadedPhotos);
  }

  get storageAreasLoadingState$(): Observable<IHttpRequestState | null> {
    return this.storageAreaStore.select(storageAreasSelectors.getStorageAreasLoadingState);
  }

  get storageAreasUpdatingState$(): Observable<IHttpRequestState | null> {
    return this.storageAreaStore.select(storageAreasSelectors.getStorageAreasUpdatingState);
  }

  get storageAreaLoadingState$(): Observable<IHttpRequestState | null> {
    return this.storageAreaStore.select(storageAreasSelectors.getStorageAreaLoadingState);
  }

  get storageAreaBookingDatesLoadingState$(): Observable<IHttpRequestState | null> {
    return this.storageAreaStore.select(storageAreasSelectors.getStorageAreaBookingDatesLoadingState);
  }

  get storageAreaPreloadingState$(): Observable<IHttpRequestState | null> {
    return this.storageAreaStore.select(storageAreasSelectors.getStorageAreaPreloadingState);
  }

  get storageAreaUpdatingState$(): Observable<IHttpRequestState | null> {
    return this.storageAreaStore.select(storageAreasSelectors.getStorageAreaUpdatingState);
  }

  get storageAreaStoreState$(): Observable<IStorageAreaStoreState> {
    return this.storageAreaStore.select(storageAreasSelectors.getStorageAreaStoreState);
  }

  public getStorageAreas(params: IStorageAreasApiParams): void {
    this.storageAreaStore.dispatch(storageAreasActions.getStorageAreas({ params }));
  }

  public getStorageArea(id: string): void {
    this.storageAreaStore.dispatch(storageAreasActions.getStorageArea({ id }));
  }

  public getStorageAreaBookingDates(bookingDatesFilters: IBookingDatesFilters, id: string): void {
    this.storageAreaStore.dispatch(storageAreasActions.getStorageAreaBookingDates({ bookingDatesFilters, id }));
  }

  public createStorageArea(data: IStorageAreaUpsert): void {
    this.storageAreaStore.dispatch(storageAreasActions.createStorageArea({ data }));
  }

  public updateStorageArea(data: IStorageAreaUpsert, id: string): void {
    this.storageAreaStore.dispatch(storageAreasActions.updateStorageArea({ data, id }));
  }

  public deleteStorageArea(id: string, params: IStorageAreasApiParams): void {
    this.storageAreaStore.dispatch(storageAreasActions.deleteStorageArea({ id, params }));
  }

  public preloadStorageAreaPhotos(photos: File[]): void {
    this.storageAreaStore.dispatch(storageAreasActions.preloadStorageAreaPhotos({ photos }));
  }

  public resetStorageAreas(): void {
    this.storageAreaStore.dispatch(storageAreasActions.resetStorageAreas());
  }

  public resetStorageArea(): void {
    this.storageAreaStore.dispatch(storageAreasActions.resetStorageArea());
  }

  public resetStorageAreaBookingDates(): void {
    this.storageAreaStore.dispatch(storageAreasActions.resetStorageAreaBookingDates());
  }

  public resetStorageAreaPreloadedPhotos(): void {
    this.storageAreaStore.dispatch(storageAreasActions.resetStorageAreaPreloadedPhotos());
  }

  public resetStorageAreasLoadingState(): void {
    this.storageAreaStore.dispatch(storageAreasActions.resetStorageAreasLoadingState());
  }

  public resetStorageAreasUpdatingState(): void {
    this.storageAreaStore.dispatch(storageAreasActions.resetStorageAreasUpdatingState());
  }

  public resetStorageAreaLoadingState(): void {
    this.storageAreaStore.dispatch(storageAreasActions.resetStorageAreaLoadingState());
  }

  public resetStorageAreaBookingDatesLoadingState(): void {
    this.storageAreaStore.dispatch(storageAreasActions.resetStorageAreaBookingDatesLoadingState());
  }

  public resetStorageAreaPreloadingState(): void {
    this.storageAreaStore.dispatch(storageAreasActions.resetStorageAreaPreloadingState());
  }

  public resetStorageAreaUpdatingState(): void {
    this.storageAreaStore.dispatch(storageAreasActions.resetStorageAreaUpdatingState());
  }

  public resetStorageAreaState(): void {
    this.storageAreaStore.dispatch(storageAreasActions.resetStorageAreaState());
  }
}
