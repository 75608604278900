import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { CurrentUserStoreService } from '../stores/current-user/current-user-store.service';
import { LoginStoreService } from '../stores/login/login-store.service';
import { RefreshTokenService } from './refresh-token.service';

import { IAccessToken } from '../models/auth.model';
import { HTTP_ERROR_CODES } from '../const/http-error-codes.const';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private currentUserStoreService: CurrentUserStoreService,
    private loginStoreService: LoginStoreService,
    private refreshTokenService: RefreshTokenService,
  ) {}

  // tslint:disable-next-line: no-any
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken: IAccessToken = this.authService.getAccessToken();

    if (accessToken && accessToken.access_token !== 'null') {
      let cloned = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${accessToken.access_token}`),
      });

      return next.handle(cloned).pipe(
        catchError(error => {
          if (error instanceof HttpErrorResponse && error.status === HTTP_ERROR_CODES.unauthorized) {
            this.refreshTokenService.tryAuthWithRefreshToken().subscribe(res => {
              if (res) {
                cloned = cloned.clone({
                  headers: cloned.headers.set('Authorization', `Bearer ${this.authService.getAccessToken().access_token}`),
                });
                return next.handle(cloned);
              }

              this.authService.removeAuthData();
              this.currentUserStoreService.resetCurrentUser();
              this.loginStoreService.resetLoginState();
              this.router.navigate(['/auth/login'], { queryParams: { returnUrl: this.router.url } });
              return next.handle(req);
            });
          }
          return throwError(error);
        }),
      );
    }

    return next.handle(req);
  }
}
