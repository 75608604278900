import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IAcceptanceDocument } from '../../models/acceptance-document.model';
import { IBooking, IBookingDocuments, IBookingGeneral, IBookings } from '../../models/booking.model';
import { IBookingsApiParams } from '../../models/booking.model';
import { IBookingStoreState } from './booking.state';
import { IHttpRequestState } from '../../models/http.model';

import * as bookingsActions from './booking.actions';
import * as bookingsSelectors from './booking.selectors';

@Injectable()
export class BookingStoreService {
  constructor(private bookingsStore: Store<IBookingStoreState>) {}

  get bookings$(): Observable<IBookings | null> {
    return this.bookingsStore.select(bookingsSelectors.getBookings);
  }

  get booking$(): Observable<IBooking | null> {
    return this.bookingsStore.select(bookingsSelectors.getBooking);
  }

  get bookingGeneral$(): Observable<IBookingGeneral | null> {
    return this.bookingsStore.select(bookingsSelectors.getBookingGeneral);
  }

  get bookingStorageRequest$(): Observable<IAcceptanceDocument | null> {
    return this.bookingsStore.select(bookingsSelectors.getBookingStorageRequest);
  }

  get bookingShipmentRequest$(): Observable<IAcceptanceDocument | null> {
    return this.bookingsStore.select(bookingsSelectors.getBookingShipmentRequest);
  }

  get bookingDocuments$(): Observable<IBookingDocuments | null> {
    return this.bookingsStore.select(bookingsSelectors.getBookingDocuments);
  }

  get bookingsLoadingState$(): Observable<IHttpRequestState> {
    return this.bookingsStore.select(bookingsSelectors.getBookingsLoadingState);
  }

  get bookingLoadingState$(): Observable<IHttpRequestState> {
    return this.bookingsStore.select(bookingsSelectors.getBookingLoadingState);
  }

  get bookingStorageRequestLoadingState$(): Observable<IHttpRequestState | null> {
    return this.bookingsStore.select(bookingsSelectors.getBookingStorageRequestLoadingState);
  }

  get bookingShipmentRequestLoadingState$(): Observable<IHttpRequestState | null> {
    return this.bookingsStore.select(bookingsSelectors.getBookingShipmentRequestLoadingState);
  }

  get bookingUpdatingState$(): Observable<IHttpRequestState> {
    return this.bookingsStore.select(bookingsSelectors.getBookingUpdatingState);
  }

  get bookingStoreState$(): Observable<IBookingStoreState> {
    return this.bookingsStore.select(bookingsSelectors.getBookingStoreState);
  }

  public getBookings(params: IBookingsApiParams): void {
    this.bookingsStore.dispatch(bookingsActions.getBookings({ params }));
  }

  public getBooking(id: string): void {
    this.bookingsStore.dispatch(bookingsActions.getBooking({ id }));
  }

  public getBookingGeneral(id: string): void {
    this.bookingsStore.dispatch(bookingsActions.getBookingGeneral({ id }));
  }

  public getBookingStorageRequest(id: string): void {
    this.bookingsStore.dispatch(bookingsActions.getBookingStorageRequest({ id }));
  }

  public getBookingShipmentRequest(id: string): void {
    this.bookingsStore.dispatch(bookingsActions.getBookingShipmentRequest({ id }));
  }

  public getBookingDocuments(id: string): void {
    this.bookingsStore.dispatch(bookingsActions.getBookingDocuments({ id }));
  }

  public uploadBookingDocument(file: File, id: string, documentType: string): void {
    this.bookingsStore.dispatch(bookingsActions.uploadBookingDocument({ file, id, documentType }));
  }

  public deleteBookingDocument(id: string, documentType: string): void {
    this.bookingsStore.dispatch(bookingsActions.deleteBookingDocument({ id, documentType }));
  }

  public cancelBooking(id: string, params: IBookingsApiParams): void {
    this.bookingsStore.dispatch(bookingsActions.cancelBooking({ id, params }));
  }

  public completeBooking(id: string, params: IBookingsApiParams): void {
    this.bookingsStore.dispatch(bookingsActions.completeBooking({ id, params }));
  }

  public resetBookings(): void {
    this.bookingsStore.dispatch(bookingsActions.resetBookings());
  }

  public resetBooking(): void {
    this.bookingsStore.dispatch(bookingsActions.resetBooking());
  }

  public resetBookingsLoadingState(): void {
    this.bookingsStore.dispatch(bookingsActions.resetBookingsLoadingState());
  }

  public resetBookingLoadingState(): void {
    this.bookingsStore.dispatch(bookingsActions.resetBookingLoadingState());
  }

  public resetBookingUpdatingState(): void {
    this.bookingsStore.dispatch(bookingsActions.resetBookingUpdatingState());
  }
}
