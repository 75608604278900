import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TeamEffects } from './team.effects';
import { TeamReducer } from './team.reducer';
import { ApiTeamService } from '../../api/admin/api-team.service';
import { TeamStoreService } from './team-store.service';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('admin-team', TeamReducer), EffectsModule.forFeature([TeamEffects])],
  providers: [ApiTeamService, TeamStoreService],
})
export class TeamStoreModule {}
