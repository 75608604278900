import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BookingCalendarModule } from './booking-calendar/booking-calendar.module';
import { ButtonModule } from './button/button.module';
import { CardModule } from './card/card.module';
import { CardsListModule } from './cards-list/cards-list.module';
import { ChangeNoteModule } from './change-note/change-note.module';
import { CheckboxModule } from './checkbox/checkbox.module';
import { ConfirmPopupModule } from './confirm-popup/confirm-popup.module';
import { ContentBlockModule } from './content-block/content-block.module';
import { CostCalculatorModule } from './cost-calculator/cost-calculator.module';
import { CounterModule } from './counter/counter.module';
import { DatepickerModule } from './datepicker/datepicker.module';
import { DrawerModule } from './drawer/drawer.module';
import { FileDndModule } from './directives/file-dnd/file-dnd.module';
import { FileUploadModule } from './file-upload/file-upload.module';
import { HintListModule } from './hint-list/hint-list.module';
import { InputCounterModule } from './input-counter/input-counter.module';
import { InputGroupModule } from './input-group/input-group.module';
import { InputHolderModule } from './input-holder/input-holder.module';
import { InputModule } from './input/input.module';
import { InputLwhModule } from './input-lwh/input-lwh.module';
import { InputPassModule } from './input-pass/input-pass.module';
import { InputPhoneModule } from './input-phone/input-phone.module';
import { InputTimeModule } from './input-time/input-time.module';
import { LiveSearchModule } from './live-search/live-search.module';
import { LoadingModule } from './directives/loading/loading.module';
import { MapModule } from './map/map.module';
import { OverflowHiderModule } from './directives/overflow-hider/overflow-hider.module';
import { PageActionButtonsModule } from './page-action-buttons/page-action-buttons.module';
import { PaginatorModule } from './paginator/paginator.module';
import { PasswordCheckerModule } from './password-checker/password-checker.module';
import { PhotoCarouselModule } from './photo-carousel/photo-carousel.module';
import { PhotoUploadModule } from './photo-upload/photo-upload.module';
import { PipesModule } from './pipes/pipes.module';
import { PopupModule } from './popup/popup.module';
import { RadioButtonModule } from './radio-button/radio-button.module';
import { SearchModule } from './search/search.module';
import { SelectModule } from './select/select.module';
import { StatusNoteModule } from './status-note/status-note.module';
import { StickyOnScrollModule } from './directives/sticky-on-scroll/sticky-on-scroll.module';
import { SwitchModule } from './switch/switch.module';
import { SwitchTabsModule } from './switch-tabs/switch-tabs.module';
import { TextareaModule } from './textarea/textarea.module';
import { TooltipModule } from './directives/tooltip/tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BookingCalendarModule,
    ButtonModule,
    CardModule,
    CardsListModule,
    ChangeNoteModule,
    CheckboxModule,
    ConfirmPopupModule,
    ContentBlockModule,
    CostCalculatorModule,
    CounterModule,
    DatepickerModule,
    DrawerModule,
    FileDndModule,
    FileUploadModule,
    HintListModule,
    InputCounterModule,
    InputGroupModule,
    InputHolderModule,
    InputModule,
    InputLwhModule,
    InputPassModule,
    InputPhoneModule,
    InputTimeModule,
    LiveSearchModule,
    LoadingModule,
    MapModule,
    OverflowHiderModule,
    PageActionButtonsModule,
    PaginatorModule,
    PasswordCheckerModule,
    PhotoCarouselModule,
    PhotoUploadModule,
    PipesModule,
    PopupModule,
    RadioButtonModule,
    SearchModule,
    SelectModule,
    StatusNoteModule,
    StickyOnScrollModule,
    SwitchModule,
    SwitchTabsModule,
    TextareaModule,
    TooltipModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    BookingCalendarModule,
    ButtonModule,
    CardModule,
    CardsListModule,
    ChangeNoteModule,
    CheckboxModule,
    ConfirmPopupModule,
    ContentBlockModule,
    CostCalculatorModule,
    CounterModule,
    DatepickerModule,
    DrawerModule,
    FileDndModule,
    FileUploadModule,
    HintListModule,
    InputCounterModule,
    InputGroupModule,
    InputHolderModule,
    InputModule,
    InputLwhModule,
    InputPassModule,
    InputPhoneModule,
    InputTimeModule,
    LiveSearchModule,
    LoadingModule,
    MapModule,
    OverflowHiderModule,
    PageActionButtonsModule,
    PaginatorModule,
    PasswordCheckerModule,
    PhotoCarouselModule,
    PhotoUploadModule,
    PipesModule,
    PopupModule,
    RadioButtonModule,
    SearchModule,
    SelectModule,
    StatusNoteModule,
    StickyOnScrollModule,
    SwitchModule,
    SwitchTabsModule,
    TextareaModule,
    TooltipModule,
  ],
})
export class UiModule {}
