<div class="input-field"
     *ngIf="lengthControl && widthControl && heightControl"
     [ngClass]="['input-field--' + theme]"
     [class.input-field--error]="isError"
     [class.input-field--disabled]="isDisabled"
>
  <div class="input-field__container" [class.input-field__container--no-unit]="!unit">
    <input
      class="input-field__field-part"
      placeholder="ДД"
      [formControl]="lengthControl"
      [class.input-field__field-part--disabled]="isDisabled"
      (input)="onValueChange()"
      (focus)="onInputFocused()"
      (blur)="lengthControl.markAsTouched(); onInputTouched()"
      (ngModelChange)="onModelChange()"
    />
    <span class="input-field__field-separator">&#10761;</span>
    <input
      class="input-field__field-part"
      placeholder="ШШ"
      [formControl]="widthControl"
      [class.input-field__field-part--disabled]="isDisabled"
      (input)="onValueChange()"
      (focus)="onInputFocused()"
      (blur)="widthControl.markAsTouched(); onInputTouched()"
      (ngModelChange)="onModelChange()"
    />
    <span class="input-field__field-separator">&#10761;</span>
    <input
      class="input-field__field-part"
      placeholder="ВВ"
      [formControl]="heightControl"
      [class.input-field__field-part--disabled]="isDisabled"
      (input)="onValueChange()"
      (focus)="onInputFocused()"
      (blur)="heightControl.markAsTouched(); onInputTouched()"
      (ngModelChange)="onModelChange()"
    />
    <div class="input-field__unit" *ngIf="unit">{{ unit }}</div>
  </div>
</div>
