import { Injectable } from '@angular/core';
import { ISelectableItem } from '../../_ui/delegate/model/delegate.model';

@Injectable({
  providedIn: 'root',
})
export class DictionaryHelperService {
  public getItemName(dictionary: { [key: string]: string }, key: string): string {
    return key ? dictionary[key] : '';
  }

  public getSelectableItemName(items: ISelectableItem[], value: string | undefined): string {
    if (!value) {
      return '';
    }

    const selectableItem: ISelectableItem | undefined = items.find(item => item.value === value);
    return selectableItem?.name || '';
  }
}
