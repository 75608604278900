<div class="hint-list">
  <h4 class="hint-list__text" *ngIf="hintDescription">
    {{ hintDescription }}
  </h4>
  <ul class="hint-list__list" *ngIf="hintList">
    <li class="hint-list__item"
        *ngFor="let hint of hintList "
        [ngClass]="'hint-list__item--' + hintListDirection"
    >
      <span class="hint-list__hint">{{ hint }}</span>
    </li>
  </ul>
</div>