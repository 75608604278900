<tlp-popup
    class="confirm-popup"
    #popup
    [containerSelector]="containerSelector"
    [hideCloseButton]="true"
    [isNestedInDrawer]="isNestedInDrawer"
    [size]="size"
>
  <ng-container heading>
    <div class="confirm-popup__title" [ngClass]="'confirm-popup__title--' + titlePosition">
      {{ title }}
    </div>
  </ng-container>
  <ng-container>
    <div class="confirm-popup__subtitle" *ngIf="subtitle">
      {{ subtitle }}
    </div>
    <div class="confirm-popup__description" *ngIf="description">
      {{ description }}
    </div>
    <div class="confirm-popup__buttons" [ngClass]="'confirm-popup__buttons--' + buttonsPosition">
      <tlp-button class="confirm-popup__confirm" theme="primary" (click)="onConfirm()">{{confirmButtonTitle}}</tlp-button>
      <tlp-button (click)="onCancel()">{{ cancelButtonTitle }}</tlp-button>
    </div>
  </ng-container>
</tlp-popup>
