import { Action, createReducer, on } from '@ngrx/store';
import { InitListingState, IListingStoreState } from './listing.state';
import * as listingActions from './listing.actions';

const getListingReducer = createReducer(
  InitListingState,
  on(listingActions.getUsersListing, state => ({
    ...state,
    usersLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(listingActions.getUsersListingSuccess, (state, { users }) => ({
    ...state,
    users,
    usersLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(listingActions.getUsersListingFailure, (state, { error }) => ({
    ...state,
    usersLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(listingActions.getOrganizationsListing, state => ({
    ...state,
    organizationsLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(listingActions.getOrganizationsListingSuccess, (state, { organizations }) => ({
    ...state,
    organizations,
    organizationsLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(listingActions.getOrganizationsListingFailure, (state, { error }) => ({
    ...state,
    organizationsLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(listingActions.getWarehousesListing, state => ({
    ...state,
    warehousesLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(listingActions.getWarehousesListingSuccess, (state, { warehouses }) => ({
    ...state,
    warehouses,
    warehousesLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(listingActions.getWarehousesListingFailure, (state, { error }) => ({
    ...state,
    warehousesLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(listingActions.getAdvertsListing, state => ({
    ...state,
    advertsLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(listingActions.getAdvertsListingSuccess, (state, { adverts }) => ({
    ...state,
    adverts,
    advertsLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(listingActions.getAdvertsListingFailure, (state, { error }) => ({
    ...state,
    advertsLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(listingActions.getBookingsListing, state => ({
    ...state,
    bookingsLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(listingActions.getBookingsListingSuccess, (state, { bookings }) => ({
    ...state,
    bookings,
    bookingsLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(listingActions.getBookingsListingFailure, (state, { error }) => ({
    ...state,
    bookingsLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(listingActions.resetUsersListing, state => ({
    ...state,
    users: null,
  })),
  on(listingActions.resetOrganizationsListing, state => ({
    ...state,
    organizations: null,
  })),
  on(listingActions.resetWarehousesListing, state => ({
    ...state,
    warehouses: null,
  })),
  on(listingActions.resetAdvertsListing, state => ({
    ...state,
    adverts: null,
  })),
  on(listingActions.resetBookingsListing, state => ({
    ...state,
    bookings: null,
  })),

  on(listingActions.resetListingLoadingState, state => ({
    ...state,
    usersLoadingState: null,
    organizationsLoadingState: null,
    warehousesLoadingState: null,
    advertsLoadingState: null,
    bookingsLoadingState: null,
  })),
  on(listingActions.resetListingState, () => ({ ...InitListingState })),
);

export const ListingReducer = (state: IListingStoreState, action: Action) => {
  return getListingReducer(state, action);
};
