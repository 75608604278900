import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { AuthService } from './auth.service';
import { ApiRefreshTokenService } from './api-refresh-token.service';
import { IRefreshToken, ITokens } from '../models/auth.model';

@Injectable()
export class RefreshTokenService {
  constructor(private authService: AuthService, private apiRefreshTokenService: ApiRefreshTokenService) {}

  public tryAuthWithRefreshToken(): Observable<boolean> {
    const refreshToken: IRefreshToken = this.authService.getRefreshToken();
    if (!refreshToken || refreshToken?.refresh_token === 'null') {
      return of(false);
    }

    const refreshTokenObservable: Subject<boolean> = new Subject<boolean>();

    this.apiRefreshTokenService.refreshToken(refreshToken).subscribe(
      (tokenResponse: ITokens) => {
        if (tokenResponse) {
          this.authService.setTokens(tokenResponse);
          refreshTokenObservable.next(true);
        } else {
          refreshTokenObservable.next(false);
        }
      },
      () => {
        refreshTokenObservable.next(false);
      },
    );

    return refreshTokenObservable;
  }
}
