import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StickyOnScrollDirective } from './sticky-on-scroll.directive';

@NgModule({
  declarations: [StickyOnScrollDirective],
  imports: [CommonModule],
  exports: [StickyOnScrollDirective],
})
export class StickyOnScrollModule {}
