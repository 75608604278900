import { Directive, ElementRef, AfterViewInit, OnDestroy, Input, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[tlpOverflowHider]',
})
export class OverflowHiderDirective implements AfterViewInit, OnDestroy {
  @Input() public overflowElementSelector!: string;
  @Input() public unsetOverflow: boolean = false;
  private observer!: MutationObserver;
  private target!: HTMLElement;

  constructor(@Inject(DOCUMENT) private document: Document, private elementRef: ElementRef, private renderer: Renderer2) {}

  public ngAfterViewInit(): void {
    const config = { attributes: true, subtree: true, characterData: true };
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.attributeName === 'class') {
          this.target = this.overflowElementSelector
            ? (this.document.querySelectorAll(this.overflowElementSelector)[0] as HTMLElement)
            : this.document.body;

          if (this.target) {
            const isOpened: boolean | undefined = this.elementRef?.nativeElement?.className
              ?.split(' ')
              .some((classItemName: string) => /--open\d*/.test(classItemName));
            this.renderer[isOpened ? 'addClass' : 'removeClass'](this.target, this.unsetOverflow ? 'unset-overflow' : 'hidden-overflow');
          }
        }
      });
    });

    this.observer?.observe(this.elementRef.nativeElement, config);
  }

  public ngOnDestroy(): void {
    if (this.target) {
      this.renderer.removeClass(this.target, 'hidden-overflow');
      this.renderer.removeClass(this.target, 'unset-overflow');
    }

    this.observer?.disconnect();
  }
}
