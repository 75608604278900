import { ITime } from '../models/date.model';
import { TIME_PARSING_REGEX, UNIX_TIME_RATIO } from '../const/date.const';
import * as moment from 'moment';

export const parseFromUnixDate = (unixDates?: number[] | number | null): moment.Moment[] | moment.Moment | null => {
  if (Array.isArray(unixDates)) {
    return unixDates.map(date => moment(date * UNIX_TIME_RATIO));
  }
  return unixDates ? moment(unixDates * UNIX_TIME_RATIO) : null;
};

export const compareTime = (fistValue: string, secondValue: string): number => {
  const decomposedTimes: string[][] = [decomposeTime(fistValue), decomposeTime(secondValue)];
  const firstParsedTime: ITime = {
    hours: getParsedDecomposedTime(decomposedTimes[0]),
    minutes: getParsedDecomposedTime(decomposedTimes[0], false),
  };
  const secondParsedTime: ITime = {
    hours: getParsedDecomposedTime(decomposedTimes[1]),
    minutes: getParsedDecomposedTime(decomposedTimes[1], false),
  };

  if (firstParsedTime.hours === secondParsedTime.hours) {
    if (firstParsedTime.minutes === secondParsedTime.minutes) {
      return 0;
    }

    return (firstParsedTime.minutes as number) > (secondParsedTime.minutes as number) ? 1 : -1;
  }

  return (firstParsedTime.hours as number) > (secondParsedTime.hours as number) ? 1 : -1;
};

export const decomposeTime = (value: string | null): string[] => {
  return TIME_PARSING_REGEX.exec(value || '') ?? ['00:00', '00', '00'];
};

export const getParsedDecomposedTime = (decomposedTime: string[], getHours: boolean = true): number => {
  return parseInt(decomposedTime[getHours ? 1 : 2], 10);
};
