import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { NumeralPluralsPipe } from '../../../pipes/numeral-plurals.pipe';
import { ToastrService } from 'ngx-toastr';

import { IDocument } from '../../../../_core/models/document.model';
import { IHttpError } from '../../../../_core/models/http.model';
import { IPhotoAction, IPhotoActionOutput } from '../../../photo-carousel/model/photo-carousel.model';

const MEGA_MULTIPLIER: number = 1e6;
const MAX_PHOTO_SIZE: number = 20; // 20 MB = MAX_PHOTO_SIZE * MEGA_MULTIPLIER
const MAX_PHOTOS_COUNT: number = 15;

export interface IMainPhotoData {
  id: string;
  recentlyUploadedPhotoIndex?: number;
}

@Component({
  selector: 'tlp-photo-upload',
  templateUrl: './photo-upload.component.html',
  styleUrls: ['./photo-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhotoUploadComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhotoUploadComponent),
      multi: true,
    },
  ],
})
export class PhotoUploadComponent {
  @Input() public fileTypes: string[] = ['jpg', 'jpeg', 'png'];
  @Input() public maxPhotosCount: number = MAX_PHOTOS_COUNT;
  @Input() public maxPhotoSize: number = MAX_PHOTO_SIZE;
  @Input() public mainPhotoId?: string | null;
  @Input() public lastMainPhotoDeletedWarningMessage: string =
    'Была удалена последняя фотография. Добавьте новые фотографии для выбора главной';
  @Input() public photosActions: IPhotoAction[] = [{ type: 'main' }, { type: 'delete' }];
  @Input() public backendError!: IHttpError | string | null;

  @Output() public newPhotosChanged: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Output() public mainPhotoChanged: EventEmitter<IMainPhotoData> = new EventEmitter<IMainPhotoData>();

  public isDisabled: boolean = false;
  public photosToShow: IDocument[] = [];
  public photosToEmit: File[] = [];
  public currentPhotos: IDocument[] = [];
  public validationErrors: string[] = [];

  constructor(private cdr: ChangeDetectorRef, private toastrService: ToastrService, private numeralPluralsPipe: NumeralPluralsPipe) {}

  @HostListener('change', ['$event.target.files'])
  public emitFiles(files: FileList): void {
    if (files?.length) {
      const newFiles: File[] = this.getValidFilesAndSetErrors(files);
      this.handleFilesChanges(newFiles);
    }
  }

  public onChange = (_: IDocument[]) => {};

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(): void {}

  public writeValue(value: IDocument[]): void {
    if (value?.length) {
      this.photosToEmit = [];
      this.photosToShow = [...value];
      this.currentPhotos = [...value];

      this.cdr.detectChanges();
    }
  }

  public setDisabledState(disabled: boolean): void {
    this.isDisabled = disabled;
    this.cdr.markForCheck();
  }

  public handleSliderActionClick(photoActionOutput: IPhotoActionOutput): void {
    if (this.isDisabled) {
      return;
    }

    if (photoActionOutput.actionType === 'main' && photoActionOutput.itemId) {
      let output: IMainPhotoData = { id: photoActionOutput.itemId };

      if (!this.currentPhotos?.find(currentPhoto => currentPhoto.id === photoActionOutput.itemId)) {
        const mainPhotoToEmit: IDocument | undefined = this.photosToShow.find(photo => photo.id === photoActionOutput.itemId);

        if (mainPhotoToEmit) {
          output = {
            ...output,
            recentlyUploadedPhotoIndex: this.photosToEmit.findIndex(photoToEmit => photoToEmit.name === mainPhotoToEmit.file.name),
          };
        }
      }

      this.mainPhotoChanged.emit(output);
    } else if (photoActionOutput.actionType === 'delete') {
      const photoToDelete: IDocument | undefined = this.photosToShow.find(photo => photo.id === photoActionOutput.itemId);
      const photoToDeleteIndex: number = this.photosToShow.findIndex(photo => photo.id === photoActionOutput.itemId);

      if (photoToDelete) {
        this.currentPhotos = this.currentPhotos.filter(currentPhoto => currentPhoto.id !== photoActionOutput.itemId);
        this.photosToShow = this.photosToShow.filter(photoToShow => photoToShow.id !== photoActionOutput.itemId);
        this.photosToEmit = this.photosToEmit.filter(photoToEmit => photoToEmit.name !== photoToDelete.file.name);
      }

      this.newPhotosChanged.emit(this.photosToEmit);
      this.onChange(this.currentPhotos);

      if (photoToDeleteIndex !== -1 && photoToDelete && this.mainPhotoId && photoToDelete.id === this.mainPhotoId) {
        const newMainPhoto: IDocument = this.photosToShow[photoToDeleteIndex] || this.photosToShow[0];
        this.mainPhotoId = newMainPhoto?.id;
        this.handleSliderActionClick({ itemId: newMainPhoto?.id, actionType: 'main' });
        this.toastrService.warning(
          newMainPhoto
            ? 'Была удалена фотография, помеченная как главная. В качестве главной выбрана следующая фотография'
            : this.lastMainPhotoDeletedWarningMessage,
        );
      }
    }
  }

  public onFileDropped(files: FileList): void {
    if (this.isDisabled) {
      return;
    }

    const newFiles: File[] = this.getValidFilesAndSetErrors(files);
    this.handleFilesChanges(newFiles);
  }

  public validate(): ValidationErrors | null {
    return null;
  }

  public get backendErrorMessage(): string | null {
    const errorMessage: string | string[] =
      typeof this.backendError === 'string' ? this.backendError : (this.backendError as IHttpError).message;

    return Array.isArray(errorMessage) ? (errorMessage.length ? errorMessage.join(', ') : null) : errorMessage;
  }

  public get photosExtensions(): string {
    return this.fileTypes.join(', ').toUpperCase();
  }

  private handleFilesChanges(files: File[]): void {
    files.forEach((newFile: File) => {
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(newFile);

      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (typeof event.target?.result === 'string') {
          const multiplier: number = 10000;

          if (this.photosToShow.length < this.maxPhotosCount) {
            this.photosToShow.push({
              id: String(Math.ceil(Math.random() * multiplier)),
              file: {
                name: newFile.name,
                path: event.target.result,
              },
            });
          }
        }

        this.cdr.markForCheck();
      };
    });

    const newPhotos: File[] = files.slice(0, this.maxPhotosCount - this.photosToEmit.length - this.currentPhotos.length);
    this.photosToEmit.push(...newPhotos);
    this.newPhotosChanged.emit(this.photosToEmit);
  }

  private getValidFilesAndSetErrors(files: FileList): File[] {
    this.resetErrors();
    this.setFilesCountError(Array.from(files));

    const newFiles: File[] = Array.from(files)
      .slice(0, this.maxPhotosCount)
      .reduce((result: File[], currentItem) => {
        const isValidFileType: boolean = this.isValidFileType(currentItem);
        const isValidFileSize: boolean = this.isValidFileSize(currentItem);

        if (!isValidFileType) {
          this.setFileTypeError(currentItem.name);
        } else if (!isValidFileSize) {
          this.setFileSizeError(currentItem.name);
        } else {
          result.push(currentItem);
        }

        return result;
      }, []);

    return newFiles;
  }

  private isValidFileType(file: File): boolean {
    const extension: string | undefined = file.name.split('.').pop();
    return !!extension && !!this.fileTypes.includes(extension.toLowerCase());
  }

  private isValidFileSize(file: File): boolean {
    return file.size <= this.maxPhotoSize * MEGA_MULTIPLIER;
  }

  private resetErrors(): void {
    this.validationErrors = [];
    this.backendError = null;
  }

  private setFilesCountError(files: File[]): void {
    if (this.currentPhotos.length === this.maxPhotosCount || this.photosToEmit.length === this.maxPhotosCount) {
      this.validationErrors.push('Вы уже загрузили максимальное количество фотографий');
    } else if (files?.length + this.photosToEmit.length > this.maxPhotosCount) {
      this.validationErrors.push(`Превышено максимальное количество файлов. Для загрузки были выбраны
       ${this.numeralPluralsPipe.transform(this.maxPhotosCount, ['первый', 'первые', 'первые'], true)}
       ${this.numeralPluralsPipe.transform(this.maxPhotosCount, ['файл', 'файлов', 'файлов'])}`);
    }
  }

  private setFileTypeError(fileName: string): void {
    this.validationErrors.push(`Файл ${fileName} имеет неверный формат. Выберите другой файл`);
  }

  private setFileSizeError(fileName: string): void {
    this.validationErrors.push(`Файл ${fileName} превышает допустимый предел ${this.maxPhotoSize} MB. Выберите другой файл.`);
  }
}
