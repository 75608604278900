import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { ICard } from '../../../card/model/card.model';

@Component({
  selector: 'tlp-cards-list',
  templateUrl: './cards-list.component.html',
  styleUrls: ['./cards-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardsListComponent {
  @Input() public description?: string[];
  @Input() public items: ICard[] | undefined;
  @Input() public loading: boolean | null = false;

  @Input() public stickyDescription: boolean = false;
  @Input() public toggleCardDetailsOnClick: boolean = false;
  @Input() public customCards: boolean = false;
  @Input() public gridTemplateColumns?: string;
  @Input() public emptyMessage: string = 'Ничего не найдено';
  @Input() public errorMessage?: string;

  @Output() public cardClicked: EventEmitter<string> = new EventEmitter<string>();

  public onCardClick(id: string): void {
    this.cardClicked.emit(id);
  }
}
