<div class="photo-loader">
  <div tlpFileDnd
       class="photo-loader__dropzone"
       cssFileOverClass="photo-loader__dropzone--file-over"
       [class.photo-loader__dropzone--error]="validationErrors.length || backendError"
       [class.photo-loader__dropzone--disabled]="isDisabled"
       (fileDropped)="onFileDropped($event)"
  >
    <div class="photo-loader__input">
      <input #fileInput multiple hidden type="file" id="fileDropRef" accept="image/png, image/jpeg"/>

      <svg-icon-sprite
          class="photo-loader__validation-icon"
          src="./assets/sprites/sprite.svg#ic-danger"
          width="32px" height="32px"
          *ngIf="validationErrors.length || backendError"
      ></svg-icon-sprite>

      <span class="photo-loader__label" [class.photo-loader__label--dark]="validationErrors.length || backendError">
        {{ (validationErrors.length || backendError) ? 'Ошибка при загрузке фото' : 'Перетащите фото сюда или' }}
      </span>
      <tlp-button theme="default" [disabled]="isDisabled" (click)="fileInput.click()">Выберите файлы с компьютера</tlp-button>

      <div class="photo-loader__requirements">
        <ng-container *ngIf="!validationErrors.length && !backendError; else errorList">
          <p class="photo-loader__text">{{ maxPhotosCount }} фотографий в формате {{ photosExtensions }}. Максимальный размер фото — {{ maxPhotoSize }} MB</p>
        </ng-container>
        <ng-template #errorList>
          <p class="photo-loader__text photo-loader__text--dark" *ngIf="backendError">{{ backendErrorMessage || 'Произошла ошибка при загрузке фотографий на сервер' }}</p>
          <ul class="photo-loader__errors" *ngIf="validationErrors.length">
            <li class="photo-loader__text photo-loader__text--dark" *ngFor="let error of validationErrors">{{ error }}</li>
          </ul>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="photo-loader__photos" *ngIf="photosToShow.length">
    <tlp-photo-carousel
        [items]="photosToShow"
        [mainItemId]="mainPhotoId"
        [actions]="isDisabled ? [] : photosActions"
        [slideWidth]="95"
        (sliderItemClicked)="handleSliderActionClick($event)"
    ></tlp-photo-carousel>
  </div>
</div>