import { Action, createReducer, on } from '@ngrx/store';
import { ILoginStoreState, InitLoginState } from './login.state';
import * as loginActions from '../login/login.actions';

const loginReducer = createReducer(
  InitLoginState,
  on(loginActions.login, state => ({
    ...state,
    loginLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(loginActions.loginSuccess, state => ({
    ...state,
    loginLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(loginActions.loginFailure, (state, { error }) => ({
    ...state,
    loginLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(loginActions.resetLoginState, () => ({ ...InitLoginState })),
);

export const LoginReducer = (state: ILoginStoreState, action: Action) => {
  return loginReducer(state, action);
};
