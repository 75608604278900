export const HTTP_ERROR_CODES = {
  badRequest: 400,
  unauthorized: 401,
  validationError: 422,
};

export const HTTP_SPECIFIC_ERROR_FIELDS = {
  verificationRequired: 'verification_required',
  userConfirm: 'user_confirm',
  activeAdvert: 'advert_count',
  activeBooking: 'active_reserve',
  emailConfirm: 'email_confirm',
};

export const HTTP_TOASTR_ERROR_FIELDS = {
  lastStorageArea: 'last_storage_area',
  lastUser: 'last_user',
};
