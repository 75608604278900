import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CheckboxComponent } from './component/checkbox/checkbox.component';

import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { TooltipModule } from '../directives/tooltip/tooltip.module';

@NgModule({
  declarations: [CheckboxComponent],
  imports: [CommonModule, FormsModule, IconSpriteModule, TooltipModule],
  exports: [CheckboxComponent],
})
export class CheckboxModule {}
