import { IHttpRequestState } from '../../models/http.model';
import { IListing } from '../../models/listing.model';

export interface IListingStoreState {
  users: IListing | null;
  organizations: IListing | null;
  warehouses: IListing | null;
  adverts: IListing | null;
  bookings: IListing | null;
  usersLoadingState: IHttpRequestState | null;
  organizationsLoadingState: IHttpRequestState | null;
  warehousesLoadingState: IHttpRequestState | null;
  advertsLoadingState: IHttpRequestState | null;
  bookingsLoadingState: IHttpRequestState | null;
}

export interface IListingLoadingState {
  usersLoadingState: IHttpRequestState | null;
  organizationsLoadingState: IHttpRequestState | null;
  warehousesLoadingState: IHttpRequestState | null;
  advertsLoadingState: IHttpRequestState | null;
  bookingsLoadingState: IHttpRequestState | null;
}

export const InitListingState: IListingStoreState = {
  users: null,
  organizations: null,
  warehouses: null,
  adverts: null,
  bookings: null,
  usersLoadingState: null,
  organizationsLoadingState: null,
  warehousesLoadingState: null,
  advertsLoadingState: null,
  bookingsLoadingState: null,
};
