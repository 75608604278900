<div class="switch-tabs" [ngClass]="['switch-tabs--' + theme]">
  <ng-container *ngFor="let item of items">
    <button
      class="switch-tabs__item"
      *ngIf="!item.hidden"
      [class.switch-tabs__item--active]="activeItems.includes(item.value)"
      (click)="onTabClick(item)"
    >
      {{ item.title }}
    </button>
  </ng-container>
</div>
