import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IHttpRequestState } from '../../models/http.model';
import { ILogs, ILogsAvailableEvent, ILogsAvailableEventsApiParams } from '../../models/logs.model';
import { ILogsApiParams } from '../../models/logs.model';
import { ILogsStoreState } from './logs.state';

import * as logsActions from './logs.actions';
import * as logsSelectors from './logs.selectors';

@Injectable()
export class LogsStoreService {
  constructor(private logsStore: Store<ILogsStoreState>) {}

  get logs$(): Observable<ILogs | null> {
    return this.logsStore.select(logsSelectors.getLogs);
  }

  get logsAvailableEvents$(): Observable<ILogsAvailableEvent[] | null> {
    return this.logsStore.select(logsSelectors.getLogsAvailableEvents);
  }

  get logsLoadingState$(): Observable<IHttpRequestState | null> {
    return this.logsStore.select(logsSelectors.getLogsLoadingState);
  }

  get logsAvailableEventsLoadingState$(): Observable<IHttpRequestState | null> {
    return this.logsStore.select(logsSelectors.getLogsAvailableEventsLoadingState);
  }

  public getLogs(params: ILogsApiParams): void {
    this.logsStore.dispatch(logsActions.getLogs({ params }));
  }

  public getLogsAvailableEvents(params: ILogsAvailableEventsApiParams): void {
    this.logsStore.dispatch(logsActions.getLogsAvailableEvents({ params }));
  }

  public resetLogsState(): void {
    this.logsStore.dispatch(logsActions.resetLogsState());
  }

  public resetLogsAvailableEvents(): void {
    this.logsStore.dispatch(logsActions.resetLogsAvailableEvents());
  }

  public resetLogsLoadingState(): void {
    this.logsStore.dispatch(logsActions.resetLogsLoadingState());
  }
}
