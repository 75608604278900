import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '../button/button.module';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { LoadingModule } from '../directives/loading/loading.module';
import { TooltipModule } from '../directives/tooltip/tooltip.module';

import { BookingAmountComponent } from './component/booking-amount/booking-amount.component';
import { BookingCalendarComponent } from './component/booking-calendar/booking-calendar/booking-calendar.component';
import { BookingCalendarExpandedComponent } from './component/booking-calendar-expanded/booking-calendar-expanded/booking-calendar-expanded.component';

@NgModule({
  declarations: [BookingAmountComponent, BookingCalendarComponent, BookingCalendarExpandedComponent],
  imports: [CommonModule, ButtonModule, IconSpriteModule, LoadingModule, TooltipModule],
  exports: [BookingAmountComponent, BookingCalendarComponent, BookingCalendarExpandedComponent],
})
export class BookingCalendarModule {}
