import { Injectable } from '@angular/core';
import { ListingLiveSearchDelegateAdvertsService } from './admin/listing-live-search-delegate-adverts.service';
import { ListingLiveSearchDelegateBookingsService } from './admin/listing-live-search-delegate-bookings.service';
import { ListingLiveSearchDelegateOrganizationsService } from './admin/listing-live-search-delegate-organizations.service';
import { ListingLiveSearchDelegateUsersService } from './admin/listing-live-search-delegate-users.service';
import { ListingLiveSearchDelegateWarehousesService } from './admin/listing-live-search-delegate-warehouses.service';
import { LiveSearchDelegateCitiesService } from './live-search-delegate-cities.service';
import { LiveSearchDelegateOrganizationBankInfoService } from './live-search-delegate-organization-bank-info.service';
import { ListingLiveSearchDelegateWarehousesUserService } from './listing-live-search-delegate-warehouses.service';

import { ILiveSearchDelegate } from '../model/live-search-delegate.model';

export enum eLiveSearchDelegateType {
  adminListingAdverts = 'adminListingAdverts',
  adminListingOrganizations = 'adminListingOrganizations',
  adminListingBookings = 'adminListingBookings',
  adminListingUsers = 'adminListingUsers',
  adminListingWarehouses = 'adminListingWarehouses',
  userListingWarehouses = 'userListingWarehouses',
  cities = 'cities',
  organizationBankInfo = 'organizationBankInfo',
}

@Injectable({ providedIn: 'root' })
export class LiveSearchDelegateFactoryService {
  constructor(
    private adminListingLiveSearchDelegateAdvertsService: ListingLiveSearchDelegateAdvertsService,
    private adminListingLiveSearchDelegateBookingsService: ListingLiveSearchDelegateBookingsService,
    private adminListingLiveSearchDelegateOrganizationsService: ListingLiveSearchDelegateOrganizationsService,
    private adminListingLiveSearchDelegateUsersService: ListingLiveSearchDelegateUsersService,
    private adminListingLiveSearchDelegateWarehousesService: ListingLiveSearchDelegateWarehousesService,
    private userListingLiveSearchDelegateWarehousesService: ListingLiveSearchDelegateWarehousesUserService,
    private liveSearchDelegateCitiesService: LiveSearchDelegateCitiesService,
    private liveSearchDelegateOrganizationBankInfoService: LiveSearchDelegateOrganizationBankInfoService,
  ) {}

  public getLiveSearchDelegate(delegateType: eLiveSearchDelegateType): ILiveSearchDelegate {
    switch (delegateType) {
      case eLiveSearchDelegateType.adminListingAdverts:
        return this.adminListingLiveSearchDelegateAdvertsService;
      case eLiveSearchDelegateType.adminListingBookings:
        return this.adminListingLiveSearchDelegateBookingsService;
      case eLiveSearchDelegateType.adminListingOrganizations:
        return this.adminListingLiveSearchDelegateOrganizationsService;
      case eLiveSearchDelegateType.adminListingUsers:
        return this.adminListingLiveSearchDelegateUsersService;
      case eLiveSearchDelegateType.adminListingWarehouses:
        return this.adminListingLiveSearchDelegateWarehousesService;
      case eLiveSearchDelegateType.userListingWarehouses:
        return this.userListingLiveSearchDelegateWarehousesService;
      case eLiveSearchDelegateType.cities:
        return this.liveSearchDelegateCitiesService;
      case eLiveSearchDelegateType.organizationBankInfo:
        return this.liveSearchDelegateOrganizationBankInfoService;
      default:
        throw new Error('Delegate not found');
    }
  }
}
