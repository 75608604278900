import { IHttpRequestState } from '../../models/http.model';
import {
  IWarehouses,
  IWarehouseDocuments,
  IWarehouseExternal,
  IWarehouseGeneral,
  IWarehouseInternal,
  IWarehouseTabState,
} from '../../models/warehouse.model';

export interface IWarehouseStoreState {
  warehouses: IWarehouses | null;
  createdWarehouseId: string | null;
  warehouseTabState: IWarehouseTabState | null;
  warehouseGeneral: IWarehouseGeneral | null;
  warehouseExternal: IWarehouseExternal | null;
  warehouseInternal: IWarehouseInternal | null;
  warehouseDocuments: IWarehouseDocuments | null;
  warehousePreloadedPhotos: string[] | null;
  detailsLoadingState: IHttpRequestState | null;
  loadingState: IHttpRequestState | null;
  preloadingState: IHttpRequestState | null;
  publishingState: IHttpRequestState | null;
  updatingState: IHttpRequestState | null;
}

export const InitWarehouseState: IWarehouseStoreState = {
  warehouses: null,
  createdWarehouseId: null,
  warehouseTabState: null,
  warehouseGeneral: null,
  warehouseExternal: null,
  warehouseInternal: null,
  warehouseDocuments: null,
  warehousePreloadedPhotos: null,
  detailsLoadingState: null,
  loadingState: null,
  preloadingState: null,
  publishingState: null,
  updatingState: null,
};
