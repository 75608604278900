<div class="booking-calendar">
  <div
    class="booking-calendar__calendars"
    [@calendarSlider]="currentPeriodDirectionHandler"
    [tlpLoading]="bookingDatesLoading"
    [addLoaderToChild]="false"
  >
    <table
      class="booking-calendar__calendar"
      *ngFor="let displayedPeriod of displayedPeriods; let i = index"
      [ngStyle]="{ width: 'calc(100% / ' + displayedPeriods.length + (i === displayedPeriods.length - 1 ? ')' : ' - 16px)')}"
    >
      <thead class="booking-calendar__header">
        <tr class="booking-calendar__row">
          <th class="booking-calendar__heading" colspan="7">{{ getDisplayedPeriodMainDate(displayedPeriod.mainDate) }}</th>
          <tlp-button
            class="booking-calendar__button booking-calendar__button--left"
            theme="link"
            size="fit"
            *ngIf="canChangePeriod('left') && i === 0"
            [isIconButton]="true"
            (click)="changeDisplayedPeriod('left')"
          >
            <svg-icon-sprite src="./assets/sprites/sprite.svg#ic-left" width="16px" height="16px"></svg-icon-sprite>
          </tlp-button>
          <tlp-button
            class="booking-calendar__button booking-calendar__button--right"
            theme="link"
            size="fit"
            *ngIf="canChangePeriod('right') && i === displayedPeriods.length - 1"
            [isIconButton]="true"
            (click)="changeDisplayedPeriod('right')"
          >
            <svg-icon-sprite src="./assets/sprites/sprite.svg#ic-right" width="16px" height="16px"></svg-icon-sprite>
          </tlp-button>
        </tr>
        <tr class="booking-calendar__row">
          <th class="booking-calendar__cell" *ngFor="let day of calendarDaysOfWeek">
            <span class="booking-calendar__label booking-calendar__label--lighter">{{ day }}</span>
          </th>
        </tr>
      </thead>
      <tbody class="booking-calendar__body">
        <tr class="booking-calendar__row" *ngFor="let week of displayedPeriod.weeks; let weekIndex = index">
          <ng-container *ngIf="weekIndex === 0 && week.length < 7">
            <td class="booking-calendar__cell" *ngFor="let stub of getStubDates(7 - week.length)">{{ stub }}</td>
          </ng-container>
          <td class="booking-calendar__cell" *ngFor="let calendarDate of week">
            <span class="booking-calendar__date-percentage" [style.height.%]="calendarDate.bookingPercent"></span>
            <span
              class="booking-calendar__date"
              [class.booking-calendar__date--past]="calendarDate.isPastDate"
              [tlpTooltip]="calendarDate.isPastDate ? null : dateTooltipInfo"
              [relativeContainer]="true"
            >
              {{ calendarDate.date | date: 'd' }}
            </span>

            <ng-template #dateTooltipInfo>
              <div class="booking-calendar__amount-tooltip">
                <tlp-booking-amount
                  [calendarDate]="calendarDate"
                  [totalAmount]="totalAmount"
                  [amountUnit]="amountUnit"
                ></tlp-booking-amount>
              </div>
            </ng-template>
          </td>
          <ng-container *ngIf="displayedPeriod.weeks && weekIndex === displayedPeriod.weeks.length - 1 && week.length < 7">
            <td class="booking-calendar__cell" *ngFor="let stub of getStubDates(7 - week.length)">{{ stub }}</td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="booking-calendar__hints">
    <div class="booking-calendar__hint booking-calendar__hint--free-space">
      <span class="booking-calendar__label booking-calendar__label--dark">Свободно</span>
    </div>
    <div class="booking-calendar__hint booking-calendar__hint--reserved-space">
      <span class="booking-calendar__label booking-calendar__label--dark">Забронировано</span>
    </div>
  </div>
</div>
