import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import { ContentBlockModule } from '../content-block/content-block.module';
import { PipesModule } from '../pipes/pipes.module';
import { CostCalculatorComponent } from './component/cost-calculator/cost-calculator.component';

@NgModule({
  declarations: [CostCalculatorComponent],
  imports: [CommonModule, NgxMaskModule, ContentBlockModule, PipesModule],
  exports: [CostCalculatorComponent],
})
export class CostCalculatorModule {}
