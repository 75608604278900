import { Action, createReducer, on } from '@ngrx/store';
import { ILogoutState, InitLogoutState } from './logout.state';
import * as LogoutActions from './logout.actions';

const logoutReducer = createReducer(
  InitLogoutState,
  on(LogoutActions.logout, state => ({ ...state, isLoading: true, isDidLoad: false, error: null })),
  on(LogoutActions.logoutFailure, (state, { error }) => ({ ...state, error, isLoading: false })),
  on(LogoutActions.logoutSuccess, state => ({ ...state, isLoading: false, isDidLoad: true })),
  on(LogoutActions.resetLogoutState, () => ({ ...InitLogoutState })),
);

export const LogoutReducer = (state: ILogoutState, action: Action) => {
  return logoutReducer(state, action);
};
