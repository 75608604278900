import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IHttpRequestState } from '../../models/http.model';
import { IOrganizationStoreState } from './organization.state';
import {
  IOrganization,
  IOrganizationDocuments,
  IOrganizationInfo,
  IOrganizationRejectInfo,
  IOrganizations,
  IOrganizationsApiParams,
} from '../../models/organization.model';

import * as organizationActions from './organization.actions';
import * as organizationSelectors from './organization.selectors';

@Injectable()
export class OrganizationStoreService {
  constructor(private organizationStore: Store<IOrganizationStoreState>) {}

  get organizations$(): Observable<IOrganizations | null> {
    return this.organizationStore.select(organizationSelectors.organizations);
  }

  get organization$(): Observable<IOrganization | null> {
    return this.organizationStore.select(organizationSelectors.organization);
  }

  get organizationCreatedId$(): Observable<string | null> {
    return this.organizationStore.select(organizationSelectors.organizationCreatedId);
  }

  get organizationGeneral$(): Observable<IOrganizationInfo | null> {
    return this.organizationStore.select(organizationSelectors.organizationGeneral);
  }

  get organizationDocuments$(): Observable<IOrganizationDocuments | null> {
    return this.organizationStore.select(organizationSelectors.organizationDocuments);
  }

  get organizationStoreState$(): Observable<IOrganizationStoreState> {
    return this.organizationStore.select(organizationSelectors.getOrganizationStoreState);
  }

  get organizationsLoadingState$(): Observable<IHttpRequestState | null> {
    return this.organizationStore.select(organizationSelectors.getOrganizationsLoadingState);
  }

  get organizationLoadingState$(): Observable<IHttpRequestState | null> {
    return this.organizationStore.select(organizationSelectors.getOrganizationLoadingState);
  }

  get organizationPublishingState$(): Observable<IHttpRequestState | null> {
    return this.organizationStore.select(organizationSelectors.getOrganizationPublishingState);
  }

  get organizationUpdatingState$(): Observable<IHttpRequestState | null> {
    return this.organizationStore.select(organizationSelectors.getOrganizationUpdatingState);
  }

  public getOrganizations(params: IOrganizationsApiParams): void {
    this.organizationStore.dispatch(organizationActions.getOrganizations({ params }));
  }

  public getOrganization(id: string): void {
    this.organizationStore.dispatch(organizationActions.getOrganization({ id }));
  }

  public getOrganizationGeneral(id: string): void {
    this.organizationStore.dispatch(organizationActions.getOrganizationGeneral({ id }));
  }

  public getOrganizationDocuments(id: string): void {
    this.organizationStore.dispatch(organizationActions.getOrganizationDocuments({ id }));
  }

  public getOrganizationDocumentsArchive(id: string): void {
    this.organizationStore.dispatch(organizationActions.getOrganizationDocumentsArchive({ id }));
  }

  public createOrganization(organizationInfo: IOrganizationInfo): void {
    this.organizationStore.dispatch(organizationActions.createOrganization({ organizationInfo }));
  }

  public setCreatedOrganizationId(id: string): void {
    this.organizationStore.dispatch(organizationActions.setCreatedOrganizationId({ id }));
  }

  public publishOrganization(id: string): void {
    this.organizationStore.dispatch(organizationActions.publishOrganization({ id }));
  }

  public verifyOrganization(id: string): void {
    this.organizationStore.dispatch(organizationActions.verifyOrganization({ id }));
  }

  public rejectOrganization(id: string, rejectInfo: IOrganizationRejectInfo): void {
    this.organizationStore.dispatch(organizationActions.rejectOrganization({ id, rejectInfo }));
  }

  public deleteOrganization(id: string, params: IOrganizationsApiParams): void {
    this.organizationStore.dispatch(organizationActions.deleteOrganization({ id, params }));
  }

  public updateOrganizationRequisites(id: string, organizationInfo: IOrganizationInfo): void {
    this.organizationStore.dispatch(organizationActions.updateOrganizationRequisites({ id, organizationInfo }));
  }

  public uploadOrganizationDocument(file: File, id: string, documentType: string): void {
    this.organizationStore.dispatch(organizationActions.uploadOrganizationDocument({ file, id, documentType }));
  }

  public deleteOrganizationDocument(id: string, documentType: string): void {
    this.organizationStore.dispatch(organizationActions.deleteOrganizationDocument({ id, documentType }));
  }

  public getOrganizationForm(id: string): void {
    this.organizationStore.dispatch(organizationActions.getOrganizationForm({ id }));
  }

  public getOrganizationReport(id: string): void {
    this.organizationStore.dispatch(organizationActions.getOrganizationReport({ id }));
  }

  public resetOrganizations(): void {
    this.organizationStore.dispatch(organizationActions.resetOrganizations());
  }

  public resetOrganization(): void {
    this.organizationStore.dispatch(organizationActions.resetOrganization());
  }

  public resetOrganizationsLoadingState(): void {
    this.organizationStore.dispatch(organizationActions.resetOrganizationsLoadingState());
  }

  public resetOrganizationLoadingState(): void {
    this.organizationStore.dispatch(organizationActions.resetOrganizationLoadingState());
  }

  public resetOrganizationPublishingState(): void {
    this.organizationStore.dispatch(organizationActions.resetOrganizationPublishingState());
  }

  public resetOrganizationUpdatingState(): void {
    this.organizationStore.dispatch(organizationActions.resetOrganizationUpdatingState());
  }

  public resetOrganizationState(): void {
    this.organizationStore.dispatch(organizationActions.resetOrganizationState());
  }
}
