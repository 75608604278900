import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AbstractApiMethodsService } from './abstract-api-methods.service';

@Injectable()
export class ApiLogoutService extends AbstractApiMethodsService {
  public logout(): Observable<boolean | HttpErrorResponse> {
    return this.httpPost({}, 'auth/logout');
  }
}
