import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

import { SharedModule } from './shared.module';
import { AdminStoreModule } from './_core/stores-admin/admin-store.module';
import { ApiModule } from './_core/api/api.module';
import { AppRoutingModule } from './app-routing.module';
import { NavigationListModule } from './navigation-list/navigation-list.module';
import { StoresModule } from './_core/stores/stores.module';

import { AppComponent } from './component/app.component';

import { environment } from '../environments/environment';
import { APP_CONFIG } from '../app.config';
import { YA_MAP_CONFIG } from 'angular8-yandex-maps';

registerLocaleData(localeRu, 'ru');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    SharedModule,
    StoresModule,
    AdminStoreModule,
    AppRoutingModule,
    NavigationListModule,
    ApiModule.forRoot({
      BASE_API_URL: environment.api,
      BASE_API_PREFIX: environment.prefix,
    }),
    EffectsModule.forRoot([]),
    IconSpriteModule.forRoot({ path: APP_CONFIG.ICON_SPRITE_PATH }),
    NgxMaskModule.forRoot(),
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'ru' },
    {
      provide: YA_MAP_CONFIG,
      useValue: { apikey: APP_CONFIG.MAP_API_KEY, lang: 'ru_RU' },
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: APP_CONFIG.RECAPTCHA_V2_SITE_KEY } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
