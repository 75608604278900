import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { ApiLogoutService } from '../../api/api-logout.service';
import { AuthService } from '../../api/auth.service';
import { CurrentUserStoreService } from '../current-user/current-user-store.service';
import { HttpErrorsService } from '../../api/http-errors.service';
import { LoginStoreService } from '../login/login-store.service';
import { eLogoutActions } from './logout.actions';

@Injectable()
export class LogoutEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private toastrService: ToastrService,
    private apiLogoutService: ApiLogoutService,
    private authService: AuthService,
    private currentUserStoreService: CurrentUserStoreService,
    private httpErrorsService: HttpErrorsService,
    private loginStoreService: LoginStoreService,
  ) {}

  public logout$: Observable<{ type: eLogoutActions } | { type: eLogoutActions; error: HttpErrorResponse }> = createEffect(() =>
    this.actions$.pipe(
      ofType(eLogoutActions.LOGOUT),
      switchMap((payload: { type: string; returnUrl: string }) =>
        this.apiLogoutService.logout().pipe(
          tap(() => {
            this.authService.removeAuthData();
            this.currentUserStoreService.resetCurrentUser();
            this.loginStoreService.resetLoginState();
            this.router.navigate([payload.returnUrl]);
          }),
          map(() => ({ type: eLogoutActions.LOGOUT_SUCCESS })),
          catchError(async (err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));

            return { type: eLogoutActions.LOGOUT_FAILURE, error: err };
          }),
        ),
      ),
    ),
  );
}
