import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IListingLoadingState, IListingStoreState } from './listing.state';

export const getListingStoreState = createFeatureSelector<IListingStoreState>('admin-listing');

export const getUsersListing = createSelector(getListingStoreState, (state: IListingStoreState) => state.users);
export const getOrganizationsListing = createSelector(getListingStoreState, (state: IListingStoreState) => state.organizations);
export const getWarehousesListing = createSelector(getListingStoreState, (state: IListingStoreState) => state.warehouses);
export const getAdvertsListing = createSelector(getListingStoreState, (state: IListingStoreState) => state.adverts);
export const getBookingsListing = createSelector(getListingStoreState, (state: IListingStoreState) => state.bookings);

export const getListingLoadingState = createSelector(
  getListingStoreState,
  (state: IListingStoreState) =>
    ({
      usersLoadingState: state.usersLoadingState,
      organizationsLoadingState: state.organizationsLoadingState,
      warehousesLoadingState: state.warehousesLoadingState,
      advertsLoadingState: state.advertsLoadingState,
      bookingsLoadingState: state.bookingsLoadingState,
    } as IListingLoadingState),
);
