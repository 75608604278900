import { Directive, Input, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[tlpLoading]',
})
export class LoadingDirective {
  @Input() public addLoaderToChild: boolean = true;
  @Input() set tlpLoading(isLoading: boolean | null) {
    let targetNativeElement: HTMLElement | Element = this.container?.element?.nativeElement;
    if (this.addLoaderToChild) {
      targetNativeElement = targetNativeElement?.children[0];
    }

    if (isLoading) {
      targetNativeElement?.classList?.add('loading');
    } else {
      targetNativeElement?.classList?.remove('loading');
    }
  }

  constructor(private container: ViewContainerRef) {}
}
