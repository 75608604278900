import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InputPhoneComponent } from './input-phone/input-phone.component';
import { SelectModule } from '../select/select.module';

@NgModule({
  declarations: [InputPhoneComponent],
  imports: [CommonModule, NgxMaskModule, ReactiveFormsModule, FormsModule, SelectModule],
  exports: [InputPhoneComponent],
})
export class InputPhoneModule {}
