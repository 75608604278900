import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CityEffects } from './city.effects';
import { CityReducer } from './city.reducer';
import { ApiCityService } from '../../api/api-city.service';
import { CityStoreService } from './city-store.service';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('city', CityReducer), EffectsModule.forFeature([CityEffects])],
  providers: [ApiCityService, CityStoreService],
})
export class CityStoreModule {}
