import { style, animate, group } from '@angular/animations';

// tslint:disable-next-line: no-magic-numbers
export const BOOKING_PERCENTS: number[] = [0, 5, 25, 50, 75, 100];
export const MIN_NON_ZERO_BOOKING_PERCENT: number = BOOKING_PERCENTS[1];

export const CALENDAR_DAYS_OF_WEEK: string[] = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];

export const CALENDAR_ANIMATION_LEFT = [
  style({ transform: 'translateX(-50px)', opacity: 0 }),
  group([
    animate(
      '0.3s 0.1s ease',
      style({
        transform: 'translateX(0)',
      }),
    ),
    animate(
      '0.3s ease',
      style({
        opacity: 1,
      }),
    ),
  ]),
];

export const CALENDAR_ANIMATION_RIGHT = [
  style({ transform: 'translateX(50px)', opacity: 0 }),
  group([
    animate(
      '0.3s 0.1s ease',
      style({
        transform: 'translateX(0)',
      }),
    ),
    animate(
      '0.3s ease',
      style({
        opacity: 1,
      }),
    ),
  ]),
];

export enum eBookingCalendarDisplayedDaysCount {
  default = 23,
  cropped = 13,
}

export enum eBookingCalendarDisplayedPeriodsCount {
  default = 2,
  cropped = 1,
}
