import { Pipe, PipeTransform } from '@angular/core';

const CASES: number[] = [2, 0, 1, 1, 1, 2];

@Pipe({
  name: 'numeralPlurals',
})
export class NumeralPluralsPipe implements PipeTransform {
  public transform(value: number | string, titles: string[], onlyTitle: boolean = false): string {
    // tslint:disable-next-line: no-magic-numbers
    const title = titles[Number(value) % 100 > 4 && Number(value) % 100 < 20 ? 2 : CASES[Number(value) % 10 < 5 ? Number(value) % 10 : 5]];
    return onlyTitle ? title : `${value} ${title}`;
  }
}
