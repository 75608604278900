import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { ButtonModule } from '../button/button.module';
import { ContentBlockModule } from '../content-block/content-block.module';
import { InputHolderModule } from '../input-holder/input-holder.module';
import { InputModule } from '../input/input.module';
import { SelectModule } from '../select/select.module';
import { SearchComponent } from './component/search/search.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconSpriteModule,
    ButtonModule,
    ContentBlockModule,
    InputHolderModule,
    InputModule,
    SelectModule,
  ],
  exports: [SearchComponent],
  declarations: [SearchComponent],
})
export class SearchModule {}
