import { INPUT_PASSWORD_LATIN_MINLENGTH, INPUT_PASSWORD_MINLENGTH } from '../../../_core/const/input-validation.const';
import { IPasswordCheckItem } from '../model/password-checker.model';

export const PASSWORD_RULES_REGEXP = {
  lengthRegexp: new RegExp(`(?=.{${INPUT_PASSWORD_MINLENGTH},})`),
  latinLengthRegexp: new RegExp(`(?=.*[a-zA-Z]{${INPUT_PASSWORD_LATIN_MINLENGTH},})`),
  latinRegexp: /(?=.*[a-zA-Z])/,
  uppercaseRegexp: /(?=.*[A-Z])/,
  digitRegexp: /(?=.*[0-9])/,
};

export const DEFAULT_PASSWORD_VALIDATION: IPasswordCheckItem[] = [
  {
    rule: 'validLatinLengthAndLatin',
    text: `Не менее ${INPUT_PASSWORD_LATIN_MINLENGTH} символов латинского алфавита (Aa-Zz)`,
    isValid: undefined,
  },
  {
    rule: 'hasUppercase',
    text: 'Не менее одной заглавной буквы',
    isValid: undefined,
  },
  {
    rule: 'hasDigit',
    text: 'Не менее одной цифры (0-9)',
    isValid: undefined,
  },
];
