import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileDndDirective } from './file-dnd.directive';

@NgModule({
  declarations: [FileDndDirective],
  imports: [CommonModule],
  exports: [FileDndDirective],
})
export class FileDndModule {}
