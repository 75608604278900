import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { OverflowHiderModule } from '../directives/overflow-hider/overflow-hider.module';
import { DrawerComponent } from './component/drawer/drawer.component';

@NgModule({
  declarations: [DrawerComponent],
  imports: [CommonModule, IconSpriteModule, OverflowHiderModule],
  exports: [DrawerComponent],
})
export class DrawerModule {}
