import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IHttpRequestState } from '../../models/http.model';
import { ILogsStoreState } from './logs.state';

export const getLogStoreState = createFeatureSelector<ILogsStoreState>('admin-logs');

export const getLogs = createSelector(getLogStoreState, (state: ILogsStoreState) => state.logs);
export const getLogsAvailableEvents = createSelector(getLogStoreState, (state: ILogsStoreState) => state.availableEvents);

export const getLogsLoadingState = createSelector(
  getLogStoreState,
  (state: ILogsStoreState) =>
    ({
      ...state.loadingState,
    } as IHttpRequestState),
);
export const getLogsAvailableEventsLoadingState = createSelector(
  getLogStoreState,
  (state: ILogsStoreState) =>
    ({
      ...state.availableEventsLoadingState,
    } as IHttpRequestState),
);
