import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { ApiOrganizationService } from '../../api/admin/api-organization.service';
import { HttpErrorsService } from '../../api/http-errors.service';
import { OrganizationStoreService } from './organization-store.service';

import { IDocument } from '../../models/document.model';
import {
  IOrganization,
  IOrganizationDocuments,
  IOrganizationInfo,
  IOrganizationRejectInfo,
  IOrganizations,
  IOrganizationsApiParams,
} from '../../models/organization.model';
import { eOrganizationActions } from './organization.actions';

@Injectable()
export class OrganizationEffects {
  constructor(
    private actions$: Actions,
    private toastrService: ToastrService,
    private apiOrganizationService: ApiOrganizationService,
    private httpErrorsService: HttpErrorsService,
    private organizationStoreService: OrganizationStoreService,
  ) {}

  public getOrganizations$: Observable<
    { type: eOrganizationActions; organizations: IOrganizations | null } | { type: eOrganizationActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eOrganizationActions.ORGANIZATIONS_GET),
      switchMap((payload: { type: string; params: IOrganizationsApiParams }) =>
        this.apiOrganizationService.getOrganizations(payload.params).pipe(
          map(organizations => ({ type: eOrganizationActions.ORGANIZATIONS_GET_SUCCESS, organizations })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eOrganizationActions.ORGANIZATIONS_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getOrganization$: Observable<
    { type: eOrganizationActions; organization: IOrganization | null } | { type: eOrganizationActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eOrganizationActions.ORGANIZATION_GET),
      switchMap((payload: { type: string; id: string }) =>
        this.apiOrganizationService.getOrganization(payload.id).pipe(
          map(organization => ({ type: eOrganizationActions.ORGANIZATION_GET_SUCCESS, organization })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eOrganizationActions.ORGANIZATION_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getOrganizationGeneral$: Observable<
    { type: eOrganizationActions; organizationGeneral: IOrganizationInfo | null } | { type: eOrganizationActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eOrganizationActions.ORGANIZATION_GENERAL_GET),
      switchMap((payload: { type: string; id: string }) =>
        this.apiOrganizationService.getOrganizationGeneral(payload.id).pipe(
          map(organizationGeneral => ({ type: eOrganizationActions.ORGANIZATION_GENERAL_GET_SUCCESS, organizationGeneral })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eOrganizationActions.ORGANIZATION_GENERAL_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getOrganizationDocuments$: Observable<
    | { type: eOrganizationActions; organizationDocuments: IOrganizationDocuments | null }
    | { type: eOrganizationActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eOrganizationActions.ORGANIZATION_DOCUMENTS_GET),
      switchMap((payload: { type: string; id: string }) =>
        this.apiOrganizationService.getOrganizationDocuments(payload.id).pipe(
          map(organizationDocuments => ({ type: eOrganizationActions.ORGANIZATION_DOCUMENTS_GET_SUCCESS, organizationDocuments })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eOrganizationActions.ORGANIZATION_DOCUMENTS_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getOrganizationDocumentArchive$: Observable<
    { type: eOrganizationActions } | { type: eOrganizationActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eOrganizationActions.ORGANIZATION_DOCUMENTS_ARCHIVE_GET),
      switchMap((payload: { type: string; id: string }) =>
        this.apiOrganizationService.getOrganizationDocumentsArchive(payload.id).pipe(
          tap((organizationDocumentsArchive: IDocument) => {
            if (organizationDocumentsArchive?.file.path) {
              window.open(organizationDocumentsArchive?.file.path, '_blank');
            }
          }),
          map(organizationDocumentsArchive => ({
            type: eOrganizationActions.ORGANIZATION_DOCUMENTS_ARCHIVE_GET_SUCCESS,
            organizationDocumentsArchive,
          })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eOrganizationActions.ORGANIZATION_DOCUMENTS_ARCHIVE_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public createOrganization$: Observable<
    { type: eOrganizationActions; response: { id: string } } | { type: eOrganizationActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eOrganizationActions.ORGANIZATION_CREATE),
      switchMap((payload: { type: string; organizationInfo: IOrganizationInfo }) =>
        this.apiOrganizationService.createOrganization(payload.organizationInfo).pipe(
          tap((response: { id: string }) => {
            this.toastrService.success('Компания успешно создана');
            this.organizationStoreService.setCreatedOrganizationId(response.id);
          }),
          map((response: { id: string }) => ({ type: eOrganizationActions.ORGANIZATION_CREATE_SUCCESS, response })),
          catchError((err: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(err)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            }

            return of({ type: eOrganizationActions.ORGANIZATION_CREATE_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public publishOrganization$: Observable<
    { type: eOrganizationActions } | { type: eOrganizationActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eOrganizationActions.ORGANIZATION_PUBLISH),
      switchMap((payload: { type: string; id: string }) =>
        this.apiOrganizationService.publishOrganization(payload.id).pipe(
          tap(() => {
            this.toastrService.success('Компания успешно добавлена');
          }),
          map(() => ({ type: eOrganizationActions.ORGANIZATION_PUBLISH_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(err)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            }

            return of({ type: eOrganizationActions.ORGANIZATION_PUBLISH_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public verifyOrganization$: Observable<
    { type: eOrganizationActions } | { type: eOrganizationActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eOrganizationActions.ORGANIZATION_VERIFY),
      switchMap((payload: { type: string; id: string }) =>
        this.apiOrganizationService.verifyOrganization(payload.id).pipe(
          tap(() => {
            this.toastrService.success('Компания успешно верифицирована');
            this.organizationStoreService.getOrganizationGeneral(payload.id);
          }),
          map(() => ({ type: eOrganizationActions.ORGANIZATION_VERIFY_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eOrganizationActions.ORGANIZATION_VERIFY_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public rejectOrganization$: Observable<
    { type: eOrganizationActions } | { type: eOrganizationActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eOrganizationActions.ORGANIZATION_REJECT),
      switchMap((payload: { type: string; id: string; rejectInfo: IOrganizationRejectInfo }) =>
        this.apiOrganizationService.rejectOrganization(payload.id, payload.rejectInfo).pipe(
          tap(() => {
            this.toastrService.success('Компания успешно отклонена');
            this.organizationStoreService.getOrganizationGeneral(payload.id);
          }),
          map(() => ({ type: eOrganizationActions.ORGANIZATION_REJECT_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eOrganizationActions.ORGANIZATION_REJECT_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public deleteOrganization$: Observable<
    { type: eOrganizationActions } | { type: eOrganizationActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eOrganizationActions.ORGANIZATION_DELETE),
      switchMap((payload: { type: string; id: string; params: IOrganizationsApiParams }) =>
        this.apiOrganizationService.deleteOrganization(payload.id).pipe(
          tap(() => {
            this.toastrService.success('Компания успешно удалена');
            this.organizationStoreService.getOrganizations(payload.params);
          }),
          map(() => ({ type: eOrganizationActions.ORGANIZATION_DELETE_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eOrganizationActions.ORGANIZATION_DELETE_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public updateOrganizationRequisites$: Observable<
    { type: eOrganizationActions; response: { id: string } } | { type: eOrganizationActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eOrganizationActions.ORGANIZATION_UPDATE_REQUISITES),
      switchMap((payload: { id: string; type: string; organizationInfo: IOrganizationInfo }) =>
        this.apiOrganizationService.updateOrganizationRequisites(payload.id, payload.organizationInfo).pipe(
          tap(() => {
            this.toastrService.success('Компания успешно обновлена');
          }),
          map((response: { id: string }) => ({ type: eOrganizationActions.ORGANIZATION_UPDATE_REQUISITES_SUCCESS, response })),
          catchError((err: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(err)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            }

            return of({ type: eOrganizationActions.ORGANIZATION_UPDATE_REQUISITES_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public uploadOrganizationDocument$: Observable<
    { type: eOrganizationActions } | { type: eOrganizationActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eOrganizationActions.ORGANIZATION_DOCUMENT_UPLOAD),
      map((payload: { type: string; file: File; id: string; documentType: string }) => {
        const formData = new FormData();
        formData.append('file', payload.file);

        return { formData, id: payload.id, documentType: payload.documentType };
      }),
      switchMap((payload: { formData: FormData; id: string; documentType: string }) =>
        this.apiOrganizationService.uploadOrganizationDocument(payload.formData, payload.id, payload.documentType).pipe(
          tap(() => {
            this.toastrService.success('Документ успешно прикреплен');
          }),
          map(() => ({ type: eOrganizationActions.ORGANIZATION_DOCUMENT_UPLOAD_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(err)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            }

            return of({ type: eOrganizationActions.ORGANIZATION_DOCUMENT_UPLOAD_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public deleteOrganizationDocument$: Observable<
    { type: eOrganizationActions } | { type: eOrganizationActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eOrganizationActions.ORGANIZATION_DOCUMENT_DELETE),
      switchMap((payload: { type: string; id: string; documentType: string }) =>
        this.apiOrganizationService.deleteOrganizationDocument(payload.id, payload.documentType).pipe(
          tap(() => {
            this.toastrService.success('Документ успешно откреплен');
          }),
          map(() => ({ type: eOrganizationActions.ORGANIZATION_DOCUMENT_DELETE_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eOrganizationActions.ORGANIZATION_DOCUMENT_DELETE_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getOrganizationForm$: Observable<
    { type: eOrganizationActions } | { type: eOrganizationActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eOrganizationActions.ORGANIZATION_FORM_GET),
      switchMap((payload: { type: string; id: string }) =>
        this.apiOrganizationService.getOrganizationForm(payload.id).pipe(
          tap(() => {
            this.toastrService.success('Анкета успешно найдена');
          }),
          map(() => ({ type: eOrganizationActions.ORGANIZATION_FORM_GET_SUCCESS })),
          catchError((error: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(error));
            return of({ type: eOrganizationActions.ORGANIZATION_FORM_GET_FAILURE, error });
          }),
        ),
      ),
    ),
  );

  public getOrganizationReport$: Observable<
    { type: eOrganizationActions } | { type: eOrganizationActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eOrganizationActions.ORGANIZATION_REPORT_GET),
      switchMap((payload: { type: string; id: string }) =>
        this.apiOrganizationService.getOrganizationReport(payload.id).pipe(
          tap((response: { url: string }) => {
            this.toastrService.success('Отчёт успешно найден');
            window.open(response.url, '_blank');
          }),
          map(() => ({ type: eOrganizationActions.ORGANIZATION_REPORT_GET_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eOrganizationActions.ORGANIZATION_REPORT_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );
}
