import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IListingAdvertsApiParams, IListingApiParams, IListingBookingsApiParams, IListing } from '../../models/listing.model';

export enum eListingActions {
  LISTING_USERS_GET = '[User: Listing] get users listing',
  LISTING_USERS_GET_SUCCESS = '[User: Listing] get users listing success',
  LISTING_USERS_GET_FAILURE = '[User: Listing] get users listing failure',

  LISTING_ORGANIZATIONS_GET = '[User: Listing] get organizations listing',
  LISTING_ORGANIZATIONS_GET_SUCCESS = '[User: Listing] get organizations listing success',
  LISTING_ORGANIZATIONS_GET_FAILURE = '[User: Listing] get organizations listing failure',

  LISTING_WAREHOUSES_GET = '[User: Listing] get warehouses listing',
  LISTING_WAREHOUSES_GET_SUCCESS = '[User: Listing] get warehouses listing success',
  LISTING_WAREHOUSES_GET_FAILURE = '[User: Listing] get warehouses listing failure',

  LISTING_ADVERTS_GET = '[User: Listing] get adverts listing',
  LISTING_ADVERTS_GET_SUCCESS = '[User: Listing] get adverts listing success',
  LISTING_ADVERTS_GET_FAILURE = '[User: Listing] get adverts listing failure',

  LISTING_BOOKINGS_GET = '[User: Listing] get bookings listing',
  LISTING_BOOKINGS_GET_SUCCESS = '[User: Listing] get bookings listing success',
  LISTING_BOOKINGS_GET_FAILURE = '[User: Listing] get bookings listing failure',

  LISTING_USERS_RESET = '[User: Listing] reset users listing state',
  LISTING_ORGANIZATIONS_RESET = '[User: Listing] reset organizations listing state',
  LISTING_WAREHOUSES_RESET = '[User: Listing] reset warehouses listing state',
  LISTING_ADVERTS_RESET = '[User: Listing] reset adverts listing state',
  LISTING_BOOKINGS_RESET = '[User: Listing] reset bookings listing state',

  LISTING_STATE_RESET = '[User: Listing] reset listing state',
  LISTING_LOADING_STATE_RESET = '[User: Listing] reset logs loading state',
}

export const getUsersListing = createAction(eListingActions.LISTING_USERS_GET, props<{ params: IListingApiParams }>());
export const getUsersListingSuccess = createAction(eListingActions.LISTING_USERS_GET_SUCCESS, props<{ users: IListing }>());
export const getUsersListingFailure = createAction(eListingActions.LISTING_USERS_GET_FAILURE, props<{ error: HttpErrorResponse }>());

export const getOrganizationsListing = createAction(eListingActions.LISTING_ORGANIZATIONS_GET, props<{ params: IListingApiParams }>());
export const getOrganizationsListingSuccess = createAction(
  eListingActions.LISTING_ORGANIZATIONS_GET_SUCCESS,
  props<{ organizations: IListing }>(),
);
export const getOrganizationsListingFailure = createAction(
  eListingActions.LISTING_ORGANIZATIONS_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getWarehousesListing = createAction(eListingActions.LISTING_WAREHOUSES_GET, props<{ params: IListingApiParams }>());
export const getWarehousesListingSuccess = createAction(eListingActions.LISTING_WAREHOUSES_GET_SUCCESS, props<{ warehouses: IListing }>());
export const getWarehousesListingFailure = createAction(
  eListingActions.LISTING_WAREHOUSES_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getAdvertsListing = createAction(eListingActions.LISTING_ADVERTS_GET, props<{ params: IListingAdvertsApiParams }>());
export const getAdvertsListingSuccess = createAction(eListingActions.LISTING_ADVERTS_GET_SUCCESS, props<{ adverts: IListing }>());
export const getAdvertsListingFailure = createAction(eListingActions.LISTING_ADVERTS_GET_FAILURE, props<{ error: HttpErrorResponse }>());

export const getBookingsListing = createAction(eListingActions.LISTING_BOOKINGS_GET, props<{ params: IListingBookingsApiParams }>());
export const getBookingsListingSuccess = createAction(eListingActions.LISTING_BOOKINGS_GET_SUCCESS, props<{ bookings: IListing }>());
export const getBookingsListingFailure = createAction(eListingActions.LISTING_BOOKINGS_GET_FAILURE, props<{ error: HttpErrorResponse }>());

export const resetUsersListing = createAction(eListingActions.LISTING_USERS_RESET);
export const resetOrganizationsListing = createAction(eListingActions.LISTING_ORGANIZATIONS_RESET);
export const resetWarehousesListing = createAction(eListingActions.LISTING_WAREHOUSES_RESET);
export const resetAdvertsListing = createAction(eListingActions.LISTING_ADVERTS_RESET);
export const resetBookingsListing = createAction(eListingActions.LISTING_BOOKINGS_RESET);

export const resetListingState = createAction(eListingActions.LISTING_STATE_RESET);
export const resetListingLoadingState = createAction(eListingActions.LISTING_LOADING_STATE_RESET);
