import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IHttpRequestState } from '../../models/http.model';
import { IWarehousesCatalogStoreState } from './warehouses-catalog.state';

export const getWarehousesCatalogStoreState = createFeatureSelector<IWarehousesCatalogStoreState>('warehouses-catalog');

export const getWarehousesCatalog = createSelector(
  getWarehousesCatalogStoreState,
  (state: IWarehousesCatalogStoreState) => state.warehousesCatalog,
);
export const getWarehousesCatalogItem = createSelector(
  getWarehousesCatalogStoreState,
  (state: IWarehousesCatalogStoreState) => state.warehousesCatalogItem,
);

export const getWarehousesCatalogLoadingState = createSelector(
  getWarehousesCatalogStoreState,
  (state: IWarehousesCatalogStoreState) =>
    ({
      ...state.warehousesCatalogLoadingState,
    } as IHttpRequestState),
);
export const getWarehousesCatalogItemLoadingState = createSelector(
  getWarehousesCatalogStoreState,
  (state: IWarehousesCatalogStoreState) =>
    ({
      ...state.warehousesCatalogItemLoadingState,
    } as IHttpRequestState),
);
