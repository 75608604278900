import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ILogs, ILogsApiParams, ILogsAvailableEvent, ILogsAvailableEventsApiParams } from '../../models/logs.model';

export enum eLogsActions {
  LOGS_GET = '[Admin: Logs] get logs',
  LOGS_GET_SUCCESS = '[Admin: Logs] get logs success',
  LOGS_GET_FAILURE = '[Admin: Logs] get logs failure',

  LOGS_AVAILABLE_EVENTS_GET = '[Admin: Logs] get logs available events',
  LOGS_AVAILABLE_EVENTS_GET_SUCCESS = '[Admin: Logs] get logs available events success',
  LOGS_AVAILABLE_EVENTS_GET_FAILURE = '[Admin: Logs] get logs available events failure',

  LOGS_STATE_RESET = '[Admin: Logs] reset logs state',
  LOGS_AVAILABLE_EVENTS_RESET = '[Admin: Logs] reset logs available events',
  LOGS_LOADING_STATE_RESET = '[Admin: Logs] reset logs loading state',
}

export const getLogs = createAction(eLogsActions.LOGS_GET, props<{ params: ILogsApiParams }>());
export const getLogsSuccess = createAction(eLogsActions.LOGS_GET_SUCCESS, props<{ logs: ILogs }>());
export const getLogsFailure = createAction(eLogsActions.LOGS_GET_FAILURE, props<{ error: HttpErrorResponse }>());

export const getLogsAvailableEvents = createAction(
  eLogsActions.LOGS_AVAILABLE_EVENTS_GET,
  props<{ params: ILogsAvailableEventsApiParams }>(),
);
export const getLogsAvailableEventsSuccess = createAction(
  eLogsActions.LOGS_AVAILABLE_EVENTS_GET_SUCCESS,
  props<{ availableEvents: ILogsAvailableEvent[] }>(),
);
export const getLogsAvailableEventsFailure = createAction(
  eLogsActions.LOGS_AVAILABLE_EVENTS_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const resetLogsState = createAction(eLogsActions.LOGS_STATE_RESET);
export const resetLogsAvailableEvents = createAction(eLogsActions.LOGS_AVAILABLE_EVENTS_RESET);
export const resetLogsLoadingState = createAction(eLogsActions.LOGS_LOADING_STATE_RESET);
