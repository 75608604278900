import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractApiMethodsService } from './abstract-api-methods.service';

import { dictionarySystemNames, IDictionaryApiParams, IDictionaryItems } from '../models/dictionary.model';

@Injectable()
export class ApiDictionaryService extends AbstractApiMethodsService {
  public getDictionary(
    dictionarySystemName: dictionarySystemNames,
    params: IDictionaryApiParams | undefined,
  ): Observable<IDictionaryItems | null> {
    return this.httpPost(params ? params : {}, `dictionary/${dictionarySystemName}/items`);
  }

  public getDictionaryPublic(
    dictionarySystemName: dictionarySystemNames,
    params: IDictionaryApiParams | undefined,
  ): Observable<IDictionaryItems | null> {
    return this.httpPost(params ? params : {}, `dictionary/${dictionarySystemName}/all`);
  }
}
