import {
  Component,
  forwardRef,
  Input,
  ChangeDetectionStrategy,
  OnInit,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
  ValidationErrors,
  FormControl,
  ValidatorFn,
} from '@angular/forms';
import { InputMask } from 'src/app/_core/const/input-mask.const';
import {
  bikValidator,
  innValidator,
  kppValidator,
  ogrnIPValidator,
  ogrnValidator,
  okpoValidator,
  rusEmailValidator,
} from '../input.validator';

export type specialInputType = 'bik' | 'inn' | 'ogrn' | 'ogrnIP' | 'okpo' | 'kpp' | 'email';
export type inputTheme = 'default' | 'transparent';

@Component({
  selector: 'tlp-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements ControlValueAccessor, Validator, OnInit, OnChanges, OnDestroy {
  @Input() public formControlName: string = '';
  @Input() public theme: inputTheme = 'default';
  @Input() public specialType?: specialInputType;
  @Input() public placeholder: string = '';
  @Input() public required: boolean = false;
  @Input() public mask: string = '';
  @Input() public autocomplete: string = 'off';
  @Input() public dropSpecialCharacters: boolean = true;
  @Input() public trim: boolean = false;
  // prettier-ignore
  @Input() public specialCharacters: string[] = ['-', '/', '(', ')', '.', ':', ' ', '+', ',', '@', '[', ']', '"', '\''];
  @Input() public separatorLimit: string = '100000000';
  @Input() public allowNegativeNumbers: boolean = false;

  public formControl!: FormControl | null;
  public isDisabled: boolean = false;

  private validators: ValidatorFn[] = [];
  private decimalMarker: string = '.';

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.specialType?.currentValue) {
      this.validators = [];
      switch (this.specialType) {
        case 'bik':
          this.validators.push(bikValidator);
          this.mask = InputMask.bik;
          break;
        case 'kpp':
          this.validators.push(kppValidator);
          this.mask = InputMask.kpp;
          break;
        case 'inn':
          this.validators.push(innValidator);
          this.mask = InputMask.inn;
          break;
        case 'ogrn':
          this.validators.push(ogrnValidator);
          this.mask = InputMask.ogrn;
          break;
        case 'ogrnIP':
          this.validators.push(ogrnIPValidator);
          this.mask = InputMask.ogrnIP;
          break;
        case 'okpo':
          this.validators.push(okpoValidator);
          this.mask = InputMask.okpo;
          break;
        case 'email':
          this.validators.push(rusEmailValidator);
          break;
        default:
          break;
      }

      this.formControl?.setValidators(this.validators);
    }
  }

  public ngOnInit(): void {
    this.formControl = new FormControl('', this.validators);
  }

  public ngOnDestroy(): void {
    this.formControl = null;
  }

  private onChange = (_: number | string) => {};
  private onTouched = () => {};

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  public writeValue(outsideValue: number | string | undefined): void {
    if (outsideValue !== undefined) {
      const value = typeof outsideValue === 'string' && this.trim ? outsideValue.trim() : outsideValue;
      this.formControl?.setValue(value, { emitEvent: false });
      this.onTouched();
      this.onChange(value);
      this.cdr.markForCheck();
    }
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.formControl?.disable();
      this.isDisabled = true;
    } else {
      this.formControl?.enable();
      this.isDisabled = false;
    }

    this.cdr.markForCheck();
  }

  public onValueChange(event: Event | FocusEvent): void {
    let value = (event.target as HTMLInputElement).value;
    if (this.mask && this.dropSpecialCharacters) {
      value = this.removeMask(value);
    }
    if (this.trim) {
      value = value.trim();
    }

    if (event.type === 'blur') {
      this.formControl?.setValue(value, { emitEvent: false });
      this.onTouched();
    }

    this.onChange(value);
    this.cdr.markForCheck();
  }

  private regExpForRemove(specialCharactersForRemove: string[]): RegExp {
    return new RegExp(specialCharactersForRemove.map((item: string) => `\\${item}`).join('|'), 'gi');
  }

  private removeMask(value: string): string {
    const specialCharactersForRemove =
      this.mask.startsWith('separator') && this.dropSpecialCharacters
        ? this.specialCharacters.filter((item: string) => {
            return item !== this.decimalMarker && (item !== '-' || !this.allowNegativeNumbers);
          })
        : this.specialCharacters;

    return value ? value.replace(this.regExpForRemove(specialCharactersForRemove), '') : value;
  }

  public validate(): ValidationErrors | null {
    return this.formControl?.errors || null;
  }

  public get isError(): boolean {
    return !!this.formControl?.errors && this.formControl?.touched;
  }
}
