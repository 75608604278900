import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { ApiOrganizationService } from '../../api/api-organization.service';
import { CurrentUserStoreService } from '../current-user/current-user-store.service';
import { HttpErrorsService } from '../../api/http-errors.service';

import {
  IOrganizationBankInfo,
  IOrganizationGeneralInfo,
  IOrganizationInfo,
  IOrganization,
  IUpsertOrganizationResponse,
} from '../../models/organization.model';
import { eOrganizationActions } from './organization.actions';

@Injectable()
export class OrganizationEffects {
  constructor(
    private actions$: Actions,
    private toastrService: ToastrService,
    private apiOrganizationService: ApiOrganizationService,
    private currentUserStoreService: CurrentUserStoreService,
    private httpErrorsService: HttpErrorsService,
  ) {}

  public checkOrganizationInn$: Observable<
    | { type: eOrganizationActions; organizationShortInfo: IOrganizationGeneralInfo }
    | { type: eOrganizationActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eOrganizationActions.ORGANIZATION_CHECK_INN),
      switchMap((payload: { type: string; inn: string }) =>
        this.apiOrganizationService.checkOrganizationInn(payload.inn).pipe(
          map(organizationShortInfo => ({ type: eOrganizationActions.ORGANIZATION_CHECK_INN_SUCCESS, organizationShortInfo })),
          catchError((err: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(err)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            }

            return of({ type: eOrganizationActions.ORGANIZATION_CHECK_INN_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public checkOrganizationBik$: Observable<
    | { type: eOrganizationActions; organizationBankInfo: IOrganizationBankInfo | IOrganizationBankInfo[] }
    | { type: eOrganizationActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eOrganizationActions.ORGANIZATION_CHECK_BIK),
      switchMap((payload: { type: string; bank_bik: string }) =>
        this.apiOrganizationService.checkOrganizationBik(payload.bank_bik).pipe(
          map(organizationBankInfo => ({ type: eOrganizationActions.ORGANIZATION_CHECK_BIK_SUCCESS, organizationBankInfo })),
          catchError((err: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(err)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            }

            return of({ type: eOrganizationActions.ORGANIZATION_CHECK_BIK_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getOrganization$: Observable<
    { type: eOrganizationActions; organization: IOrganization } | { type: eOrganizationActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eOrganizationActions.ORGANIZATION_GET_CURRENT),
      switchMap(() =>
        this.apiOrganizationService.getCurrentOrganization().pipe(
          map(organization => ({ type: eOrganizationActions.ORGANIZATION_GET_CURRENT_SUCCESS, organization })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eOrganizationActions.ORGANIZATION_GET_CURRENT_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public createOrganization$: Observable<
    { type: eOrganizationActions; response: IUpsertOrganizationResponse } | { type: eOrganizationActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eOrganizationActions.ORGANIZATION_CREATE),
      switchMap((payload: { type: string; organizationInfo: IOrganizationInfo }) =>
        this.apiOrganizationService.createOrganization(payload.organizationInfo).pipe(
          tap((response: IUpsertOrganizationResponse) => {
            this.currentUserStoreService.setCurrentUserOrganization(response.id);
            this.toastrService.success('Компания успешно создана');
          }),
          map((response: IUpsertOrganizationResponse) => ({ type: eOrganizationActions.ORGANIZATION_CREATE_SUCCESS, response })),
          catchError((err: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(err)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            }

            return of({ type: eOrganizationActions.ORGANIZATION_CREATE_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public publishOrganization$: Observable<
    { type: eOrganizationActions } | { type: eOrganizationActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eOrganizationActions.ORGANIZATION_PUBLISH),
      switchMap(() =>
        this.apiOrganizationService.publishOrganization().pipe(
          tap(() => {
            this.toastrService.success('Компания успешно зарегистрирована и отправлена на проверку');
          }),
          map(() => ({ type: eOrganizationActions.ORGANIZATION_PUBLISH_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(err)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            }

            return of({ type: eOrganizationActions.ORGANIZATION_PUBLISH_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public updateOrganizationRequisites$: Observable<
    { type: eOrganizationActions; response: IUpsertOrganizationResponse } | { type: eOrganizationActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eOrganizationActions.ORGANIZATION_UPDATE_REQUISITES),
      switchMap((payload: { type: string; organizationInfo: IOrganizationInfo }) =>
        this.apiOrganizationService.updateOrganizationRequisites(payload.organizationInfo).pipe(
          tap(() => {
            this.toastrService.success('Компания успешно обновлена');
          }),
          map((response: IUpsertOrganizationResponse) => ({ type: eOrganizationActions.ORGANIZATION_UPDATE_REQUISITES_SUCCESS, response })),
          catchError((err: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(err)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            }

            return of({ type: eOrganizationActions.ORGANIZATION_UPDATE_REQUISITES_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public uploadOrganizationDocument$: Observable<
    { type: eOrganizationActions } | { type: eOrganizationActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eOrganizationActions.ORGANIZATION_DOCUMENT_UPLOAD),
      map((payload: { type: string; file: File; documentType: string }) => {
        const formData = new FormData();
        formData.append('file', payload.file);

        return { formData, documentType: payload.documentType };
      }),
      switchMap((payload: { formData: FormData; documentType: string }) =>
        this.apiOrganizationService.uploadOrganizationDocument(payload.formData, payload.documentType).pipe(
          tap(() => {
            this.toastrService.success('Документ успешно прикреплен');
          }),
          map(() => ({ type: eOrganizationActions.ORGANIZATION_DOCUMENT_UPLOAD_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(err)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            }

            return of({ type: eOrganizationActions.ORGANIZATION_DOCUMENT_UPLOAD_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public deleteOrganizationDocument$: Observable<
    { type: eOrganizationActions } | { type: eOrganizationActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eOrganizationActions.ORGANIZATION_DOCUMENT_DELETE),
      switchMap((payload: { type: string; documentType: string }) =>
        this.apiOrganizationService.deleteOrganizationDocument(payload.documentType).pipe(
          tap(() => {
            this.toastrService.success('Документ успешно откреплен');
          }),
          map(() => ({ type: eOrganizationActions.ORGANIZATION_DOCUMENT_DELETE_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eOrganizationActions.ORGANIZATION_DOCUMENT_DELETE_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );
}
