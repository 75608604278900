<div class="main-header">
  <div class="main-header__main">
    <span class="main-header__title">{{ pageTitle }}</span>
    <div class="main-header__container" *ngIf="links?.length">
      <div class="main-header__wrapper" (scroll)="onWrapperScroll()" #mainHeaderWrapper>
        <ul class="main-header__list" #mainHeaderList>
          <li *ngFor="let link of links" class="main-header__list-item">
            <tlp-button
                theme="transparent"
                fontSize="small"
                class="main-header__link"
                [isActive]="link?.href === activeLink?.href"
                [ngClass]="{'main-header__link--active' : link?.href === activeLink?.href}"
                (click)="navigate(link.href, link.queryParams)"
            >
              <span>{{ link.title }}</span>
              <span *ngIf="link.totalCount && link.totalCount > 0"
                    class="main-header__link-count main-header__link-count--total"
              >{{ link.totalCount }}</span>
              <span *ngIf="link.countOfNew && link.countOfNew > 0"
                    class="main-header__link-count main-header__link-count--unread"
              >{{ link.countOfNew }}</span>
            </tlp-button>
          </li>
        </ul>
      </div>
      <button class="main-header__button main-header__button--prev" [class.main-header__button--hidden]="!isPrevShow" (click)="onSlideLeft()">
        <svg-icon-sprite [src]="'./assets/sprites/sprite.svg#ic-left'" [width]="'16px'" [height]="'16px'"></svg-icon-sprite>
      </button>
      <button
        class="main-header__button main-header__button--next"
        [class.main-header__button--hidden]="!isNextShow"
        (click)="onSlideRight()"
      >
        <svg-icon-sprite [src]="'./assets/sprites/sprite.svg#ic-right'" [width]="'16px'" [height]="'16px'"></svg-icon-sprite>
      </button>
    </div>
  </div>
  <div class="main-header__action-buttons">
    <div class="main-header__action-buttons-item" *ngIf="isPageActionButtonsConfigured">
      <tlp-page-action-buttons></tlp-page-action-buttons>
    </div>
    <div class="main-header__action-buttons-item">
      <tlp-notifications-menu></tlp-notifications-menu>
    </div>
  </div>
</div>
