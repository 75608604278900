import { Action, createReducer, on } from '@ngrx/store';
import { InitOrganizationState, IOrganizationStoreState } from './organization.state';
import * as organizationActions from './organization.actions';

const organizationReducer = createReducer(
  InitOrganizationState,
  on(organizationActions.getOrganizations, state => ({
    ...state,
    organizationsLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(organizationActions.getOrganizationsSuccess, (state, { organizations }) => ({
    ...state,
    organizations,
    organizationsLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(organizationActions.getOrganizationsFailure, (state, { error }) => ({
    ...state,
    organizationsLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(organizationActions.getOrganization, state => ({
    ...state,
    organizationLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(organizationActions.getOrganizationSuccess, (state, { organization }) => ({
    ...state,
    organization,
    organizationLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(organizationActions.getOrganizationFailure, (state, { error }) => ({
    ...state,
    organizationLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(organizationActions.getOrganizationGeneral, state => ({
    ...state,
    organizationLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(organizationActions.getOrganizationGeneralSuccess, (state, { organizationGeneral }) => ({
    ...state,
    organizationGeneral,
    organizationLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(organizationActions.getOrganizationGeneralFailure, (state, { error }) => ({
    ...state,
    organizationLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(organizationActions.getOrganizationDocuments, state => ({
    ...state,
    organizationLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(organizationActions.getOrganizationDocumentsSuccess, (state, { organizationDocuments }) => ({
    ...state,
    organizationDocuments,
    organizationLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(organizationActions.getOrganizationDocumentsFailure, (state, { error }) => ({
    ...state,
    organizationLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(organizationActions.createOrganization, state => ({
    ...state,
    organizationUpdatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(organizationActions.createOrganizationSuccess, state => ({
    ...state,
    organizationUpdatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(organizationActions.createOrganizationFailure, (state, { error }) => ({
    ...state,
    organizationUpdatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(organizationActions.setCreatedOrganizationId, (state, { id }) => ({
    ...state,
    createdOrganizationId: id,
  })),

  on(organizationActions.publishOrganization, state => ({
    ...state,
    organizationPublishingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(organizationActions.publishOrganizationSuccess, state => ({
    ...state,
    organizationPublishingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(organizationActions.publishOrganizationFailure, (state, { error }) => ({
    ...state,
    organizationPublishingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(organizationActions.verifyOrganization, state => ({
    ...state,
    organizationUpdatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(organizationActions.verifyOrganizationSuccess, state => ({
    ...state,
    organizationUpdatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(organizationActions.verifyOrganizationFailure, (state, { error }) => ({
    ...state,
    organizationUpdatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(organizationActions.rejectOrganization, state => ({
    ...state,
    organizationUpdatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(organizationActions.rejectOrganizationSuccess, state => ({
    ...state,
    organizationUpdatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(organizationActions.rejectOrganizationFailure, (state, { error }) => ({
    ...state,
    organizationUpdatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(organizationActions.deleteOrganization, state => ({
    ...state,
    organizationsLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(organizationActions.deleteOrganizationSuccess, state => ({
    ...state,
    organizationsLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(organizationActions.deleteOrganizationFailure, (state, { error }) => ({
    ...state,
    organizationsLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(organizationActions.updateOrganizationRequisites, state => ({
    ...state,
    organizationUpdatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(organizationActions.updateOrganizationRequisitesSuccess, state => ({
    ...state,
    organizationUpdatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(organizationActions.updateOrganizationRequisitesFailure, (state, { error }) => ({
    ...state,
    organizationUpdatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(organizationActions.uploadOrganizationDocument, state => ({
    ...state,
    organizationUpdatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(organizationActions.uploadOrganizationDocumentSuccess, state => ({
    ...state,
    organizationUpdatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(organizationActions.uploadOrganizationDocumentFailure, (state, { error }) => ({
    ...state,
    organizationUpdatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(organizationActions.deleteOrganizationDocument, state => ({
    ...state,
    organizationUpdatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(organizationActions.deleteOrganizationDocumentSuccess, state => ({
    ...state,
    organizationUpdatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(organizationActions.deleteOrganizationDocumentFailure, (state, { error }) => ({
    ...state,
    organizationUpdatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(organizationActions.resetOrganizations, state => ({
    ...state,
    organizations: null,
    organizationsLoadingState: null,
  })),
  on(organizationActions.resetOrganization, state => ({
    ...state,
    organization: null,
    createdOrganizationId: null,
    organizationGeneral: null,
    organizationDocuments: null,
    organizationLoadingState: null,
    organizationPublishingState: null,
    organizationUpdatingState: null,
  })),
  on(organizationActions.resetOrganizationsLoadingState, state => ({
    ...state,
    organizationsLoadingState: null,
  })),
  on(organizationActions.resetOrganizationLoadingState, state => ({
    ...state,
    organizationLoadingState: null,
  })),
  on(organizationActions.resetOrganizationPublishingState, state => ({
    ...state,
    organizationPublishingState: null,
  })),
  on(organizationActions.resetOrganizationUpdatingState, state => ({
    ...state,
    organizationUpdatingState: null,
  })),
  on(organizationActions.resetOrganizationState, () => ({ ...InitOrganizationState })),
);

export const OrganizationReducer = (state: IOrganizationStoreState, action: Action) => {
  return organizationReducer(state, action);
};
