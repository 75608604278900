<ng-container *ngIf="formControl">
  <tlp-select
    class="code"
    [items]="availableCountryCodes"
    [selectable]="!isDisabled"
    [selectOnInit]="false"
    [ngModel]="countryCode"
    (ngModelChange)="selectCountryCode($event)"
  ></tlp-select>
  <input
    class="input-field"
    autocomplete="disabled"
    [class.input-field--error]="formControl.touched && formControl.errors"
    [class.input-field--disabled]="isDisabled"
    [formControl]="formControl"
    [placeholder]="placeholder || mask"
    [mask]="mask"
    [required]="required"
    [dropSpecialCharacters]="false"
    (keyup)="onValueChange($event)"
    (blur)="onValueChange($event)"
  />
</ng-container>
