import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { TooltipModule } from '../directives/tooltip/tooltip.module';

import { SelectComponent } from './component/select/select.component';

@NgModule({
  declarations: [SelectComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgSelectModule, IconSpriteModule, TooltipModule],
  exports: [SelectComponent],
})
export class SelectModule {}
