import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IHttpRequestState } from '../../models/http.model';
import { ILoginStoreState } from './login.state';

import * as loginActions from './login.actions';
import * as loginSelectors from './login.selectors';

@Injectable()
export class LoginStoreService {
  constructor(private loginStore: Store<ILoginStoreState>) {}

  get loginStoreState$(): Observable<ILoginStoreState> {
    return this.loginStore.select(loginSelectors.getLoginStoreState);
  }

  get loginLoadingState$(): Observable<IHttpRequestState | null> {
    return this.loginStore.select(loginSelectors.getLoginLoadingState);
  }

  public login(credentials: { email: string; password: string }): void {
    this.loginStore.dispatch(loginActions.login({ ...credentials }));
  }

  public resetLoginState(): void {
    this.loginStore.dispatch(loginActions.resetLoginState());
  }
}
