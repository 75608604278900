import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { eListingActions } from './listing.actions';
import { ApiListingUserService } from '../../api/api-listing-user.service';
import { IListing, IListingApiParams, IListingBookingsApiParams } from '../../models/listing.model';

@Injectable()
export class ListingEffects {
  constructor(private actions$: Actions, private apiUserListingService: ApiListingUserService) {}

  public getWarehousesListing$: Observable<
    { type: eListingActions; warehouses: IListing | null } | { type: eListingActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eListingActions.LISTING_WAREHOUSES_GET),
      switchMap((payload: { type: string; params: IListingApiParams }) =>
        this.apiUserListingService.getWarehousesListing(payload.params).pipe(
          map(warehouses => ({ type: eListingActions.LISTING_WAREHOUSES_GET_SUCCESS, warehouses })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eListingActions.LISTING_WAREHOUSES_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getBookingsListing$: Observable<
    { type: eListingActions; bookings: IListing | null } | { type: eListingActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eListingActions.LISTING_BOOKINGS_GET),
      switchMap((payload: { type: string; params: IListingBookingsApiParams }) =>
        this.apiUserListingService.getBookingsListing(payload.params).pipe(
          map(bookings => ({ type: eListingActions.LISTING_BOOKINGS_GET_SUCCESS, bookings })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eListingActions.LISTING_BOOKINGS_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );
}
