import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type buttonSizes = 'medium' | 'small' | 'fit';
export type buttonThemes = 'default' | 'primary' | 'secondary' | 'transparent' | 'link' | 'link-secondary' | 'link-dark';
export type iconTypeName =
  | 'add'
  | 'close'
  | 'draft'
  | 'down'
  | 'download'
  | 'delete'
  | 'edit'
  | 'exit'
  | 'file'
  | 'form'
  | 'inspect'
  | 'left'
  | 'mark'
  | 'notificationOff'
  | 'right'
  | 'report'
  | 'search'
  | 'up'
  | 'map'
  | 'sort'
  | 'no_avatar'
  | 'list';

const iconType: { [K: string]: string } = {
  add: 'ic-add',
  close: 'ic-close',
  down: 'ic-down',
  download: 'ic-download',
  delete: 'ic-delete',
  draft: 'ic-draft',
  edit: 'ic-edit',
  exit: 'ic-exit',
  file: 'ic-file',
  form: 'ic-form',
  inspect: 'ic-inspect',
  left: 'ic-left',
  mark: 'ic-mark',
  notificationOff: 'ic-notification-off',
  right: 'ic-right',
  report: 'ic-report',
  search: 'ic-search',
  up: 'ic-up',
  map: 'ic-map',
  sort: 'ic-sort',
  no_avatar: 'ic-no-avatar',
  list: 'ic-list',
};

@Component({
  selector: 'tlp-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() public theme: buttonThemes = 'default';
  @Input() public size: buttonSizes = 'medium';
  @Input() public fontSize: buttonSizes = 'medium';
  @Input() public minWidth: string = 'auto';
  @Input() public iconLeft?: iconTypeName | string;
  @Input() public iconRight?: iconTypeName | string;
  @Input() public iconSize: string = '16px';
  @Input() public isActive: boolean | undefined = false;
  @Input() public isIconButton: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public disabledVisually: boolean = false;
  @Input() public type: string = 'button';

  public getLeftIconPath(): string {
    return this.iconLeft === undefined ? '' : iconType[this.iconLeft];
  }

  public getRightIconPath(): string {
    return this.iconRight === undefined ? '' : iconType[this.iconRight];
  }
}
