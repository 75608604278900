import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { ApiUsersService } from '../../api/admin/api-users.service';
import { HttpErrorsService } from '../../api/http-errors.service';
import { UsersStoreService } from './users-store.service';

import { IUser, IUsersApiParams, IUsers } from '../../models/user.model';
import { eUsersActions } from './users.actions';

@Injectable()
export class UsersEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private toastrService: ToastrService,
    private apiUsersService: ApiUsersService,
    private httpErrorsService: HttpErrorsService,
    private userStoreService: UsersStoreService,
  ) {}

  public getUsers$: Observable<
    { type: eUsersActions; users: IUsers | null } | { type: eUsersActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eUsersActions.USERS_GET),
      switchMap((payload: { type: string; params: IUsersApiParams }) =>
        this.apiUsersService.getUsers(payload.params).pipe(
          map(users => ({ type: eUsersActions.USERS_GET_SUCCESS, users })),
          catchError((error: HttpErrorResponse) => {
            return of({ type: eUsersActions.USERS_GET_FAILURE, error });
          }),
        ),
      ),
    ),
  );

  public getUser$: Observable<
    { type: eUsersActions; user: IUser | null } | { type: eUsersActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eUsersActions.USER_GET),
      switchMap((payload: { type: string; id: string }) =>
        this.apiUsersService.getUser(payload.id).pipe(
          map(user => ({ type: eUsersActions.USER_GET_SUCCESS, user })),
          catchError((error: HttpErrorResponse) => {
            return of({ type: eUsersActions.USER_GET_FAILURE, error });
          }),
        ),
      ),
    ),
  );

  public deleteUser$: Observable<
    { type: eUsersActions; user: IUser | null } | { type: eUsersActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eUsersActions.USER_DELETE),
      switchMap((payload: { type: string; id: string }) =>
        this.apiUsersService.deleteUser(payload.id).pipe(
          tap(() => {
            this.toastrService.success('Пользователь успешно удален');
            this.router.navigateByUrl('/profile/admin/users/all');
          }),
          map(user => ({ type: eUsersActions.USER_DELETE_SUCCESS, user })),
          catchError((error: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(error));
            return of({ type: eUsersActions.USER_DELETE_FAILURE, error });
          }),
        ),
      ),
    ),
  );

  public createUser$: Observable<
    { type: eUsersActions; response: { id: string } } | { type: eUsersActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eUsersActions.USER_CREATE),
      switchMap((payload: { type: string; data: IUser }) =>
        this.apiUsersService.createUser(payload.data).pipe(
          tap(() => {
            this.toastrService.success('Пользователь успешно создан');
            this.router.navigate(['profile/admin/users/all']);
          }),
          map((response: { id: string }) => ({ type: eUsersActions.USER_CREATE_SUCCESS, response })),
          catchError((error: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(error)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(error));
            }
            return of({ type: eUsersActions.USER_CREATE_FAILURE, error });
          }),
        ),
      ),
    ),
  );

  public updateUser$: Observable<{ type: eUsersActions } | { type: eUsersActions; error: HttpErrorResponse }> = createEffect(() =>
    this.actions$.pipe(
      ofType(eUsersActions.USER_UPDATE),
      switchMap((payload: { type: string; data: IUser; id: string; publish: boolean }) =>
        this.apiUsersService.updateUser(payload.data, payload.id).pipe(
          tap(() => {
            this.toastrService.success('Пользователь успешно обновлен');
            this.userStoreService.getUser(payload.id);
          }),
          map(() => ({ type: eUsersActions.USER_UPDATE_SUCCESS })),
          catchError((error: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(error)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(error));
            }
            return of({ type: eUsersActions.USER_UPDATE_FAILURE, error });
          }),
        ),
      ),
    ),
  );
}
