import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractApiMethodsService } from './abstract-api-methods.service';

import { IBookingDate, IBookingDatesFilters } from '../models/booking.model';
import { IStorageArea, IStorageAreas, IStorageAreasApiParams, IStorageAreaUpsert } from '../models/storage-area.model';

@Injectable()
export class ApiStorageAreaService extends AbstractApiMethodsService {
  public getStorageAreas(params: IStorageAreasApiParams): Observable<IStorageAreas | null> {
    return this.httpPost(params, 'storage-area/index');
  }

  public getStorageArea(id: string): Observable<IStorageArea | null> {
    return this.httpPost({}, `storage-area/${id}/view`);
  }

  public getStorageAreaBookingDates(bookingDatesFilters: IBookingDatesFilters, id: string): Observable<IBookingDate[] | null> {
    return this.httpPost(bookingDatesFilters, `storage-area/${id}/reserved-dates`);
  }

  public createStorageArea(data: IStorageAreaUpsert): Observable<{ id: string }> {
    return this.httpPost(data, `storage-area/create`);
  }

  public updateStorageArea(data: IStorageAreaUpsert, id: string): Observable<{ id: string }> {
    return this.httpPost(data, `storage-area/${id}/update`);
  }

  public deleteStorageArea(id: string): Observable<boolean> {
    return this.httpPost({}, `storage-area/${id}/delete`);
  }

  public preloadStorageAreaPhotos(formData: FormData): Observable<string[]> {
    return this.httpPost(formData, `storage-area/new_photo`, true);
  }
}
