import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { TooltipModule } from '../directives/tooltip/tooltip.module';
import { ChangeNoteComponent } from './component/change-note/change-note.component';

@NgModule({
  declarations: [ChangeNoteComponent],
  imports: [CommonModule, IconSpriteModule, TooltipModule],
  exports: [ChangeNoteComponent],
})
export class ChangeNoteModule {}
