import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

export type statusNames =
  | 'active'
  | 'adjustment'
  | 'canceled'
  | 'completed'
  | 'deleted'
  | 'draft'
  | 'failed'
  | 'in_progress'
  | 'published'
  | 'reserved'
  | 'restricted'
  | 'shipped'
  | 'verification'
  | 'verified';
export type statusThemes = 'default' | 'extended';
export type statusIcons = 'alert' | 'cancel' | 'mark' | 'timer';

@Component({
  selector: 'tlp-status-note',
  templateUrl: './status-note.component.html',
  styleUrls: ['./status-note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusNoteComponent implements OnChanges {
  @Input() public statusName!: statusNames | string | undefined;
  @Input() public statusTheme: statusThemes = 'default';
  @Input() public statusIcon!: statusIcons | string | null;
  @Input() public text?: string;
  @Input() public textColor?: string;
  @Input() public additionalText?: string;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.statusName?.currentValue) {
      this.statusIcon = this.getIcon(this.statusName);
    }
  }

  private getIcon(theme: statusNames | string | undefined): string | null {
    switch (theme) {
      case 'active':
      case 'published':
      case 'shipped':
      case 'verified':
        return 'mark';
      case 'adjustment':
      case 'in_progress':
      case 'reserved':
      case 'verification':
        return 'timer';
      case 'canceled':
      case 'failed':
        return 'cancel';
      case 'restricted':
        return 'block';
      default:
        return null;
    }
  }
}
