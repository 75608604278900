import { Action, createReducer, on } from '@ngrx/store';
import { InitWarehousesCatalogState, IWarehousesCatalogStoreState } from './warehouses-catalog.state';
import * as warehousesCatalogActions from './warehouses-catalog.actions';

const warehousesCatalogReducer = createReducer(
  InitWarehousesCatalogState,

  on(warehousesCatalogActions.getWarehousesCatalog, state => ({
    ...state,
    warehousesCatalogLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(warehousesCatalogActions.getWarehousesCatalogSuccess, (state, { warehousesCatalog }) => ({
    ...state,
    warehousesCatalog,
    warehousesCatalogLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(warehousesCatalogActions.getWarehousesCatalogFailure, (state, { error }) => ({
    ...state,
    warehousesCatalogLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(warehousesCatalogActions.getWarehousesCatalogItem, state => ({
    ...state,
    warehousesCatalogItemLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(warehousesCatalogActions.getWarehousesCatalogItemSuccess, (state, { warehousesCatalogItem }) => ({
    ...state,
    warehousesCatalogItem,
    warehousesCatalogItemLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(warehousesCatalogActions.getWarehousesCatalogItemFailure, (state, { error }) => ({
    ...state,
    warehousesCatalogItemLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(warehousesCatalogActions.resetWarehousesCatalog, state => ({
    ...state,
    warehousesCatalog: null,
    warehousesCatalogLoadingState: null,
  })),
  on(warehousesCatalogActions.resetWarehousesCatalogItem, state => ({
    ...state,
    warehousesCatalogItem: null,
    warehousesCatalogItemLoadingState: null,
  })),
  on(warehousesCatalogActions.resetWarehousesCatalogState, () => ({ ...InitWarehousesCatalogState })),
);

export const WarehousesCatalogReducer = (state: IWarehousesCatalogStoreState, action: Action) => {
  return warehousesCatalogReducer(state, action);
};
