import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApiListingService } from '../../api/admin/api-listing.service';
import { IListingAdvertsApiParams, IListingApiParams, IListingBookingsApiParams, IListing } from '../../models/listing.model';
import { eListingActions } from './listing.actions';

@Injectable()
export class ListingEffects {
  constructor(private actions$: Actions, private apiAdminListingService: ApiListingService) {}

  public getUsersListing$: Observable<
    { type: eListingActions; users: IListing | null } | { type: eListingActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eListingActions.LISTING_USERS_GET),
      switchMap((payload: { type: string; params: IListingApiParams }) =>
        this.apiAdminListingService.getUsersListing(payload.params).pipe(
          map(users => ({ type: eListingActions.LISTING_USERS_GET_SUCCESS, users })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eListingActions.LISTING_USERS_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getOrganizationsListing$: Observable<
    { type: eListingActions; organizations: IListing | null } | { type: eListingActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eListingActions.LISTING_ORGANIZATIONS_GET),
      switchMap((payload: { type: string; params: IListingApiParams }) =>
        this.apiAdminListingService.getOrganizationsListing(payload.params).pipe(
          map(organizations => ({ type: eListingActions.LISTING_ORGANIZATIONS_GET_SUCCESS, organizations })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eListingActions.LISTING_ORGANIZATIONS_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getWarehousesListing$: Observable<
    { type: eListingActions; warehouses: IListing | null } | { type: eListingActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eListingActions.LISTING_WAREHOUSES_GET),
      switchMap((payload: { type: string; params: IListingApiParams }) =>
        this.apiAdminListingService.getWarehousesListing(payload.params).pipe(
          map(warehouses => ({ type: eListingActions.LISTING_WAREHOUSES_GET_SUCCESS, warehouses })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eListingActions.LISTING_WAREHOUSES_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getAdvertsListing$: Observable<
    { type: eListingActions; adverts: IListing | null } | { type: eListingActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eListingActions.LISTING_ADVERTS_GET),
      switchMap((payload: { type: string; params: IListingAdvertsApiParams }) =>
        this.apiAdminListingService.getAdvertsListing(payload.params).pipe(
          map(adverts => ({ type: eListingActions.LISTING_ADVERTS_GET_SUCCESS, adverts })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eListingActions.LISTING_ADVERTS_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getBookingsListing$: Observable<
    { type: eListingActions; bookings: IListing | null } | { type: eListingActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eListingActions.LISTING_BOOKINGS_GET),
      switchMap((payload: { type: string; params: IListingBookingsApiParams }) =>
        this.apiAdminListingService.getBookingsListing(payload.params).pipe(
          map(bookings => ({ type: eListingActions.LISTING_BOOKINGS_GET_SUCCESS, bookings })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eListingActions.LISTING_BOOKINGS_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );
}
