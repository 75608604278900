import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputPassComponent } from './input-pass/input-pass.component';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from '../directives/tooltip/tooltip.module';

@NgModule({
  declarations: [InputPassComponent],
  imports: [CommonModule, IconSpriteModule, ReactiveFormsModule, TooltipModule],
  exports: [InputPassComponent],
})
export class InputPassModule {}
