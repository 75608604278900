import { Action, createReducer, on } from '@ngrx/store';
import { InitWarehouseState, IWarehouseStoreState } from './warehouse.state';
import * as warehousesActions from './warehouse.actions';

const warehouseReducer = createReducer(
  InitWarehouseState,
  on(warehousesActions.getWarehouses, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(warehousesActions.getWarehousesSuccess, (state, { warehouses }) => ({
    ...state,
    warehouses,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(warehousesActions.getWarehousesFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(warehousesActions.createWarehouse, state => ({
    ...state,
    updatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(warehousesActions.createWarehouseSuccess, state => ({
    ...state,
    updatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(warehousesActions.createWarehouseFailure, (state, { error }) => ({
    ...state,
    updatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(warehousesActions.setCreatedWarehouseId, (state, { id }) => ({
    ...state,
    createdWarehouseId: id,
  })),

  on(warehousesActions.deleteWarehouse, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(warehousesActions.deleteWarehouseSuccess, state => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(warehousesActions.deleteWarehouseFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(warehousesActions.publishWarehouse, state => ({
    ...state,
    publishingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(warehousesActions.publishWarehouseSuccess, state => ({
    ...state,
    publishingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(warehousesActions.publishWarehouseFailure, (state, { error }) => ({
    ...state,
    publishingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(warehousesActions.getWarehouseTabState, state => ({
    ...state,
    detailsLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(warehousesActions.getWarehouseTabStateSuccess, (state, { warehouseTabState }) => ({
    ...state,
    warehouseTabState,
    detailsLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(warehousesActions.getWarehouseTabStateFailure, (state, { error }) => ({
    ...state,
    detailsLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(warehousesActions.getWarehouseGeneral, state => ({
    ...state,
    detailsLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(warehousesActions.getWarehouseGeneralSuccess, (state, { warehouseGeneral }) => ({
    ...state,
    warehouseGeneral,
    detailsLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(warehousesActions.getWarehouseGeneralFailure, (state, { error }) => ({
    ...state,
    detailsLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(warehousesActions.updateWarehouseGeneral, state => ({
    ...state,
    updatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(warehousesActions.updateWarehouseGeneralSuccess, state => ({
    ...state,
    updatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(warehousesActions.updateWarehouseGeneralFailure, (state, { error }) => ({
    ...state,
    updatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(warehousesActions.getWarehouseExternal, state => ({
    ...state,
    warehouseLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(warehousesActions.getWarehouseExternalSuccess, (state, { warehouseExternal }) => ({
    ...state,
    warehouseExternal,
    warehouseLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(warehousesActions.getWarehouseExternalFailure, (state, { error }) => ({
    ...state,
    warehouseLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(warehousesActions.updateWarehouseExternal, state => ({
    ...state,
    updatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(warehousesActions.updateWarehouseExternalSuccess, state => ({
    ...state,
    updatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(warehousesActions.updateWarehouseExternalFailure, (state, { error }) => ({
    ...state,
    updatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(warehousesActions.getWarehouseInternal, state => ({
    ...state,
    detailsLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(warehousesActions.getWarehouseInternalSuccess, (state, { warehouseInternal }) => ({
    ...state,
    warehouseInternal,
    detailsLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(warehousesActions.getWarehouseInternalFailure, (state, { error }) => ({
    ...state,
    detailsLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(warehousesActions.updateWarehouseInternal, state => ({
    ...state,
    updatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(warehousesActions.updateWarehouseInternalSuccess, state => ({
    ...state,
    updatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(warehousesActions.updateWarehouseInternalFailure, (state, { error }) => ({
    ...state,
    updatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(warehousesActions.getWarehouseDocuments, state => ({
    ...state,
    detailsLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(warehousesActions.getWarehouseDocumentsSuccess, (state, { warehouseDocuments }) => ({
    ...state,
    warehouseDocuments,
    detailsLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(warehousesActions.getWarehouseDocumentsFailure, (state, { error }) => ({
    ...state,
    detailsLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(warehousesActions.updateWarehouseDocuments, state => ({
    ...state,
    updatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(warehousesActions.updateWarehouseDocumentsSuccess, state => ({
    ...state,
    updatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(warehousesActions.updateWarehouseDocumentsFailure, (state, { error }) => ({
    ...state,
    updatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(warehousesActions.uploadWarehouseDocument, state => ({
    ...state,
    updatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(warehousesActions.uploadWarehouseDocumentSuccess, state => ({
    ...state,
    updatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(warehousesActions.uploadWarehouseDocumentFailure, (state, { error }) => ({
    ...state,
    updatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(warehousesActions.deleteWarehouseDocument, state => ({
    ...state,
    updatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(warehousesActions.deleteWarehouseDocumentSuccess, state => ({
    ...state,
    updatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(warehousesActions.deleteWarehouseDocumentFailure, (state, { error }) => ({
    ...state,
    updatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(warehousesActions.preloadWarehousePhotos, state => ({
    ...state,
    preloadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(warehousesActions.preloadWarehousePhotosSuccess, (state, { warehousePreloadedPhotos }) => ({
    ...state,
    warehousePreloadedPhotos,
    preloadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(warehousesActions.preloadWarehousePhotosFailure, (state, { error }) => ({
    ...state,
    preloadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(warehousesActions.resetWarehouses, state => ({
    ...state,
    warehouses: null,
    loadingState: null,
  })),
  on(warehousesActions.resetWarehouse, state => ({
    ...state,
    createdWarehouseId: null,
    warehouseTabState: null,
    warehouseGeneral: null,
    warehouseExternal: null,
    warehouseExternalPublic: null,
    warehouseInternal: null,
    warehouseInternalPublic: null,
    warehouseDocuments: null,
    detailsLoadingState: null,
    publishingState: null,
    updatingState: null,
  })),
  on(warehousesActions.resetWarehousePreloadedPhotos, state => ({
    ...state,
    warehousePreloadedPhotos: null,
  })),
  on(warehousesActions.resetWarehouseUpdatingState, state => ({
    ...state,
    updatingState: null,
  })),
  on(warehousesActions.resetWarehousePreloadingState, state => ({
    ...state,
    preloadingState: null,
  })),
  on(warehousesActions.resetWarehouseState, () => ({ ...InitWarehouseState })),
);

export const WarehouseReducer = (state: IWarehouseStoreState, action: Action) => {
  return warehouseReducer(state, action);
};
