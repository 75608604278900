import {
  Component,
  ChangeDetectionStrategy,
  forwardRef,
  Input,
  ViewEncapsulation,
  ViewChild,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NG_VALIDATORS, ValidationErrors, Validator, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { DelegateFactoryService, eDelegateType } from '../../../delegate/service/delegate-factory.service';
import { NgSelectComponent } from '@ng-select/ng-select';

import { IDelegate, ISelectableItem } from '../../../delegate/model/delegate.model';
import { tooltipPlacements } from '../../../directives/tooltip/tooltip.directive';

@Component({
  selector: 'tlp-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements ControlValueAccessor, Validator, OnChanges, OnInit, OnDestroy {
  @ViewChild(NgSelectComponent) private ngSelectComponent!: NgSelectComponent;
  @Input() set delegateType(delegateType: eDelegateType | string | undefined) {
    if (!delegateType) {
      return;
    }
    this.delegate = this.delegateFactoryService.getDelegate(delegateType as eDelegateType);
  }
  @Input() public delegate: IDelegate | null = null;
  @Input() public getParams: object | undefined = undefined;
  @Input() public items!: ISelectableItem[] | undefined;
  @Input() public shortItemsName: boolean = false;
  @Input() public disabledOptions?: string[] | number[];

  @Input() public multiple: boolean = false;
  @Input() public concatenatedSelections: boolean = false;
  @Input() public required: boolean = false;
  @Input() public searchable: boolean = false;
  @Input() public selectable: boolean = true;
  @Input() public clearable: boolean = false;

  @Input() public infoTip: string | null = null;
  @Input() public infoTipPlacement: tooltipPlacements = 'follow';
  @Input() public placeholder: string = '';
  @Input() public notFoundText: string = 'Нет вариантов';
  @Input() public emptyOption: boolean = false;
  @Input() public closeOnSelect: boolean = true;
  @Input() public selectOnInit: boolean = true;

  // tslint:disable-next-line: no-any
  @Output() public changeValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() public itemSelected: EventEmitter<ISelectableItem | ISelectableItem[] | undefined> = new EventEmitter<
    ISelectableItem | ISelectableItem[] | undefined
  >();

  public formControl!: FormControl | null;
  public value: string | number | boolean | Array<string | number | boolean> | undefined = undefined;
  public concatenatedLabel: string | null = null;

  private delegateSub: Subscription | null = null;
  private onChange: (_: string | number | boolean | Array<string | number | boolean> | undefined) => void;
  private onTouched: () => void;

  constructor(private delegateFactoryService: DelegateFactoryService) {
    this.onChange = (_: string | number | boolean | Array<string | number | boolean> | undefined) => {};
    this.onTouched = () => {};
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.disabledOptions?.currentValue) {
      setTimeout(() => {
        this.items = this.items?.map(item =>
          changes.disabledOptions.currentValue.includes(item.value) &&
          !(this.value as Array<string | number>)?.includes(item.value as string | number)
            ? { ...item, disabled: true }
            : changes?.disabledOptions?.previousValue?.includes(item.value)
            ? { ...item, disabled: false }
            : item,
        );
      }, 0);
    }
  }

  public ngOnInit(): void {
    this.formControl = new FormControl(this.multiple ? [] : null);
    if (this.delegate) {
      this.delegate.get(this.shortItemsName, this.getParams);
      this.delegateSub = this.delegate.getResult$.subscribe(getResults => {
        this.items = getResults;

        if (this.emptyOption) {
          this.items.unshift({ name: this.placeholder, value: '' });
        }

        if (this.items?.length && this.value) {
          const items = this.multiple
            ? getResults.filter(resultItem => (this.value as Array<string | number | boolean>)?.includes(resultItem?.value))
            : getResults.find(result => result?.value === this.value);

          if (items) {
            this.selectItem(items);
          }
        } else if (!this.value && this.items?.length && this.selectOnInit) {
          this.selectItem(this.multiple ? [getResults[0]] : getResults[0]);
        } else if (this.value && !this.items?.length) {
          this.selectItem(this.multiple ? [] : undefined);
        }
      });
    }
  }

  public ngOnDestroy(): void {
    this.delegate?.reset();
    this.delegateSub?.unsubscribe();
    this.formControl = null;
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.formControl?.disable();
    } else {
      this.formControl?.enable();
    }
  }

  public writeValue(value: string | number | boolean | Array<string | number | boolean> | undefined): void {
    this.ngSelectComponent?.handleClearClick();

    if ((value && !Array.isArray(value)) || (Array.isArray(value) && value?.length)) {
      this.value = value;

      if (this.items?.length) {
        const filteredItems: ISelectableItem | ISelectableItem[] | undefined = this.multiple
          ? this.items?.filter(item => (this.value as Array<string | number | boolean>)?.includes(item?.value))
          : this.items?.find(item => item?.value === this.value);

        const itemsFromValue: ISelectableItem | ISelectableItem[] | undefined = this.multiple
          ? (filteredItems as ISelectableItem[])?.length
            ? [...(filteredItems as ISelectableItem[])]
            : undefined
          : (filteredItems as ISelectableItem);

        if (itemsFromValue) {
          this.selectItem(itemsFromValue);
        }
      }
    } else if (this.selectOnInit) {
      if (!this.delegate && this.items && this.items[0]) {
        this.selectItem(this.multiple ? [this.items[0]] : this.items[0]);
      }
    }
  }

  public selectItem(items: ISelectableItem | ISelectableItem[] | undefined): void {
    this.changeValue.emit(items);
    this.itemSelected.emit(items);
    this.value = items ? (Array.isArray(items) ? items.map(item => item?.value) : items?.value) : undefined;
    if (this.multiple && this.concatenatedSelections && Array.isArray(items)) {
      this.concatenatedLabel = this.concatSelections(items);
    }

    this.formControl?.setValue(this.value, { emitEvent: false });
    this.onChange(this.value);
    this.onTouched();
  }

  public markAsTouched(): void {
    this.onChange(this.value);
    this.onTouched();
  }

  public validate(): ValidationErrors | null {
    return this.formControl?.errors || null;
  }

  private concatSelections(items: ISelectableItem[], separator: string = ', '): string {
    return items?.length === this.items?.length
      ? `${this.items?.[0].name ?? ''}-${this.items?.[items.length - 1].name ?? ''}`
      : items.map(item => item.name)?.join(separator);
  }
}
