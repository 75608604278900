import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularYandexMapsModule } from 'angular8-yandex-maps';
import { ButtonModule } from '../button/button.module';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { MapComponent } from './component/map/map.component';

@NgModule({
  declarations: [MapComponent],
  imports: [CommonModule, AngularYandexMapsModule, ButtonModule, IconSpriteModule],
  exports: [MapComponent],
})
export class MapModule {}
