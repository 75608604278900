import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IHttpRequestState } from '../../models/http.model';
import { ITeamStoreState } from './team.state';

export const getTeamStoreState = createFeatureSelector<ITeamStoreState>('admin-team');

export const getTeam = createSelector(getTeamStoreState, (state: ITeamStoreState) => state.team);
export const getTeamRequests = createSelector(getTeamStoreState, (state: ITeamStoreState) => state.teamRequests);

export const getTeamLoadingState = createSelector(
  getTeamStoreState,
  (state: ITeamStoreState) =>
    ({
      ...state.teamLoadingState,
    } as IHttpRequestState),
);
export const getTeamRequestsLoadingState = createSelector(
  getTeamStoreState,
  (state: ITeamStoreState) =>
    ({
      ...state.teamRequestsLoadingState,
    } as IHttpRequestState),
);
