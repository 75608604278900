export const APP_CONFIG = {
  BASE_API_URL: {
    DEV: 'http://api.dev.wa.xplanet.ru',
    TEST: 'http://api.test.wa.xplanet.ru',
    STABLE: 'http://api.stable.wa.xplanet.ru',
    PROD: 'https://api.wa.globus-ltd.com',
  },
  BASE_API_PREFIX: {
    DEV: '/v0',
    TEST: '/v0',
    STABLE: '/v0',
    PROD: '/v0',
  },
  ICON_SPRITE_PATH: 'assets/sprites/sprite.svg',
  MAP_API_KEY: '840ae18d-629f-493b-abd7-6c0cd3d67f99',
  RECAPTCHA_V2_SITE_KEY: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
};
