import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { IAcceptanceDocument } from '../../models/acceptance-document.model';
import { IBooking, IBookingDocuments, IBookingGeneral, IBookings, IBookingsApiParams } from '../../models/booking.model';

export enum eBookingActions {
  BOOKINGS_GET = '[Bookings] get bookings',
  BOOKINGS_GET_SUCCESS = '[Bookings] get bookings success',
  BOOKINGS_GET_FAILURE = '[Bookings] get bookings failure',

  BOOKING_GET = '[Booking] get booking',
  BOOKING_GET_SUCCESS = '[Booking] get booking item success',
  BOOKING_GET_FAILURE = '[Booking] get booking item failure',

  BOOKING_GENERAL_GET = '[Booking] get booking general info',
  BOOKING_GENERAL_GET_SUCCESS = '[Booking] get booking general info success',
  BOOKING_GENERAL_GET_FAILURE = '[Booking] get booking general info failure',

  BOOKING_STORAGE_REQUEST_GET = '[Booking] get booking storage request',
  BOOKING_STORAGE_REQUEST_GET_SUCCESS = '[Booking] get booking storage request success',
  BOOKING_STORAGE_REQUEST_GET_FAILURE = '[Booking] get booking storage request failure',

  BOOKING_SHIPMENT_REQUEST_GET = '[Booking] get booking shipment request',
  BOOKING_SHIPMENT_REQUEST_GET_SUCCESS = '[Booking] get booking shipment request success',
  BOOKING_SHIPMENT_REQUEST_GET_FAILURE = '[Booking] get booking shipment request failure',

  BOOKING_DOCUMENTS_GET = '[Booking] get booking documents',
  BOOKING_DOCUMENTS_GET_SUCCESS = '[Booking] get booking documents success',
  BOOKING_DOCUMENTS_GET_FAILURE = '[Booking] get booking documents failure',

  BOOKING_DOCUMENT_UPLOAD = '[Booking] upload booking document',
  BOOKING_DOCUMENT_UPLOAD_SUCCESS = '[Booking] upload booking document success',
  BOOKING_DOCUMENT_UPLOAD_FAILURE = '[Booking] upload booking document failure',

  BOOKING_DOCUMENT_DELETE = '[Booking] delete booking document',
  BOOKING_DOCUMENT_DELETE_SUCCESS = '[Booking] delete booking document success',
  BOOKING_DOCUMENT_DELETE_FAILURE = '[Booking] delete booking document failure',

  BOOKING_CANCEL = '[Booking] cancel booking',
  BOOKING_CANCEL_SUCCESS = '[Booking] cancel booking success',
  BOOKING_CANCEL_FAILURE = '[Booking] cancel booking failure',

  BOOKING_COMPLETE = '[Booking] complete booking',
  BOOKING_COMPLETE_SUCCESS = '[Booking] complete booking success',
  BOOKING_COMPLETE_FAILURE = '[Booking] complete booking failure',

  BOOKINGS_RESET = '[Booking] reset bookings',
  BOOKING_RESET = '[Booking] reset booking details',

  BOOKINGS_LOADING_STATE_RESET = '[Booking] reset bookings loading state',
  BOOKING_LOADING_STATE_RESET = '[Booking] reset booking loading state',
  BOOKING_UPDATING_STATE_RESET = '[Booking] reset booking updating state',
  BOOKING_STATE_RESET = '[Booking] reset booking state',
}

export const getBookings = createAction(eBookingActions.BOOKINGS_GET, props<{ params: IBookingsApiParams }>());
export const getBookingsSuccess = createAction(eBookingActions.BOOKINGS_GET_SUCCESS, props<{ bookings: IBookings }>());
export const getBookingsFailure = createAction(eBookingActions.BOOKINGS_GET_FAILURE, props<{ error: HttpErrorResponse }>());

export const getBooking = createAction(eBookingActions.BOOKING_GET, props<{ id: string }>());
export const getBookingSuccess = createAction(eBookingActions.BOOKING_GET_SUCCESS, props<{ booking: IBooking }>());
export const getBookingFailure = createAction(eBookingActions.BOOKING_GET_FAILURE, props<{ error: HttpErrorResponse }>());

export const getBookingGeneral = createAction(eBookingActions.BOOKING_GENERAL_GET, props<{ id: string }>());
export const getBookingGeneralSuccess = createAction(
  eBookingActions.BOOKING_GENERAL_GET_SUCCESS,
  props<{ bookingGeneral: IBookingGeneral }>(),
);
export const getBookingGeneralFailure = createAction(eBookingActions.BOOKING_GENERAL_GET_FAILURE, props<{ error: HttpErrorResponse }>());
export const getBookingStorageRequest = createAction(eBookingActions.BOOKING_STORAGE_REQUEST_GET, props<{ id: string }>());
export const getBookingStorageRequestSuccess = createAction(
  eBookingActions.BOOKING_STORAGE_REQUEST_GET_SUCCESS,
  props<{ bookingStorageRequest: IAcceptanceDocument }>(),
);

export const getBookingStorageRequestFailure = createAction(
  eBookingActions.BOOKING_STORAGE_REQUEST_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getBookingShipmentRequest = createAction(eBookingActions.BOOKING_SHIPMENT_REQUEST_GET, props<{ id: string }>());
export const getBookingShipmentRequestSuccess = createAction(
  eBookingActions.BOOKING_SHIPMENT_REQUEST_GET_SUCCESS,
  props<{ bookingShipmentRequest: IAcceptanceDocument }>(),
);
export const getBookingShipmentRequestFailure = createAction(
  eBookingActions.BOOKING_SHIPMENT_REQUEST_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getBookingDocuments = createAction(eBookingActions.BOOKING_DOCUMENTS_GET, props<{ id: string }>());
export const getBookingDocumentsSuccess = createAction(
  eBookingActions.BOOKING_DOCUMENTS_GET_SUCCESS,
  props<{ bookingDocuments: IBookingDocuments }>(),
);
export const getBookingDocumentsFailure = createAction(
  eBookingActions.BOOKING_DOCUMENTS_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const uploadBookingDocument = createAction(
  eBookingActions.BOOKING_DOCUMENT_UPLOAD,
  props<{ file: File; id: string; documentType: string }>(),
);
export const uploadBookingDocumentSuccess = createAction(eBookingActions.BOOKING_DOCUMENT_UPLOAD_SUCCESS);
export const uploadBookingDocumentFailure = createAction(
  eBookingActions.BOOKING_DOCUMENT_UPLOAD_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const deleteBookingDocument = createAction(eBookingActions.BOOKING_DOCUMENT_DELETE, props<{ id: string; documentType: string }>());
export const deleteBookingDocumentSuccess = createAction(eBookingActions.BOOKING_DOCUMENT_DELETE_SUCCESS);
export const deleteBookingDocumentFailure = createAction(
  eBookingActions.BOOKING_DOCUMENT_DELETE_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const cancelBooking = createAction(eBookingActions.BOOKING_CANCEL, props<{ id: string; params: IBookingsApiParams }>());
export const cancelBookingSuccess = createAction(eBookingActions.BOOKING_CANCEL_SUCCESS);
export const cancelBookingFailure = createAction(eBookingActions.BOOKING_CANCEL_FAILURE, props<{ error: HttpErrorResponse }>());

export const completeBooking = createAction(eBookingActions.BOOKING_COMPLETE, props<{ id: string; params: IBookingsApiParams }>());
export const completeBookingSuccess = createAction(eBookingActions.BOOKING_COMPLETE_SUCCESS);
export const completeBookingFailure = createAction(eBookingActions.BOOKING_COMPLETE_FAILURE, props<{ error: HttpErrorResponse }>());

export const resetBookings = createAction(eBookingActions.BOOKINGS_RESET);
export const resetBooking = createAction(eBookingActions.BOOKING_RESET);
export const resetBookingsLoadingState = createAction(eBookingActions.BOOKINGS_LOADING_STATE_RESET);
export const resetBookingLoadingState = createAction(eBookingActions.BOOKING_LOADING_STATE_RESET);
export const resetBookingUpdatingState = createAction(eBookingActions.BOOKING_UPDATING_STATE_RESET);
export const resetBookingState = createAction(eBookingActions.BOOKING_STATE_RESET);
