import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ILogoutState } from './logout.state';
import * as logoutActions from './logout.actions';
import * as logoutSelectors from './logout.selectors';

@Injectable()
export class LogoutStoreService {
  constructor(private logoutStore: Store<ILogoutState>) {}

  get state$(): Observable<ILogoutState> {
    return this.logoutStore.select(logoutSelectors.getLogoutState);
  }

  get isLoading$(): Observable<boolean> {
    return this.logoutStore.select(logoutSelectors.getLogoutIsLoading);
  }

  get isDidLoad$(): Observable<boolean> {
    return this.logoutStore.select(logoutSelectors.getLogoutIsDidLoad);
  }

  get error$(): Observable<Error | null> {
    return this.logoutStore.select(logoutSelectors.getLogoutError);
  }

  public logout(returnUrl: string = '/'): void {
    this.logoutStore.dispatch(logoutActions.logout({ returnUrl }));
  }
}
