import { Injectable } from '@angular/core';

import { DelegateCitiesService } from './delegate-cities.service';
import { DelegateUserCitiesService } from './delegate-user-cities.service';
import { DelegateWarehousesService } from './delegate-warehouses.service';

import { DictionaryDelegateAccountingControlSystemsService } from './dictionary-delegate-accounting-control-systems.service';
import { DictionaryDelegateAccountingSystemTypeService } from './dictionary-delegate-accounting-system-type.service';
import { DictionaryDelegateClassificationService } from './dictionary-delegate-classification.service';
import { DictionaryDelegateCranageTypesService } from './dictionary-delegate-cranage-types.service';
import { DictionaryDelegateElectricityGridTypeService } from './dictionary-delegate-electricity-grid-type.service';
import { DictionaryDelegateFireExtSystemTypeService } from './dictionary-delegate-fire-ext-system-type.service';
import { DictionaryDelegateFloorCoveringService } from './dictionary-delegate-floor-covering.service';
import { DictionaryDelegateHeatingSystemTypeService } from './dictionary-delegate-heating-system-type.service';
import { DictionaryDelegateLoadingUnloadingMethodsService } from './dictionary-delegate-loading-unloading-methods.service';
import { DictionaryDelegateLoadingUnloadingStructTypesService } from './dictionary-delegate-loading-unloading-struct-types.service';
import { DictionaryDelegateLoadingUnloadingTechTypesService } from './dictionary-delegate-loading-unloading-tech-types.service';
import { DictionaryDelegateOfficeFacilityTypesService } from './dictionary-delegate-office-facility-types.service';
import { DictionaryDelegateParkingTypeService } from './dictionary-delegate-parking-type.service';
import { DictionaryDelegateStorageFormatService } from './dictionary-delegate-storage-format.service';
import { DictionaryDelegateStorageSystemService } from './dictionary-delegate-storage-system.service';
import { DictionaryDelegateStorageSystemPublicService } from './dictionary-delegate-storage-system-public.service';
import { DictionaryDelegateTaxationTypeService } from './dictionary-delegate-taxation-type.service';
import { DictionaryDelegateTemperatureModeService } from './dictionary-delegate-temperature-mode.service';
import { DictionaryDelegateUnitService } from './dictionary-delegate-unit.service';
import { DictionaryDelegateUnitDistanceService } from './dictionary-delegate-unit-distance.service';
import { DictionaryDelegateUnitMassService } from './dictionary-delegate-unit-mass.service';
import { DictionaryDelegateUnitSquareService } from './dictionary-delegate-unit-square.service';
import { DictionaryDelegateUnitTemperatureService } from './dictionary-delegate-unit-temperature.service';

import { IDelegate } from '../model/delegate.model';
import { DictionaryDelegateLegalTypeService } from './dictionary-delegate-legal-type.service';
import { DictionaryDelegatePartnerTypeService } from './dictionary-delegate-partner-type.service';

export enum eDelegateType {
  dictionaryAccountingControlSystems = 'dictionaryAccountingControlSystems',
  dictionaryAccountingSystemType = 'dictionaryAccountingSystemType',
  dictionaryClassification = 'dictionaryClassification',
  dictionaryCranageTypes = 'dictionaryCranageTypes',
  dictionaryElectricityGridType = 'dictionaryElectricityGridType',
  dictionaryFireExtSystemType = 'dictionaryFireExtSystemType',
  dictionaryFloorCovering = 'dictionaryFloorCovering',
  dictionaryHeatingSystemType = 'dictionaryHeatingSystemType',
  dictionaryLoadingUnloadingMethods = 'dictionaryLoadingUnloadingMethods',
  dictionaryLoadingUnloadingStructTypes = 'dictionaryLoadingUnloadingStructTypes',
  dictionaryLoadingUnloadingTechTypes = 'dictionaryLoadingUnloadingTechTypes',
  dictionaryOfficeFacilityTypes = 'dictionaryOfficeFacilityTypes',
  dictionaryParkingType = 'dictionaryParkingType',
  dictionaryStorageFormat = 'dictionaryStorageFormat',
  dictionaryStorageSystem = 'dictionaryStorageSystem',
  dictionaryStorageSystemPublic = 'dictionaryStorageSystemPublic',
  dictionaryTaxationType = 'dictionaryTaxationType',
  dictionaryTemperatureMode = 'dictionaryTemperatureMode',
  dictionaryUnit = 'dictionaryUnit',
  dictionaryUnitDistance = 'dictionaryUnitDistance',
  dictionaryUnitMass = 'dictionaryUnitMass',
  dictionaryUnitSquare = 'dictionaryUnitSquare',
  dictionaryUnitTemperature = 'dictionaryUnitTemperature',
  warehouses = 'warehouses',
  cities = 'cities',
  userCities = 'userCities',
  dictionaryLegalType = 'dictionaryLegalType',
  dictionaryPartnerType = 'dictionaryPartnerType',
}

@Injectable({ providedIn: 'root' })
export class DelegateFactoryService {
  constructor(
    private delegateCities: DelegateCitiesService,
    private delegateUserCities: DelegateUserCitiesService,
    private delegateWarehouses: DelegateWarehousesService,

    private dictionaryDelegateAccountingControlSystemsService: DictionaryDelegateAccountingControlSystemsService,
    private dictionaryDelegateAccountingSystemTypeService: DictionaryDelegateAccountingSystemTypeService,
    private dictionaryDelegateClassificationService: DictionaryDelegateClassificationService,
    private dictionaryDelegateCranageTypesService: DictionaryDelegateCranageTypesService,
    private dictionaryDelegateElectricityGridTypeService: DictionaryDelegateElectricityGridTypeService,
    private dictionaryDelegateFireExtSystemTypeService: DictionaryDelegateFireExtSystemTypeService,
    private dictionaryDelegateFloorCoveringService: DictionaryDelegateFloorCoveringService,
    private dictionaryDelegateHeatingSystemTypeService: DictionaryDelegateHeatingSystemTypeService,
    private dictionaryDelegateLoadingUnloadingMethodsService: DictionaryDelegateLoadingUnloadingMethodsService,
    private dictionaryDelegateLoadingUnloadingStructTypesService: DictionaryDelegateLoadingUnloadingStructTypesService,
    private dictionaryDelegateLoadingUnloadingTechTypesService: DictionaryDelegateLoadingUnloadingTechTypesService,
    private dictionaryDelegateOfficeFacilityTypesService: DictionaryDelegateOfficeFacilityTypesService,
    private dictionaryDelegateParkingTypeService: DictionaryDelegateParkingTypeService,
    private dictionaryDelegateStorageFormatService: DictionaryDelegateStorageFormatService,
    private dictionaryDelegateStorageSystemService: DictionaryDelegateStorageSystemService,
    private dictionaryDelegateStorageSystemPublicService: DictionaryDelegateStorageSystemPublicService,
    private dictionaryDelegateTaxationTypeService: DictionaryDelegateTaxationTypeService,
    private dictionaryDelegateTemperatureModeService: DictionaryDelegateTemperatureModeService,
    private dictionaryDelegateUnitService: DictionaryDelegateUnitService,
    private dictionaryDelegateUnitDistanceService: DictionaryDelegateUnitDistanceService,
    private dictionaryDelegateUnitMassService: DictionaryDelegateUnitMassService,
    private dictionaryDelegateUnitSquareService: DictionaryDelegateUnitSquareService,
    private dictionaryDelegateUnitTemperatureService: DictionaryDelegateUnitTemperatureService,
    private dictionaryDelegateLegalTypeService: DictionaryDelegateLegalTypeService,
    private dictionaryDelegatePartnerTypeService: DictionaryDelegatePartnerTypeService,
  ) {}

  public getDelegate(delegateType: eDelegateType): IDelegate {
    switch (delegateType) {
      case eDelegateType.cities:
        return this.delegateCities;
      case eDelegateType.userCities:
        return this.delegateUserCities;
      case eDelegateType.warehouses:
        return this.delegateWarehouses;

      case eDelegateType.dictionaryAccountingControlSystems:
        return this.dictionaryDelegateAccountingControlSystemsService;
      case eDelegateType.dictionaryAccountingSystemType:
        return this.dictionaryDelegateAccountingSystemTypeService;
      case eDelegateType.dictionaryClassification:
        return this.dictionaryDelegateClassificationService;
      case eDelegateType.dictionaryCranageTypes:
        return this.dictionaryDelegateCranageTypesService;
      case eDelegateType.dictionaryElectricityGridType:
        return this.dictionaryDelegateElectricityGridTypeService;
      case eDelegateType.dictionaryFireExtSystemType:
        return this.dictionaryDelegateFireExtSystemTypeService;
      case eDelegateType.dictionaryFloorCovering:
        return this.dictionaryDelegateFloorCoveringService;
      case eDelegateType.dictionaryHeatingSystemType:
        return this.dictionaryDelegateHeatingSystemTypeService;
      case eDelegateType.dictionaryLoadingUnloadingMethods:
        return this.dictionaryDelegateLoadingUnloadingMethodsService;
      case eDelegateType.dictionaryLoadingUnloadingStructTypes:
        return this.dictionaryDelegateLoadingUnloadingStructTypesService;
      case eDelegateType.dictionaryLoadingUnloadingTechTypes:
        return this.dictionaryDelegateLoadingUnloadingTechTypesService;
      case eDelegateType.dictionaryOfficeFacilityTypes:
        return this.dictionaryDelegateOfficeFacilityTypesService;
      case eDelegateType.dictionaryParkingType:
        return this.dictionaryDelegateParkingTypeService;
      case eDelegateType.dictionaryStorageFormat:
        return this.dictionaryDelegateStorageFormatService;
      case eDelegateType.dictionaryStorageSystem:
        return this.dictionaryDelegateStorageSystemService;
      case eDelegateType.dictionaryStorageSystemPublic:
        return this.dictionaryDelegateStorageSystemPublicService;
      case eDelegateType.dictionaryTaxationType:
        return this.dictionaryDelegateTaxationTypeService;
      case eDelegateType.dictionaryTemperatureMode:
        return this.dictionaryDelegateTemperatureModeService;
      case eDelegateType.dictionaryUnit:
        return this.dictionaryDelegateUnitService;
      case eDelegateType.dictionaryUnitDistance:
        return this.dictionaryDelegateUnitDistanceService;
      case eDelegateType.dictionaryUnitMass:
        return this.dictionaryDelegateUnitMassService;
      case eDelegateType.dictionaryUnitSquare:
        return this.dictionaryDelegateUnitSquareService;
      case eDelegateType.dictionaryUnitTemperature:
        return this.dictionaryDelegateUnitTemperatureService;
      case eDelegateType.dictionaryLegalType:
        return this.dictionaryDelegateLegalTypeService;
      case eDelegateType.dictionaryPartnerType:
        return this.dictionaryDelegatePartnerTypeService;
      default:
        throw new Error('Delegate not found');
    }
  }
}
