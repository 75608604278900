import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICityStoreState } from './city.state';

export const getCityStoreState = createFeatureSelector<ICityStoreState>('city');

export const getCities = createSelector(getCityStoreState, (state: ICityStoreState) => state.cities);

export const getUserCities = createSelector(getCityStoreState, (state: ICityStoreState) => state.userCities);

export const getLoadingState = createSelector(getCityStoreState, (state: ICityStoreState) => state.loadingState);
