import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BookingEffects } from './booking.effects';
import { BookingReducer } from './booking.reducer';
import { ApiBookingService } from '../../api/api-booking.service';
import { BookingStoreService } from './booking-store.service';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('booking', BookingReducer), EffectsModule.forFeature([BookingEffects])],
  providers: [ApiBookingService, BookingStoreService],
})
export class BookingStoreModule {}
