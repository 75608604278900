<button
  class="btn"
  [ngClass]="['btn--' + theme, 'btn--size-' + size, 'btn--font-' + fontSize]"
  [class.btn--icon]="isIconButton"
  [class.btn--active]="isActive"
  [class.btn--disabled]="disabledVisually || disabled"
  [style.minWidth.px]="minWidth"
  [disabled]="disabled"
  [type]="type"
>
  <span *ngIf="getLeftIconPath()" class="btn__prepend">
    <svg-icon-sprite [src]="'./assets/sprites/sprite.svg#' + getLeftIconPath()" [width]="iconSize" [height]="iconSize"></svg-icon-sprite>
  </span>
  <ng-content></ng-content>
  <span *ngIf="getRightIconPath()" class="btn__append">
    <svg-icon-sprite [src]="'./assets/sprites/sprite.svg#' + getRightIconPath()" [width]="iconSize" [height]="iconSize"></svg-icon-sprite>
  </span>
</button>
