import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { ApiLoginService } from '../../api/api-login.service';
import { AuthService } from '../../api/auth.service';
import { HttpErrorsService } from '../../api/http-errors.service';

import { IAuth, ICredentials } from '../../models/auth.model';
import { eLoginActions } from './login.actions';

@Injectable()
export class LoginEffects {
  constructor(
    private actions$: Actions,
    private apiLoginService: ApiLoginService,
    private authService: AuthService,
    private httpErrorsService: HttpErrorsService,
    private toastrService: ToastrService,
  ) {}

  public login$: Observable<{ type: eLoginActions; authData: IAuth } | { type: eLoginActions; error: HttpErrorResponse }> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(eLoginActions.LOGIN),
        switchMap((credentials: ICredentials) =>
          this.apiLoginService.login({ email: credentials.email, password: credentials.password }).pipe(
            tap(authData => {
              this.authService.setAuthData(authData);
            }),
            map(authData => ({ type: eLoginActions.LOGIN_SUCCESS, authData })),
            catchError((err: HttpErrorResponse) => {
              if (!this.httpErrorsService.isDataValidationError(err)) {
                this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
              }
              return of({ type: eLoginActions.LOGIN_FAILURE, error: err });
            }),
          ),
        ),
      ),
  );
}
