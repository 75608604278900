import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'tlp-search',
  templateUrl: 'search.component.html',
  styleUrls: ['search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent {
  @Input() public placeholder: string = 'Поиск';
  @Input() public hasFilters: boolean = true;
  @Input() public hasSearchInput: boolean = true;
  @Input() public presetFilters: boolean = false;
  @Output() public searchStringChanged: EventEmitter<string> = new EventEmitter<string>();

  public debounce: Subject<string> = new Subject<string>();
  public formControl!: FormControl;
  public showFilters: boolean = false;

  constructor() {
    this.formControl = new FormControl('');
    const time: number = 1000;

    this.debounce.pipe(debounceTime(time)).subscribe(value => this.searchStringChanged.emit(value));
  }

  public toggleFilters(): void {
    this.presetFilters = false;
    this.showFilters = !this.showFilters;
  }

  public handleInputChanges(): void {
    this.debounce.next(this.formControl.value.trim());
  }
}
