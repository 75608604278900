import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IForgotStoreState } from './forgot.state';
import { IHttpRequestState } from '../../models/http.model';

export const getForgotStoreState = createFeatureSelector<IForgotStoreState>('forgot');

export const getForgotLoadingState = createSelector(
  getForgotStoreState,
  (state: IForgotStoreState) =>
    ({
      ...state.forgotLoadingState,
    } as IHttpRequestState),
);
