<tlp-content-block [type]="hasSearchInput ? 'default' : 'transparent'">
  <div class="search">
    <ng-container *ngIf="hasSearchInput">
      <svg-icon-sprite src="./assets/sprites/sprite.svg#ic-search" width="16px" height="16px"></svg-icon-sprite>
      <div class="search__input">
        <tlp-input
            theme="transparent"
            [placeholder]="placeholder"
            [formControl]="formControl"
            (keyup)="handleInputChanges()"
        ></tlp-input>
      </div>
    </ng-container>

    <div class="search__filters"
         *ngIf="hasFilters"
         [class.search__filters--visible]="showFilters"
         [class.search__filters--preset]="presetFilters"
         [class.search__filters--single]="!hasSearchInput"
         (click)="toggleFilters()">
      <svg-icon-sprite src="./assets/sprites/sprite.svg#ic-filters" width="20px" height="24px"></svg-icon-sprite>
      Фильтры
    </div>
  </div>
</tlp-content-block>

<ng-content *ngIf="showFilters"></ng-content>
