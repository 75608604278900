import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_core/services/auth.guard';

const routes: Routes = [
  { path: '', loadChildren: () => import('./+home/home.module').then(m => m.HomeModule) },
  { path: 'auth', loadChildren: () => import('./+auth/auth.module').then(m => m.AuthModule) },
  {
    path: 'profile',
    loadChildren: () => import('./+profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
