import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { OrganizationReducer } from './organization.reducer';
import { OrganizationEffects } from './organization.effects';
import { ApiOrganizationService } from '../../api/admin/api-organization.service';
import { OrganizationStoreService } from './organization-store.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('admin-organization', OrganizationReducer),
    EffectsModule.forFeature([OrganizationEffects]),
  ],
  providers: [OrganizationStoreService, ApiOrganizationService],
})
export class OrganizationStoreModule {}
