<ng-content></ng-content>
<div *ngIf="isFieldChanged && showChanges"
     class="change-note"
     [style.marginTop.px]="changedNoteMarginTop"
     [style.maxWidth.px]="changedNoteMaxWidth"
>
  <p class="change-note__changes-info">
    <span class="change-note__text">{{ changedNoteMessage || (changedNoteMode === 'fieldChanges' ? 'Параметр изменен' : 'Документ изменен')}} </span>
    <svg-icon-sprite
        class="change-note__tip"
        *ngIf="changedNoteMode === 'fieldChanges' && changedFieldValue"
        [tlpTooltip]="changedFieldValue"
        src="assets/sprites/sprite.svg#ic-tip"
        width="14px"
        height="14px"
    ></svg-icon-sprite>
  </p>
</div>