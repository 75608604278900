import { Component, forwardRef, Input, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
  ValidationErrors,
  FormControl,
  ValidatorFn,
} from '@angular/forms';

export type textareaThemes = 'default' | 'transparent';

@Component({
  selector: 'tlp-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
})
export class TextareaComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() public theme: textareaThemes = 'default';
  @Input() public placeholder: string = '';
  @Input() public rows: number = 6;
  @Input() public required: boolean = false;

  public formControl!: FormControl;
  public isDisabled: boolean = false;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    const validators: ValidatorFn[] = [];
    this.formControl = new FormControl('', validators);
  }

  private onChange = (_: number | string) => {};
  private onTouched = () => {};

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  public writeValue(outsideValue: number | string | undefined): void {
    if (outsideValue !== undefined) {
      this.formControl.setValue(outsideValue, { emitEvent: false });
      this.onTouched();
      this.onChange(outsideValue);
    }
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.formControl.disable();
      this.isDisabled = true;
    } else {
      this.formControl.enable();
      this.isDisabled = false;
    }

    this.cdr.detectChanges();
  }

  public onValueChange(event: KeyboardEvent | FocusEvent): void {
    const value = (event.target as HTMLInputElement).value;
    this.onTouched();
    this.onChange(value);
  }

  public validate(): ValidationErrors | null {
    return this.formControl.errors;
  }
}
