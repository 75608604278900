<mat-form-field floatLabel="never">
  <label class="datepicker__label">
    <input
      matInput
      rInputMask="99.99.9999"
      class="datepicker__input"
      [class.ng-invalid]="!value && required"
      [matDatepicker]="picker"
      [ngModel]="value"
      [placeholder]="placeholder || 'Выберите дату'"
      [readonly]="isDisabled"
      [class.datepicker--active-state]="isActive"
      [class.datepicker--disabled]="isDisabled"
      (dateChange)="onDateChange($event)"
      (focus)="onChangeIsActive(true)"
      (blur)="onBlur()"
    />
  </label>
  <mat-datepicker-toggle matSuffix [for]="picker" *ngIf="!isDisabled">
    <mat-icon matDatepickerToggleIcon class="datepicker__calendar">
      <div class="datepicker__calendar-svg">
        <svg-icon-sprite src="./assets/sprites/sprite.svg#ic-calendar" width="18px" height="18px"></svg-icon-sprite>
      </div>
    </mat-icon>
  </mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
