import { Directive, ElementRef, AfterViewInit, Input, OnDestroy, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subscription, fromEvent } from 'rxjs';

@Directive({
  selector: '[tlpStickyOnScroll]',
})
export class StickyOnScrollDirective implements AfterViewInit, OnDestroy {
  @Input() public cssScrollClass: string = 'sticky';
  @Input() public scrollElementSelector!: string;
  @Input() public makeTargetSticky: boolean = true;

  private targetStickyElementBottomPosition!: number | undefined;
  private scrollEventSub: Subscription | null = null;

  constructor(@Inject(DOCUMENT) private document: Document, private elementRef: ElementRef, private renderer: Renderer2) {}

  public ngAfterViewInit(): void {
    const listenElement = (this.document.querySelectorAll(this.scrollElementSelector)[0] as HTMLElement) || window;
    this.targetStickyElementBottomPosition = this.elementRef?.nativeElement?.getBoundingClientRect()?.bottom;

    this.scrollEventSub = fromEvent(listenElement, 'scroll').subscribe(() => this.handleScroll());
  }

  public ngOnDestroy(): void {
    this.scrollEventSub?.unsubscribe();
  }

  private handleScroll(): void {
    if (!this.makeTargetSticky || !this.elementRef?.nativeElement || this.targetStickyElementBottomPosition === undefined) {
      return;
    }

    const siblingElementTopPosition = this.elementRef.nativeElement?.nextElementSibling?.getBoundingClientRect()?.top;

    if (siblingElementTopPosition < this.targetStickyElementBottomPosition && !this.isSticky) {
      this.renderer.addClass(this.elementRef.nativeElement, this.cssScrollClass);
    } else if (siblingElementTopPosition >= this.targetStickyElementBottomPosition && this.isSticky) {
      this.renderer.removeClass(this.elementRef.nativeElement, this.cssScrollClass);
    }
  }

  private get isSticky(): boolean {
    return !!this.elementRef?.nativeElement?.classList?.contains(this.cssScrollClass);
  }
}
