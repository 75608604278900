import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { ApiRefreshTokenService } from './api-refresh-token.service';
import { AuthInterceptor } from './auth.interceptor';
import { AuthService } from './auth.service';
import { HttpErrorsService } from './http-errors.service';
import { HttpService } from './http.service';
import { RefreshTokenService } from './refresh-token.service';
import { API_CONFIG, IApiModel } from '../models/api.model';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  imports: [CommonModule, HttpClientModule],
})
export class ApiModule {
  public static forRoot(config: IApiModel): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        { provide: API_CONFIG, useValue: config },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
        HttpClient,
        ApiRefreshTokenService,
        AuthService,
        HttpService,
        HttpErrorsService,
        RefreshTokenService,
      ],
    };
  }
}
