import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractApiMethodsService } from './abstract-api-methods.service';
import { IListing, IListingApiParams, IListingBookingsApiParams } from '../models/listing.model';

@Injectable()
export class ApiListingUserService extends AbstractApiMethodsService {
  public getWarehousesListing(params: IListingApiParams): Observable<IListing | null> {
    return this.httpPost(params, 'listing/warehouse');
  }

  public getBookingsListing(params: IListingBookingsApiParams): Observable<IListing | null> {
    return this.httpPost(params, 'listing/reserve');
  }
}
