export class InputMask {
  public static phone: string = '(000) 000-0000';
  public static inn: string = '000000000099';
  public static bik: string = '000000000';
  public static ogrn: string = '0000000000000';
  public static ogrnIP: string = '000000000000000';
  public static okpo: string = '0000000099';
  public static kpp: string = '0000AA000';
  public static time: string = '00:00';
}
