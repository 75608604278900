import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractApiMethodsService } from './abstract-api-methods.service';

import { IAcceptanceDocument } from '../models/acceptance-document.model';
import { IBooking, IBookingDocuments, IBookingGeneral, IBookings, IBookingsApiParams } from '../models/booking.model';

@Injectable()
export class ApiBookingService extends AbstractApiMethodsService {
  public getBookings(params: IBookingsApiParams): Observable<IBookings | null> {
    return this.httpPost(params, 'reserve/index');
  }

  public getBooking(id: string): Observable<IBooking | null> {
    return this.httpPost({}, `reserve/${id}/view`);
  }

  public getBookingGeneral(id: string): Observable<IBookingGeneral | null> {
    return this.httpPost({}, `reserve/${id}/view/general`);
  }

  public getBookingStorageRequest(id: string): Observable<IAcceptanceDocument | null> {
    return this.httpPost({}, `reserve/${id}/view/storage-request`);
  }

  public getBookingShipmentRequest(id: string): Observable<IAcceptanceDocument | null> {
    return this.httpPost({}, `reserve/${id}/view/shipment-request`);
  }

  public getBookingDocuments(id: string): Observable<IBookingDocuments | null> {
    return this.httpPost({}, `reserve/${id}/view/documents`);
  }

  public uploadBookingDocument(formData: FormData, id: string, documentType: string): Observable<boolean> {
    return this.httpPost(formData, `reserve/${id}/upload-document/${documentType}`, true);
  }

  public deleteBookingDocument(id: string, documentType: string): Observable<boolean> {
    return this.httpPost({}, `reserve/${id}/delete-document/${documentType}`);
  }

  public cancelBooking(id: string): Observable<boolean> {
    return this.httpPost({}, `reserve/${id}/cancel`);
  }

  public completeBooking(id: string): Observable<boolean> {
    return this.httpPost({}, `reserve/${id}/complete`);
  }
}
