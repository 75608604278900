import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { transition, trigger } from '@angular/animations';
import * as moment from 'moment';

import { BookingCalendarService } from '../../../service/booking-calendar.service';
import { IBookingDate, IBookingDatesFilters } from '../../../../../_core/models/booking.model';
import { IBookingCalendarDate, tPeriodDirections } from '../../../model/booking-calendar.model';
import { eBookingCalendarDisplayedDaysCount } from '../../../const/booking-calendar.const';
import { TRANSLATE_LEFT, TRANSLATE_RIGHT } from '../../../../animation/animation.const';
import { UNIX_TIME_RATIO } from '../../../../../_core/const/date.const';

@Component({
  selector: 'tlp-booking-calendar',
  templateUrl: './booking-calendar.component.html',
  styleUrls: ['../../../styles/booking-calendar-shared.component.scss', './booking-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('calendarSlider', [
      transition(':enter', []),
      transition(':increment', TRANSLATE_RIGHT),
      transition(':decrement', TRANSLATE_LEFT),
    ]),
  ],
})
export class BookingCalendarComponent implements OnChanges, OnInit {
  @Input() public amountUnit!: string;
  @Input() public totalAmount!: number | null;
  @Input() public showFreeAmount: boolean = true;
  @Input() public bookingDates!: IBookingDate[] | undefined;
  @Input() public bookingDatesLoading: boolean = false;
  @Input() public bookingDatesLoadingFailure?: boolean;

  @Input() public animated: boolean = true;
  @Input() public leftLimitDate?: number | moment.Moment | null = null;
  @Input() public rightLimitDate?: number | moment.Moment | null = null;
  @Input() public displayedDaysCount: number = eBookingCalendarDisplayedDaysCount.default;
  @Output() public bookingDatesUpdateRequested: EventEmitter<IBookingDatesFilters> = new EventEmitter<IBookingDatesFilters>();

  public bookingCalendar: IBookingCalendarDate[] | undefined = undefined;
  public displayedPeriod: IBookingCalendarDate[] = [];
  public mainDate: moment.Moment | null = null;
  public currentPeriodDirectionHandler: number = 0;

  constructor(private cdr: ChangeDetectorRef, private bookingCalendarService: BookingCalendarService) {
    moment.locale('ru');
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.bookingDates && changes.bookingDates.currentValue) {
      if (this.bookingCalendar) {
        this.bookingDates = this.bookingCalendarService.getParsedBookingDates(changes.bookingDates.currentValue);
        this.bookingCalendar = this.bookingCalendarService.getUpdatedBookingCalendar(
          this.totalAmount,
          this.bookingCalendar,
          changes.bookingDates.currentValue,
        );
        this.updateDisplayedPeriod();
        this.bookingDatesLoading = false;
      } else {
        this.parseDates(changes.bookingDates?.currentValue);
        this.bookingCalendar = this.bookingCalendarService.getBookingCalendar(
          this.totalAmount,
          this.leftLimitDate || null,
          this.rightLimitDate || null,
          this.bookingDates,
          true,
          this.displayedDaysCount,
        );
      }
    }

    if (changes.bookingDatesLoadingFailure && changes.bookingDatesLoadingFailure.currentValue) {
      this.bookingDatesLoading = false;
      this.cdr.detectChanges();
    }
  }

  public ngOnInit(): void {
    if (!this.bookingCalendar) {
      this.parseDates();
      this.bookingCalendar = this.bookingCalendarService.getBookingCalendar(
        this.totalAmount,
        this.leftLimitDate || null,
        this.rightLimitDate || null,
        this.bookingDates,
      );
    }

    this.displayedPeriod = this.bookingCalendar?.slice(0, this.displayedDaysCount) || [];
    this.mainDate = this.displayedPeriod?.[0].date as moment.Moment;
  }

  public canChangePeriod(direction: tPeriodDirections): boolean {
    switch (direction) {
      case 'left':
        if (!this.leftLimitDate) {
          return true;
        }
        return !moment(this.displayedPeriod?.[0]?.date)
          .subtract(this.displayedDaysCount, 'days')
          .isBefore(this.bookingCalendarService.getMomentDate(this.leftLimitDate).format('YYYY-MM-DD'));
      case 'right':
        if (!this.rightLimitDate) {
          return true;
        }
        return !moment(this.displayedPeriod?.[this.displayedPeriod.length - 1]?.date)
          .add(1, 'days')
          .isAfter(this.bookingCalendarService.getMomentDate(this.rightLimitDate).format('YYYY-MM-DD'));
      default:
        return false;
    }
  }

  public changeDisplayedPeriod(direction: tPeriodDirections): void {
    let firstDateToDisplay: moment.Moment | null;
    switch (direction) {
      case 'left':
        firstDateToDisplay = moment(this.displayedPeriod?.[0]?.date).subtract(this.displayedDaysCount, 'days');
        if (this.leftLimitDate && firstDateToDisplay.isBefore(this.bookingCalendarService.getMomentDate(this.leftLimitDate))) {
          firstDateToDisplay = this.bookingCalendarService.getMomentDate(this.leftLimitDate);
        }
        break;
      case 'right':
        firstDateToDisplay = moment(this.displayedPeriod?.[this.displayedPeriod.length - 1]?.date).add(1, 'days');
        if (this.rightLimitDate && firstDateToDisplay.isAfter(this.bookingCalendarService.getMomentDate(this.rightLimitDate))) {
          firstDateToDisplay = this.bookingCalendarService.getMomentDate(this.rightLimitDate).subtract(this.displayedDaysCount, 'days');
        }
        break;
      default:
        firstDateToDisplay = this.displayedPeriod?.[0]?.date as moment.Moment;
        break;
    }

    let lastDateToDisplay: moment.Moment | null = moment(firstDateToDisplay).add(this.displayedDaysCount - 1, 'days');
    if (this.rightLimitDate && lastDateToDisplay.isAfter(this.bookingCalendarService.getMomentDate(this.rightLimitDate))) {
      lastDateToDisplay = this.bookingCalendarService.getMomentDate(this.rightLimitDate);
    }

    if (
      this.bookingDates &&
      this.bookingDates[0]?.date &&
      (direction === 'left'
        ? firstDateToDisplay.isBefore(this.bookingCalendarService.getMomentDate(this.bookingDates[0]?.date))
        : firstDateToDisplay.isSameOrAfter(
            this.bookingCalendarService.getMomentDate(this.bookingDates[this.bookingDates.length - 1]?.date),
          ) || this.bookingCalendarService.getMomentDate(this.bookingDates[this.bookingDates.length - 1]?.date).isBefore(lastDateToDisplay))
    ) {
      this.bookingDatesLoading = true;
      this.bookingDatesUpdateRequested.emit({
        rent_from: moment(firstDateToDisplay.format('YYYY-MM-DD')).valueOf() / UNIX_TIME_RATIO,
        rent_to: moment(lastDateToDisplay.format('YYYY-MM-DD')).add(1, 'days').valueOf() / UNIX_TIME_RATIO,
      });
      this.cdr.markForCheck();
    }

    if (this.bookingCalendar) {
      const missedDaysCount = this.bookingCalendarService.getMissedDisplayedDaysCount(
        firstDateToDisplay,
        lastDateToDisplay,
        this.displayedDaysCount,
      );

      const firstCalendarDateIndex = this.bookingCalendar?.findIndex(calendarItem =>
        moment(calendarItem.date).isSame(moment(firstDateToDisplay).format('YYYY-MM-DD')),
      );
      const lastCalendarDateIndex = this.bookingCalendar?.findIndex(calendarItem =>
        moment(calendarItem.date).isSame(moment(lastDateToDisplay).add(missedDaysCount, 'days').format('YYYY-MM-DD')),
      );

      if (this.animated) {
        this.currentPeriodDirectionHandler += direction === 'right' ? +1 : -1;
      }

      this.displayedPeriod = this.bookingCalendar?.slice(firstCalendarDateIndex, lastCalendarDateIndex);
      this.mainDate = this.displayedPeriod?.[0].date as moment.Moment;
    }
  }

  public get displayedMainDate(): string {
    return this.mainDate ? moment(this.mainDate).format('MMMM YYYY') : '';
  }

  private updateDisplayedPeriod(): void {
    this.displayedPeriod?.forEach((displayedPeriodItem, index) => {
      const sameBookingDateIndex = this.bookingCalendar?.findIndex(calendarItem =>
        moment(calendarItem.date).isSame(displayedPeriodItem.date),
      );
      if (sameBookingDateIndex !== undefined && sameBookingDateIndex !== -1 && this.bookingCalendar![sameBookingDateIndex]) {
        this.displayedPeriod![index] = { ...this.bookingCalendar![sameBookingDateIndex] };
      }
    });
  }

  private parseDates(bookingDates?: IBookingDate[] | undefined): void {
    this.bookingDates = this.bookingCalendarService.getParsedBookingDates(bookingDates || this.bookingDates);

    this.leftLimitDate = this.bookingCalendarService.getParsedLeftLimit(this.bookingDates, this.leftLimitDate || null);
    this.rightLimitDate = this.bookingCalendarService.getParsedRightLimit(this.bookingDates, this.leftLimitDate, this.rightLimitDate);
  }
}
