import { Injectable } from '@angular/core';
import { AbstractApiMethodsService } from './abstract-api-methods.service';
import { Observable } from 'rxjs';
import { INotifications, IUnreadNotificationsCount } from '../models/notifications.model';
import { IApiPagination } from '../models/pagination.model';

@Injectable()
export class ApiNotificationsService extends AbstractApiMethodsService {
  public getNotifications(pagination: IApiPagination): Observable<INotifications | null> {
    return this.httpPost(pagination, 'notification/index');
  }

  public getUnreadCount(): Observable<IUnreadNotificationsCount> {
    return this.httpPost({}, 'notification/unread-count');
  }

  /* TODO:
       notification/<id>/view
       notification/<id>/mark-read
       notification/mark-all-read
       notification/<id>/delete
  */
}
