import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { ButtonModule } from '../button/button.module';
import { TooltipModule } from '../directives/tooltip/tooltip.module';

import { PageActionButtonsComponent } from './component/page-action-buttons/page-action-buttons.component';

@NgModule({
  imports: [CommonModule, IconSpriteModule, ButtonModule, TooltipModule],
  declarations: [PageActionButtonsComponent],
  exports: [PageActionButtonsComponent],
})
export class PageActionButtonsModule {}
