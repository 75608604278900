<div class="notifications-menu">
  <tlp-button
    [theme]="'default'"
    [isIconButton]="true"
    [isActive]="isDropdownShown"
    (click)="toggleDropdown()"
  >
    <svg-icon-sprite [src]="'./assets/sprites/sprite.svg#ic-notification'" width="16px" height="16px"></svg-icon-sprite>
    <ng-container *ngIf="unreadCount$ | async as unreadCount">
      <span *ngIf="unreadCount > 0" class="notifications-menu__unread">{{ unreadCount }}</span>
    </ng-container>
  </tlp-button>
  <div class="notifications-menu__dropdown" [class.notifications-menu__dropdown--active]="isDropdownShown">
    <tlp-content-block [dialog]="true" [tlpLoading]="(isNotificationsLoading$ | async)">
      <div
        class="notifications-menu__dropdown-content"
        infiniteScroll
        [scrollWindow]="false"
        (scrolled)="getNotifications()"
      >
        <div class="notifications-menu__heading">
          <div class="notifications-menu__header">
            Уведомления
          </div>
        </div>
        <div class="notifications-menu__new">
          Новые
          <span class="notifications-menu__new-count">{{ newNotifications.length }}</span>
        </div>
        <div *ngFor="let note of newNotifications" class="notifications-menu__notify notifications-menu__notify--unread">
          <span class="notifications-menu__notify-date">{{ note.created_at * 1000 | date : 'dd.MM.yy h:mm' : 'UTC+0'}}</span>
          <div class="notifications-menu__notify-text" [innerHTML]="note.text"></div>
        </div>
        <div class="notifications-menu__old">Прочитанные</div>
        <div *ngFor="let note of oldNotifications" class="notifications-menu__notify">
          <span class="notifications-menu__notify-date">{{ note.created_at * 1000 | date : 'dd.MM.yy h:mm' : 'UTC+0'}}</span>
          <div class="notifications-menu__notify-text" [innerHTML]="note.text"></div>
        </div>
      </div>
    </tlp-content-block>
  </div>
</div>
