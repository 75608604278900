import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IHttpRequestState } from '../../models/http.model';
import { IWarehouseStoreState } from './warehouse.state';

export const getWarehouseStoreState = createFeatureSelector<IWarehouseStoreState>('warehouse');

export const getWarehouses = createSelector(getWarehouseStoreState, (state: IWarehouseStoreState) => state.warehouses);

export const getCreatedWarehouseId = createSelector(getWarehouseStoreState, (state: IWarehouseStoreState) => state.createdWarehouseId);

export const getWarehouseTabState = createSelector(getWarehouseStoreState, (state: IWarehouseStoreState) => state.warehouseTabState);
export const getWarehouseCanEdit = createSelector(getWarehouseStoreState, (state: IWarehouseStoreState) => ({
  can_edit: state.warehouseGeneral?.can_edit,
  can_update: state.warehouseGeneral?.status.system_name !== 'restricted',
}));
export const getWarehouseGeneral = createSelector(getWarehouseStoreState, (state: IWarehouseStoreState) => state.warehouseGeneral);
export const getWarehouseExternal = createSelector(getWarehouseStoreState, (state: IWarehouseStoreState) => state.warehouseExternal);
export const getWarehouseInternal = createSelector(getWarehouseStoreState, (state: IWarehouseStoreState) => state.warehouseInternal);
export const getWarehouseDocuments = createSelector(getWarehouseStoreState, (state: IWarehouseStoreState) => state.warehouseDocuments);
export const getWarehousePreloadedPhotos = createSelector(
  getWarehouseStoreState,
  (state: IWarehouseStoreState) => state.warehousePreloadedPhotos,
);

export const getWarehouseDetailsLoadingState = createSelector(
  getWarehouseStoreState,
  (state: IWarehouseStoreState) =>
    ({
      ...state.detailsLoadingState,
    } as IHttpRequestState),
);
export const getWarehousesLoadingState = createSelector(
  getWarehouseStoreState,
  (state: IWarehouseStoreState) =>
    ({
      ...state.loadingState,
    } as IHttpRequestState),
);
export const getWarehousesPreloadingState = createSelector(
  getWarehouseStoreState,
  (state: IWarehouseStoreState) =>
    ({
      ...state.preloadingState,
    } as IHttpRequestState),
);
export const getWarehousePublishingState = createSelector(
  getWarehouseStoreState,
  (state: IWarehouseStoreState) =>
    ({
      ...state.publishingState,
    } as IHttpRequestState),
);
export const getWarehouseUpdatingState = createSelector(
  getWarehouseStoreState,
  (state: IWarehouseStoreState) =>
    ({
      ...state.updatingState,
    } as IHttpRequestState),
);
