import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LogsEffects } from './logs.effects';
import { LogsReducer } from './logs.reducer';
import { ApiLogsService } from '../../api/admin/api-logs.service';
import { LogsStoreService } from './logs-store.service';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('admin-logs', LogsReducer), EffectsModule.forFeature([LogsEffects])],
  providers: [LogsStoreService, ApiLogsService],
})
export class LogsStoreModule {}
