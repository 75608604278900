import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../api/auth.service';
import { ApiRegisterService } from '../../api/api-register.service';
import { HttpErrorsService } from '../../api/http-errors.service';

import { IAuth, IRegisterUser } from '../../models/auth.model';
import { eRegisterActions } from './register.actions';

@Injectable()
export class RegisterEffects {
  constructor(
    private actions$: Actions,
    private toastrService: ToastrService,
    private authService: AuthService,
    private apiRegisterService: ApiRegisterService,
    private httpErrorsService: HttpErrorsService,
  ) {}

  public register$: Observable<{ type: eRegisterActions } | { type: eRegisterActions; error: HttpErrorResponse }> = createEffect(() =>
    this.actions$.pipe(
      ofType(eRegisterActions.REGISTER),
      switchMap((payload: { type: string; user: IRegisterUser }) =>
        this.apiRegisterService.register(payload.user).pipe(
          map(() => ({ type: eRegisterActions.REGISTER_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(err)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            }

            return of({ type: eRegisterActions.REGISTER_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public confirmRegister$: Observable<
    { type: eRegisterActions; authData: IAuth } | { type: eRegisterActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eRegisterActions.REGISTER_CONFIRM),
      switchMap((payload: { type: string; token: string }) =>
        this.apiRegisterService.confirmRegister(payload.token).pipe(
          tap(authData => {
            this.authService.setAuthData(authData);
          }),
          map(authData => ({ type: eRegisterActions.REGISTER_CONFIRM_SUCCESS, authData })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eRegisterActions.REGISTER_CONFIRM_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public sendRegisterConfirmEmail$: Observable<
    { type: eRegisterActions } | { type: eRegisterActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eRegisterActions.REGISTER_SEND_CONFIRM_EMAIL),
      switchMap((payload: { type: string; email: string }) =>
        this.apiRegisterService.sendRegisterConfirmEmail(payload.email).pipe(
          tap(() => {
            this.toastrService.success('Ссылка успешно отправлена на Ваш электронный адрес');
          }),
          map(() => ({ type: eRegisterActions.REGISTER_SEND_CONFIRM_EMAIL_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eRegisterActions.REGISTER_SEND_CONFIRM_EMAIL_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );
}
