import { Injectable } from '@angular/core';
import { IButtonConfig } from '../model/page-action-button.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageActionButtonsService {
  public config: BehaviorSubject<IButtonConfig | null> = new BehaviorSubject<IButtonConfig | null>(null);
}
