import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IWarehousesCatalog, IWarehousesCatalogApiParams, IWarehousesCatalogItem } from '../../models/warehouses-catalog.model';
import { IWarehousesCatalogStoreState } from './warehouses-catalog.state';
import { IHttpRequestState } from '../../models/http.model';

import * as warehousesCatalogActions from './warehouses-catalog.actions';
import * as warehousesCatalogSelectors from './warehouses-catalog.selectors';

@Injectable()
export class WarehousesCatalogStoreService {
  constructor(private advertsStore: Store<IWarehousesCatalogStoreState>) {}

  get warehousesCatalog$(): Observable<IWarehousesCatalog | null> {
    return this.advertsStore.select(warehousesCatalogSelectors.getWarehousesCatalog);
  }

  get warehousesCatalogItem$(): Observable<IWarehousesCatalogItem | null> {
    return this.advertsStore.select(warehousesCatalogSelectors.getWarehousesCatalogItem);
  }

  get warehousesCatalogStoreState$(): Observable<IWarehousesCatalogStoreState> {
    return this.advertsStore.select(warehousesCatalogSelectors.getWarehousesCatalogStoreState);
  }

  get warehousesCatalogLoadingState$(): Observable<IHttpRequestState> {
    return this.advertsStore.select(warehousesCatalogSelectors.getWarehousesCatalogLoadingState);
  }

  get warehousesCatalogItemLoadingState$(): Observable<IHttpRequestState> {
    return this.advertsStore.select(warehousesCatalogSelectors.getWarehousesCatalogItemLoadingState);
  }

  public getWarehousesCatalog(params: IWarehousesCatalogApiParams): void {
    this.advertsStore.dispatch(warehousesCatalogActions.getWarehousesCatalog({ params }));
  }

  public getWarehousesCatalogItem(id: string): void {
    this.advertsStore.dispatch(warehousesCatalogActions.getWarehousesCatalogItem({ id }));
  }

  public resetWarehousesCatalog(): void {
    this.advertsStore.dispatch(warehousesCatalogActions.resetWarehousesCatalog());
  }

  public resetWarehousesCatalogItem(): void {
    this.advertsStore.dispatch(warehousesCatalogActions.resetWarehousesCatalogItem());
  }

  public resetWarehousesCatalogState(): void {
    this.advertsStore.dispatch(warehousesCatalogActions.resetWarehousesCatalogState());
  }
}
