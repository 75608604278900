<div class="input-counter">
  <div class="input-counter__input">
    <ng-content></ng-content>
  </div>
  <div class="input-counter__buttons">
    <tlp-button
      class="input-counter__button"
      size="small"
      fontSize="small"
      [class.input-counter__button--error]="hasErrors"
      [class.input-counter__button--disabled]="disabled"
      [isIconButton]="true"
      [disabled]="disabled"
      (click)="changeAmount(false)"
    >
      <svg-icon-sprite src="./assets/sprites/sprite.svg#ic-remove" width="10px" height="16px" class="input-counter__icon"></svg-icon-sprite>
    </tlp-button>
    <tlp-button
      class="input-counter__button"
      size="small"
      fontSize="small"
      [class.input-counter__button--error]="hasErrors"
      [class.input-counter__button--disabled]="disabled"
      [isIconButton]="true"
      [disabled]="disabled"
      (click)="changeAmount(true)"
    >
      <svg-icon-sprite
        src="./assets/sprites/sprite.svg#ic-add-small"
        width="10px"
        height="12px"
        viewBox="0 0 12 12"
        class="input-counter__icon"
      ></svg-icon-sprite>
    </tlp-button>
  </div>
</div>
