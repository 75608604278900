const svgSpritePath = './assets/sprites/sprite.svg#ic-flag-';

interface IPhoneCode {
  code: string;
  icon: string;
  mask?: string;
}

export const COUNTRY_PHONE_CODES: IPhoneCode[] = [
  {
    // Uzbekistan
    code: '998',
    icon: `${svgSpritePath}uz`,
    mask: '(00) 000-00-00',
  },
  {
    // Kyrgyz Republic
    code: '996',
    icon: `${svgSpritePath}kg`,
    mask: '(0000) 00-000',
  },
  {
    // Turkmenistan
    code: '993',
    icon: `${svgSpritePath}tm`,
    mask: '(00) 00-00-00',
  },
  {
    // Azerbaijan
    code: '994',
    icon: `${svgSpritePath}az`,
    mask: '(00) 000-00-00',
  },
  {
    // Ukraine
    code: '380',
    icon: `${svgSpritePath}ua`,
    mask: '(00) 000-00-00',
  },
  {
    // Moldova
    code: '373',
    icon: `${svgSpritePath}md`,
    mask: '(00) 00-00-00',
  },
  {
    // Estonia
    code: '372',
    icon: `${svgSpritePath}ee`,
    mask: '(00) 00-000',
  },
  {
    // Latvia
    code: '371',
    icon: `${svgSpritePath}lv`,
    mask: '(00) 000-000',
  },
  {
    // Liethuania
    code: '370',
    icon: `${svgSpritePath}lt`,
    mask: '(0) 000-00-00',
  },
  {
    // Armenia
    code: '374',
    icon: `${svgSpritePath}ar`,
    mask: '0000-0000',
  },
  {
    // Belarus
    code: '375',
    icon: `${svgSpritePath}blr`,
    mask: '(00) 000-00-00',
  },
  {
    // Russia
    code: '7',
    icon: `${svgSpritePath}ru`,
    mask: '(000) 000-00-00',
  },
];

export const DEFAULT_COUNTRY_CODE: string = '7';
export const DEFAULT_COUNTRY_PHONE_CODE: IPhoneCode | undefined = COUNTRY_PHONE_CODES.find(
  countryCodePhone => countryCodePhone.code === DEFAULT_COUNTRY_CODE,
);
