import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { WarehouseEffects } from './warehouse.effects';
import { WarehouseReducer } from './warehouse.reducer';
import { ApiWarehouseService } from '../../api/admin/api-warehouse.service';
import { WarehouseStoreService } from './warehouse-store.service';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('admin-warehouse', WarehouseReducer), EffectsModule.forFeature([WarehouseEffects])],
  providers: [WarehouseStoreService, ApiWarehouseService],
})
export class WarehouseStoreModule {}
