import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ICityApiParams, ICities } from '../../models/city.model';

export enum eCityActions {
  CITY_USER_GET = '[City] get user warehouse cities',
  CITY_USER_GET_SUCCESS = '[City] get user warehouse cities success',
  CITY_USER_GET_FAILURE = '[City] get user warehouse cities failure',

  CITY_GET = '[City] get all cities',
  CITY_GET_SUCCESS = '[City] get all cities success',
  CITY_GET_FAILURE = '[City] get all cities failure',

  CITY_STATE_RESET = '[City] reset state',
  CITY_LOADING_STATE_RESET = '[City] reset loading state',
}

export const getCities = createAction(eCityActions.CITY_GET, props<{ params: ICityApiParams | undefined }>());
export const getCitiesSuccess = createAction(eCityActions.CITY_GET_SUCCESS, props<{ cities: ICities }>());
export const getCitiesFailure = createAction(eCityActions.CITY_GET_FAILURE, props<{ error: HttpErrorResponse }>());

export const getUserCities = createAction(eCityActions.CITY_USER_GET, props<{ params: ICityApiParams | undefined }>());
export const getUserCitiesSuccess = createAction(eCityActions.CITY_USER_GET_SUCCESS, props<{ userCities: ICities }>());
export const getUserCitiesFailure = createAction(eCityActions.CITY_USER_GET_FAILURE, props<{ error: HttpErrorResponse }>());

export const resetCityState = createAction(eCityActions.CITY_STATE_RESET);
export const resetCityLoadingState = createAction(eCityActions.CITY_LOADING_STATE_RESET);
