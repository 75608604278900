<label class="checkbox-container"
       [class.checkbox-container--with-wrap]="allowTextWrap"
       [class.checkbox-container--disabled]="!selectable"
       [tlpTooltip]="infoTip"
       [tooltipPlacement]="infoTipPlacement"
>
  <span tabindex="0" class="checkbox" [class.checkbox--active]="isCheckboxActive">
    <span class="checkbox__icon">
      <svg
          viewBox="0 0 11 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="checkbox__icon-svg"
          [class.checkbox__icon-svg--active]="isCheckboxActive"
      >
        <path fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.25 1.76911L5.06979 6.9182C4.63874 7.34666 3.93042 7.36218 3.4796 6.95303L0.25 4.02198L1.80352 2.43321L4.2202 4.62649L8.62313 0.25L10.25 1.76911Z "
              stroke="none"/>
      </svg>
    </span>
    <input
      type="checkbox"
      class="checkbox__value"
      [attr.aria-label]="label"
      [checked]="isCheckboxActive"
      [value]="isCheckboxActive"
      (click)="onToggleSwitch()">
  </span>
  <span class="checkbox-label" *ngIf="label">{{ label }}</span>
</label>