import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IAuth, ICredentials } from '../../models/auth.model';

export enum eLoginActions {
  LOGIN = '[Login] login action',
  LOGIN_SUCCESS = '[Login] login success',
  LOGIN_FAILURE = '[Login] login failure',
  LOGIN_STATE_RESET = '[Login] login state reset',
}

export const login = createAction(eLoginActions.LOGIN, props<ICredentials>());
export const loginSuccess = createAction(eLoginActions.LOGIN_SUCCESS, props<{ authData: IAuth }>());
export const loginFailure = createAction(eLoginActions.LOGIN_FAILURE, props<{ error: HttpErrorResponse }>());
export const resetLoginState = createAction(eLoginActions.LOGIN_STATE_RESET);
