import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { MainLayoutComponent } from './component/main-layout/main-layout.component';

@NgModule({
  declarations: [MainLayoutComponent],
  imports: [CommonModule, IconSpriteModule],
  exports: [MainLayoutComponent],
})
export class MainLayoutModule {}
