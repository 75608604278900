<input
  type="text"
  class="input-field"
  *ngIf="formControl"
  [ngClass]="['input-field--' + theme]"
  [class.input-field--error]="isError"
  [class.input-field--disabled]="isDisabled"
  [formControl]="formControl"
  [name]="formControlName"
  [placeholder]="placeholder"
  [mask]="mask"
  [separatorLimit]="separatorLimit"
  [allowNegativeNumbers]="allowNegativeNumbers"
  [dropSpecialCharacters]="false"
  [required]="required"
  [autocomplete]="autocomplete"
  (input)="onValueChange($event)"
  (blur)="onValueChange($event)"
/>
