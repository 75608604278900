import { ICities } from '../../models/city.model';
import { IHttpRequestState } from '../../models/http.model';

export interface ICityStoreState {
  cities: ICities | null;
  userCities: ICities | null;
  loadingState: IHttpRequestState | null;
}

export const InitCityState: ICityStoreState = {
  cities: null,
  userCities: null,
  loadingState: null,
};
