import { Action, createReducer, on } from '@ngrx/store';
import { IBookingRequestsLoadingState, IBookingStoreState, InitBookingState } from './booking.state';
import * as bookingsActions from './booking.actions';

const bookingReducer = createReducer(
  InitBookingState,
  on(bookingsActions.getBookings, state => ({ ...state, bookingsLoadingState: { isLoading: true, isDidLoad: false, error: null } })),
  on(bookingsActions.getBookingsSuccess, (state, { bookings }) => ({
    ...state,
    bookings,
    bookingsLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(bookingsActions.getBookingsFailure, (state, { error }) => ({
    ...state,
    bookingsLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(bookingsActions.getBooking, state => ({ ...state, bookingLoadingState: { isLoading: true, isDidLoad: false, error: null } })),
  on(bookingsActions.getBookingSuccess, (state, { booking }) => ({
    ...state,
    booking,
    bookingLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(bookingsActions.getBookingFailure, (state, { error }) => ({
    ...state,
    bookingLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(bookingsActions.getBookingGeneral, state => ({ ...state, bookingLoadingState: { isLoading: true, isDidLoad: false, error: null } })),
  on(bookingsActions.getBookingGeneralSuccess, (state, { bookingGeneral }) => ({
    ...state,
    bookingGeneral,
    bookingLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(bookingsActions.getBookingGeneralFailure, (state, { error }) => ({
    ...state,
    bookingLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(bookingsActions.getBookingStorageRequest, state => ({
    ...state,
    bookingRequestsLoadingState: {
      ...state.bookingRequestsLoadingState,
      storageRequestState: { isLoading: true, isDidLoad: false, error: null },
    } as IBookingRequestsLoadingState,
  })),
  on(bookingsActions.getBookingStorageRequestSuccess, (state, { bookingStorageRequest }) => ({
    ...state,
    bookingStorageRequest,
    bookingRequestsLoadingState: {
      ...state.bookingRequestsLoadingState,
      storageRequestState: { isLoading: false, isDidLoad: true, error: null },
    } as IBookingRequestsLoadingState,
  })),
  on(bookingsActions.getBookingStorageRequestFailure, (state, { error }) => ({
    ...state,
    bookingRequestsLoadingState: {
      ...state.bookingRequestsLoadingState,
      storageRequestState: { isLoading: false, isDidLoad: false, error },
    } as IBookingRequestsLoadingState,
  })),

  on(bookingsActions.getBookingShipmentRequest, state => ({
    ...state,
    bookingRequestsLoadingState: {
      ...state.bookingRequestsLoadingState,
      shipmentRequestState: { isLoading: true, isDidLoad: false, error: null },
    } as IBookingRequestsLoadingState,
  })),
  on(bookingsActions.getBookingShipmentRequestSuccess, (state, { bookingShipmentRequest }) => ({
    ...state,
    bookingShipmentRequest,
    bookingRequestsLoadingState: {
      ...state.bookingRequestsLoadingState,
      shipmentRequestState: { isLoading: false, isDidLoad: true, error: null },
    } as IBookingRequestsLoadingState,
  })),
  on(bookingsActions.getBookingShipmentRequestFailure, (state, { error }) => ({
    ...state,
    bookingRequestsLoadingState: {
      ...state.bookingRequestsLoadingState,
      shipmentRequestState: { isLoading: false, isDidLoad: false, error },
    } as IBookingRequestsLoadingState,
  })),

  on(bookingsActions.getBookingDocuments, state => ({ ...state, bookingLoadingState: { isLoading: true, isDidLoad: false, error: null } })),
  on(bookingsActions.getBookingDocumentsSuccess, (state, { bookingDocuments }) => ({
    ...state,
    bookingDocuments,
    bookingLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(bookingsActions.getBookingDocumentsFailure, (state, { error }) => ({
    ...state,
    bookingLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(bookingsActions.uploadBookingDocument, state => ({
    ...state,
    bookingUpdatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(bookingsActions.uploadBookingDocumentSuccess, state => ({
    ...state,
    bookingUpdatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(bookingsActions.uploadBookingDocumentFailure, (state, { error }) => ({
    ...state,
    bookingUpdatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(bookingsActions.deleteBookingDocument, state => ({
    ...state,
    bookingUpdatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(bookingsActions.deleteBookingDocumentSuccess, state => ({
    ...state,
    bookingUpdatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(bookingsActions.deleteBookingDocumentFailure, (state, { error }) => ({
    ...state,
    bookingUpdatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(bookingsActions.cancelBooking, state => ({
    ...state,
    bookingUpdatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(bookingsActions.cancelBookingSuccess, state => ({
    ...state,
    bookingUpdatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(bookingsActions.cancelBookingFailure, (state, { error }) => ({
    ...state,
    bookingUpdatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(bookingsActions.completeBooking, state => ({
    ...state,
    bookingUpdatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(bookingsActions.completeBookingSuccess, state => ({
    ...state,
    bookingUpdatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(bookingsActions.completeBookingFailure, (state, { error }) => ({
    ...state,
    bookingUpdatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(bookingsActions.resetBookings, state => ({
    ...state,
    bookings: null,
  })),
  on(bookingsActions.resetBooking, state => ({
    ...state,
    booking: null,
    bookingGeneral: null,
    bookingStorageRequest: null,
    bookingShipmentRequest: null,
    bookingDocuments: null,
    bookingLoadingState: null,
    bookingRequestsLoadingState: null,
    bookingUpdatingState: null,
  })),
  on(bookingsActions.resetBookingsLoadingState, state => ({
    ...state,
    bookingsLoadingState: null,
  })),
  on(bookingsActions.resetBookingLoadingState, state => ({
    ...state,
    bookingLoadingState: null,
  })),
  on(bookingsActions.resetBookingUpdatingState, state => ({
    ...state,
    bookingUpdatingState: null,
  })),
  on(bookingsActions.resetBookingState, () => ({ ...InitBookingState })),
);

export const BookingReducer = (state: IBookingStoreState, action: Action) => {
  return bookingReducer(state, action);
};
