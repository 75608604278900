import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { InputLwhComponent } from './component/input-lwh/input-lwh.component';

@NgModule({
  declarations: [InputLwhComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgxMaskModule],
  exports: [InputLwhComponent],
})
export class InputLwhModule {}
