import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractApiMethodsService } from './abstract-api-methods.service';
import {
  IOrganizationBankInfo,
  IOrganizationGeneralInfo,
  IOrganizationInfo,
  IUpsertOrganizationResponse,
} from '../models/organization.model';

@Injectable({ providedIn: 'root' })
export class ApiOrganizationService extends AbstractApiMethodsService {
  public checkOrganizationInn(inn: string): Observable<IOrganizationGeneralInfo> {
    return this.httpPost({ inn }, 'utils/get-organization-by-inn');
  }

  public checkOrganizationBik(bank_bik: string): Observable<IOrganizationBankInfo | IOrganizationBankInfo[]> {
    return this.httpPost({ bank_bik }, 'utils/bik-bank-info');
  }

  public createOrganization(info: IOrganizationInfo): Observable<IUpsertOrganizationResponse> {
    return this.httpPost(info, 'organization/create');
  }

  public publishOrganization(): Observable<boolean> {
    return this.httpPost({}, 'organization/my/publish');
  }

  public updateOrganizationRequisites(info: IOrganizationInfo): Observable<IUpsertOrganizationResponse> {
    return this.httpPost(info, 'organization/my/update');
  }

  public uploadOrganizationDocument(formData: FormData, documentType: string): Observable<boolean> {
    return this.httpPost(formData, `organization/my/upload-document/${documentType}`, true);
  }

  public deleteOrganizationDocument(documentType: string): Observable<boolean> {
    return this.httpPost({}, `organization/my/delete-document/${documentType}`);
  }

  public getCurrentOrganization(): Observable<IOrganizationInfo> {
    return this.httpPost({}, `organization/my/view`);
  }
}
