import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { IDocument } from '../../models/document.model';
import {
  IOrganization,
  IOrganizationDocuments,
  IOrganizationInfo,
  IOrganizationRejectInfo,
  IOrganizations,
  IOrganizationsApiParams,
} from '../../models/organization.model';

export enum eOrganizationActions {
  ORGANIZATIONS_GET = '[Admin: Organization] get organizations',
  ORGANIZATIONS_GET_SUCCESS = '[Admin: Organization] get organizations success',
  ORGANIZATIONS_GET_FAILURE = '[Admin: Organization] get organizations failure',

  ORGANIZATION_GET = '[Admin: Organization] get organization',
  ORGANIZATION_GET_SUCCESS = '[Admin: Organization] get organization success',
  ORGANIZATION_GET_FAILURE = '[Admin: Organization] get organization failure',

  ORGANIZATION_GENERAL_GET = '[Admin: Organization] get organization general info',
  ORGANIZATION_GENERAL_GET_SUCCESS = '[Admin: Organization] get organization general info success',
  ORGANIZATION_GENERAL_GET_FAILURE = '[Admin: Organization] get organization general info failure',

  ORGANIZATION_DOCUMENTS_GET = '[Admin: Organization] get organization documents',
  ORGANIZATION_DOCUMENTS_GET_SUCCESS = '[Admin: Organization] get organization documents success',
  ORGANIZATION_DOCUMENTS_GET_FAILURE = '[Admin: Organization] get organization documents failure',

  ORGANIZATION_DOCUMENTS_ARCHIVE_GET = '[Admin: Organization] get organization documents archive',
  ORGANIZATION_DOCUMENTS_ARCHIVE_GET_SUCCESS = '[Admin: Organization] get organization documents archive success',
  ORGANIZATION_DOCUMENTS_ARCHIVE_GET_FAILURE = '[Admin: Organization] get organization documents archive failure',

  ORGANIZATION_CREATE = '[Admin: Organization] create organization',
  ORGANIZATION_CREATE_SUCCESS = '[Admin: Organization] create organization success',
  ORGANIZATION_CREATE_FAILURE = '[Admin: Organization] create organization failure',

  ORGANIZATION_SET_CREATED_ID = '[Admin: Organization] set created organization id',

  ORGANIZATION_PUBLISH = '[Admin: Organization] publish organization',
  ORGANIZATION_PUBLISH_SUCCESS = '[Admin: Organization] publish organization success',
  ORGANIZATION_PUBLISH_FAILURE = '[Admin: Organization] publish organization failure',

  ORGANIZATION_VERIFY = '[Admin: Organization] verify organization',
  ORGANIZATION_VERIFY_SUCCESS = '[Admin: Organization] verify organization success',
  ORGANIZATION_VERIFY_FAILURE = '[Admin: Organization] verify organization failure',

  ORGANIZATION_REJECT = '[Admin: Organization] reject organization',
  ORGANIZATION_REJECT_SUCCESS = '[Admin: Organization] reject organization success',
  ORGANIZATION_REJECT_FAILURE = '[Admin: Organization] reject organization failure',

  ORGANIZATION_DELETE = '[Admin: Organization] delete organization',
  ORGANIZATION_DELETE_SUCCESS = '[Admin: Organization] delete organization success',
  ORGANIZATION_DELETE_FAILURE = '[Admin: Organization] delete organization failure',

  ORGANIZATION_UPDATE_REQUISITES = '[Admin: Organization] update organization requisites',
  ORGANIZATION_UPDATE_REQUISITES_SUCCESS = '[Admin: Organization] update organization requisites success',
  ORGANIZATION_UPDATE_REQUISITES_FAILURE = '[Admin: Organization] update organization requisites failure',

  ORGANIZATION_DOCUMENT_UPLOAD = '[Admin: Organization] upload organization document',
  ORGANIZATION_DOCUMENT_UPLOAD_SUCCESS = '[Admin: Organization] upload organization document success',
  ORGANIZATION_DOCUMENT_UPLOAD_FAILURE = '[Admin: Organization] upload organization document failure',

  ORGANIZATION_DOCUMENT_DELETE = '[Admin: Organization] delete organization document',
  ORGANIZATION_DOCUMENT_DELETE_SUCCESS = '[Admin: Organization] delete organization document success',
  ORGANIZATION_DOCUMENT_DELETE_FAILURE = '[Admin: Organization] delete organization document failure',

  ORGANIZATION_FORM_GET = '[Admin: Organization] get organization form',
  ORGANIZATION_FORM_GET_SUCCESS = '[Admin: Organization] get organization form success',
  ORGANIZATION_FORM_GET_FAILURE = '[Admin: Organization] get organization form failure',

  ORGANIZATION_REPORT_GET = '[Admin: Organization] get organization report',
  ORGANIZATION_REPORT_GET_SUCCESS = '[Admin: Organization] get organization report success',
  ORGANIZATION_REPORT_GET_FAILURE = '[Admin: Organization] get organization report failure',

  ORGANIZATIONS_RESET = '[Admin: Organization] reset organizations',
  ORGANIZATION_RESET = '[Admin: Organization] reset organization',
  ORGANIZATIONS_RESET_LOADING_STATE = '[Admin: Organization] reset organizations loading state',
  ORGANIZATION_RESET_LOADING_STATE = '[Admin: Organization] reset organization loading state',
  ORGANIZATION_RESET_PUBLISHING_STATE = '[Admin: Organization] reset organization publishing state',
  ORGANIZATION_RESET_UPDATING_STATE = '[Admin: Organization] reset organization updating state',
  ORGANIZATION_RESET_STATE = '[Admin: Organization] reset organization state',
}

export const getOrganizations = createAction(eOrganizationActions.ORGANIZATIONS_GET, props<{ params: IOrganizationsApiParams }>());
export const getOrganizationsSuccess = createAction(
  eOrganizationActions.ORGANIZATIONS_GET_SUCCESS,
  props<{ organizations: IOrganizations }>(),
);
export const getOrganizationsFailure = createAction(eOrganizationActions.ORGANIZATIONS_GET_FAILURE, props<{ error: HttpErrorResponse }>());

export const getOrganization = createAction(eOrganizationActions.ORGANIZATION_GET, props<{ id: string }>());
export const getOrganizationSuccess = createAction(eOrganizationActions.ORGANIZATION_GET_SUCCESS, props<{ organization: IOrganization }>());
export const getOrganizationFailure = createAction(eOrganizationActions.ORGANIZATION_GET_FAILURE, props<{ error: HttpErrorResponse }>());

export const getOrganizationGeneral = createAction(eOrganizationActions.ORGANIZATION_GENERAL_GET, props<{ id: string }>());
export const getOrganizationGeneralSuccess = createAction(
  eOrganizationActions.ORGANIZATION_GENERAL_GET_SUCCESS,
  props<{ organizationGeneral: IOrganizationInfo }>(),
);
export const getOrganizationGeneralFailure = createAction(
  eOrganizationActions.ORGANIZATION_GENERAL_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getOrganizationDocuments = createAction(eOrganizationActions.ORGANIZATION_DOCUMENTS_GET, props<{ id: string }>());
export const getOrganizationDocumentsSuccess = createAction(
  eOrganizationActions.ORGANIZATION_DOCUMENTS_GET_SUCCESS,
  props<{ organizationDocuments: IOrganizationDocuments }>(),
);
export const getOrganizationDocumentsFailure = createAction(
  eOrganizationActions.ORGANIZATION_DOCUMENTS_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getOrganizationDocumentsArchive = createAction(
  eOrganizationActions.ORGANIZATION_DOCUMENTS_ARCHIVE_GET,
  props<{ id: string }>(),
);
export const getOrganizationDocumentsArchiveSuccess = createAction(
  eOrganizationActions.ORGANIZATION_DOCUMENTS_ARCHIVE_GET_SUCCESS,
  props<{ organizationDocumentsArchive: IDocument }>(),
);
export const getOrganizationDocumentsArchiveFailure = createAction(
  eOrganizationActions.ORGANIZATION_DOCUMENTS_ARCHIVE_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const createOrganization = createAction(eOrganizationActions.ORGANIZATION_CREATE, props<{ organizationInfo: IOrganizationInfo }>());
export const createOrganizationSuccess = createAction(eOrganizationActions.ORGANIZATION_CREATE_SUCCESS, props<{ id: string }>());
export const createOrganizationFailure = createAction(
  eOrganizationActions.ORGANIZATION_CREATE_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const setCreatedOrganizationId = createAction(eOrganizationActions.ORGANIZATION_SET_CREATED_ID, props<{ id: string }>());

export const publishOrganization = createAction(eOrganizationActions.ORGANIZATION_PUBLISH, props<{ id: string }>());
export const publishOrganizationSuccess = createAction(eOrganizationActions.ORGANIZATION_PUBLISH_SUCCESS);
export const publishOrganizationFailure = createAction(
  eOrganizationActions.ORGANIZATION_PUBLISH_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const verifyOrganization = createAction(eOrganizationActions.ORGANIZATION_VERIFY, props<{ id: string }>());
export const verifyOrganizationSuccess = createAction(eOrganizationActions.ORGANIZATION_VERIFY_SUCCESS);
export const verifyOrganizationFailure = createAction(
  eOrganizationActions.ORGANIZATION_VERIFY_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const rejectOrganization = createAction(
  eOrganizationActions.ORGANIZATION_REJECT,
  props<{ id: string; rejectInfo: IOrganizationRejectInfo }>(),
);
export const rejectOrganizationSuccess = createAction(eOrganizationActions.ORGANIZATION_REJECT_SUCCESS);
export const rejectOrganizationFailure = createAction(
  eOrganizationActions.ORGANIZATION_REJECT_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const deleteOrganization = createAction(
  eOrganizationActions.ORGANIZATION_DELETE,
  props<{ id: string; params: IOrganizationsApiParams }>(),
);
export const deleteOrganizationSuccess = createAction(eOrganizationActions.ORGANIZATION_DELETE_SUCCESS);
export const deleteOrganizationFailure = createAction(
  eOrganizationActions.ORGANIZATION_DELETE_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const updateOrganizationRequisites = createAction(
  eOrganizationActions.ORGANIZATION_UPDATE_REQUISITES,
  props<{ id: string; organizationInfo: IOrganizationInfo }>(),
);
export const updateOrganizationRequisitesSuccess = createAction(eOrganizationActions.ORGANIZATION_UPDATE_REQUISITES_SUCCESS);
export const updateOrganizationRequisitesFailure = createAction(
  eOrganizationActions.ORGANIZATION_UPDATE_REQUISITES_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const uploadOrganizationDocument = createAction(
  eOrganizationActions.ORGANIZATION_DOCUMENT_UPLOAD,
  props<{ file: File; id: string; documentType: string }>(),
);
export const uploadOrganizationDocumentSuccess = createAction(eOrganizationActions.ORGANIZATION_DOCUMENT_UPLOAD_SUCCESS);
export const uploadOrganizationDocumentFailure = createAction(
  eOrganizationActions.ORGANIZATION_DOCUMENT_UPLOAD_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const deleteOrganizationDocument = createAction(
  eOrganizationActions.ORGANIZATION_DOCUMENT_DELETE,
  props<{ id: string; documentType: string }>(),
);
export const deleteOrganizationDocumentSuccess = createAction(eOrganizationActions.ORGANIZATION_DOCUMENT_DELETE_SUCCESS);
export const deleteOrganizationDocumentFailure = createAction(
  eOrganizationActions.ORGANIZATION_DOCUMENT_DELETE_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getOrganizationForm = createAction(eOrganizationActions.ORGANIZATION_FORM_GET, props<{ id: string }>());
export const getOrganizationFormSuccess = createAction(eOrganizationActions.ORGANIZATION_FORM_GET_SUCCESS);
export const getOrganizationFormFailure = createAction(
  eOrganizationActions.ORGANIZATION_FORM_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getOrganizationReport = createAction(eOrganizationActions.ORGANIZATION_REPORT_GET, props<{ id: string }>());
export const getOrganizationReportSuccess = createAction(eOrganizationActions.ORGANIZATION_REPORT_GET_SUCCESS);
export const getOrganizationReportFailure = createAction(
  eOrganizationActions.ORGANIZATION_REPORT_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const resetOrganizations = createAction(eOrganizationActions.ORGANIZATIONS_RESET);
export const resetOrganization = createAction(eOrganizationActions.ORGANIZATION_RESET);
export const resetOrganizationsLoadingState = createAction(eOrganizationActions.ORGANIZATIONS_RESET_LOADING_STATE);
export const resetOrganizationLoadingState = createAction(eOrganizationActions.ORGANIZATION_RESET_LOADING_STATE);
export const resetOrganizationPublishingState = createAction(eOrganizationActions.ORGANIZATION_RESET_PUBLISHING_STATE);
export const resetOrganizationUpdatingState = createAction(eOrganizationActions.ORGANIZATION_RESET_UPDATING_STATE);
export const resetOrganizationState = createAction(eOrganizationActions.ORGANIZATION_RESET_STATE);
