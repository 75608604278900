export interface ILogoutState {
  isLoading: boolean;
  isDidLoad: boolean;
  error: Error | null;
}

export const InitLogoutState: ILogoutState = {
  isLoading: false,
  isDidLoad: false,
  error: null,
};
