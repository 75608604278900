import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CityStoreService } from '../../../../_core/stores/city/city-store.service';
import { MapLiveSearchDelegateHelperService } from './map-live-search-delegate-helper.service';

import { ICities, ICityApiParams } from '../../../../_core/models/city.model';
import { IHttpRequestState } from '../../../../_core/models/http.model';
import { ILiveSearchDelegate, ILiveSearchSelectableItem } from '../model/live-search-delegate.model';

@Injectable({
  providedIn: 'root',
})
export class LiveSearchDelegateCitiesService implements ILiveSearchDelegate {
  public isLoad$!: Observable<boolean | undefined>;
  public searchResult$!: Observable<ILiveSearchSelectableItem[] | null>;

  constructor(private cityStoreService: CityStoreService, private mapLiveSearchDelegateHelperService: MapLiveSearchDelegateHelperService) {
    this.isLoad$ = this.cityStoreService.loadingState$.pipe(map((state: IHttpRequestState | null) => state?.isLoading));

    this.searchResult$ = this.cityStoreService.cities$.pipe(
      map((cities: ICities | null) => this.mapLiveSearchDelegateHelperService.mapValues(cities?.items)),
    );
  }

  public search(searchQuery?: string, searchParams?: object, searchId?: string[]): void {
    let apiParams: ICityApiParams = {};
    if (searchId) {
      apiParams = { ...apiParams, id: searchId };
    }
    if (searchQuery) {
      apiParams = { title: searchQuery };
    }
    if (searchParams) {
      apiParams = { ...apiParams, ...searchParams };
    }

    this.cityStoreService.getCities(apiParams);
  }

  public reset(): void {}
}
