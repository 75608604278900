import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { IPage, IPaginator } from '../../model/paginator.model';
import { PAGINATOR_MIN_SIZE } from '../../../../_core/const/pagination.const';

const defaultPaginatorVisiblePagesAmount: number = 6;

@Component({
  selector: 'tlp-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent implements OnChanges {
  @Input() public loading: boolean | null = false;
  @Input() public totalCount: number | undefined; // количество элементов массива
  @Input() public currentPage: number = 1; // активная страница
  @Input() public itemsPerPage!: number; // колличество элементов массива объектов на одну страницу (если нет paginatorSize)
  @Input() public paginatorSize: number[] = []; // передается массив (к примеру [2, 4, 6])
  @Input() public pagesAmountToShow: number = defaultPaginatorVisiblePagesAmount;
  @Output() public paginatorChanged: EventEmitter<IPaginator> = new EventEmitter<IPaginator>();

  public paginatorMinSize: number = PAGINATOR_MIN_SIZE;
  public size: number = defaultPaginatorVisiblePagesAmount;
  public pagesAmount!: number;

  public pages: IPage[] = [];
  public pagesToShow: IPage[] = [];
  public currentPageIndex!: number;
  public paginatorSizeActiveBtn: number = 0;

  private initialSize!: number;
  private middlePage!: number;
  private shift: number = 0;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.totalCount?.currentValue) {
      this.initPaginator();
    }

    if (changes.paginatorSize?.currentValue) {
      this.paginatorMinSize = changes.paginatorSize.currentValue[0];
    }
  }

  public onGoToNext(): void {
    this.onChangePages(this.pagesToShow[this.pagesToShow.length - 1].pageNumber);
  }

  public onGoToPrev(): void {
    this.onChangePages(this.pagesToShow[0].pageNumber - this.pagesAmountToShow);
  }

  public onChangePages(page: number): void {
    this.shift = page - this.pages[this.middlePage].pageNumber;
    this.pages.forEach(value => {
      value.pageNumber += this.shift;
    });
    this.currentPageIndex = this.middlePage;
    this.currentPage = this.pages[this.currentPageIndex].pageNumber;
    this.restrictPageNumbers(page);
    this.emitItems();
  }

  public onChangeItemsPerPage(index: number): void {
    this.pages = [];
    this.size = this.initialSize;
    this.itemsPerPage = this.paginatorSize![index];
    this.paginatorSizeActiveBtn = index;

    this.definePageNumbers();
    this.middlePage = Math.floor(this.size / 2);
    this.createPaginator();

    this.emitItems();
  }

  private initPaginator(): void {
    if (!this.totalCount) {
      this.pages = [];
      return;
    }

    if (!this.itemsPerPage && this.paginatorSize.length) {
      this.itemsPerPage = this.paginatorSize[0];
    }

    this.size = Math.floor(this.totalCount / this.itemsPerPage) + 1;
    this.initialSize = this.size;
    this.middlePage = Math.floor(this.size / 2);

    if (this.paginatorSize) {
      this.onChangeItemsPerPage(0);
    } else {
      this.definePageNumbers();
      this.middlePage = Math.floor(this.size / 2);
      this.createPaginator();
    }
  }

  private definePageNumbers(): void {
    if (!this.totalCount) {
      return;
    }

    this.pagesAmount = Math.ceil(this.totalCount / this.itemsPerPage);
    if (this.pagesAmount < this.size) {
      this.size = this.pagesAmount;
    }
    if (this.pagesAmount < this.currentPage) {
      this.currentPage = 1;
    }
  }

  private createPaginator(): void {
    for (let i = 1; i <= this.size; i++) {
      this.pages.push({ pageNumber: i });
    }
    this.onChangePages(this.currentPage);
  }

  private emitItems(): void {
    const changePageObject: IPaginator = {
      currentPage: this.currentPage,
      itemsPerPage: this.itemsPerPage,
    };
    this.paginatorChanged.emit(changePageObject);
  }

  private restrictPageNumbers(page: number): void {
    if (this.pages[0].pageNumber < 1) {
      this.pages.forEach((val, i) => {
        val.pageNumber = i + 1;
      });
    }
    if (this.pages[this.pages.length - 1].pageNumber > this.pagesAmount) {
      this.pages.forEach((value, index) => {
        value.pageNumber = this.pagesAmount - this.size + index + 1;
      });
    }
    this.currentPageIndex = this.pages.findIndex(value => value.pageNumber === page);
    this.currentPage = this.pages[this.currentPageIndex].pageNumber;

    if ((page - 1) % this.pagesAmountToShow === 0 && page !== this.pages[this.pages.length - 1].pageNumber) {
      this.pagesToShow = this.pages.slice(page - 1, this.pages[page + this.pagesAmountToShow] ? page + this.pagesAmountToShow : undefined);
    }
  }
}
