import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MapDelegateHelperService } from './map-delegate-helper.service';
import { CityStoreService } from '../../../_core/stores/city/city-store.service';

import { IDelegate, ISelectableItem } from '../model/delegate.model';
import { ICities } from '../../../_core/models/city.model';

@Injectable({
  providedIn: 'root',
})
export class DelegateCitiesService implements IDelegate {
  public getResult$!: Observable<ISelectableItem[]>;

  constructor(private mapDelegateHelperService: MapDelegateHelperService, private cityStoreService: CityStoreService) {}

  public get(): void {
    this.cityStoreService.getCities({});

    this.getResult$ = this.cityStoreService.cities$.pipe(
      map((cities: ICities | null) => this.mapDelegateHelperService.mapValues(cities?.items)),
    );
  }

  public reset(): void {}
}
