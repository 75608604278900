import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { CurrentUserStoreService } from '../../../_core/stores/current-user/current-user-store.service';
import { LogoutStoreService } from '../../../_core/stores/logout/logout-store.service';

import { IHttpRequestState } from '../../../_core/models/http.model';
import { IUser } from '../../../_core/models/user.model';

export type homeMenuThemes = 'dark' | 'white';

@Component({
  selector: 'tlp-home-menu',
  templateUrl: './home-menu.component.html',
  styleUrls: ['./home-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeMenuComponent implements OnInit, OnDestroy {
  @Input() public theme: homeMenuThemes = 'white';

  public currentUser$: Observable<IUser | null> = this.currentUserStoreService.currentUser$;
  public currentUser!: IUser | null;

  public loading: boolean = false;
  public isDropdownShown: boolean = false;

  private currentUserLoadingState$: Observable<IHttpRequestState | null> = this.currentUserStoreService.currentUserLoadingState$;
  private currentUserLoadingStateSub: Subscription | null = null;
  private currentUserSub: Subscription | null = null;

  private readonly currentUrl!: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private currentUserStoreService: CurrentUserStoreService,
    private logoutStoreService: LogoutStoreService,
  ) {
    this.currentUrl = this.router.routerState?.snapshot?.url;
  }

  public ngOnInit(): void {
    this.currentUserSub = this.currentUser$.subscribe(user => {
      this.currentUser = user;
      this.cdr.detectChanges();
    });

    this.currentUserLoadingStateSub = this.currentUserLoadingState$.subscribe(state => {
      this.loading = state && !state.error ? state.isLoading : false;
      this.cdr.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.currentUserLoadingStateSub?.unsubscribe();
    this.currentUserSub?.unsubscribe();
  }

  public toggleDropdown(): void {
    this.isDropdownShown = !this.isDropdownShown;
  }

  public logout(): void {
    this.logoutStoreService.logout(this.currentUrl);
  }
}
