import { IAcceptanceDocument } from '../../models/acceptance-document.model';
import { IBooking, IBookingDocuments, IBookingGeneral, IBookings } from '../../models/booking.model';
import { IHttpRequestState } from '../../models/http.model';

export interface IBookingRequestsLoadingState {
  storageRequestState: IHttpRequestState | null;
  shipmentRequestState: IHttpRequestState | null;
}

export interface IBookingStoreState {
  bookings: IBookings | null;
  booking: IBooking | null;
  createdBookingId: string | null;
  bookingGeneral: IBookingGeneral | null;
  bookingStorageRequest: IAcceptanceDocument | null;
  bookingShipmentRequest: IAcceptanceDocument | null;
  bookingDocuments: IBookingDocuments | null;
  bookingsLoadingState: IHttpRequestState | null;
  bookingLoadingState: IHttpRequestState | null;
  bookingRequestsLoadingState: IBookingRequestsLoadingState | null;
  bookingPublishingState: IHttpRequestState | null;
  bookingUpdatingState: IHttpRequestState | null;
}

export const InitBookingState: IBookingStoreState = {
  bookings: null,
  booking: null,
  createdBookingId: null,
  bookingGeneral: null,
  bookingStorageRequest: null,
  bookingShipmentRequest: null,
  bookingDocuments: null,
  bookingsLoadingState: null,
  bookingLoadingState: null,
  bookingRequestsLoadingState: null,
  bookingPublishingState: null,
  bookingUpdatingState: null,
};
