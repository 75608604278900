import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { NgxMaskModule, MaskPipe } from 'ngx-mask';

import { HomeHeaderModule } from './home-header/home-header.module';
import { MainHeaderModule } from './main-header/main-header.module';
import { MainLayoutModule } from './main-layout/main-layout.module';
import { MainNavigationModule } from './main-navigation/main-navigation.module';
import { NotificationsMenuModule } from './notifications-menu/notifications-menu.module';
import { ProfileContentLayoutModule } from './profile-content-layout/profile-content-layout.module';
import { UiModule } from './_ui/ui.module';

@NgModule({
  imports: [
    CommonModule,
    IconSpriteModule,
    NgxMaskModule,
    HomeHeaderModule,
    MainHeaderModule,
    MainLayoutModule,
    MainNavigationModule,
    NotificationsMenuModule,
    ProfileContentLayoutModule,
    UiModule,
  ],
  exports: [
    CommonModule,
    IconSpriteModule,
    NgxMaskModule,
    HomeHeaderModule,
    MainHeaderModule,
    MainLayoutModule,
    MainNavigationModule,
    NotificationsMenuModule,
    ProfileContentLayoutModule,
    UiModule,
  ],
  providers: [DatePipe, MaskPipe],
})
export class SharedModule {}
