import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IChangedField } from '../../../../_core/models/verification.model';
import { IDictionary } from '../../../../_core/models/dictionary.model';

export type changeNoteModes = 'documentChanges' | 'fieldChanges' | 'otherChanges';

@Component({
  selector: 'tlp-change-note',
  templateUrl: './change-note.component.html',
  styleUrls: ['./change-note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeNoteComponent {
  @Input() public changedNoteMode: changeNoteModes = 'fieldChanges';
  @Input() public changedNoteMessage!: string;
  @Input() public changedNoteTip!: string;
  @Input() public changedNoteMarginTop: number = 0;
  @Input() public changedNoteMaxWidth: number = 140;
  @Input() public showChanges: boolean = true;
  @Input() public changedFields: IChangedField[] | undefined = undefined;
  @Input() public changedFieldName!: string | string[];
  @Input() public relatedFieldName!: string;

  public get isFieldChanged(): boolean {
    if (!this.changedFieldName) {
      return false;
    }

    if (Array.isArray(this.changedFieldName)) {
      return this.containsAtLeastOneChangedField(this.changedFieldName);
    }

    return !!this.getChangedField(this.changedFieldName);
  }

  public get changedFieldValue(): string | null {
    const changedField: IChangedField | undefined = this.getChangedField(this.changedFieldName as string);
    let relatedField: IChangedField | undefined;

    if (!changedField) {
      return null;
    }

    const changedFieldValue: string | number | undefined = this.getChangedFieldValue(changedField.verified_value);
    let relatedFieldChanged: boolean | undefined;

    if (this.changedNoteTip && this.relatedFieldName) {
      relatedField = this.getChangedField(this.relatedFieldName as string);
      relatedFieldChanged = relatedField?.verified_value as boolean;
    }

    return changedFieldValue || (relatedFieldChanged && this.changedNoteTip) || this.changedNoteTip
      ? `Предыдущее значение: ${this.changedNoteTip || changedFieldValue}`
      : null;
  }

  private getChangedField(field: string): IChangedField | undefined {
    return this.changedFields?.find(changedField => changedField.field === field);
  }

  private getChangedFieldValue(fieldValue: object | boolean | string | number | IDictionary | undefined): string | number | undefined {
    let changedFieldValue: string | number | undefined;

    if (typeof fieldValue === 'string' || typeof fieldValue === 'number') {
      changedFieldValue = fieldValue || 'Не указано';
    } else if (fieldValue?.hasOwnProperty('system_name')) {
      changedFieldValue = (fieldValue as IDictionary).title || (fieldValue as IDictionary).title_short;
    }

    return changedFieldValue;
  }

  private containsAtLeastOneChangedField(fields: string[]): boolean {
    for (const field of fields) {
      if (this.changedFields?.find(changedField => changedField.field === field)) {
        return true;
      }
    }
    return false;
  }
}
