import { Action, createReducer, on } from '@ngrx/store';
import { InitDictionaryState, IDictionaryStoreState } from './dictionary.state';
import * as dictionaryActions from './dictionary.actions';

const dictionaryReducer = createReducer(
  InitDictionaryState,
  on(dictionaryActions.getAccountingControlSystemsDictionary, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(dictionaryActions.getAccountingControlSystemsDictionarySuccess, (state, { accountingControlSystems }) => ({
    ...state,
    accountingControlSystems,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(dictionaryActions.getAccountingControlSystemsDictionaryFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(dictionaryActions.getAccountingSystemTypeDictionary, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(dictionaryActions.getAccountingSystemTypeDictionarySuccess, (state, { accountingSystemType }) => ({
    ...state,
    accountingSystemType,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(dictionaryActions.getAccountingSystemTypeDictionaryFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(dictionaryActions.getClassificationDictionary, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(dictionaryActions.getClassificationDictionarySuccess, (state, { classification }) => ({
    ...state,
    classification,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(dictionaryActions.getClassificationDictionaryFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(dictionaryActions.getCranageTypesDictionary, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(dictionaryActions.getCranageTypesDictionarySuccess, (state, { cranageTypes }) => ({
    ...state,
    cranageTypes,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(dictionaryActions.getCranageTypesDictionaryFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(dictionaryActions.getElectricityGridTypeDictionary, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(dictionaryActions.getElectricityGridTypeDictionarySuccess, (state, { electricityGridType }) => ({
    ...state,
    electricityGridType,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(dictionaryActions.getElectricityGridTypeDictionaryFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(dictionaryActions.getFireExtSystemTypeDictionary, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(dictionaryActions.getFireExtSystemTypeDictionarySuccess, (state, { fireExtSystemType }) => ({
    ...state,
    fireExtSystemType,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(dictionaryActions.getFireExtSystemTypeDictionaryFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(dictionaryActions.getFloorCoveringDictionary, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(dictionaryActions.getFloorCoveringDictionarySuccess, (state, { floorCovering }) => ({
    ...state,
    floorCovering,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(dictionaryActions.getFloorCoveringDictionaryFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(dictionaryActions.getHeatingSystemTypeDictionary, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(dictionaryActions.getHeatingSystemTypeDictionarySuccess, (state, { heatingSystemType }) => ({
    ...state,
    heatingSystemType,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(dictionaryActions.getHeatingSystemTypeDictionaryFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(dictionaryActions.getLoadingUnloadingMethodsDictionary, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(dictionaryActions.getLoadingUnloadingMethodsDictionarySuccess, (state, { loadingUnloadingMethods }) => ({
    ...state,
    loadingUnloadingMethods,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(dictionaryActions.getLoadingUnloadingMethodsDictionaryFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(dictionaryActions.getLoadingUnloadingStructTypesDictionary, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(dictionaryActions.getLoadingUnloadingStructTypesDictionarySuccess, (state, { loadingUnloadingStructTypes }) => ({
    ...state,
    loadingUnloadingStructTypes,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(dictionaryActions.getLoadingUnloadingStructTypesDictionaryFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(dictionaryActions.getLoadingUnloadingTechTypesDictionary, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(dictionaryActions.getLoadingUnloadingTechTypesDictionarySuccess, (state, { loadingUnloadingTechTypes }) => ({
    ...state,
    loadingUnloadingTechTypes,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(dictionaryActions.getLoadingUnloadingTechTypesDictionaryFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(dictionaryActions.getOfficeFacilityTypesDictionary, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(dictionaryActions.getOfficeFacilityTypesDictionarySuccess, (state, { officeFacilityTypes }) => ({
    ...state,
    officeFacilityTypes,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(dictionaryActions.getOfficeFacilityTypesDictionaryFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(dictionaryActions.getParkingTypeDictionary, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(dictionaryActions.getParkingTypeDictionarySuccess, (state, { parkingType }) => ({
    ...state,
    parkingType,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(dictionaryActions.getParkingTypeDictionaryFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(dictionaryActions.getStorageFormatDictionary, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(dictionaryActions.getStorageFormatDictionarySuccess, (state, { storageFormat }) => ({
    ...state,
    storageFormat,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(dictionaryActions.getStorageFormatDictionaryFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(dictionaryActions.getStorageSystemDictionary, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(dictionaryActions.getStorageSystemDictionarySuccess, (state, { storageSystem }) => ({
    ...state,
    storageSystem,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(dictionaryActions.getStorageSystemDictionaryFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(dictionaryActions.getTaxationTypeDictionary, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(dictionaryActions.getTaxationTypeDictionarySuccess, (state, { taxationType }) => ({
    ...state,
    taxationType,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(dictionaryActions.getTaxationTypeDictionaryFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(dictionaryActions.getTemperatureModeDictionary, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(dictionaryActions.getTemperatureModeDictionarySuccess, (state, { temperatureMode }) => ({
    ...state,
    temperatureMode,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(dictionaryActions.getTemperatureModeDictionaryFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(dictionaryActions.getUnitDictionary, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(dictionaryActions.getUnitDictionarySuccess, (state, { unit }) => ({
    ...state,
    unit,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(dictionaryActions.getUnitDictionaryFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(dictionaryActions.getUnitDistanceDictionary, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(dictionaryActions.getUnitDistanceDictionarySuccess, (state, { unitDistance }) => ({
    ...state,
    unitDistance,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(dictionaryActions.getUnitDistanceDictionaryFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(dictionaryActions.getUnitMassDictionary, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(dictionaryActions.getUnitMassDictionarySuccess, (state, { unitMass }) => ({
    ...state,
    unitMass,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(dictionaryActions.getUnitMassDictionaryFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(dictionaryActions.getUnitSquareDictionary, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(dictionaryActions.getUnitSquareDictionarySuccess, (state, { unitSquare }) => ({
    ...state,
    unitSquare,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(dictionaryActions.getUnitSquareDictionaryFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(dictionaryActions.getUnitTemperatureDictionary, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(dictionaryActions.getUnitTemperatureDictionarySuccess, (state, { unitTemperature }) => ({
    ...state,
    unitTemperature,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(dictionaryActions.getUnitTemperatureDictionaryFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(dictionaryActions.getLegalTypeDictionary, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(dictionaryActions.getLegalTypeDictionarySuccess, (state, { legalType }) => ({
    ...state,
    legalType,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(dictionaryActions.getLegalTypeDictionaryFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(dictionaryActions.getPartnerTypeDictionary, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(dictionaryActions.getPartnerTypeDictionarySuccess, (state, { partnerType }) => ({
    ...state,
    partnerType,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(dictionaryActions.getPartnerTypeDictionaryFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(dictionaryActions.resetDictionaryState, () => ({ ...InitDictionaryState })),
  on(dictionaryActions.resetDictionaryLoadingState, state => ({
    ...state,
    loadingState: null,
  })),
);

export const DictionaryReducer = (state: IDictionaryStoreState, action: Action) => {
  return dictionaryReducer(state, action);
};
