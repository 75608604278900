import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractApiMethodsService } from './abstract-api-methods.service';
import { IAuth, IRegisterResponse, IRegisterUser } from '../models/auth.model';

@Injectable()
export class ApiRegisterService extends AbstractApiMethodsService {
  public register(user: IRegisterUser): Observable<IRegisterResponse> {
    return this.httpPost(user, 'auth/sign-up');
  }

  public confirmRegister(token: string): Observable<IAuth> {
    return this.httpPost({ token }, 'auth/sign-up-confirm');
  }

  public sendRegisterConfirmEmail(email: string): Observable<boolean> {
    return this.httpPost({ email }, 'auth/send-confirm-email');
  }
}
