import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { ButtonModule } from '../button/button.module';
import { ContentBlockModule } from '../content-block/content-block.module';
import { LoadingModule } from '../directives/loading/loading.module';
import { SelectModule } from '../select/select.module';
import { StatusNoteModule } from '../status-note/status-note.module';
import { TooltipModule } from '../directives/tooltip/tooltip.module';

import { CardComponent } from './component/card/card.component';

@NgModule({
  declarations: [CardComponent],
  imports: [
    CommonModule,
    FormsModule,
    IconSpriteModule,
    ButtonModule,
    ContentBlockModule,
    LoadingModule,
    SelectModule,
    StatusNoteModule,
    TooltipModule,
  ],
  exports: [CardComponent],
})
export class CardModule {}
