import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordCheckerComponent } from './component/password-checker/password-checker.component';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

@NgModule({
  declarations: [PasswordCheckerComponent],
  imports: [CommonModule, IconSpriteModule],
  exports: [PasswordCheckerComponent],
})
export class PasswordCheckerModule {}
