import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CurrentUserEffects } from './current-user.effects';
import { CurrentUserReducer } from './current-user.reducer';
import { ApiCurrentUserService } from '../../api/api-current-user.service';
import { CurrentUserStoreService } from './current-user-store.service';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('current-user', CurrentUserReducer), EffectsModule.forFeature([CurrentUserEffects])],
  providers: [ApiCurrentUserService, CurrentUserStoreService],
})
export class CurrentUserStoreModule {}
