<div tlpOverflowHider
     class="popup"
     [overflowElementSelector]="containerSelector"
     [unsetOverflow]="isNestedInDrawer"
     [class.popup--opened]="isOpen"
>
  <div class="popup__wrapper" tabindex="-1" role="dialog" aria-popup="true">
    <div class="popup__dialog" [style.max-width.px]="size">
      <div class="popup__content">
        <button *ngIf="!hideCloseButton" type="button" class="popup__close" (click)="close()">
          <span class="popup__close-text" *ngIf="!hideCloseButtonText">Закрыть</span>
          <svg-icon-sprite src="./assets/sprites/sprite.svg#ic-close" [width]="closeButtonSize" [height]="closeButtonSize"></svg-icon-sprite>
        </button>
        <div class="popup__header">
          <ng-content select="[heading]"></ng-content>
        </div>
        <div class="popup__body">
          <ng-content></ng-content>
        </div>
        <div class="popup__footer">
          <ng-content select="[footer]"></ng-content>
        </div>
      </div>
    </div>
  </div>
  <div class="popup__overlay" (click)="close()"></div>
</div>
