import {
  Component,
  ChangeDetectionStrategy,
  forwardRef,
  Input,
  ViewEncapsulation,
  ViewChild,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ILiveSearchDelegate, ILiveSearchParams, ILiveSearchSelectableItem } from '../delegate/model/live-search-delegate.model';
import { eLiveSearchDelegateType, LiveSearchDelegateFactoryService } from '../delegate/services/live-search-delegate-factory.service';
import { tooltipPlacements } from '../../directives/tooltip/tooltip.directive';

const DEBOUNCE_TIME_MS = 500;

@Component({
  selector: 'tlp-live-search',
  templateUrl: './live-search.component.html',
  styleUrls: ['./live-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LiveSearchComponent),
      multi: true,
    },
  ],
})
export class LiveSearchComponent implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {
  @ViewChild(NgSelectComponent) private ngSelectComponent!: NgSelectComponent;
  @Input() set delegateType(delegateType: eLiveSearchDelegateType | string) {
    this.delegate = this.liveSearchDelegateFactoryService.getLiveSearchDelegate(delegateType as eLiveSearchDelegateType);
  }
  @Input() public delegate: ILiveSearchDelegate | null = null;
  @Input() public searchParams: object | undefined = undefined;
  @Input() public searchOnInit: boolean = true;
  @Input() public searchById: boolean = false;
  @Input() public searchOnWriteValue: boolean = false;
  @Input() public items!: ILiveSearchSelectableItem[] | null;

  @Input() public multiple: boolean = false;
  @Input() public concatenatedSelections: boolean = false;
  @Input() public required: boolean = false;
  @Input() public selectable: boolean = true;
  @Input() public clearable: boolean = false;

  @Input() public infoTip: string | null = null;
  @Input() public infoTipPlacement: tooltipPlacements = 'follow';
  @Input() public placeholder: string = '';
  @Input() public notFoundText: string = 'Нет вариантов';
  @Input() public loadingText: string = 'Загрузка...';
  @Input() public autoselect: boolean = false;
  @Input() public closeOnSelect: boolean = true;
  @Input() public isDropdownListTemplate: boolean = false;

  @Output() public itemSelected: EventEmitter<ILiveSearchSelectableItem | ILiveSearchSelectableItem[]> = new EventEmitter<
    ILiveSearchSelectableItem | ILiveSearchSelectableItem[]
  >();

  public formControl!: FormControl | null;
  public value: string | number | boolean | Array<string | number | boolean | undefined> | undefined = undefined;
  public concatenatedLabel: string | null = null;
  public loading: boolean = false;

  private onChange: (_: string | number | boolean | Array<string | number | boolean | undefined> | undefined) => void;
  private onTouched: () => void;

  private search$$: Subject<ILiveSearchParams> = new Subject<ILiveSearchParams>();
  private searchSub: Subscription | undefined = undefined;
  private delegateSub: Subscription | undefined = undefined;
  private delegateLoadingSub: Subscription | undefined = undefined;

  constructor(private liveSearchDelegateFactoryService: LiveSearchDelegateFactoryService) {
    this.onChange = (_: string | number | boolean | Array<string | number | boolean | undefined> | undefined) => {};
    this.onTouched = () => {};
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchParams?.currentValue) {
      this.updateParamsAndSearch(changes.searchParams.currentValue);
    }
  }

  public ngOnInit(): void {
    this.formControl = new FormControl(this.multiple ? [] : null);
    this.searchSub = this.search$$.pipe(debounceTime(DEBOUNCE_TIME_MS), distinctUntilChanged()).subscribe(params => {
      this.delegate?.search(params.searchQuery, params.searchParams, params.searchId);
    });

    if (this.searchOnInit) {
      this.search$$.next(this.searchParams ? { searchParams: this.searchParams } : {});
    }

    this.delegateSub = this.delegate?.searchResult$.subscribe((liveSearchSelectableItems: ILiveSearchSelectableItem[] | null) => {
      this.items = liveSearchSelectableItems;

      if (liveSearchSelectableItems?.length && this.autoselect && !this.value) {
        this.formControl?.setValue(this.multiple ? [liveSearchSelectableItems[0].value] : liveSearchSelectableItems[0].value, {
          emitEvent: false,
        });
      }
    });

    this.delegateLoadingSub = this.delegate?.isLoad$.subscribe((delegateLoading: boolean | undefined) => {
      if (this.loading && !delegateLoading) {
        this.loading = false;
      }
    });
  }

  public ngOnDestroy(): void {
    this.delegate?.reset();

    this.delegateSub?.unsubscribe();
    this.delegateLoadingSub?.unsubscribe();
    this.searchSub?.unsubscribe();

    this.formControl = null;
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.formControl?.disable();
    } else {
      this.formControl?.enable();
    }
  }

  public writeValue(value: string | undefined): void {
    this.ngSelectComponent?.handleClearClick();

    if (value) {
      this.value = value;
      this.formControl?.setValue(this.value, { emitEvent: false });
      this.onChange(this.value);
      if (this.searchOnWriteValue) {
        this.updateParamsAndSearch();
      }
    }
  }

  public selectItem(items: ILiveSearchSelectableItem | ILiveSearchSelectableItem[]): void {
    if (items) {
      this.value = items ? (Array.isArray(items) ? items.map(item => item.value) : items.value) : undefined;
      if (this.multiple && this.concatenatedSelections && Array.isArray(items)) {
        this.concatenatedLabel = this.concatSelections(items);
      }
      this.onChange(this.value);

      this.onTouched();
      this.itemSelected.emit(items);
    }
  }

  public onSearch(event: { term: string; items: string[] }): void {
    this.items = null;
    this.loading = true;
    this.search$$.next({ searchQuery: event.term, searchParams: this.searchParams });
  }

  public markAsTouched(): void {
    this.onChange(this.value);
    this.onTouched();
  }

  private updateParamsAndSearch(searchParams?: ILiveSearchParams): void {
    let updatedSearchParams: ILiveSearchParams = { searchParams: searchParams || this.searchParams };

    if (this.searchById && !this.searchOnInit && this.value) {
      updatedSearchParams = {
        ...updatedSearchParams,
        searchId: this.multiple && Array.isArray(this.value) ? (this.value as string[]) : [this.value as string],
      };
    } else if (this.value && !this.searchOnInit) {
      updatedSearchParams = { ...updatedSearchParams, searchQuery: this.value as string };
    }

    this.search$$.next(updatedSearchParams);
  }

  private concatSelections(items: ILiveSearchSelectableItem[], separator: string = ', '): string {
    console.log(items);
    return items?.length === this.items?.length
      ? `${this.items?.[0].name ?? ''}-${this.items?.[items.length - 1].name ?? ''}`
      : items.map(item => item.name)?.join(separator);
  }
}
