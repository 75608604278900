import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApiLogsService } from '../../api/admin/api-logs.service';
import { ILogs, ILogsApiParams, ILogsAvailableEvent, ILogsAvailableEventsApiParams } from '../../models/logs.model';
import { eLogsActions } from './logs.actions';

@Injectable()
export class LogsEffects {
  constructor(private actions$: Actions, private apiLogsService: ApiLogsService) {}

  public getLogs$: Observable<{ type: eLogsActions; logs: ILogs | null } | { type: eLogsActions; error: HttpErrorResponse }> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(eLogsActions.LOGS_GET),
        switchMap((payload: { type: string; params: ILogsApiParams }) =>
          this.apiLogsService.getLogs(payload.params).pipe(
            map(logs => ({ type: eLogsActions.LOGS_GET_SUCCESS, logs })),
            catchError((err: HttpErrorResponse) => {
              return of({ type: eLogsActions.LOGS_GET_FAILURE, error: err });
            }),
          ),
        ),
      ),
  );

  public getLogsAvailableEvents$: Observable<
    { type: eLogsActions; availableEvents: ILogsAvailableEvent[] | null } | { type: eLogsActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eLogsActions.LOGS_AVAILABLE_EVENTS_GET),
      switchMap((payload: { type: string; params: ILogsAvailableEventsApiParams }) =>
        this.apiLogsService.getLogsAvailableEvents(payload.params).pipe(
          map(availableEvents => ({ type: eLogsActions.LOGS_AVAILABLE_EVENTS_GET_SUCCESS, availableEvents })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eLogsActions.LOGS_AVAILABLE_EVENTS_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );
}
