import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IHttpRequestState } from '../../models/http.model';
import { IRegisterStoreState } from './register.state';

export const registerState = (state: IRegisterStoreState) => state;

export const getRegisterStoreState = createFeatureSelector<IRegisterStoreState>('register');

export const getRegisterLoadingState = createSelector(
  getRegisterStoreState,
  (state: IRegisterStoreState) =>
    ({
      ...state.registerLoadingState,
    } as IHttpRequestState),
);
