import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ITeam, ICurrentTeamRequestResponse, ITeamApiParams } from '../../models/team.model';

export enum eTeamActions {
  TEAM_GET = '[Team] get team',
  TEAM_GET_SUCCESS = '[Team] get team success',
  TEAM_GET_FAILURE = '[Team] get team failure',

  TEAM_REQUESTS_GET = '[Team] get team requests',
  TEAM_REQUESTS_GET_SUCCESS = '[Team] get team requests success',
  TEAM_REQUESTS_GET_FAILURE = '[Team] get team requests failure',

  TEAM_CREATE_REQUEST = '[Team] create team request',
  TEAM_CREATE_REQUEST_SUCCESS = '[Team] create team request success',
  TEAM_CREATE_REQUEST_FAILURE = '[Team] create team request failure',

  TEAM_GET_CURRENT_REQUEST = '[Team] get current team request',
  TEAM_GET_CURRENT_REQUEST_SUCCESS = '[Team] get current team request success',
  TEAM_GET_CURRENT_REQUEST_FAILURE = '[Team] get current team request failure',

  TEAM_CANCEL_CURRENT_REQUEST = '[Team] cancel current team request',
  TEAM_CANCEL_CURRENT_REQUEST_SUCCESS = '[Team] cancel current team request success',
  TEAM_CANCEL_CURRENT_REQUEST_FAILURE = '[Team] cancel current team request failure',

  TEAM_APPROVE_REQUEST = '[Team] approve request',
  TEAM_APPROVE_REQUEST_SUCCESS = '[Team] approve request success',
  TEAM_APPROVE_REQUEST_FAILURE = '[Team] approve request failure',

  TEAM_REJECT_REQUEST = '[Team] reject request',
  TEAM_REJECT_REQUEST_SUCCESS = '[Team] reject request success',
  TEAM_REJECT_REQUEST_FAILURE = '[Team] reject request failure',

  TEAM_DELETE_MEMBER = '[Team] delete member',
  TEAM_DELETE_MEMBER_SUCCESS = '[Team] delete member success',
  TEAM_DELETE_MEMBER_FAILURE = '[Team] delete member failure',

  TEAM_RESET = '[Team] reset team',
  TEAM_REQUESTS_RESET = '[Team] reset team requests',
  TEAM_RESET_STATE = '[Team] reset team state',
}

export const getTeam = createAction(eTeamActions.TEAM_GET, props<{ params: ITeamApiParams }>());
export const getTeamSuccess = createAction(eTeamActions.TEAM_GET_SUCCESS, props<{ team: ITeam }>());
export const getTeamFailure = createAction(eTeamActions.TEAM_GET_FAILURE, props<{ error: HttpErrorResponse }>());

export const getTeamRequests = createAction(eTeamActions.TEAM_REQUESTS_GET, props<{ params: ITeamApiParams }>());
export const getTeamRequestsSuccess = createAction(eTeamActions.TEAM_REQUESTS_GET_SUCCESS, props<{ teamRequests: ITeam }>());
export const getTeamRequestsFailure = createAction(eTeamActions.TEAM_REQUESTS_GET_FAILURE, props<{ error: HttpErrorResponse }>());

export const getCurrentTeamRequest = createAction(eTeamActions.TEAM_GET_CURRENT_REQUEST);
export const getCurrentTeamRequestSuccess = createAction(
  eTeamActions.TEAM_GET_CURRENT_REQUEST_SUCCESS,
  props<{ currentTeamRequestResponse: ICurrentTeamRequestResponse | null }>(),
);
export const getCurrentTeamRequestFailure = createAction(
  eTeamActions.TEAM_GET_CURRENT_REQUEST_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const createTeamRequest = createAction(eTeamActions.TEAM_CREATE_REQUEST, props<{ organizationId: string }>());
export const createTeamRequestSuccess = createAction(eTeamActions.TEAM_CREATE_REQUEST_SUCCESS);
export const createTeamRequestFailure = createAction(eTeamActions.TEAM_CREATE_REQUEST_FAILURE, props<{ error: HttpErrorResponse }>());

export const cancelCurrentTeamRequest = createAction(eTeamActions.TEAM_CANCEL_CURRENT_REQUEST);
export const cancelCurrentTeamRequestSuccess = createAction(eTeamActions.TEAM_CANCEL_CURRENT_REQUEST_SUCCESS);
export const cancelCurrentTeamRequestFailure = createAction(
  eTeamActions.TEAM_CANCEL_CURRENT_REQUEST_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const approveTeamRequest = createAction(
  eTeamActions.TEAM_APPROVE_REQUEST,
  props<{ id: string; params: { teamApiParams: ITeamApiParams; teamRequestsApiParams: ITeamApiParams } }>(),
);
export const approveTeamRequestSuccess = createAction(eTeamActions.TEAM_APPROVE_REQUEST_SUCCESS);
export const approveTeamRequestFailure = createAction(eTeamActions.TEAM_APPROVE_REQUEST_FAILURE, props<{ error: HttpErrorResponse }>());

export const rejectTeamRequest = createAction(eTeamActions.TEAM_REJECT_REQUEST, props<{ id: string; params: ITeamApiParams }>());
export const rejectTeamRequestSuccess = createAction(eTeamActions.TEAM_REJECT_REQUEST_SUCCESS);
export const rejectTeamRequestFailure = createAction(eTeamActions.TEAM_REJECT_REQUEST_FAILURE, props<{ error: HttpErrorResponse }>());

export const deleteTeamMember = createAction(eTeamActions.TEAM_DELETE_MEMBER, props<{ id: string; params: ITeamApiParams }>());
export const deleteTeamMemberSuccess = createAction(eTeamActions.TEAM_DELETE_MEMBER_SUCCESS);
export const deleteTeamMemberFailure = createAction(eTeamActions.TEAM_DELETE_MEMBER_FAILURE, props<{ error: HttpErrorResponse }>());

export const resetTeam = createAction(eTeamActions.TEAM_RESET);
export const resetTeamRequests = createAction(eTeamActions.TEAM_REQUESTS_RESET);
export const resetTeamState = createAction(eTeamActions.TEAM_RESET_STATE);
