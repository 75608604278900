<div class="input" *ngIf="formControl">
  <input
    [formControl]="formControl"
    class="input__field"
    [type]="isPasswordVisible ? 'text' : 'password'"
    [class.input__field--error]="formControl.touched && formControl.errors"
    [class.input__field--disabled]="isDisabled"
    [required]="required"
    (input)="onValueChange($event)"
    (blur)="onValueChange($event)"
    [placeholder]="placeholder"
    [autocomplete]="autocomplete"
  />
  <span
    class="input__icon"
    [tlpTooltip]="isPasswordVisible ? 'Скрыть пароль' : 'Показать пароль'"
    [class.input__icon--active]="isPasswordVisible"
    (click)="isPasswordVisible = !isPasswordVisible"
  >
    <svg-icon-sprite src="./assets/sprites/sprite.svg#ic-password-hide" width="16px" height="16px"></svg-icon-sprite>
  </span>
</div>
