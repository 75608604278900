import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IForgotStoreState } from './forgot.state';
import { IHttpRequestState } from '../../models/http.model';
import { INewPassword } from '../../models/auth.model';

import * as forgotActions from './forgot.actions';
import * as forgotSelectors from '../forgot/forgot.selectors';

@Injectable()
export class ForgotStoreService {
  constructor(private forgotStore: Store<IForgotStoreState>) {}

  get forgotStoreState$(): Observable<IForgotStoreState> {
    return this.forgotStore.select(forgotSelectors.getForgotStoreState);
  }

  get forgotLoadingState$(): Observable<IHttpRequestState | null> {
    return this.forgotStore.select(forgotSelectors.getForgotLoadingState);
  }

  public resetPassword(email: string): void {
    this.forgotStore.dispatch(forgotActions.resetPassword({ email }));
  }

  public setPassword(newPassword: INewPassword): void {
    this.forgotStore.dispatch(forgotActions.setPassword({ newPassword }));
  }

  public resetForgotState(): void {
    this.forgotStore.dispatch(forgotActions.resetForgotState());
  }
}
