import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { tooltipPlacements } from '../../../directives/tooltip/tooltip.directive';

@Component({
  selector: 'tlp-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() public selectable: boolean = true;
  @Input() public isCheckboxActive: boolean = false;

  @Input() public label!: string;
  @Input() public allowTextWrap: boolean = false;
  @Input() public infoTip: string | null = null;
  @Input() public infoTipPlacement: tooltipPlacements = 'follow';

  private onChange = (_: boolean) => {};

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(): void {}

  public writeValue(value: boolean): void {
    if (value) {
      this.isCheckboxActive = value;
      this.onChange(this.isCheckboxActive);
    }
  }

  public setDisabledState(disabled: boolean): void {
    this.selectable = !disabled;
  }

  public onToggleSwitch(): void {
    if (this.selectable) {
      this.isCheckboxActive = !this.isCheckboxActive;
      this.onChange(this.isCheckboxActive);
    }
  }
}
