import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputHolderComponent } from './input-holder/input-holder.component';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { TooltipModule } from '../directives/tooltip/tooltip.module';

@NgModule({
  declarations: [InputHolderComponent],
  imports: [CommonModule, IconSpriteModule, TooltipModule],
  exports: [InputHolderComponent],
})
export class InputHolderModule {}
