<input
  class="input-field"
  type="time"
  *ngIf="formControl"
  [formControl]="formControl"
  [placeholder]="placeholder"
  [required]="required"
  [class.input-field--error]="isError"
  [class.input-field--disabled]="isDisabled"
  (keyup)="onValueChange($event)"
  (blur)="onValueChange($event)"
/>
