import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { ButtonModule } from '../button/button.module';
import { InputCounterComponent } from './input/input-counter.component';

@NgModule({
  declarations: [InputCounterComponent],
  imports: [CommonModule, IconSpriteModule, ButtonModule],
  exports: [InputCounterComponent],
})
export class InputCounterModule {}
