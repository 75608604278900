import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { IBookingDate, IBookingDatesFilters } from '../../models/booking.model';
import { IStorageArea, IStorageAreaUpsert, IStorageAreas, IStorageAreasApiParams } from '../../models/storage-area.model';

export enum eStorageAreaActions {
  STORAGE_AREAS_GET = '[Storage Area] get storage areas',
  STORAGE_AREAS_GET_SUCCESS = '[Storage Area] get storage areas success',
  STORAGE_AREAS_GET_FAILURE = '[Storage Area] get storage areas failure',

  STORAGE_AREA_GET = '[Storage Area] get storage area',
  STORAGE_AREA_GET_SUCCESS = '[Storage Area] get storage area success',
  STORAGE_AREA_GET_FAILURE = '[Storage Area] get storage area failure',

  STORAGE_AREA_BOOKING_DATES_GET = '[Storage Area] get storage area booking dates',
  STORAGE_AREA_BOOKING_DATES_GET_SUCCESS = '[Storage Area] get storage area booking dates success',
  STORAGE_AREA_BOOKING_DATES_GET_FAILURE = '[Storage Area] get storage area booking dates failure',

  STORAGE_AREA_CREATE = '[Storage Area] create storage area',
  STORAGE_AREA_CREATE_SUCCESS = '[Storage Area] create storage area success',
  STORAGE_AREA_CREATE_FAILURE = '[Storage Area] create storage area failure',

  STORAGE_AREA_UPDATE = '[Storage Area] update storage area',
  STORAGE_AREA_UPDATE_SUCCESS = '[Storage Area] update storage area success',
  STORAGE_AREA_UPDATE_FAILURE = '[Storage Area] update storage area failure',

  STORAGE_AREA_DELETE = '[Storage Area] delete storage area',
  STORAGE_AREA_DELETE_SUCCESS = '[Storage Area] delete storage area success',
  STORAGE_AREA_DELETE_FAILURE = '[Storage Area] delete storage area failure',

  STORAGE_AREA_PHOTOS_PRELOAD = '[Storage Area] preload storage area photos',
  STORAGE_AREA_PHOTOS_PRELOAD_SUCCESS = '[Storage Area] preload storage area photos success',
  STORAGE_AREA_PHOTOS_PRELOAD_FAILURE = '[Storage Area] preload storage area photos failure',

  STORAGE_AREAS_RESET = '[Storage Area] reset storage areas',
  STORAGE_AREA_RESET = '[Storage Area] reset storage area',
  STORAGE_AREA_BOOKING_DATES_RESET = '[Admin: Storage Area] reset storage area booking dates',
  STORAGE_AREA_PRELOADED_PHOTOS_RESET = '[Storage Area] reset preloaded storage area photos',

  STORAGE_AREAS_LOADING_STATE_RESET = '[Storage Area] reset storage areas loading state',
  STORAGE_AREAS_UPDATING_STATE_RESET = '[Storage Area] reset storage areas updating state',
  STORAGE_AREA_LOADING_STATE_RESET = '[Storage Area] reset storage area loading state',
  STORAGE_AREA_BOOKING_DATES_LOADING_STATE_RESET = '[Storage Area] reset storage area booking dates loading state',
  STORAGE_AREA_PRELOADING_STATE_RESET = '[Storage Area] reset storage area preloading state',
  STORAGE_AREA_UPDATING_STATE_RESET = '[Storage Area] reset storage area updating state',
  STORAGE_AREA_STATE_RESET = '[Storage Area] reset storage area state',
}

export const getStorageAreas = createAction(eStorageAreaActions.STORAGE_AREAS_GET, props<{ params: IStorageAreasApiParams }>());
export const getStorageAreasSuccess = createAction(eStorageAreaActions.STORAGE_AREAS_GET_SUCCESS, props<{ storageAreas: IStorageAreas }>());
export const getStorageAreasFailure = createAction(eStorageAreaActions.STORAGE_AREAS_GET_FAILURE, props<{ error: HttpErrorResponse }>());

export const getStorageArea = createAction(eStorageAreaActions.STORAGE_AREA_GET, props<{ id: string }>());
export const getStorageAreaSuccess = createAction(eStorageAreaActions.STORAGE_AREA_GET_SUCCESS, props<{ storageArea: IStorageArea }>());
export const getStorageAreaFailure = createAction(eStorageAreaActions.STORAGE_AREA_GET_FAILURE, props<{ error: HttpErrorResponse }>());

export const getStorageAreaBookingDates = createAction(
  eStorageAreaActions.STORAGE_AREA_BOOKING_DATES_GET,
  props<{ bookingDatesFilters: IBookingDatesFilters; id: string }>(),
);
export const getStorageAreaBookingDatesSuccess = createAction(
  eStorageAreaActions.STORAGE_AREA_BOOKING_DATES_GET_SUCCESS,
  props<{ storageAreaBookingDates: IBookingDate[] }>(),
);
export const getStorageAreaBookingDatesFailure = createAction(
  eStorageAreaActions.STORAGE_AREA_BOOKING_DATES_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const createStorageArea = createAction(eStorageAreaActions.STORAGE_AREA_CREATE, props<{ data: IStorageAreaUpsert }>());
export const createStorageAreaSuccess = createAction(eStorageAreaActions.STORAGE_AREA_CREATE_SUCCESS, props<{ id: string }>());
export const createStorageAreaFailure = createAction(
  eStorageAreaActions.STORAGE_AREA_CREATE_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const updateStorageArea = createAction(eStorageAreaActions.STORAGE_AREA_UPDATE, props<{ data: IStorageAreaUpsert; id: string }>());
export const updateStorageAreaSuccess = createAction(eStorageAreaActions.STORAGE_AREA_UPDATE_SUCCESS, props<{ id: string }>());
export const updateStorageAreaFailure = createAction(
  eStorageAreaActions.STORAGE_AREA_UPDATE_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const deleteStorageArea = createAction(
  eStorageAreaActions.STORAGE_AREA_DELETE,
  props<{ id: string; params: IStorageAreasApiParams }>(),
);
export const deleteStorageAreaSuccess = createAction(eStorageAreaActions.STORAGE_AREA_DELETE_SUCCESS);
export const deleteStorageAreaFailure = createAction(
  eStorageAreaActions.STORAGE_AREA_DELETE_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const preloadStorageAreaPhotos = createAction(eStorageAreaActions.STORAGE_AREA_PHOTOS_PRELOAD, props<{ photos: File[] }>());
export const preloadStorageAreaPhotosSuccess = createAction(
  eStorageAreaActions.STORAGE_AREA_PHOTOS_PRELOAD_SUCCESS,
  props<{ storageAreaPreloadedPhotos: string[] }>(),
);
export const preloadStorageAreaPhotosFailure = createAction(
  eStorageAreaActions.STORAGE_AREA_PHOTOS_PRELOAD_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const resetStorageAreas = createAction(eStorageAreaActions.STORAGE_AREAS_RESET);
export const resetStorageArea = createAction(eStorageAreaActions.STORAGE_AREA_RESET);
export const resetStorageAreaBookingDates = createAction(eStorageAreaActions.STORAGE_AREA_BOOKING_DATES_RESET);
export const resetStorageAreaPreloadedPhotos = createAction(eStorageAreaActions.STORAGE_AREA_PRELOADED_PHOTOS_RESET);

export const resetStorageAreasLoadingState = createAction(eStorageAreaActions.STORAGE_AREAS_LOADING_STATE_RESET);
export const resetStorageAreasUpdatingState = createAction(eStorageAreaActions.STORAGE_AREAS_UPDATING_STATE_RESET);
export const resetStorageAreaLoadingState = createAction(eStorageAreaActions.STORAGE_AREA_LOADING_STATE_RESET);
export const resetStorageAreaBookingDatesLoadingState = createAction(eStorageAreaActions.STORAGE_AREA_BOOKING_DATES_LOADING_STATE_RESET);
export const resetStorageAreaPreloadingState = createAction(eStorageAreaActions.STORAGE_AREA_PRELOADING_STATE_RESET);
export const resetStorageAreaUpdatingState = createAction(eStorageAreaActions.STORAGE_AREA_UPDATING_STATE_RESET);

export const resetStorageAreaState = createAction(eStorageAreaActions.STORAGE_AREA_STATE_RESET);
