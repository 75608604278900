import { Injectable } from '@angular/core';
import { IDictionary, IDictionaryItems } from '../../../_core/models/dictionary.model';
import { IDelegateEntity, ISelectableItem } from '../model/delegate.model';

@Injectable({
  providedIn: 'root',
})
export class MapDelegateHelperService {
  public mapDictionary(items: IDictionaryItems | null, shortName?: boolean): ISelectableItem[] | [] {
    if (items) {
      return items.items?.map((item: IDictionary | null) => ({
        name: (shortName ? item?.title_short : item?.title) || '',
        value: item?.system_name || '',
      }));
    }
    return [];
  }

  public mapEntity(items: IDelegateEntity[] | null): ISelectableItem[] | [] {
    if (items) {
      return items.map((item: IDelegateEntity | null) => ({
        name: item?.title || '',
        value: item?.id?.toString() || '',
      }));
    }
    return [];
  }

  public mapValues(items?: string[]): ISelectableItem[] | [] {
    if (items) {
      return items.map((item: string) => ({
        name: item,
        value: item,
      }));
    }
    return [];
  }
}
