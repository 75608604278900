import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { IHttpError } from '../models/http.model';
import { HTTP_ERROR_CODES } from '../const/http-error-codes.const';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorsService {
  public handleError<T>(operation: string = 'operation', err: T): Observable<T> {
    console.error('[OPERATION]', operation, '[ERROR]', err);
    return throwError(err);
  }

  public getValidationError(errors: IHttpError | IHttpError[] | null, fieldName: string): IHttpError | null {
    if (!Array.isArray(errors) && errors?.status) {
      return errors.status && errors.status === HTTP_ERROR_CODES.validationError ? errors : null;
    }

    if (Array.isArray(errors)) {
      const error = errors?.find(httpError => httpError.field === fieldName);
      return error ? error : null;
    }

    return errors;
  }

  public getErrorMessage(errorResponse: HttpErrorResponse | null): string | undefined {
    if (!errorResponse) {
      return undefined;
    }

    if (errorResponse?.error?.message) {
      return errorResponse?.error.message;
    }

    const fieldErrors: IHttpError[] | string | null = errorResponse?.error?.error || errorResponse?.error;
    if (Array.isArray(fieldErrors) && fieldErrors?.length) {
      return fieldErrors.map(error => error.message).join('. ');
    }

    return 'Произошла ошибка при получении данных';
  }

  public isDataValidationError(error: HttpErrorResponse): boolean {
    return error && error.status === HTTP_ERROR_CODES.validationError;
  }

  public isCertainError(error: HttpErrorResponse, errors: { [K: string]: string }): boolean {
    if (!error || !errors) {
      return false;
    }

    const errorsValues: string[] = Object.values<string>(errors);
    let certainError: boolean;
    if (error.error && Array.isArray(error.error)) {
      certainError = error.error.some((fieldObject: IHttpError) => errorsValues.includes(fieldObject.field as string));
    } else {
      certainError = errorsValues.includes(error.error?.field as string);
    }

    return certainError;
  }
}
