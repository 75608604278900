import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CommonModule } from '@angular/common';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { PopupModule } from '../popup/popup.module';
import { PhotoCarouselComponent } from './component/photo-carousel/photo-carousel.component';

@NgModule({
  declarations: [PhotoCarouselComponent],
  imports: [CommonModule, CarouselModule, IconSpriteModule, PopupModule],
  exports: [PhotoCarouselComponent],
})
export class PhotoCarouselModule {}
