<div class="action-buttons">
  <div class="action-buttons__item" *ngFor="let button of buttons">
    <tlp-button
      [theme]="button.theme"
      [tlpTooltip]="button.tooltip || null"
      [iconRight]="button.iconRight"
      [iconLeft]="button.iconLeft"
      [isActive]="button.isActive"
      [isIconButton]="!!button.icon"
      [disabled]="!!button.disabled"
      [disabledVisually]="!!button.disabledVisually"
      (click)="clickButton(button)">
      {{ button.text }}
      <svg-icon-sprite *ngIf="button.icon" [src]="'./assets/sprites/sprite.svg#ic-' + button.icon" width="16px" height="16px"></svg-icon-sprite>
    </tlp-button>
  </div>
</div>
