<div class="home-header">
  <div class="home-header__head">
    <a class="home-header__logo" routerLink="/">
      <img  src="../../../../assets/images/logo-white.svg" width="120px" alt="XPlanet - агрегатор складов" />
    </a>
    <div class="home-header__menu">
      <tlp-home-menu></tlp-home-menu>
    </div>
  </div>
  <div class="home-header__body">
    <ng-content></ng-content>
  </div>
</div>