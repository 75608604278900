import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from '../card/card.module';
import { SelectModule } from '../select/select.module';
import { FormsModule } from '@angular/forms';
import { LoadingModule } from '../directives/loading/loading.module';
import { CardsListComponent } from './component/cards-list/cards-list.component';

@NgModule({
  declarations: [CardsListComponent],
  imports: [CommonModule, FormsModule, CardModule, LoadingModule, SelectModule],
  exports: [CardsListComponent],
})
export class CardsListModule {}
