import {
  Component,
  ChangeDetectionStrategy,
  Input,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  OnInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { PageActionButtonsService } from '../../../_ui/page-action-buttons/service/page-action-buttons.service';
import { LayoutService } from '../../../_core/services/layout.service';
import { IHeaderLink } from '../../models/header-link.model';

interface IHeaderHTMLElements {
  wrapperEl: HTMLElement | null;
  listEl: HTMLElement | null;
}

@Component({
  selector: 'tlp-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
  // tslint:disable-next-line: prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MainHeaderComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('mainHeaderWrapper') public mainHeaderWrapper!: ElementRef;
  @ViewChild('mainHeaderList') public mainHeaderList!: ElementRef;
  @Input() public pageTitle: string = 'Профиль';
  @Input() public links!: IHeaderLink[];
  public activeLink: IHeaderLink | undefined;

  public isPrevShow: boolean = false;
  public isNextShow: boolean = false;

  public isNotificationsShown!: boolean;
  public isPageActionButtonsConfigured!: boolean;

  constructor(private router: Router, private pageActionButtonsService: PageActionButtonsService, private layoutService: LayoutService) {
    this.isNotificationsShown = this.layoutService.isSidebarShown;
  }

  public ngOnInit(): void {
    this.router.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd)).subscribe((result: NavigationEnd) => {
      this.activeLink = this.findLink(result.urlAfterRedirects);
    });

    if (this.pageActionButtonsService.config.getValue()) {
      this.isPageActionButtonsConfigured = true;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.links) {
      this.activeLink = this.findLink(this.router?.url);
    }
  }

  public ngAfterViewInit(): void {
    this.onWrapperScroll();
  }

  public toggleNotifications(): void {
    this.layoutService.isSidebarShown = !this.layoutService.isSidebarShown;
    this.isNotificationsShown = this.layoutService.isSidebarShown;
  }

  public onWrapperScroll(): void {
    if (!this.mainHeaderWrapper || !this.mainHeaderList) {
      return;
    }

    const { wrapperEl, listEl } = this.getNativeElements();

    if (wrapperEl !== null && listEl !== null) {
      if (wrapperEl.scrollLeft === 0) {
        this.isPrevShow = false;
      } else {
        this.isPrevShow = true;
      }
      if (wrapperEl.scrollLeft === listEl.clientWidth - wrapperEl.clientWidth || listEl.clientWidth === wrapperEl.clientWidth) {
        this.isNextShow = false;
      } else {
        this.isNextShow = true;
      }
    }
  }

  public onSlideLeft(): void {
    if (!this.mainHeaderWrapper || !this.mainHeaderList) {
      return;
    }

    const { wrapperEl, listEl } = this.getNativeElements();

    if (wrapperEl !== null && listEl !== null) {
      wrapperEl.scrollLeft = 0;
    }
  }

  public onSlideRight(): void {
    if (!this.mainHeaderWrapper || !this.mainHeaderList) {
      return;
    }

    const { wrapperEl, listEl } = this.getNativeElements();

    if (wrapperEl !== null && listEl !== null) {
      wrapperEl.scrollLeft = listEl.clientWidth - wrapperEl.clientWidth;
    }
  }

  public navigate(link: string, queryParams: object | undefined): void {
    if (link) {
      this.router.navigate([link], queryParams ? { queryParams } : undefined);
    }
  }

  private getNativeElements(): IHeaderHTMLElements {
    return {
      wrapperEl: this.mainHeaderWrapper.nativeElement,
      listEl: this.mainHeaderList.nativeElement,
    };
  }

  private findLink(url: string): IHeaderLink | undefined {
    return this.links?.find(link => url?.match(link.href));
  }
}
