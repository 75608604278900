import { IHttpRequestState } from '../../models/http.model';
import { ILogsAvailableEvent, ILogs } from '../../models/logs.model';

export interface ILogsStoreState {
  logs: ILogs | null;
  availableEvents: ILogsAvailableEvent[] | null;
  loadingState: IHttpRequestState | null;
  availableEventsLoadingState: IHttpRequestState | null;
}

export const InitLogsState: ILogsStoreState = {
  logs: null,
  availableEvents: null,
  loadingState: null,
  availableEventsLoadingState: null,
};
