import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { OverflowHiderModule } from '../directives/overflow-hider/overflow-hider.module';
import { PopupComponent } from './component/popup/popup.component';

@NgModule({
  declarations: [PopupComponent],
  imports: [CommonModule, IconSpriteModule, OverflowHiderModule],
  exports: [PopupComponent],
})
export class PopupModule {}
