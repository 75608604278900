import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IHttpRequestState } from '../../models/http.model';
import { IOrganizationStoreState } from './organization.state';

export const getOrganizationStoreState = createFeatureSelector<IOrganizationStoreState>('organization');

export const organization = createSelector(getOrganizationStoreState, (state: IOrganizationStoreState) => state.organization);
export const organizationBankInfo = createSelector(
  getOrganizationStoreState,
  (state: IOrganizationStoreState) => state.organizationBankInfo,
);
export const organizationShortInfo = createSelector(
  getOrganizationStoreState,
  (state: IOrganizationStoreState) => state.organizationShortInfo,
);

export const getOrganizationBankInfoIsLoading = createSelector(
  getOrganizationStoreState,
  (state: IOrganizationStoreState) => state.organizationBankInfoLoadingState?.isLoading,
);
export const getOrganizationBankInfoLoadingState = createSelector(
  getOrganizationStoreState,
  (state: IOrganizationStoreState) =>
    ({
      ...state.organizationBankInfoLoadingState,
    } as IHttpRequestState),
);
export const getOrganizationLoadingState = createSelector(
  getOrganizationStoreState,
  (state: IOrganizationStoreState) =>
    ({
      ...state.organizationLoadingState,
    } as IHttpRequestState),
);
export const getOrganizationPublishingState = createSelector(
  getOrganizationStoreState,
  (state: IOrganizationStoreState) =>
    ({
      ...state.organizationPublishingState,
    } as IHttpRequestState),
);
export const getOrganizationUpdatingState = createSelector(
  getOrganizationStoreState,
  (state: IOrganizationStoreState) =>
    ({
      ...state.organizationUpdatingState,
    } as IHttpRequestState),
);
