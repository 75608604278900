import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractApiMethodsService } from '../abstract-api-methods.service';
import { ITeam, ITeamApiParams } from '../../models/team.model';

@Injectable()
export class ApiTeamService extends AbstractApiMethodsService {
  public getTeam(params: ITeamApiParams): Observable<ITeam | null> {
    return this.httpPost(params, 'adm/team-members/index');
  }

  public getTeamRequests(params: ITeamApiParams): Observable<ITeam | null> {
    return this.httpPost(params, 'adm/team-members/request/index');
  }

  public approveTeamRequest(id: string): Observable<boolean> {
    return this.httpPost({}, `adm/team-members/request/${id}/accept`);
  }

  public rejectTeamRequest(id: string): Observable<boolean> {
    return this.httpPost({}, `adm/team-members/request/${id}/reject`);
  }

  public deleteTeamMember(id: string): Observable<ITeam | null> {
    return this.httpPost({}, `adm/team-members/${id}/delete`);
  }
}
