import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AbstractApiMethodsService } from '../abstract-api-methods.service';
import {
  IOrganization,
  IOrganizationDocuments,
  IOrganizationInfo,
  IOrganizationRejectInfo,
  IOrganizations,
  IOrganizationsApiParams,
} from '../../models/organization.model';
import { IDocument } from '../../models/document.model';

@Injectable()
export class ApiOrganizationService extends AbstractApiMethodsService {
  public getOrganizations(params: IOrganizationsApiParams): Observable<IOrganizations | null> {
    return this.httpPost(params, 'adm/organization/index');
  }

  public getOrganization(id: string): Observable<IOrganization | null> {
    return this.httpPost({}, `adm/organization/${id}/view`);
  }

  public getOrganizationGeneral(id: string): Observable<IOrganizationInfo | null> {
    return this.httpPost({}, `adm/organization/${id}/view/general`);
  }

  public getOrganizationDocuments(id: string): Observable<IOrganizationDocuments | null> {
    return this.httpPost({}, `adm/organization/${id}/view/documents`);
  }

  public getOrganizationDocumentsArchive(id: string): Observable<IDocument> {
    return this.httpPost({}, `adm/organization/${id}/view/documents-archive`);
  }

  public createOrganization(info: IOrganizationInfo): Observable<{ id: string }> {
    return this.httpPost(info, 'adm/organization/create');
  }

  public publishOrganization(id: string): Observable<{ id: string }> {
    return this.httpPost({}, `adm/organization/${id}/publish`);
  }

  public verifyOrganization(id: string): Observable<boolean> {
    return this.httpPost({}, `adm/organization/${id}/verify`);
  }

  public rejectOrganization(id: string, rejectInfo: IOrganizationRejectInfo): Observable<boolean> {
    return this.httpPost(rejectInfo, `adm/organization/${id}/reject`);
  }

  public deleteOrganization(id: string): Observable<boolean> {
    return this.httpPost({}, `adm/organization/${id}/delete`);
  }

  public updateOrganizationRequisites(id: string, info: IOrganizationInfo): Observable<{ id: string }> {
    return this.httpPost(info, `adm/organization/${id}/update`);
  }

  public uploadOrganizationDocument(formData: FormData, id: string, documentType: string): Observable<boolean> {
    return this.httpPost(formData, `adm/organization/${id}/upload-document/${documentType}`, true);
  }

  public deleteOrganizationDocument(id: string, documentType: string): Observable<boolean> {
    return this.httpPost({}, `adm/organization/${id}/delete-document/${documentType}`);
  }

  public getOrganizationForm(id: string): Observable<boolean> {
    const windowObject: Window | null = window.open(
      `${this.httpService.apiUrl}/adm/organization/${id}/download-excel-form?access-token=${localStorage.getItem('access_token')}`,
      '_blank',
    );
    setTimeout(() => windowObject?.close(), 2000);
    return of(true);
  }

  public getOrganizationReport(id: string): Observable<{ url: string }> {
    return this.httpPost({}, `adm/organization/${id}/kontur-focus-report`);
  }
}
