import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AbstractApiMethodsService } from './abstract-api-methods.service';
import { INewPassword } from '../models/auth.model';

@Injectable()
export class ApiForgotService extends AbstractApiMethodsService {
  public resetPassword(email: string): Observable<boolean | HttpErrorResponse> {
    return this.httpPost({ email }, 'auth/reset-password');
  }

  public setPassword(newPassword: INewPassword): Observable<boolean | HttpErrorResponse> {
    return this.httpPost({ ...newPassword }, 'auth/set-password');
  }
}
