import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractApiMethodsService } from './abstract-api-methods.service';

import { ICities, ICityApiParams } from '../models/city.model';

@Injectable()
export class ApiCityService extends AbstractApiMethodsService {
  public getCities(params: ICityApiParams): Observable<ICities | null> {
    return this.httpPost(params, `wh_cities/all`);
  }

  public getUserCities(params: ICityApiParams): Observable<ICities | null> {
    return this.httpPost(params, `wh_cities/my`);
  }

  public getClientCities(params: ICityApiParams): Observable<ICities | null> {
    return this.httpPost(params, `wh_cities/all`);
  }
}
