import { IHttpRequestState } from '../../models/http.model';
import { IOrganizationBankInfo, IOrganizationGeneralInfo, IOrganization } from '../../models/organization.model';

export interface IOrganizationStoreState {
  organization: IOrganization | null;
  organizationBankInfo: IOrganizationBankInfo | IOrganizationBankInfo[] | null;
  organizationShortInfo: IOrganizationGeneralInfo | null;
  organizationBankInfoLoadingState: IHttpRequestState | null;
  organizationLoadingState: IHttpRequestState | null;
  organizationPublishingState: IHttpRequestState | null;
  organizationUpdatingState: IHttpRequestState | null;
}

export const InitOrganizationState: IOrganizationStoreState = {
  organization: null,
  organizationBankInfo: null,
  organizationShortInfo: null,
  organizationBankInfoLoadingState: null,
  organizationLoadingState: null,
  organizationPublishingState: null,
  organizationUpdatingState: null,
};
