import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { ApiWarehouseService } from '../../api/admin/api-warehouse.service';
import { HttpErrorsService } from '../../api/http-errors.service';
import { WarehouseStoreService } from './warehouse-store.service';

import { IDocument } from '../../models/document.model';
import { IRejectInfo } from '../../models/verification.model';
import {
  IWarehouses,
  IWarehouseApiParams,
  IWarehouseDocuments,
  IWarehouseExternal,
  IWarehouseExternalUpsert,
  IWarehouseGeneral,
  IWarehouseGeneralUpsert,
  IWarehouseInternal,
  IWarehouseInternalUpsert,
  IWarehouseTabState,
} from '../../models/warehouse.model';
import { IWarehouseUpsertConfirmation } from '../../../warehouse/warehouse-details-upsert-view/model/warehouse-details-upsert.model';
import { eWarehouseActions } from './warehouse.actions';

@Injectable()
export class WarehouseEffects {
  constructor(
    private actions$: Actions,
    private toastrService: ToastrService,
    private apiWarehouseService: ApiWarehouseService,
    private httpErrorsService: HttpErrorsService,
    private warehouseStoreService: WarehouseStoreService,
  ) {}

  public getWarehouses$: Observable<
    { type: eWarehouseActions; warehouses: IWarehouses | null } | { type: eWarehouseActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eWarehouseActions.WAREHOUSES_GET),
      switchMap((payload: { type: string; params: IWarehouseApiParams }) =>
        this.apiWarehouseService.getWarehouses(payload.params).pipe(
          map(warehouses => ({ type: eWarehouseActions.WAREHOUSES_GET_SUCCESS, warehouses })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eWarehouseActions.WAREHOUSES_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public createWarehouse$: Observable<{ type: eWarehouseActions } | { type: eWarehouseActions; error: HttpErrorResponse }> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(eWarehouseActions.WAREHOUSE_CREATE),
        switchMap((payload: { type: string; data: IWarehouseGeneralUpsert }) =>
          this.apiWarehouseService.createWarehouse(payload.data).pipe(
            tap((response: { id: string }) => {
              this.toastrService.success('Склад успешно создан');

              this.warehouseStoreService.setCreatedWarehouseId(response.id);
              this.warehouseStoreService.getWarehouseGeneral(response.id);
            }),
            map(() => ({ type: eWarehouseActions.WAREHOUSE_CREATE_SUCCESS })),
            catchError((err: HttpErrorResponse) => {
              if (!this.httpErrorsService.isDataValidationError(err)) {
                this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
              }

              return of({ type: eWarehouseActions.WAREHOUSE_CREATE_FAILURE, error: err });
            }),
          ),
        ),
      ),
  );

  public deleteWarehouse$: Observable<{ type: eWarehouseActions } | { type: eWarehouseActions; error: HttpErrorResponse }> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(eWarehouseActions.WAREHOUSE_DELETE),
        switchMap((payload: { type: string; id: string; params: IWarehouseApiParams }) =>
          this.apiWarehouseService.deleteWarehouse(payload.id).pipe(
            tap(() => {
              this.toastrService.success('Склад успешно удален');
              this.warehouseStoreService.getWarehouses(payload.params);
            }),
            map(() => ({ type: eWarehouseActions.WAREHOUSE_DELETE_SUCCESS })),
            catchError((err: HttpErrorResponse) => {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
              return of({ type: eWarehouseActions.WAREHOUSE_DELETE_FAILURE, error: err });
            }),
          ),
        ),
      ),
  );

  public publishWarehouse$: Observable<{ type: eWarehouseActions } | { type: eWarehouseActions; error: HttpErrorResponse }> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(eWarehouseActions.WAREHOUSE_PUBLISH),
        switchMap((payload: { type: string; id: string }) =>
          this.apiWarehouseService.publishWarehouse(payload.id).pipe(
            tap(() => {
              this.toastrService.success('Склад успешно добавлен');
            }),
            map(() => ({ type: eWarehouseActions.WAREHOUSE_PUBLISH_SUCCESS })),
            catchError((err: HttpErrorResponse) => {
              if (!this.httpErrorsService.isDataValidationError(err)) {
                this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
              }
              return of({ type: eWarehouseActions.WAREHOUSE_PUBLISH_FAILURE, error: err });
            }),
          ),
        ),
      ),
  );

  public certifyWarehouse: Observable<{ type: eWarehouseActions } | { type: eWarehouseActions; error: HttpErrorResponse }> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(eWarehouseActions.WAREHOUSE_CERTIFY),
        switchMap((payload: { type: string; id: string }) =>
          this.apiWarehouseService.certifyWarehouse(payload.id).pipe(
            tap(() => {
              this.toastrService.success('Склад успешно аттестован');
              this.warehouseStoreService.getWarehouseGeneral(payload.id);
            }),
            map(() => ({ type: eWarehouseActions.WAREHOUSE_CERTIFY_SUCCESS })),
            catchError((err: HttpErrorResponse) => {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
              return of({ type: eWarehouseActions.WAREHOUSE_CERTIFY_FAILURE, error: err });
            }),
          ),
        ),
      ),
  );

  public verifyWarehouse: Observable<{ type: eWarehouseActions } | { type: eWarehouseActions; error: HttpErrorResponse }> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(eWarehouseActions.WAREHOUSE_VERIFY),
        switchMap((payload: { type: string; id: string }) =>
          this.apiWarehouseService.verifyWarehouse(payload.id).pipe(
            tap(() => {
              this.toastrService.success('Склад успешно аккредитован');
              this.warehouseStoreService.getWarehouseGeneral(payload.id);
            }),
            map(() => ({ type: eWarehouseActions.WAREHOUSE_VERIFY_SUCCESS })),
            catchError((err: HttpErrorResponse) => {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
              return of({ type: eWarehouseActions.WAREHOUSE_VERIFY_FAILURE, error: err });
            }),
          ),
        ),
      ),
  );

  public rejectCertifyWarehouse: Observable<
    { type: eWarehouseActions } | { type: eWarehouseActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eWarehouseActions.WAREHOUSE_REJECT_CERTIFY),
      switchMap((payload: { type: string; id: string; rejectInfo: IRejectInfo }) =>
        this.apiWarehouseService.rejectCertifyWarehouse(payload.id, payload.rejectInfo).pipe(
          tap(() => {
            this.toastrService.success('Аттестация склада успешно отклонена');
            this.warehouseStoreService.getWarehouseGeneral(payload.id);
          }),
          map(() => ({ type: eWarehouseActions.WAREHOUSE_REJECT_CERTIFY_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eWarehouseActions.WAREHOUSE_REJECT_CERTIFY_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public rejectVerifyWarehouse: Observable<
    { type: eWarehouseActions } | { type: eWarehouseActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eWarehouseActions.WAREHOUSE_REJECT_VERIFY),
      switchMap((payload: { type: string; id: string; rejectInfo: IRejectInfo }) =>
        this.apiWarehouseService.rejectVerifyWarehouse(payload.id, payload.rejectInfo).pipe(
          tap(() => {
            this.toastrService.success('Аккредитация склада успешно отклонена');
            this.warehouseStoreService.getWarehouseGeneral(payload.id);
          }),
          map(() => ({ type: eWarehouseActions.WAREHOUSE_REJECT_VERIFY_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eWarehouseActions.WAREHOUSE_REJECT_VERIFY_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getWarehouseTabState$: Observable<
    { type: eWarehouseActions; warehouseTabState: IWarehouseTabState | null } | { type: eWarehouseActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eWarehouseActions.WAREHOUSE_TAB_STATE_GET),
      switchMap((payload: { type: string; id: string }) =>
        this.apiWarehouseService.getWarehouseTabState(payload.id).pipe(
          map(warehouseTabState => ({ type: eWarehouseActions.WAREHOUSE_TAB_STATE_GET_SUCCESS, warehouseTabState })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eWarehouseActions.WAREHOUSE_TAB_STATE_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getWarehouseGeneral$: Observable<
    { type: eWarehouseActions; warehouseGeneral: IWarehouseGeneral | null } | { type: eWarehouseActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eWarehouseActions.WAREHOUSE_GENERAL_GET),
      switchMap((payload: { type: string; id: string }) =>
        this.apiWarehouseService.getWarehouseGeneral(payload.id).pipe(
          map(warehouseGeneral => ({ type: eWarehouseActions.WAREHOUSE_GENERAL_GET_SUCCESS, warehouseGeneral })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eWarehouseActions.WAREHOUSE_GENERAL_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public updateWarehouseGeneral$: Observable<
    { type: eWarehouseActions } | { type: eWarehouseActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eWarehouseActions.WAREHOUSE_GENERAL_UPDATE),
      switchMap((payload: { type: string; data: IWarehouseGeneralUpsert; id: string }) =>
        this.apiWarehouseService.updateWarehouseGeneral(payload.data, payload.id).pipe(
          tap((response: { id: string }) => {
            this.toastrService.success('Основные параметры склада успешно обновлены');
            this.warehouseStoreService.getWarehouseGeneral(response.id);
          }),
          map(() => ({ type: eWarehouseActions.WAREHOUSE_GENERAL_UPDATE_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(err)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            }

            return of({ type: eWarehouseActions.WAREHOUSE_GENERAL_UPDATE_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getWarehouseExternal$: Observable<
    { type: eWarehouseActions; warehouseExternal: IWarehouseExternal | null } | { type: eWarehouseActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eWarehouseActions.WAREHOUSE_EXTERNAL_GET),
      switchMap((payload: { type: string; id: string }) =>
        this.apiWarehouseService.getWarehouseExternal(payload.id).pipe(
          map(warehouseExternal => ({ type: eWarehouseActions.WAREHOUSE_EXTERNAL_GET_SUCCESS, warehouseExternal })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eWarehouseActions.WAREHOUSE_EXTERNAL_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public updateWarehouseExternal$: Observable<
    { type: eWarehouseActions } | { type: eWarehouseActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eWarehouseActions.WAREHOUSE_EXTERNAL_UPDATE),
      switchMap((payload: { type: string; data: IWarehouseExternalUpsert; id: string }) =>
        this.apiWarehouseService.updateWarehouseExternal(payload.data, payload.id).pipe(
          tap((response: { id: string }) => {
            this.toastrService.success('Внешние параметры склада успешно обновлены');
            this.warehouseStoreService.getWarehouseExternal(response.id);
          }),
          map(() => ({ type: eWarehouseActions.WAREHOUSE_EXTERNAL_UPDATE_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(err)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            }

            return of({ type: eWarehouseActions.WAREHOUSE_EXTERNAL_UPDATE_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getWarehouseInternal$: Observable<
    { type: eWarehouseActions; warehouseInternal: IWarehouseInternal | null } | { type: eWarehouseActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eWarehouseActions.WAREHOUSE_INTERNAL_GET),
      switchMap((payload: { type: string; id: string }) =>
        this.apiWarehouseService.getWarehouseInternal(payload.id).pipe(
          map(warehouseInternal => ({ type: eWarehouseActions.WAREHOUSE_INTERNAL_GET_SUCCESS, warehouseInternal })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eWarehouseActions.WAREHOUSE_INTERNAL_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public updateWarehouseInternal$: Observable<
    { type: eWarehouseActions } | { type: eWarehouseActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eWarehouseActions.WAREHOUSE_INTERNAL_UPDATE),
      switchMap((payload: { type: string; data: IWarehouseInternalUpsert; id: string }) =>
        this.apiWarehouseService.updateWarehouseInternal(payload.data, payload.id).pipe(
          tap((response: { id: string }) => {
            this.toastrService.success('Внутренние параметры склада успешно обновлены');
            this.warehouseStoreService.getWarehouseInternal(response.id);
          }),
          map(() => ({ type: eWarehouseActions.WAREHOUSE_INTERNAL_UPDATE_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(err)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            }

            return of({ type: eWarehouseActions.WAREHOUSE_INTERNAL_UPDATE_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getWarehouseDocuments$: Observable<
    { type: eWarehouseActions; warehouseDocuments: IWarehouseDocuments | null } | { type: eWarehouseActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eWarehouseActions.WAREHOUSE_DOCUMENTS_GET),
      switchMap((payload: { type: string; id: string }) =>
        this.apiWarehouseService.getWarehouseDocuments(payload.id).pipe(
          map(warehouseDocuments => ({ type: eWarehouseActions.WAREHOUSE_DOCUMENTS_GET_SUCCESS, warehouseDocuments })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eWarehouseActions.WAREHOUSE_DOCUMENTS_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getWarehouseDocumentArchive$: Observable<
    { type: eWarehouseActions } | { type: eWarehouseActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eWarehouseActions.WAREHOUSE_DOCUMENTS_ARCHIVE_GET),
      switchMap((payload: { type: string; id: string }) =>
        this.apiWarehouseService.getWarehouseDocumentsArchive(payload.id).pipe(
          tap((warehouseDocumentsArchive: IDocument) => {
            if (warehouseDocumentsArchive?.file.path) {
              window.open(warehouseDocumentsArchive?.file.path, '_blank');
            }
          }),
          map(warehouseDocumentsArchive => ({
            type: eWarehouseActions.WAREHOUSE_DOCUMENTS_ARCHIVE_GET_SUCCESS,
            warehouseDocumentsArchive,
          })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eWarehouseActions.WAREHOUSE_DOCUMENTS_ARCHIVE_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public uploadWarehouseDocument$: Observable<
    { type: eWarehouseActions } | { type: eWarehouseActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eWarehouseActions.WAREHOUSE_DOCUMENT_UPLOAD),
      map((payload: { type: string; file: File; id: string; documentType: string; changesConfirmed?: boolean }) => {
        const formData = new FormData();
        formData.append('file', payload.file);
        if (payload.changesConfirmed !== undefined) {
          formData.append('user_confirm', JSON.stringify(payload.changesConfirmed));
        }

        return { formData, id: payload.id, documentType: payload.documentType };
      }),
      switchMap((payload: { formData: FormData; id: string; documentType: string }) =>
        this.apiWarehouseService.uploadWarehouseDocument(payload.formData, payload.id, payload.documentType).pipe(
          tap(() => {
            this.toastrService.success('Документ успешно прикреплен');
          }),
          map(() => ({ type: eWarehouseActions.WAREHOUSE_DOCUMENT_UPLOAD_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(err)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            }

            return of({ type: eWarehouseActions.WAREHOUSE_DOCUMENT_UPLOAD_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public deleteWarehouseDocument$: Observable<
    { type: eWarehouseActions } | { type: eWarehouseActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eWarehouseActions.WAREHOUSE_DOCUMENT_DELETE),
      map((payload: { type: string; id: string; documentType: string; changesConfirmed?: boolean }) => {
        return { id: payload.id, documentType: payload.documentType, changesConfirmed: { user_confirm: payload.changesConfirmed } };
      }),
      switchMap((payload: { id: string; documentType: string; changesConfirmed: IWarehouseUpsertConfirmation }) =>
        this.apiWarehouseService.deleteWarehouseDocument(payload.id, payload.documentType, payload.changesConfirmed).pipe(
          tap(() => {
            this.toastrService.success('Документ успешно откреплен');
          }),
          map(() => ({ type: eWarehouseActions.WAREHOUSE_DOCUMENT_DELETE_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eWarehouseActions.WAREHOUSE_DOCUMENT_DELETE_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public preloadWarehousePhotos$: Observable<
    { type: eWarehouseActions; warehousePreloadedPhotos: string[] } | { type: eWarehouseActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eWarehouseActions.WAREHOUSE_PHOTOS_PRELOAD),
      map((payload: { type: string; photos: File[] }) => {
        const formData = new FormData();
        payload.photos.forEach(photo => {
          formData.append('file[]', photo);
        });

        return { formData };
      }),
      switchMap((payload: { formData: FormData }) =>
        this.apiWarehouseService.preloadWarehousePhotos(payload.formData).pipe(
          map(warehousePreloadedPhotos => ({ type: eWarehouseActions.WAREHOUSE_PHOTOS_PRELOAD_SUCCESS, warehousePreloadedPhotos })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eWarehouseActions.WAREHOUSE_PHOTOS_PRELOAD_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getWarehouseReport$: Observable<
    { type: eWarehouseActions } | { type: eWarehouseActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eWarehouseActions.WAREHOUSE_REPORT_GET),
      switchMap((payload: { type: string; id: string }) =>
        this.apiWarehouseService.getWarehouseReport(payload.id).pipe(
          tap((response: { url: string }) => {
            this.toastrService.success('Отчёт успешно найден');
            window.open(response.url, '_blank');
          }),
          map(() => ({ type: eWarehouseActions.WAREHOUSE_REPORT_GET_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eWarehouseActions.WAREHOUSE_REPORT_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );
}
