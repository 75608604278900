import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OrganizationEffects } from './organization.effects';
import { OrganizationReducer } from './organization.reducer';
import { ApiOrganizationService } from '../../api/api-organization.service';
import { OrganizationStoreService } from './organization-store.service';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('organization', OrganizationReducer), EffectsModule.forFeature([OrganizationEffects])],
  providers: [ApiOrganizationService, OrganizationStoreService],
})
export class OrganizationStoreModule {}
