import { IHttpRequestState } from '../../models/http.model';
import { IUser, IUsers } from '../../models/user.model';

export interface IUsersStoreState {
  user: IUser | null;
  users: IUsers | null;
  usersLoadingState: IHttpRequestState | null;
  userLoadingState: IHttpRequestState | null;
  userUpdatingState: IHttpRequestState | null;
}

export const InitUsersState: IUsersStoreState = {
  users: null,
  user: null,
  usersLoadingState: null,
  userLoadingState: null,
  userUpdatingState: null,
};
