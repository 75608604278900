<div class="label"
     [ngClass]="'label--'+labelTheme"
     [class.label--justify-flex-start]="validationIconAtStart"
>
  <div class="label__text-block">
    <span class="label__text" [innerHTML]="label"></span>
    <svg-icon-sprite
        class="label__text-block--tip"
        width="14px" height="14px"
        *ngIf="infoTip"
        [ngClass]="'label__text-block--' + (infoTipType || 'tip')"
        [tlpTooltip]="infoTip"
        [tooltipPlacement]="infoTipPlacement"
        [src]="'assets/sprites/sprite.svg#ic-' + (infoTipType || 'tip')"
    ></svg-icon-sprite>
  </div>
  <div class="label__additions">
    <ng-content select="[inputAdditions]"></ng-content>
    <div class="label__validation-icon"
         *ngIf="validationIcon && showValidationIcon"
         [ngClass]="'label__validation-icon--' + validationIcon"
         [class.label__validation-icon--error]="errorMessage"
    >
      <svg-icon-sprite
          [src]="'./assets/sprites/sprite.svg#ic-' + validationIcon"
          [width]="validationIconSize" [height]="validationIconSize"
      ></svg-icon-sprite>
    </div>
  </div>
</div>
<div class="input"
     [class.ng-invalid]="control?.errors || backendError"
     [class.ng-touched]="control?.touched"
>
  <ng-container *ngIf="errorMessagePosition === 'beforeContent'">
    <ng-container *ngTemplateOutlet="errorMessageTpl"></ng-container>
  </ng-container>
  <ng-content></ng-content>
  <ng-container *ngIf="errorMessagePosition === 'afterContent'">
    <ng-container *ngTemplateOutlet="errorMessageTpl"></ng-container>
  </ng-container>
  <ng-template #errorMessageTpl>
    <div class="input__error-message" *ngIf="errorMessage">{{ errorMessage }}</div>
  </ng-template>
</div>
