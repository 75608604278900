import { Action, createReducer, on } from '@ngrx/store';
import { InitCityState, ICityStoreState } from './city.state';
import * as cityActions from './city.actions';

const cityReducer = createReducer(
  InitCityState,

  on(cityActions.getCities, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(cityActions.getCitiesSuccess, (state, { cities }) => ({
    ...state,
    cities,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(cityActions.getCitiesFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(cityActions.getUserCities, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(cityActions.getUserCitiesSuccess, (state, { userCities }) => ({
    ...state,
    userCities,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(cityActions.getUserCitiesFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(cityActions.resetCityState, () => ({ ...InitCityState })),
  on(cityActions.resetCityLoadingState, state => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error: null },
  })),
);

export const CityReducer = (state: ICityStoreState, action: Action) => {
  return cityReducer(state, action);
};
