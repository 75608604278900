import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractApiMethodsService } from '../abstract-api-methods.service';
import { IUser, IUsersApiParams, IUsers } from '../../models/user.model';

@Injectable()
export class ApiUsersService extends AbstractApiMethodsService {
  public getUsers(params: IUsersApiParams): Observable<IUsers | null> {
    return this.httpPost(params, 'adm/user/index');
  }

  public getUser(id: string): Observable<IUser | null> {
    return this.httpPost({}, `adm/user/${id}/view`);
  }

  public createUser(data: IUser): Observable<{ id: string }> {
    return this.httpPost(data, `adm/user/create`);
  }

  public deleteUser(id: string): Observable<IUser | null> {
    return this.httpPost({}, `adm/user/${id}/delete`);
  }

  public updateUser(data: IUser, id: string): Observable<{ id: string }> {
    return this.httpPost(data, `adm/user/${id}/update`);
  }
}
