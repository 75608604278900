import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { ApiForgotService } from '../../api/api-forgot.service';
import { HttpErrorsService } from '../../api/http-errors.service';

import { INewPassword } from '../../models/auth.model';
import { eForgotActions } from './forgot.actions';

@Injectable()
export class ForgotEffects {
  constructor(
    private actions$: Actions,
    private toastrService: ToastrService,
    private apiForgotService: ApiForgotService,
    private httpErrorsService: HttpErrorsService,
  ) {}

  public resetPassword$: Observable<{ type: eForgotActions } | { type: eForgotActions; error: HttpErrorResponse }> = createEffect(() =>
    this.actions$.pipe(
      ofType(eForgotActions.FORGOT_RESET_PASSWORD),
      switchMap((payload: { type: string; email: string }) =>
        this.apiForgotService.resetPassword(payload.email).pipe(
          map(() => ({ type: eForgotActions.FORGOT_RESET_PASSWORD_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(err)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            }

            return of({ type: eForgotActions.FORGOT_RESET_PASSWORD_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public setPassword$: Observable<{ type: eForgotActions } | { type: eForgotActions; error: HttpErrorResponse }> = createEffect(() =>
    this.actions$.pipe(
      ofType(eForgotActions.FORGOT_SET_PASSWORD),
      switchMap((payload: { type: string; newPassword: INewPassword }) =>
        this.apiForgotService.setPassword(payload.newPassword).pipe(
          map(() => ({ type: eForgotActions.FORGOT_SET_PASSWORD_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(err)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            }

            return of({ type: eForgotActions.FORGOT_SET_PASSWORD_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );
}
