import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BookingEffects } from './booking.effects';
import { BookingReducer } from './booking.reducer';
import { ApiBookingService } from '../../api/admin/api-booking.service';
import { BookingStoreService } from './booking-store.service';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('admin-booking', BookingReducer), EffectsModule.forFeature([BookingEffects])],
  providers: [BookingStoreService, ApiBookingService],
})
export class BookingStoreModule {}
