import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IHttpRequestState } from '../../models/http.model';
import {
  IWarehouses,
  IWarehouseApiParams,
  IWarehouseCanEdit,
  IWarehouseDocuments,
  IWarehouseDocumentsUpsert,
  IWarehouseExternal,
  IWarehouseExternalUpsert,
  IWarehouseGeneral,
  IWarehouseGeneralUpsert,
  IWarehouseInternal,
  IWarehouseInternalUpsert,
  IWarehouseTabState,
} from '../../models/warehouse.model';
import { IWarehouseStoreState } from './warehouse.state';

import * as warehousesActions from './warehouse.actions';
import * as warehousesSelectors from './warehouse.selectors';

@Injectable()
export class WarehouseStoreService {
  constructor(private warehousesStore: Store<IWarehouseStoreState>) {}

  get warehouses$(): Observable<IWarehouses | null> {
    return this.warehousesStore.select(warehousesSelectors.getWarehouses);
  }

  get createdWarehouseId$(): Observable<string | null> {
    return this.warehousesStore.select(warehousesSelectors.getCreatedWarehouseId);
  }

  get warehouseTabState$(): Observable<IWarehouseTabState | null> {
    return this.warehousesStore.select(warehousesSelectors.getWarehouseTabState);
  }

  get warehouseCanEdit$(): Observable<IWarehouseCanEdit | null> {
    return this.warehousesStore.select(warehousesSelectors.getWarehouseCanEdit);
  }

  get warehouseGeneral$(): Observable<IWarehouseGeneral | null> {
    return this.warehousesStore.select(warehousesSelectors.getWarehouseGeneral);
  }

  get warehouseExternal$(): Observable<IWarehouseExternal | null> {
    return this.warehousesStore.select(warehousesSelectors.getWarehouseExternal);
  }

  get warehouseInternal$(): Observable<IWarehouseInternal | null> {
    return this.warehousesStore.select(warehousesSelectors.getWarehouseInternal);
  }

  get warehouseDocuments$(): Observable<IWarehouseDocuments | null> {
    return this.warehousesStore.select(warehousesSelectors.getWarehouseDocuments);
  }

  get warehousePreloadedPhotos$(): Observable<string[] | null> {
    return this.warehousesStore.select(warehousesSelectors.getWarehousePreloadedPhotos);
  }

  get warehousesStoreState$(): Observable<IWarehouseStoreState> {
    return this.warehousesStore.select(warehousesSelectors.getWarehouseStoreState);
  }

  get warehouseDetailsLoadingState$(): Observable<IHttpRequestState | null> {
    return this.warehousesStore.select(warehousesSelectors.getWarehouseDetailsLoadingState);
  }

  get warehousesLoadingState$(): Observable<IHttpRequestState | null> {
    return this.warehousesStore.select(warehousesSelectors.getWarehousesLoadingState);
  }

  get warehousesPreloadingState$(): Observable<IHttpRequestState | null> {
    return this.warehousesStore.select(warehousesSelectors.getWarehousesPreloadingState);
  }

  get warehousePublishingState$(): Observable<IHttpRequestState | null> {
    return this.warehousesStore.select(warehousesSelectors.getWarehousePublishingState);
  }

  get warehousesUpdatingState$(): Observable<IHttpRequestState | null> {
    return this.warehousesStore.select(warehousesSelectors.getWarehouseUpdatingState);
  }

  public getWarehouses(params: IWarehouseApiParams): void {
    this.warehousesStore.dispatch(warehousesActions.getWarehouses({ params }));
  }

  public createWarehouse(data: IWarehouseGeneralUpsert): void {
    this.warehousesStore.dispatch(warehousesActions.createWarehouse({ data }));
  }

  public setCreatedWarehouseId(id: string): void {
    this.warehousesStore.dispatch(warehousesActions.setCreatedWarehouseId({ id }));
  }

  public deleteWarehouse(id: string, params: IWarehouseApiParams): void {
    this.warehousesStore.dispatch(warehousesActions.deleteWarehouse({ id, params }));
  }

  public publishWarehouse(id: string): void {
    this.warehousesStore.dispatch(warehousesActions.publishWarehouse({ id }));
  }

  public getWarehouseTabState(id: string): void {
    this.warehousesStore.dispatch(warehousesActions.getWarehouseTabState({ id }));
  }

  public getWarehouseGeneral(id: string): void {
    this.warehousesStore.dispatch(warehousesActions.getWarehouseGeneral({ id }));
  }

  public updateWarehouseGeneral(data: IWarehouseGeneralUpsert, id: string): void {
    this.warehousesStore.dispatch(warehousesActions.updateWarehouseGeneral({ data, id }));
  }

  public getWarehouseExternal(id: string): void {
    this.warehousesStore.dispatch(warehousesActions.getWarehouseExternal({ id }));
  }

  public updateWarehouseExternal(data: IWarehouseExternalUpsert, id: string): void {
    this.warehousesStore.dispatch(warehousesActions.updateWarehouseExternal({ data, id }));
  }

  public getWarehouseInternal(id: string): void {
    this.warehousesStore.dispatch(warehousesActions.getWarehouseInternal({ id }));
  }

  public updateWarehouseInternal(data: IWarehouseInternalUpsert, id: string): void {
    this.warehousesStore.dispatch(warehousesActions.updateWarehouseInternal({ data, id }));
  }

  public getWarehouseDocuments(id: string): void {
    this.warehousesStore.dispatch(warehousesActions.getWarehouseDocuments({ id }));
  }

  // Метод можно использовать, если есть необходимость сабмитить форму полностью
  public updateWarehouseDocuments(data: IWarehouseDocumentsUpsert, id: string): void {
    this.warehousesStore.dispatch(warehousesActions.updateWarehouseDocuments({ data, id }));
  }

  public uploadWarehouseDocument(file: File, id: string, documentType: string, changesConfirmed?: boolean): void {
    this.warehousesStore.dispatch(warehousesActions.uploadWarehouseDocument({ file, id, documentType, changesConfirmed }));
  }

  public deleteWarehouseDocument(id: string, documentType: string, changesConfirmed?: boolean): void {
    this.warehousesStore.dispatch(warehousesActions.deleteWarehouseDocument({ id, documentType, changesConfirmed }));
  }

  public preloadWarehousePhotos(photos: File[]): void {
    this.warehousesStore.dispatch(warehousesActions.preloadWarehousePhotos({ photos }));
  }

  public resetWarehouses(): void {
    this.warehousesStore.dispatch(warehousesActions.resetWarehouses());
  }

  public resetWarehouse(): void {
    this.warehousesStore.dispatch(warehousesActions.resetWarehouse());
  }

  public resetWarehousePreloadedPhotos(): void {
    this.warehousesStore.dispatch(warehousesActions.resetWarehousePreloadedPhotos());
  }

  public resetWarehousePreloadingState(): void {
    this.warehousesStore.dispatch(warehousesActions.resetWarehousePreloadingState());
  }

  public resetWarehouseUpdatingState(): void {
    this.warehousesStore.dispatch(warehousesActions.resetWarehouseUpdatingState());
  }

  public resetWarehousesState(): void {
    this.warehousesStore.dispatch(warehousesActions.resetWarehouseState());
  }
}
