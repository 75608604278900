import { Action, createReducer, on } from '@ngrx/store';
import { InitLogsState, ILogsStoreState } from './logs.state';
import * as logsActions from './logs.actions';

const getLogsReducer = createReducer(
  InitLogsState,
  on(logsActions.getLogs, state => ({
    ...state,
    loadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(logsActions.getLogsSuccess, (state, { logs }) => ({
    ...state,
    logs,
    loadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(logsActions.getLogsFailure, (state, { error }) => ({
    ...state,
    loadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(logsActions.getLogsAvailableEvents, state => ({
    ...state,
    availableEventsLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(logsActions.getLogsAvailableEventsSuccess, (state, { availableEvents }) => ({
    ...state,
    availableEvents,
    availableEventsLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(logsActions.getLogsAvailableEventsFailure, (state, { error }) => ({
    ...state,
    availableEventsLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(logsActions.resetLogsLoadingState, state => ({
    ...state,
    loadingState: null,
  })),
  on(logsActions.resetLogsAvailableEvents, state => ({
    ...state,
    availableEvents: null,
    availableEventsLoadingState: null,
  })),
  on(logsActions.resetLogsState, () => ({ ...InitLogsState })),
);

export const LogsReducer = (state: ILogsStoreState, action: Action) => {
  return getLogsReducer(state, action);
};
