import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractApiMethodsService } from './abstract-api-methods.service';
import { IAuth, ICredentials } from '../models/auth.model';

@Injectable()
export class ApiLoginService extends AbstractApiMethodsService {
  public login(credentials: ICredentials): Observable<IAuth> {
    return this.httpPost(credentials, 'auth/sign-in');
  }
}
