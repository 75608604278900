import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { ApiBookingService } from '../../api/admin/api-booking.service';
import { BookingStoreService } from './booking-store.service';
import { HttpErrorsService } from '../../api/http-errors.service';

import { IAcceptanceDocument } from '../../models/acceptance-document.model';
import { IDocument } from '../../models/document.model';
import {
  IBooking,
  IBookingDocuments,
  IBookings,
  IBookingsApiParams,
  IBookingGeneralUpsert,
  IBookingGeneral,
} from '../../models/booking.model';
import { eBookingActions } from './booking.actions';

@Injectable()
export class BookingEffects {
  constructor(
    private actions$: Actions,
    private toastrService: ToastrService,
    private apiBookingService: ApiBookingService,
    private bookingStoreService: BookingStoreService,
    private httpErrorsService: HttpErrorsService,
  ) {}

  public getBookings$: Observable<
    { type: eBookingActions; bookings: IBookings | null } | { type: eBookingActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eBookingActions.BOOKINGS_GET),
      switchMap((payload: { type: string; params: IBookingsApiParams }) =>
        this.apiBookingService.getBookings(payload.params).pipe(
          map(bookings => ({ type: eBookingActions.BOOKINGS_GET_SUCCESS, bookings })),
          catchError((error: HttpErrorResponse) => {
            return of({ type: eBookingActions.BOOKINGS_GET_FAILURE, error });
          }),
        ),
      ),
    ),
  );

  public getBooking$: Observable<
    { type: eBookingActions; booking: IBooking | null } | { type: eBookingActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eBookingActions.BOOKING_GET),
      switchMap((payload: { type: string; id: string }) =>
        this.apiBookingService.getBooking(payload.id).pipe(
          map(booking => ({ type: eBookingActions.BOOKING_GET_SUCCESS, booking })),
          catchError((error: HttpErrorResponse) => {
            return of({ type: eBookingActions.BOOKING_GET_FAILURE, error });
          }),
        ),
      ),
    ),
  );

  public getBookingGeneral$: Observable<
    { type: eBookingActions; bookingGeneral: IBookingGeneral | null } | { type: eBookingActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eBookingActions.BOOKING_GENERAL_GET),
      switchMap((payload: { type: string; id: string }) =>
        this.apiBookingService.getBookingGeneral(payload.id).pipe(
          map(bookingGeneral => ({ type: eBookingActions.BOOKING_GENERAL_GET_SUCCESS, bookingGeneral })),
          catchError((error: HttpErrorResponse) => {
            return of({ type: eBookingActions.BOOKING_GENERAL_GET_FAILURE, error });
          }),
        ),
      ),
    ),
  );

  public getBookingStorageRequest$: Observable<
    { type: eBookingActions; bookingStorageRequest: IAcceptanceDocument | null } | { type: eBookingActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eBookingActions.BOOKING_STORAGE_REQUEST_GET),
      switchMap((payload: { type: string; id: string }) =>
        this.apiBookingService.getBookingStorageRequest(payload.id).pipe(
          map(bookingStorageRequest => ({ type: eBookingActions.BOOKING_STORAGE_REQUEST_GET_SUCCESS, bookingStorageRequest })),
          catchError((error: HttpErrorResponse) => {
            return of({ type: eBookingActions.BOOKING_STORAGE_REQUEST_GET_FAILURE, error });
          }),
        ),
      ),
    ),
  );

  public getBookingShipmentRequest$: Observable<
    { type: eBookingActions; bookingShipmentRequest: IAcceptanceDocument | null } | { type: eBookingActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eBookingActions.BOOKING_SHIPMENT_REQUEST_GET),
      switchMap((payload: { type: string; id: string }) =>
        this.apiBookingService.getBookingShipmentRequest(payload.id).pipe(
          map(bookingShipmentRequest => ({ type: eBookingActions.BOOKING_SHIPMENT_REQUEST_GET_SUCCESS, bookingShipmentRequest })),
          catchError((error: HttpErrorResponse) => {
            return of({ type: eBookingActions.BOOKING_SHIPMENT_REQUEST_GET_FAILURE, error });
          }),
        ),
      ),
    ),
  );

  public getBookingDocuments$: Observable<
    { type: eBookingActions; bookingDocuments: IBookingDocuments | null } | { type: eBookingActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eBookingActions.BOOKING_DOCUMENTS_GET),
      switchMap((payload: { id: string }) =>
        this.apiBookingService.getBookingDocuments(payload.id).pipe(
          map(bookingDocuments => ({ type: eBookingActions.BOOKING_DOCUMENTS_GET_SUCCESS, bookingDocuments })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eBookingActions.BOOKING_DOCUMENTS_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getBookingDocumentArchive$: Observable<
    { type: eBookingActions } | { type: eBookingActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eBookingActions.BOOKING_DOCUMENTS_ARCHIVE_GET),
      switchMap((payload: { type: string; id: string }) =>
        this.apiBookingService.getBookingDocumentsArchive(payload.id).pipe(
          tap((bookingDocumentsArchive: IDocument) => {
            if (bookingDocumentsArchive?.file.path) {
              window.open(bookingDocumentsArchive?.file.path, '_blank');
            }
          }),
          map(bookingDocumentsArchive => ({
            type: eBookingActions.BOOKING_DOCUMENTS_ARCHIVE_GET_SUCCESS,
            bookingDocumentsArchive,
          })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eBookingActions.BOOKING_DOCUMENTS_ARCHIVE_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public createBooking$: Observable<
    { type: eBookingActions; response: { id: string } } | { type: eBookingActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eBookingActions.BOOKING_CREATE),
      switchMap((payload: { type: string; data: IBookingGeneralUpsert; publish: boolean }) =>
        this.apiBookingService.createBooking(payload.data).pipe(
          tap((response: { id: string }) => {
            this.toastrService.success('Бронь успешно создана');
            this.bookingStoreService.setCreatedBookingId(response.id);
          }),
          map((response: { id: string }) => ({ type: eBookingActions.BOOKING_CREATE_SUCCESS, response })),
          catchError((error: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(error)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(error));
            }
            return of({ type: eBookingActions.BOOKING_CREATE_FAILURE, error });
          }),
        ),
      ),
    ),
  );

  public publishBooking$: Observable<{ type: eBookingActions } | { type: eBookingActions; error: HttpErrorResponse }> = createEffect(() =>
    this.actions$.pipe(
      ofType(eBookingActions.BOOKING_PUBLISH),
      switchMap((payload: { type: string; id: string }) =>
        this.apiBookingService.publishBooking(payload.id).pipe(
          tap(() => {
            this.toastrService.success('Бронь успешно опубликована');
          }),
          map(() => ({ type: eBookingActions.BOOKING_PUBLISH_SUCCESS })),
          catchError((error: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(error)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(error));
            }
            return of({ type: eBookingActions.BOOKING_PUBLISH_FAILURE, error });
          }),
        ),
      ),
    ),
  );

  public cancelBooking$: Observable<{ type: eBookingActions } | { type: eBookingActions; error: HttpErrorResponse }> = createEffect(() =>
    this.actions$.pipe(
      ofType(eBookingActions.BOOKING_CANCEL),
      switchMap((payload: { type: string; id: string; params: IBookingsApiParams }) =>
        this.apiBookingService.cancelBooking(payload.id).pipe(
          tap(() => {
            this.toastrService.success('Бронь успешно отменена');
            this.bookingStoreService.getBookingGeneral(payload.id);
          }),
          map(() => ({ type: eBookingActions.BOOKING_CANCEL_SUCCESS })),
          catchError((error: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(error));
            return of({ type: eBookingActions.BOOKING_CANCEL_FAILURE, error });
          }),
        ),
      ),
    ),
  );

  public completeBooking$: Observable<{ type: eBookingActions } | { type: eBookingActions; error: HttpErrorResponse }> = createEffect(() =>
    this.actions$.pipe(
      ofType(eBookingActions.BOOKING_COMPLETE),
      switchMap((payload: { type: string; id: string; params: IBookingsApiParams }) =>
        this.apiBookingService.completeBooking(payload.id).pipe(
          tap(() => {
            this.toastrService.success('Бронь успешно завершена');
            this.bookingStoreService.getBookingGeneral(payload.id);
          }),
          map(() => ({ type: eBookingActions.BOOKING_COMPLETE_SUCCESS })),
          catchError((error: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(error));
            return of({ type: eBookingActions.BOOKING_COMPLETE_FAILURE, error });
          }),
        ),
      ),
    ),
  );

  public updateBooking$: Observable<
    { type: eBookingActions; response: { id: string }; publish: boolean } | { type: eBookingActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eBookingActions.BOOKING_UPDATE),
      switchMap((payload: { type: string; data: IBookingGeneralUpsert; id: string; publish: boolean }) =>
        this.apiBookingService.updateBooking(payload.data, payload.id).pipe(
          tap(() => {
            this.toastrService.success('Бронь успешно обновлена');
          }),
          map((response: { id: string }) => ({ type: eBookingActions.BOOKING_UPDATE_SUCCESS, response, publish: payload.publish })),
          catchError((error: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(error)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(error));
            }
            return of({ type: eBookingActions.BOOKING_UPDATE_FAILURE, error });
          }),
        ),
      ),
    ),
  );

  public uploadBookingDocument$: Observable<{ type: eBookingActions } | { type: eBookingActions; error: HttpErrorResponse }> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(eBookingActions.BOOKING_DOCUMENT_UPLOAD),
        map((payload: { type: string; file: File; id: string; documentType: string }) => {
          const formData = new FormData();
          formData.append('file', payload.file);

          return { formData, id: payload.id, documentType: payload.documentType };
        }),
        switchMap((payload: { formData: FormData; id: string; documentType: string }) =>
          this.apiBookingService.uploadBookingDocument(payload.formData, payload.id, payload.documentType).pipe(
            tap(() => {
              this.toastrService.success('Документ успешно прикреплен');
            }),
            map(() => ({ type: eBookingActions.BOOKING_DOCUMENT_UPLOAD_SUCCESS })),
            catchError((err: HttpErrorResponse) => {
              if (!this.httpErrorsService.isDataValidationError(err)) {
                this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
              }

              return of({ type: eBookingActions.BOOKING_DOCUMENT_UPLOAD_FAILURE, error: err });
            }),
          ),
        ),
      ),
  );

  public deleteBookingDocument$: Observable<{ type: eBookingActions } | { type: eBookingActions; error: HttpErrorResponse }> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(eBookingActions.BOOKING_DOCUMENT_DELETE),
        switchMap((payload: { type: string; id: string; documentType: string }) =>
          this.apiBookingService.deleteBookingDocument(payload.id, payload.documentType).pipe(
            tap(() => {
              this.toastrService.success('Документ успешно откреплен');
            }),
            map(() => ({ type: eBookingActions.BOOKING_DOCUMENT_DELETE_SUCCESS })),
            catchError((err: HttpErrorResponse) => {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
              return of({ type: eBookingActions.BOOKING_DOCUMENT_DELETE_FAILURE, error: err });
            }),
          ),
        ),
      ),
  );
}
