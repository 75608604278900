import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractApiMethodsService } from './abstract-api-methods.service';
import { ITeam, ITeamCreateRequestResponse, ICurrentTeamRequestResponse, ITeamApiParams } from '../models/team.model';

@Injectable()
export class ApiTeamService extends AbstractApiMethodsService {
  public getTeam(params: ITeamApiParams): Observable<ITeam | null> {
    return this.httpPost(params, 'team-members/index');
  }

  public getTeamRequests(params: ITeamApiParams): Observable<ITeam | null> {
    return this.httpPost(params, 'team-members/request/index');
  }

  public getCurrentTeamRequest(): Observable<ICurrentTeamRequestResponse | null> {
    return this.httpPost({}, 'team-members/request/my/view');
  }

  public createTeamRequest(organizationId: string): Observable<ITeamCreateRequestResponse | null> {
    return this.httpPost({ organization_id: organizationId }, 'team-members/request/create');
  }

  public cancelCurrentTeamRequest(): Observable<ICurrentTeamRequestResponse | null> {
    return this.httpPost({}, 'team-members/request/my/cancel');
  }

  public approveTeamRequest(id: string): Observable<boolean> {
    return this.httpPost({}, `team-members/request/${id}/accept`);
  }

  public rejectTeamRequest(id: string): Observable<boolean> {
    return this.httpPost({}, `team-members/request/${id}/reject`);
  }

  public deleteTeamMember(id: string): Observable<ITeam | null> {
    return this.httpPost({}, `team-members/${id}/delete`);
  }
}
