import { IHttpRequestState } from '../../models/http.model';
import {
  IWarehouses,
  IWarehouseDocuments,
  IWarehouseExternal,
  IWarehouseGeneral,
  IWarehouseInternal,
  IWarehouseTabState,
} from '../../models/warehouse.model';

export interface IWarehouseStoreState {
  warehouses: IWarehouses | null;
  createdWarehouseId: string | null;
  warehouseTabState: IWarehouseTabState | null;

  warehouseGeneral: IWarehouseGeneral | null;
  warehouseExternal: IWarehouseExternal | null;
  warehouseInternal: IWarehouseInternal | null;
  warehouseDocuments: IWarehouseDocuments | null;
  warehousePreloadedPhotos: string[] | null;

  warehousesLoadingState: IHttpRequestState | null;
  warehouseLoadingState: IHttpRequestState | null;
  warehousePreloadingState: IHttpRequestState | null;
  warehousePublishingState: IHttpRequestState | null;
  warehouseUpdatingState: IHttpRequestState | null;
}

export const InitWarehouseState: IWarehouseStoreState = {
  warehouses: null,
  createdWarehouseId: null,
  warehouseTabState: null,

  warehouseGeneral: null,
  warehouseExternal: null,
  warehouseInternal: null,
  warehouseDocuments: null,
  warehousePreloadedPhotos: null,

  warehousesLoadingState: null,
  warehouseLoadingState: null,
  warehousePreloadingState: null,
  warehousePublishingState: null,
  warehouseUpdatingState: null,
};
