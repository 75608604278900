import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { IApiPagination } from '../../models/pagination.model';
import { INotifications } from '../../models/notifications.model';
import { INotificationsLoadingState, INotificationsState } from './notifications.state';

import * as notificationsActions from './notifications.actions';
import * as notificationsSelectors from './notifications.selectors';

@Injectable()
export class NotificationsStoreService {
  constructor(private notificationsStore: Store<INotifications>) {}

  get state$(): Observable<INotificationsState> {
    return this.notificationsStore.select(notificationsSelectors.getNotificationsState);
  }

  get notifications$(): Observable<INotifications | null> {
    return this.notificationsStore.select(notificationsSelectors.getNotifications);
  }

  get unreadCount$(): Observable<number> {
    return this.notificationsStore.select(notificationsSelectors.getUnreadCount);
  }

  get isNotificationsLoading$(): Observable<boolean> {
    return this.notificationsStore.select(notificationsSelectors.getNotificationsIsLoading);
  }

  get isNotificationsDidLoad$(): Observable<boolean> {
    return this.notificationsStore.select(notificationsSelectors.getNotificationsIsDidLoad);
  }

  get loadingState$(): Observable<INotificationsLoadingState> {
    return this.notificationsStore.select(notificationsSelectors.getNotificationsLoadingState);
  }

  public getNotifications(pagination: IApiPagination): void {
    this.notificationsStore.dispatch(notificationsActions.getNotifications({ pagination }));
  }

  public getUnreadCount(): void {
    this.notificationsStore.dispatch(notificationsActions.getUnreadCount());
  }

  public resetNotifications(): void {
    this.notificationsStore.dispatch(notificationsActions.resetNotifications());
  }

  public resetUnreadCount(): void {
    this.notificationsStore.dispatch(notificationsActions.resetUnreadCount());
  }
}
