<div class="cards-list">
  <div class="cards-list__content" [tlpLoading]="loading">
    <div class="cards-list__list-wrapper">
      <ng-container *ngIf="items?.length && !loading; else noItems">
        <div
          class="cards-list__card-description"
          *ngIf="description && description.length"
          [class.cards-list__card-description--sticky]="stickyDescription"
          [style.grid-template-columns]="gridTemplateColumns || 'repeat(auto-fit, minmax(90px, 1fr))'"
        >
          <div class="cards-list__card-description-field" *ngFor="let field of description">
            <span class="cards-list__card-description-text">{{ field }}</span>
          </div>
        </div>

        <ng-content *ngIf="customCards; else templateCards"></ng-content>
        <ng-template #templateCards>
          <tlp-card
            *ngFor="let item of items"
            [item]="item"
            [gridTemplateColumns]="gridTemplateColumns"
            [toggleItemDetailsOnClick]="toggleCardDetailsOnClick"
            (click)="onCardClick(item.id)"
          ></tlp-card>
        </ng-template>
      </ng-container>
    </div>
    <ng-template #noItems>
      <p *ngIf="!loading && !errorMessage" class="cards-list__empty-message empty-message">{{ emptyMessage }}</p>
      <p *ngIf="errorMessage" class="cards-list__error-message error-message">{{ errorMessage }}</p>
    </ng-template>
  </div>
</div>
