import { IHttpRequestState } from '../../models/http.model';
import { ITeam } from '../../models/team.model';

export interface ITeamStoreState {
  team: ITeam | null;
  teamRequests: ITeam | null;
  teamLoadingState: IHttpRequestState | null;
  teamRequestsLoadingState: IHttpRequestState | null;
}

export const InitTeamState: ITeamStoreState = {
  team: null,
  teamRequests: null,
  teamLoadingState: null,
  teamRequestsLoadingState: null,
};
