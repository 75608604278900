import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileContentLayoutComponent } from './component/profile-content-layout.component';

@NgModule({
  declarations: [ProfileContentLayoutComponent],
  imports: [CommonModule],
  exports: [ProfileContentLayoutComponent],
})
export class ProfileContentLayoutModule {}
