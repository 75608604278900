<div class="password-checker">
  <h3 class="password-checker__title">Пароль должен содержать:</h3>
  <ul class="password-checker__validation-list">
    <li class="password-checker__validation-list-item" *ngFor="let validationItem of validationList">
      <svg-icon-sprite
          src="./assets/sprites/sprite.svg#ic-done"
          width="13px" height="14px"
          class="password-checker__icon"
          [ngClass]="'password-checker__icon--' + getItemIcon(validationItem.isValid)"
      ></svg-icon-sprite>
      <span class="password-checker__text">{{ validationItem.text }}</span>
    </li>
  </ul>
</div>