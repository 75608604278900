import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DictionaryStoreService } from '../../../_core/stores/dictionary/dictionary-store.service';
import { MapDelegateHelperService } from './map-delegate-helper.service';

import { IDictionaryItems } from '../../../_core/models/dictionary.model';
import { IDelegate, ISelectableItem } from '../model/delegate.model';

@Injectable({
  providedIn: 'root',
})
export class DictionaryDelegateCranageTypesService implements IDelegate {
  public getResult$!: Observable<ISelectableItem[]>;

  constructor(private mapDelegateHelperService: MapDelegateHelperService, private dictionaryStoreService: DictionaryStoreService) {}

  public get(shortName?: boolean): void {
    this.dictionaryStoreService.getCranageTypesDictionary();

    this.getResult$ = this.dictionaryStoreService.cranageTypesDictionary$.pipe(
      map((items: IDictionaryItems | null) => this.mapDelegateHelperService.mapDictionary(items, shortName)),
    );
  }

  public reset(): void {}
}
