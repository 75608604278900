import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ApiNotificationsService } from '../../api/api-notifications.service';
import { IApiPagination } from '../../models/pagination.model';
import { INotifications, IUnreadNotificationsCount } from '../../models/notifications.model';
import { eNotificationsActions } from './notifications.actions';

@Injectable()
export class NotificationsEffects {
  constructor(private actions$: Actions, private apiNotificationsService: ApiNotificationsService) {}

  public getNotifications$: Observable<
    { type: eNotificationsActions; notifications: INotifications | null } | { type: eNotificationsActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eNotificationsActions.NOTIFICATIONS_GET),
      switchMap((payload: { type: string; pagination: IApiPagination }) =>
        this.apiNotificationsService.getNotifications(payload.pagination).pipe(
          map(notifications => ({ type: eNotificationsActions.NOTIFICATIONS_GET_SUCCESS, notifications })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eNotificationsActions.NOTIFICATIONS_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getUnreadCount$: Observable<
    { type: eNotificationsActions; unreadCount: IUnreadNotificationsCount } | { type: eNotificationsActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eNotificationsActions.NOTIFICATIONS_GET_UNREAD_COUNT),
      switchMap(() =>
        this.apiNotificationsService.getUnreadCount().pipe(
          map(unreadCount => ({ type: eNotificationsActions.NOTIFICATIONS_GET_UNREAD_COUNT_SUCCESS, unreadCount })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eNotificationsActions.NOTIFICATIONS_GET_UNREAD_COUNT_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );
}
