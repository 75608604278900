<ng-select
  *ngIf="formControl"
  [formControl]="formControl"
  [class.error]="formControl.touched && formControl.errors"
  [placeholder]="placeholder"
  [notFoundText]="notFoundText"
  [tlpTooltip]="infoTip"
  [tooltipPlacement]="infoTipPlacement"

  [items]="items || (delegate?.getResult$ | async) || []"
  [bindLabel]="'name'"
  [bindValue]="'value'"

  [multiple]="multiple"
  [required]="required"
  [searchable]="searchable"
  [readonly]="!selectable"
  [clearable]="clearable"
  [closeOnSelect]="closeOnSelect"

  (change)="selectItem($event)"
  (close)="markAsTouched()"
>
  <ng-template ng-label-tmp let-item="item" let-clear="clear" *ngIf="!concatenatedSelections; else multipleLabel">
    <svg-icon-sprite width="16px" height="16px" *ngIf="item.icon" [src]="item.icon"></svg-icon-sprite>
    {{ item.name }}
    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
  </ng-template>
  <ng-template #multipleLabel>
    <ng-template ng-multi-label-tmp>
      <div class="ng-value ng-value--concatenated">{{ this.concatenatedLabel }}</div>
    </ng-template>
  </ng-template>
</ng-select>
