import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ICityApiParams, ICities } from '../../models/city.model';
import { ICityStoreState } from './city.state';
import { IHttpRequestState } from '../../models/http.model';

import * as cityActions from './city.actions';
import * as citySelectors from './city.selectors';

@Injectable()
export class CityStoreService {
  constructor(private cityStore: Store<ICityStoreState>) {}

  get cities$(): Observable<ICities | null> {
    return this.cityStore.select(citySelectors.getCities);
  }

  get userCities$(): Observable<ICities | null> {
    return this.cityStore.select(citySelectors.getUserCities);
  }

  get loadingState$(): Observable<IHttpRequestState | null> {
    return this.cityStore.select(citySelectors.getLoadingState);
  }

  public getCities(params: ICityApiParams): void {
    this.cityStore.dispatch(cityActions.getCities({ params }));
  }

  public getUserCities(params?: ICityApiParams): void {
    this.cityStore.dispatch(cityActions.getUserCities({ params }));
  }

  public resetDictionaryStoreState(): void {
    this.cityStore.dispatch(cityActions.resetCityState());
  }

  public resetDictionaryLoadingState(): void {
    this.cityStore.dispatch(cityActions.resetCityLoadingState());
  }
}
