import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { ButtonModule } from '../button/button.module';
import { ChangeNoteModule } from '../change-note/change-note.module';
import { ContentBlockModule } from '../content-block/content-block.module';
import { FileDndModule } from '../directives/file-dnd/file-dnd.module';
import { TooltipModule } from '../directives/tooltip/tooltip.module';

import { FileUploadComponent } from './component/file-upload/file-upload.component';

@NgModule({
  declarations: [FileUploadComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconSpriteModule,
    ButtonModule,
    ChangeNoteModule,
    ContentBlockModule,
    FileDndModule,
    TooltipModule,
  ],
  exports: [FileUploadComponent],
})
export class FileUploadModule {}
