<div class="home-menu">
  <ng-container *ngIf="currentUser; else unauthorized">
    <a class="home-menu__button" [ngClass]="'home-menu__button--' + theme" (click)="toggleDropdown()">
      <svg-icon-sprite
          class="home-menu__avatar"
          src="./assets/sprites/sprite.svg#ic-no-avatar"
          width="18px" height="18px"
      ></svg-icon-sprite>
      <div class="home-menu__info" >
        {{ currentUser.last_name }} {{ currentUser.first_name[0] }}. {{ currentUser.middle_name ? currentUser.middle_name[0] + '.' : '' }}
      </div>
    </a>
    <tlp-content-block
        arrowLocation="top-left"
        class="home-menu__dropdown"
        [class.home-menu__dropdown--active]="isDropdownShown"
        [dialog]="true"
    >
      <div class="home-menu__navigation">
        <tlp-navigation-list
            [showIcons]="false"
            [showProfileLink]="true"
            [dark]="true"
        >
        </tlp-navigation-list>
        <div class="home-menu__exit-button">
          <tlp-button [theme]="'link'" [iconRight]="'exit'" (click)="logout()">Выйти</tlp-button>
        </div>
      </div>
    </tlp-content-block>
  </ng-container>

  <ng-template #unauthorized>
    <ng-container *ngIf="!loading">
      <a routerLink="/auth/login" class="home-menu__button" [ngClass]="'home-menu__button--' + theme">
        <svg-icon-sprite
            class="home-menu__avatar"
            src="./assets/sprites/sprite.svg#ic-no-avatar"
            width="18px" height="18px"
        ></svg-icon-sprite>
        <span class="home-menu__info">Вход и регистрация</span>
      </a>
    </ng-container>
  </ng-template>
</div>
