import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractApiMethodsService } from './abstract-api-methods.service';
import { IUser, IUserShortInfo, IUserPassword } from '../models/user.model';

@Injectable()
export class ApiCurrentUserService extends AbstractApiMethodsService {
  public getCurrentUser(): Observable<IUser | null> {
    return this.httpPost({}, `profile/view`);
  }

  public updateCurrentUserData(user: IUserShortInfo): Observable<IUser | null> {
    return this.httpPost(user, `profile/update`);
  }

  public updateCurrentUserPassword(password: IUserPassword): Observable<IUser | null> {
    const body: IUserPassword = {
      password: password.password,
      password_current: password.password_current,
    };

    return this.httpPost(body, `profile/change-password`);
  }
}
