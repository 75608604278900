import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractApiMethodsService } from './abstract-api-methods.service';
import { IRefreshToken, ITokens } from '../models/auth.model';

@Injectable()
export class ApiRefreshTokenService extends AbstractApiMethodsService {
  public refreshToken(refreshToken: IRefreshToken): Observable<ITokens> {
    return this.httpPost({ refresh_token: refreshToken.refresh_token }, 'auth/refresh-token');
  }
}
