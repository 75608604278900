import { Action, createReducer, on } from '@ngrx/store';
import { InitUserState, ICurrentUserStoreState } from './current-user';
import { IUser } from '../../models/user.model';
import * as userActions from './current-user.actions';

const currentUserReducer = createReducer(
  InitUserState,
  on(userActions.getCurrentUser, state => ({
    ...state,
    currentUserLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(userActions.getCurrentUserSuccess, (state, { currentUser }) => ({
    ...state,
    currentUser,
    currentUserLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(userActions.getCurrentUserFailure, (state, { error }) => ({
    ...state,
    currentUserLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(userActions.updateCurrentUserData, state => ({
    ...state,
    currentUserUpdatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(userActions.updateCurrentUserDataSuccess, state => ({
    ...state,
    currentUserUpdatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(userActions.updateCurrentUserDataFailure, (state, { error }) => ({
    ...state,
    currentUserUpdatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(userActions.updateCurrentUserPassword, state => ({
    ...state,
    currentUserUpdatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(userActions.updateCurrentUserPasswordSuccess, state => ({
    ...state,
    currentUserUpdatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(userActions.updateCurrentUserPasswordFailure, (state, { error }) => ({
    ...state,
    currentUserUpdatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(userActions.setCurrentUserOrganization, (state, { organization_id }) => {
    const currentUser: IUser = { ...state.currentUser, organization_id } as IUser;
    return { ...state, currentUser };
  }),

  on(userActions.resetCurrentUser, () => ({ ...InitUserState })),
);

export const CurrentUserReducer = (state: ICurrentUserStoreState, action: Action) => {
  return currentUserReducer(state, action);
};
