import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

import { IBookingCostParams } from '../../../../_core/models/booking.model';
import { UNIX_TIME_RATIO } from '../../../../_core/const/date.const';

export type costCalculatorModes = 'dynamic' | 'static';
export type costCalculatorThemes = 'default' | 'compact';

@Component({
  selector: 'tlp-cost-calculator',
  templateUrl: './cost-calculator.component.html',
  styleUrls: ['./cost-calculator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CostCalculatorComponent implements OnChanges {
  @Input() public bookingCostParams: IBookingCostParams | null = null;
  @Input() public costCalculatorMode: costCalculatorModes = 'static';
  @Input() public costCalculatorTheme: costCalculatorThemes = 'default';

  public daysCount: number | null = null;
  public summaryCost: number | null = null;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.bookingCostParams?.currentValue) {
      this.daysCount = this.calcDaysCount(changes.bookingCostParams?.currentValue);
      this.summaryCost = this.calcSummaryCost(changes.bookingCostParams?.currentValue, this.daysCount);
      this.cdr.detectChanges();
    }
  }

  private calcDaysCount(booking: IBookingCostParams | null): number | null {
    if (!booking || !booking.rent_from || !booking.rent_to) {
      return null;
    }

    const rentFrom = moment.utc(booking.rent_from * UNIX_TIME_RATIO);
    const rentTo = moment.utc(booking.rent_to * UNIX_TIME_RATIO);
    return rentFrom && rentTo && rentTo.isAfter(rentFrom)
      ? rentTo.diff(rentFrom, 'days') / (this.costCalculatorMode === 'static' ? 1 : UNIX_TIME_RATIO)
      : null;
  }

  private calcSummaryCost(booking: IBookingCostParams | null, daysCount: number | null): number | null {
    if (!booking || !daysCount || isNaN(Number(booking.cost))) {
      return null;
    }

    return Number(booking.amount) * Number(booking.cost) * daysCount;
  }
}
