import { IDictionaryItems } from '../../models/dictionary.model';
import { IHttpRequestState } from '../../models/http.model';

export interface IDictionaryStoreState {
  accountingControlSystems: IDictionaryItems | null;
  accountingSystemType: IDictionaryItems | null;
  classification: IDictionaryItems | null;
  cranageTypes: IDictionaryItems | null;
  electricityGridType: IDictionaryItems | null;
  fireExtSystemType: IDictionaryItems | null;
  floorCovering: IDictionaryItems | null;
  heatingSystemType: IDictionaryItems | null;
  loadingUnloadingMethods: IDictionaryItems | null;
  loadingUnloadingStructTypes: IDictionaryItems | null;
  loadingUnloadingTechTypes: IDictionaryItems | null;
  officeFacilityTypes: IDictionaryItems | null;
  parkingType: IDictionaryItems | null;
  storageFormat: IDictionaryItems | null;
  storageSystem: IDictionaryItems | null;
  taxationType: IDictionaryItems | null;
  temperatureMode: IDictionaryItems | null;
  unit: IDictionaryItems | null;
  unitDistance: IDictionaryItems | null;
  unitMass: IDictionaryItems | null;
  unitSquare: IDictionaryItems | null;
  unitTemperature: IDictionaryItems | null;
  loadingState: IHttpRequestState | null;
  legalType: IDictionaryItems | null;
  partnerType: IDictionaryItems | null;
}

export const InitDictionaryState: IDictionaryStoreState = {
  accountingControlSystems: null,
  accountingSystemType: null,
  classification: null,
  cranageTypes: null,
  electricityGridType: null,
  fireExtSystemType: null,
  floorCovering: null,
  heatingSystemType: null,
  loadingUnloadingMethods: null,
  loadingUnloadingStructTypes: null,
  loadingUnloadingTechTypes: null,
  officeFacilityTypes: null,
  parkingType: null,
  storageFormat: null,
  storageSystem: null,
  taxationType: null,
  temperatureMode: null,
  unit: null,
  unitDistance: null,
  unitMass: null,
  unitSquare: null,
  unitTemperature: null,
  loadingState: null,
  legalType: null,
  partnerType: null,
};
