import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IHttpRequestState } from '../../models/http.model';
import { ITeam, ITeamApiParams } from '../../models/team.model';
import { ITeamStoreState } from './team.state';

import * as teamActions from './team.actions';
import * as teamSelectors from './team.selectors';

@Injectable()
export class TeamStoreService {
  constructor(private teamStore: Store<ITeamStoreState>) {}

  get team$(): Observable<ITeam | null> {
    return this.teamStore.select(teamSelectors.getTeam);
  }

  get teamRequests$(): Observable<ITeam | null> {
    return this.teamStore.select(teamSelectors.getTeamRequests);
  }

  get teamLoadingState$(): Observable<IHttpRequestState | null> {
    return this.teamStore.select(teamSelectors.getTeamLoadingState);
  }

  get teamRequestsLoadingState$(): Observable<IHttpRequestState | null> {
    return this.teamStore.select(teamSelectors.getTeamLoadingState);
  }

  public getTeam(params: ITeamApiParams): void {
    this.teamStore.dispatch(teamActions.getTeam({ params }));
  }

  public getTeamRequests(params: ITeamApiParams): void {
    this.teamStore.dispatch(teamActions.getTeamRequests({ params }));
  }

  public approveTeamRequest(id: string, params: { teamApiParams: ITeamApiParams; teamRequestsApiParams: ITeamApiParams }): void {
    this.teamStore.dispatch(teamActions.approveTeamRequest({ id, params }));
  }

  public rejectTeamRequest(id: string, params: ITeamApiParams): void {
    this.teamStore.dispatch(teamActions.rejectTeamRequest({ id, params }));
  }

  public deleteTeamMember(id: string, params: ITeamApiParams): void {
    this.teamStore.dispatch(teamActions.deleteTeamMember({ id, params }));
  }

  public resetTeam(): void {
    this.teamStore.dispatch(teamActions.resetTeam());
  }

  public resetTeamRequests(): void {
    this.teamStore.dispatch(teamActions.resetTeamRequests());
  }

  public resetTeamState(): void {
    this.teamStore.dispatch(teamActions.resetTeamState());
  }
}
