import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IHttpRequestState } from '../../models/http.model';
import { IOrganizationStoreState } from './organization.state';

export const getOrganizationStoreState = createFeatureSelector<IOrganizationStoreState>('admin-organization');

export const organizations = createSelector(getOrganizationStoreState, (state: IOrganizationStoreState) => state.organizations);
export const organization = createSelector(getOrganizationStoreState, (state: IOrganizationStoreState) => state.organization);
export const organizationCreatedId = createSelector(
  getOrganizationStoreState,
  (state: IOrganizationStoreState) => state.createdOrganizationId,
);
export const organizationGeneral = createSelector(getOrganizationStoreState, (state: IOrganizationStoreState) => state.organizationGeneral);
export const organizationDocuments = createSelector(
  getOrganizationStoreState,
  (state: IOrganizationStoreState) => state.organizationDocuments,
);

export const getOrganizationsLoadingState = createSelector(
  getOrganizationStoreState,
  (state: IOrganizationStoreState) =>
    ({
      ...state.organizationsLoadingState,
    } as IHttpRequestState),
);
export const getOrganizationLoadingState = createSelector(
  getOrganizationStoreState,
  (state: IOrganizationStoreState) =>
    ({
      ...state.organizationLoadingState,
    } as IHttpRequestState),
);
export const getOrganizationPublishingState = createSelector(
  getOrganizationStoreState,
  (state: IOrganizationStoreState) =>
    ({
      ...state.organizationPublishingState,
    } as IHttpRequestState),
);
export const getOrganizationUpdatingState = createSelector(
  getOrganizationStoreState,
  (state: IOrganizationStoreState) =>
    ({
      ...state.organizationUpdatingState,
    } as IHttpRequestState),
);
