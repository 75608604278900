import { IBookingDate } from '../../models/booking.model';
import { IHttpRequestState } from '../../models/http.model';
import { IStorageArea, IStorageAreas } from '../../models/storage-area.model';

export interface IStorageAreaStoreState {
  storageAreas: IStorageAreas | null;
  storageArea: IStorageArea | null;
  storageAreaBookingDates: IBookingDate[] | null;
  storageAreaPreloadedPhotos: string[] | null;
  storageAreasLoadingState: IHttpRequestState | null;
  storageAreasUpdatingState: IHttpRequestState | null;
  storageAreaLoadingState: IHttpRequestState | null;
  storageAreaBookingDatesLoadingState: IHttpRequestState | null;
  storageAreaPreloadingState: IHttpRequestState | null;
  storageAreaUpdatingState: IHttpRequestState | null;
}

export const InitStorageAreaState: IStorageAreaStoreState = {
  storageAreas: null,
  storageArea: null,
  storageAreaBookingDates: null,
  storageAreaPreloadedPhotos: null,
  storageAreasLoadingState: null,
  storageAreasUpdatingState: null,
  storageAreaLoadingState: null,
  storageAreaBookingDatesLoadingState: null,
  storageAreaPreloadingState: null,
  storageAreaUpdatingState: null,
};
