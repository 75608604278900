import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IHttpRequestState } from '../../models/http.model';
import { IStorageAreaStoreState } from './storage-area.state';

export const getStorageAreaStoreState = createFeatureSelector<IStorageAreaStoreState>('storage-area');

export const getStorageAreas = createSelector(getStorageAreaStoreState, (state: IStorageAreaStoreState) => state.storageAreas);
export const getStorageArea = createSelector(getStorageAreaStoreState, (state: IStorageAreaStoreState) => state.storageArea);
export const getStorageAreaBookingDates = createSelector(
  getStorageAreaStoreState,
  (state: IStorageAreaStoreState) => state.storageAreaBookingDates,
);
export const getStorageAreaPreloadedPhotos = createSelector(
  getStorageAreaStoreState,
  (state: IStorageAreaStoreState) => state.storageAreaPreloadedPhotos,
);

export const getStorageAreasLoadingState = createSelector(
  getStorageAreaStoreState,
  (state: IStorageAreaStoreState) =>
    ({
      ...state.storageAreasLoadingState,
    } as IHttpRequestState),
);
export const getStorageAreasUpdatingState = createSelector(getStorageAreaStoreState, (state: IStorageAreaStoreState) =>
  state.storageAreasUpdatingState
    ? ({
        ...state.storageAreasUpdatingState,
      } as IHttpRequestState)
    : null,
);
export const getStorageAreaLoadingState = createSelector(
  getStorageAreaStoreState,
  (state: IStorageAreaStoreState) =>
    ({
      ...state.storageAreaLoadingState,
    } as IHttpRequestState),
);
export const getStorageAreaBookingDatesLoadingState = createSelector(getStorageAreaStoreState, (state: IStorageAreaStoreState) =>
  state.storageAreaBookingDatesLoadingState
    ? ({
        ...state.storageAreaBookingDatesLoadingState,
      } as IHttpRequestState)
    : null,
);
export const getStorageAreaPreloadingState = createSelector(
  getStorageAreaStoreState,
  (state: IStorageAreaStoreState) =>
    ({
      ...state.storageAreaPreloadingState,
    } as IHttpRequestState),
);
export const getStorageAreaUpdatingState = createSelector(
  getStorageAreaStoreState,
  (state: IStorageAreaStoreState) =>
    ({
      ...state.storageAreaUpdatingState,
    } as IHttpRequestState),
);
