import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ToastrService } from 'ngx-toastr';
import { ApiCurrentUserService } from '../../api/api-current-user.service';
import { CurrentUserStoreService } from './current-user-store.service';
import { HttpErrorsService } from '../../api/http-errors.service';

import { IUser, IUserPassword } from '../../models/user.model';
import { eUserActions } from './current-user.actions';

@Injectable()
export class CurrentUserEffects {
  constructor(
    private actions$: Actions,
    private toastrService: ToastrService,
    private apiCurrentUserService: ApiCurrentUserService,
    private currentUserStoreService: CurrentUserStoreService,
    private httpErrorsService: HttpErrorsService,
  ) {}

  public getCurrentUser$: Observable<{ type: eUserActions } | { type: eUserActions; error: HttpErrorResponse }> = createEffect(() =>
    this.actions$.pipe(
      ofType(eUserActions.USER_CURRENT_GET),
      switchMap(() =>
        this.apiCurrentUserService.getCurrentUser().pipe(
          map(currentUser => ({ type: eUserActions.USER_CURRENT_GET_SUCCESS, currentUser })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eUserActions.USER_CURRENT_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public updateCurrentUserData$: Observable<{ type: eUserActions } | { type: eUserActions; error: HttpErrorResponse }> = createEffect(() =>
    this.actions$.pipe(
      ofType(eUserActions.USER_CURRENT_UPDATE_DATA),
      switchMap((payload: { type: string; user: IUser }) =>
        this.apiCurrentUserService.updateCurrentUserData(payload.user).pipe(
          tap(() => {
            this.toastrService.success('Данные успешно сохранены');
            this.currentUserStoreService.getCurrentUser();
          }),
          map(() => ({ type: eUserActions.USER_CURRENT_UPDATE_DATA_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(err)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            }

            return of({ type: eUserActions.USER_CURRENT_UPDATE_DATA_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public updateUserPassword$: Observable<{ type: eUserActions } | { type: eUserActions; error: HttpErrorResponse }> = createEffect(() =>
    this.actions$.pipe(
      ofType(eUserActions.USER_CURRENT_UPDATE_PASSWORD),
      switchMap((payload: { type: string; password: IUserPassword }) =>
        this.apiCurrentUserService.updateCurrentUserPassword(payload.password).pipe(
          tap(() => {
            this.toastrService.success('Пароль успешно изменен');
          }),
          map(() => ({ type: eUserActions.USER_CURRENT_UPDATE_PASSWORD_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(err)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            }

            return of({ type: eUserActions.USER_CURRENT_UPDATE_PASSWORD_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );
}
