import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'tlp-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  // tslint:disable-next-line: prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PopupComponent {
  @Input() public hideCloseButton: boolean = false;
  @Input() public hideCloseButtonText: boolean = false;
  @Input() public closeButtonSize: string = '25px';
  @Input() public containerSelector!: string;
  @Input() public isNestedInDrawer: boolean = false;
  @Input() public size: number = 630;

  public isOpen: boolean = false;

  public open(): void {
    this.isOpen = true;
  }

  public close(): void {
    this.isOpen = false;
  }
}
