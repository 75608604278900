<div class="status-note"
     [ngClass]="['status-note--' + (statusName || 'undefined')]"
     [class.status-note--default]="statusTheme === 'default'"
     [class.status-note--extended]="statusTheme === 'extended'"
>
  <span class="status-note__text">{{ text || 'Не установлено' }}</span>
  <span class="status-note__additional-text" *ngIf="additionalText">{{ additionalText }}</span>
  <div *ngIf="statusIcon" class="status-note__icon status-note-icon">
    <svg-icon-sprite [src]="'./assets/sprites/sprite.svg#ic-' + statusIcon" [width]="'16px'" [height]="'16px'"></svg-icon-sprite>
  </div>
</div>