import {
  Component,
  Input,
  HostListener,
  EventEmitter,
  Output,
  forwardRef,
  ChangeDetectionStrategy,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, ValidationErrors, FormControl } from '@angular/forms';

import { IDocument } from '../../../../_core/models/document.model';
import { IChangedField } from '../../../../_core/models/verification.model';
import { IHttpError } from '../../../../_core/models/http.model';
import {
  fileUploadActionTypes,
  fileUploadEvents,
  fileUploadIcons,
  fileUploadModes,
  IFileUploadAction,
} from '../../model/file-upload.model';
import { saveAs } from 'file-saver';

export const FILE_COUNT_LIMIT: number = 1;

@Component({
  selector: 'tlp-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
})
export class FileUploadComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {
  @ViewChild('fileInput') public fileInput!: ElementRef;

  @Input() public showChanges: boolean = false;
  @Input() public changedFieldName!: string | string[];
  @Input() public changedFields: IChangedField[] | undefined = undefined;

  @Input() public formControlName!: string;
  @Input() public fileTitle!: string | string[];
  @Input() public fileDescription!: string | string[];
  @Input() public fileTemplateLink!: string;
  @Input() public fileTypes: string[] = ['pdf'];

  @Input() public interactionMode: fileUploadModes = 'upload';
  @Input() public infoTooltipMessage!: string | null;
  @Input() public hasDependencies: boolean = false;
  @Input() public readonly: boolean = false;
  @Input() public required: boolean = true;

  @Input() public backendError: IHttpError | string | boolean | null = null;
  @Input() public forceDownload: boolean = false;
  @Input() public withPreload: boolean = false;

  @Input() public needUserConfirm: boolean = false;
  @Input() public userConfirmed: boolean | undefined = undefined;

  @Output() public fileChanged: EventEmitter<File | null> = new EventEmitter<File | null>();
  @Output() public destructiveActionFired: EventEmitter<string> = new EventEmitter<string>();

  public formControl!: FormControl;
  public file!: File | null;
  public fileDocument!: IDocument | null;

  public icon: fileUploadIcons | undefined = 'undone';
  public validFileExtension: boolean = true;
  public validFilesCount: boolean = true;
  public hasChanges: boolean = false;

  public fileUploadActionsVisible: boolean = false;
  public fileUploadActions: IFileUploadAction[] = [];

  private lastFiredAction: fileUploadActionTypes | null = null;
  private lastDroppedFile: File | null = null;

  @HostListener('document:click', ['$event'])
  public onClick(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.toggleActionMenu(false);
    }
  }

  @HostListener('change', ['$event.target.files']) public emitFiles(event: FileList): void {
    const file = event.item(0);
    if (file) {
      this.validFilesCount = true;
      this.handleFileChanges(file);
    }
  }

  constructor(private cdr: ChangeDetectorRef, private elementRef: ElementRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.required && !changes.required.currentValue) {
      this.setIcon(this.fileDocument || this.file ? 'done' : undefined);
    }

    if (changes.fileTemplateLink?.currentValue) {
      this.updateFileUploadActions('templateUpdate');
    }

    if (changes.backendError?.currentValue) {
      if (!this.fileDocument && this.file) {
        this.file = null;
      }
      this.setIcon('attention');
    }

    if (changes.userConfirmed?.currentValue !== undefined && changes.userConfirmed?.currentValue !== null) {
      if (changes.userConfirmed.currentValue) {
        switch (this.lastFiredAction) {
          case 'deleteFile':
            this.removeFile();
            break;
          case 'uploadFile':
            if (this.lastDroppedFile) {
              this.handleFileChanges(this.lastDroppedFile);
            } else {
              this.fileInput.nativeElement.click();
              this.toggleActionMenu();
            }
            break;
          default:
            return;
        }
      }
    }

    if (changes.backendError?.currentValue) {
      if (!this.fileDocument && this.file) {
        this.file = null;
      }
      this.setIcon('attention');
    }
  }

  public ngOnInit(): void {
    this.formControl = new FormControl();
    if (this.interactionMode === 'view') {
      this.readonly = true;
    }

    if (!this.readonly) {
      this.updateFileUploadActions('init');
    }
  }

  private onChange = (_: File | number | null) => {};

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(): void {}

  public writeValue(value: IDocument | File | null): void {
    if (value) {
      if ((value as IDocument).id) {
        this.fileDocument = value as IDocument;
        this.file = null;
        this.updateFileUploadActions('documentUpdate');
      } else {
        this.file = value as File;
        this.fileDocument = null;
        this.updateFileUploadActions('fileUpdate');
      }

      this.setIcon('done');
    } else {
      this.file = null;
      this.fileDocument = null;
      this.setIcon(this.required ? 'undone' : undefined);
    }

    this.cdr.detectChanges();
  }

  public onFileDropped(files: FileList): void {
    let firstValidFileIndex: number | undefined;

    const newFiles: File[] = Array.from(files).reduce((result: File[], currentItem, index) => {
      const extension: string | undefined = currentItem.name.split('.').pop();

      if (extension && this.fileTypes.includes(extension.toLowerCase())) {
        firstValidFileIndex = firstValidFileIndex === undefined ? index : firstValidFileIndex;
        result.push(currentItem);
      }
      return result;
    }, []);

    const file = files.item(firstValidFileIndex || 0);

    if (this.needUserConfirm && !this.userConfirmed) {
      this.lastFiredAction = 'uploadFile';
      this.lastDroppedFile = file;
      this.destructiveActionFired.emit(this.formControlName);
      return;
    }

    if (newFiles.length > FILE_COUNT_LIMIT) {
      this.validFilesCount = false;
    }

    this.handleFileChanges(file);
    this.toggleActionMenu(false);
  }

  public handleFileChanges(file: File | null): void {
    if (this.isValidExtension(file)) {
      this.file = file;
      this.fileDocument = null;
      this.validFileExtension = true;
      this.hasChanges = true;

      if (this.withPreload) {
        this.fileChanged.emit(this.file);
        this.onChange(null);
      } else {
        this.fileChanged.emit(this.file);
        this.onChange(this.file);
      }
      this.setIcon('done');
      this.updateFileUploadActions('fileUpdate');
    } else if (file) {
      this.validFileExtension = false;
      this.setIcon('attention');
    }
    this.backendError = null;

    this.cdr.detectChanges();
  }

  public validate(): ValidationErrors | null {
    if (this.fileDocument) {
      return null;
    }

    if (!this.isValidExtension(this.file)) {
      return {
        invalidFileType: true,
      };
    }

    return null;
  }

  public toggleActionMenu(isVisible?: boolean): void {
    this.fileUploadActionsVisible = isVisible === undefined ? !this.fileUploadActionsVisible : isVisible;
    this.cdr.detectChanges();
  }

  public downloadFile(filePath?: string, fileName?: string): void {
    const url: string | undefined = filePath || this.fileDocument?.file.path;
    if (this.forceDownload && url) {
      saveAs(url, fileName || this.fileDocument?.file.name || '');
    } else {
      this.inspectFile(url);
    }
  }

  public get currentFileName(): string | undefined {
    return this.file?.name || this.fileDocument?.file.name;
  }

  public get currentFileTypes(): string | undefined {
    return this.fileTypes?.map(fileType => `application/${fileType}`).join(',');
  }

  private inspectFile(filePath?: string): void {
    if (filePath || this.fileDocument) {
      window.open(filePath || this.fileDocument?.file.path, '_blank');
    }
  }

  private chooseNewFile(): void {
    this.lastFiredAction = null;
    this.lastDroppedFile = null;

    if (this.needUserConfirm && !this.userConfirmed) {
      this.lastFiredAction = 'uploadFile';
      this.destructiveActionFired.emit(this.formControlName);
      return;
    }

    this.fileInput.nativeElement.click();
    this.toggleActionMenu();
  }

  private removeFile(): void {
    this.lastFiredAction = null;
    this.lastDroppedFile = null;

    if (this.needUserConfirm && !this.userConfirmed) {
      this.lastFiredAction = 'deleteFile';
      this.destructiveActionFired.emit(this.formControlName);
      return;
    }

    if (this.fileInput?.nativeElement) {
      this.fileInput.nativeElement.value = null;
    }

    this.file = null;
    this.fileDocument = null;
    this.fileChanged.emit(this.file);
    this.onChange(this.file);

    this.validFileExtension = true;
    this.validFilesCount = true;
    this.backendError = null;
    if (this.required) {
      this.setIcon('undone');
    }

    this.updateFileUploadActions('delete');
    this.toggleActionMenu();
  }

  private setIcon(iconType: fileUploadIcons | undefined): void {
    this.icon = iconType;
  }

  private updateFileUploadActions(event: fileUploadEvents): void {
    switch (event) {
      case 'delete':
        const documentUpdateActionTypes: string[] = this.getFileUploadActions('documentUpdate').map(action => action.type);
        this.fileUploadActions = this.fileUploadActions.filter(action => !documentUpdateActionTypes.includes(action.type));
        break;
      case 'fileUpdate':
        this.updateFileUploadActions('delete');
        this.fileUploadActions.push(...this.getFileUploadActions(event));
        break;
      default:
        const currentFileActionTypes: string[] = this.fileUploadActions.map(action => action.type);
        const actionsToUpdate: IFileUploadAction[] = this.getFileUploadActions(event);
        actionsToUpdate.forEach(action => !currentFileActionTypes.includes(action.type) && this.fileUploadActions.push(action));
    }
    this.fileUploadActions.sort((firstEl, secondEl) => firstEl.order - secondEl.order);
  }

  private getFileUploadActions(event: fileUploadEvents): IFileUploadAction[] {
    switch (event) {
      case 'init':
        return [{ type: 'uploadFile', order: 0, name: 'Загрузить новый', icon: 'file', callback: () => this.chooseNewFile() }];
      case 'documentUpdate':
        const baseActions: IFileUploadAction[] = [
          { type: 'downloadFile', order: 2, name: 'Скачать', icon: 'download', callback: () => this.downloadFile() },
        ];
        return this.readonly
          ? baseActions
          : [...baseActions, { type: 'deleteFile', order: 1, name: 'Удалить', icon: 'delete', callback: () => this.removeFile() }];
      case 'fileUpdate':
        return this.readonly ? [] : [{ type: 'deleteFile', order: 1, name: 'Удалить', icon: 'delete', callback: () => this.removeFile() }];
      case 'templateUpdate':
        return [
          {
            type: 'downloadTemplate',
            order: 3,
            name: 'Скачать шаблон',
            icon: 'download',
            callback: () => this.downloadFile(this.fileTemplateLink, 'Шаблон документа.pdf'),
          },
        ];
      default:
        return [];
    }
  }

  private isValidExtension(file: File | null): boolean {
    if (!this.fileTypes.length) {
      return true;
    }

    if (file) {
      const extension = file.name?.split('.').pop();
      return extension !== undefined && this.fileTypes.includes(extension.toLowerCase());
    }

    return true;
  }
}
