import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IHttpRequestState } from '../../models/http.model';
import { IUser, IUsersApiParams, IUsers } from '../../models/user.model';
import { IUsersStoreState } from './users.state';

import * as usersSelectors from './users.selectors';
import * as usersActions from './users.actions';

@Injectable()
export class UsersStoreService {
  constructor(private usersStore: Store<IUsersStoreState>) {}

  get users$(): Observable<IUsers | null> {
    return this.usersStore.select(usersSelectors.getUsers);
  }

  get user$(): Observable<IUser | null> {
    return this.usersStore.select(usersSelectors.getUser);
  }

  get usersStoreState$(): Observable<IUsersStoreState> {
    return this.usersStore.select(usersSelectors.getUserStoreState);
  }

  get usersLoadingState$(): Observable<IHttpRequestState> {
    return this.usersStore.select(usersSelectors.getUsersLoadingState);
  }

  get userLoadingState$(): Observable<IHttpRequestState> {
    return this.usersStore.select(usersSelectors.getUserLoadingState);
  }

  get userUpdatingState$(): Observable<IHttpRequestState> {
    return this.usersStore.select(usersSelectors.getUserUpdatingState);
  }

  public getUsers(params: IUsersApiParams): void {
    this.usersStore.dispatch(usersActions.getUsers({ params }));
  }

  public getUser(id: string): void {
    this.usersStore.dispatch(usersActions.getUser({ id }));
  }

  public createUser(data: IUser): void {
    this.usersStore.dispatch(usersActions.createUser({ data }));
  }

  public deleteUser(id: string): void {
    this.usersStore.dispatch(usersActions.deleteUser({ id }));
  }

  public updateUser(data: IUser, id: string): void {
    this.usersStore.dispatch(usersActions.updateUser({ data, id }));
  }

  public resetUsers(): void {
    this.usersStore.dispatch(usersActions.resetUser());
  }

  public resetUser(): void {
    this.usersStore.dispatch(usersActions.resetUser());
  }

  public resetUsersLoadingState(): void {
    this.usersStore.dispatch(usersActions.resetUsersLoadingState());
  }

  public resetUserLoadingState(): void {
    this.usersStore.dispatch(usersActions.resetUserLoadingState());
  }

  public resetUserUpdatingState(): void {
    this.usersStore.dispatch(usersActions.resetUserUpdatingState());
  }

  public resetUsersState(): void {
    this.usersStore.dispatch(usersActions.resetUsersState());
  }
}
