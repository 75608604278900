import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractApiMethodsService } from '../abstract-api-methods.service';

import { IDocument } from '../../models/document.model';
import { IRejectInfo } from '../../models/verification.model';
import {
  IWarehouses,
  IWarehouseApiParams,
  IWarehouseDocuments,
  IWarehouseExternal,
  IWarehouseExternalUpsert,
  IWarehouseGeneral,
  IWarehouseGeneralUpsert,
  IWarehouseInternal,
  IWarehouseInternalUpsert,
  IWarehouseTabState,
} from '../../models/warehouse.model';
import { IWarehouseUpsertConfirmation } from '../../../warehouse/warehouse-details-upsert-view/model/warehouse-details-upsert.model';

@Injectable()
export class ApiWarehouseService extends AbstractApiMethodsService {
  public getWarehouses(params: IWarehouseApiParams): Observable<IWarehouses | null> {
    return this.httpPost(params, 'adm/warehouse/index');
  }

  public createWarehouse(data: IWarehouseGeneralUpsert): Observable<{ id: string }> {
    return this.httpPost(data, `adm/warehouse/create`);
  }

  public deleteWarehouse(id: string): Observable<boolean> {
    return this.httpPost({}, `adm/warehouse/${id}/delete`);
  }

  public publishWarehouse(id: string): Observable<boolean> {
    return this.httpPost({}, `adm/warehouse/${id}/publish`);
  }

  public certifyWarehouse(id: string): Observable<boolean> {
    return this.httpPost({}, `adm/warehouse/${id}/verify-dsl`);
  }

  public verifyWarehouse(id: string): Observable<boolean> {
    return this.httpPost({}, `adm/warehouse/${id}/verify-sb`);
  }

  public rejectCertifyWarehouse(id: string, rejectInfo: IRejectInfo): Observable<boolean> {
    return this.httpPost(rejectInfo, `adm/warehouse/${id}/reject-dsl`);
  }

  public rejectVerifyWarehouse(id: string, rejectInfo: IRejectInfo): Observable<boolean> {
    return this.httpPost(rejectInfo, `adm/warehouse/${id}/reject-sb`);
  }

  public getWarehouseTabState(id: string): Observable<IWarehouseTabState | null> {
    return this.httpPost({}, `adm/warehouse/${id}/tab_states`);
  }

  public getWarehouseGeneral(id: string): Observable<IWarehouseGeneral | null> {
    return this.httpPost({}, `adm/warehouse/${id}/view/general`);
  }

  public updateWarehouseGeneral(data: IWarehouseGeneralUpsert, id: string): Observable<{ id: string }> {
    return this.httpPost(data, `adm/warehouse/${id}/update/general`);
  }

  public getWarehouseExternal(id: string): Observable<IWarehouseExternal | null> {
    return this.httpPost({}, `adm/warehouse/${id}/view/external`);
  }

  public updateWarehouseExternal(data: IWarehouseExternalUpsert, id: string): Observable<{ id: string }> {
    return this.httpPost(data, `adm/warehouse/${id}/update/external`);
  }

  public getWarehouseInternal(id: string): Observable<IWarehouseInternal | null> {
    return this.httpPost({}, `adm/warehouse/${id}/view/internal`);
  }

  public updateWarehouseInternal(data: IWarehouseInternalUpsert, id: string): Observable<{ id: string }> {
    return this.httpPost(data, `adm/warehouse/${id}/update/internal`);
  }

  public getWarehouseDocuments(id: string): Observable<IWarehouseDocuments | null> {
    return this.httpPost({}, `adm/warehouse/${id}/view/documents`);
  }

  public getWarehouseDocumentsArchive(id: string): Observable<IDocument> {
    return this.httpPost({}, `adm/warehouse/${id}/view/documents-archive`);
  }

  public uploadWarehouseDocument(formData: FormData, id: string, documentType: string): Observable<boolean> {
    return this.httpPost(formData, `adm/warehouse/${id}/upload-document/${documentType}`, true);
  }

  public deleteWarehouseDocument(id: string, documentType: string, changesConfirmed?: IWarehouseUpsertConfirmation): Observable<boolean> {
    return this.httpPost(changesConfirmed || {}, `adm/warehouse/${id}/delete-document/${documentType}`);
  }

  public preloadWarehousePhotos(formData: FormData): Observable<string[]> {
    return this.httpPost(formData, `adm/warehouse/new_photo`, true);
  }

  public getWarehouseReport(id: string): Observable<{ url: string }> {
    return this.httpPost({}, `adm/warehouse/${id}/kontur-focus-report`);
  }
}
