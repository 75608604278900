import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { IEvent, ILoadEvent } from 'angular8-yandex-maps';
import { IClusterConfig, IMapConfig, IPlacemark, IPlacemarkConfig, mapControls } from '../../model/map.model';

export type mapModes = 'view' | 'static-view' | 'pick';

export const DEFAULT_MAP_CENTER_LAT: number = 55.75222;
export const DEFAULT_MAP_CENTER_LON: number = 37.61556;
export const DEFAULT_MAP_ZOOM: number = 12;

const ACTIVE_PLACEMARK_ICON: string = '/assets/icons/ic-placemark-active.svg';
const PLACEMARK_ICON: string = '/assets/icons/ic-placemark.svg';
const PLACEMARK_ICON_SIZE: number = 50;

@Component({
  selector: 'tlp-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnChanges {
  @ViewChild('suggestInput') public suggestInput!: ElementRef;
  @Input() public clusterConfig!: IClusterConfig;
  @Input() public mapControls: mapControls[] | string[] = [];
  @Input() public mapConfig!: IMapConfig;
  @Input() public mode: mapModes = 'view';
  @Input() public search?: boolean = true;
  @Input() public placemarks: IPlacemark[] | null = null;
  @Output() public addressChanged: EventEmitter<IPlacemark> = new EventEmitter<IPlacemark>();
  @Output() public placemarkClicked: EventEmitter<IPlacemark> = new EventEmitter<IPlacemark>();

  public placemarkConfig!: IPlacemarkConfig;
  public placemarkActiveConfig!: IPlacemarkConfig;
  public pickedPlacemark: IPlacemark | null = null;
  public pickedPlacemarkChanged: boolean = false;
  // tslint:disable-next-line: no-any
  public ymapsObject!: any;

  constructor(private cdr: ChangeDetectorRef) {
    this.mapConfig = {
      center: [DEFAULT_MAP_CENTER_LAT, DEFAULT_MAP_CENTER_LON],
      controls: this.mapControls,
      zoom: DEFAULT_MAP_ZOOM,
    };

    this.placemarkConfig = {
      draggable: false,
      iconLayout: 'default#image',
      iconImageHref: PLACEMARK_ICON,
      iconImageSize: [PLACEMARK_ICON_SIZE, PLACEMARK_ICON_SIZE],
      hasBalloon: false,
    };

    this.placemarkActiveConfig = {
      ...this.placemarkConfig,
      iconImageHref: ACTIVE_PLACEMARK_ICON,
    };

    this.clusterConfig = {
      preset: 'islands#invertedRedClusterIcons',
      hasBalloon: false,
    };
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.mapControls?.currentValue?.length) {
      this.mapConfig?.controls?.push(...changes.mapControls.currentValue);
    }

    if (changes.placemarks?.currentValue?.length) {
      if (!this.pickedPlacemark) {
        this.mapConfig.center = [changes.placemarks.currentValue[0].address_lat, changes.placemarks.currentValue[0].address_lon];

        if (this.mode === 'pick' || changes.placemarks.currentValue.length === 1) {
          this.pickedPlacemark = {
            ...changes.placemarks.currentValue[0],
            isActive: true,
          };
        }
      }

      this.cdr.detectChanges();
    }
  }

  // TODO отрефакторить смену иконки
  // tslint:disable-next-line: no-any
  public handlePlacemarkClick(event: any, placemark: IPlacemark): void {
    if (this.placemarks && this.placemarks.length > 1) {
      this.placemarks = this.placemarks.map(placemarkItem =>
        placemarkItem.address_lon === placemark.address_lon && placemarkItem.address_lat === placemark.address_lat
          ? { ...placemarkItem, isActive: true }
          : { ...placemarkItem, isActive: false },
      );

      if (placemark.isActive) {
        event.instance.options.set('iconImageHref', PLACEMARK_ICON);
      } else {
        event.instance.options.set('iconImageHref', ACTIVE_PLACEMARK_ICON);
      }
    }

    this.placemarkClicked.emit(placemark);
  }

  public setPlacemark(event: IEvent): void {
    if (this.mode === 'pick') {
      const coords: number[] = event.event.get('coords');
      // tslint:disable-next-line: no-any
      this.ymapsObject.geocode(coords).then((geocodeResult: any) => {
        this.handleGeocodeResult(geocodeResult, false, coords);
      });
    }
  }

  public emitNewAddress(placemark: IPlacemark): void {
    this.addressChanged.emit(placemark);
  }

  public onMapLoad(event: ILoadEvent): void {
    this.ymapsObject = event.ymaps;

    if (this.mode === 'pick') {
      const suggest = new this.ymapsObject.SuggestView('suggest');

      // tslint:disable-next-line: no-any
      suggest.events.add(['select'], (selectEvent: any) => {
        const value: string = selectEvent.get('item').value;
        // tslint:disable-next-line: no-any
        this.ymapsObject.geocode(value).then((geocodeResult: any) => {
          this.handleGeocodeResult(geocodeResult, true);
        });
      });
    }
  }

  public resetSuggest(): void {
    this.suggestInput.nativeElement.value = '';
  }

  // tslint:disable-next-line: no-any
  private handleGeocodeResult(geocodeResult: any, updateMapCenter?: boolean, coords?: number[]): void {
    const geoObject = geocodeResult.geoObjects.get(0);

    if (geoObject) {
      const coordinates: number[] = coords ? coords : geoObject.geometry.getCoordinates();
      if (updateMapCenter) {
        this.mapConfig.center = [coordinates[0], coordinates[1]];
      }

      this.pickedPlacemark = {
        address_ym_geocoder: geoObject.getAddressLine(),
        address_city_ym_geocoder: geoObject.getLocalities()?.length ? geoObject.getLocalities()[0] : '',
        address_lat: coordinates[0],
        address_lon: coordinates[1],
        isActive: true,
      };

      this.pickedPlacemarkChanged = true;
      this.cdr.detectChanges();
    }
  }
}
