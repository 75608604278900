<div class="counter">
    <input class="counter__value" 
           type="number" 
           (change)="onChangeValue()"
           [(ngModel)]="counterValue">
    <button class="counter__button"
            (click)="onDecrement()"
            [disabled]="isDisabledDecrement"
    >
        <svg class="counter__button-icon counter__button-icon--decrement" width="8" height="2" viewBox="0 0 8 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path class="counter__button-icon-path" stroke="none" d="M8 1.8002H0V0.200195H8V1.8002Z" />
        </svg>    
    </button>
    <button class="counter__button"
            (click)="onIncrement()"
            [disabled]="isDisabledIncrement"
    >
        <svg class="counter__button-icon counter__button-icon--increment" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path class="counter__button-icon-path" stroke="none" d="M8 4.8H4.8V8H3.2V4.8H0V3.2H3.2V0H4.8V3.2H8V4.8Z" />
        </svg>   
    </button>
</div>