import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IOrganizationBankInfo, IOrganizationGeneralInfo, IOrganizationInfo, IOrganization } from '../../models/organization.model';

export enum eOrganizationActions {
  ORGANIZATION_CHECK_INN = '[Organization] check inn',
  ORGANIZATION_CHECK_INN_SUCCESS = '[Organization] check inn success',
  ORGANIZATION_CHECK_INN_FAILURE = '[Organization] check inn failure',

  ORGANIZATION_CHECK_BIK = '[Organization] check bik',
  ORGANIZATION_CHECK_BIK_SUCCESS = '[Organization] check bik success',
  ORGANIZATION_CHECK_BIK_FAILURE = '[Organization] check bik failure',

  ORGANIZATION_GET_CURRENT = '[Organization] get current organization',
  ORGANIZATION_GET_CURRENT_SUCCESS = '[Organization] get current organization success',
  ORGANIZATION_GET_CURRENT_FAILURE = '[Organization] get current organization failure',
  ORGANIZATION_SET_SHORT_INFO = '[Organization] set short organization info',

  ORGANIZATION_CREATE = '[Organization] create organization',
  ORGANIZATION_CREATE_SUCCESS = '[Organization] create organization success',
  ORGANIZATION_CREATE_FAILURE = '[Organization] create organization failure',

  ORGANIZATION_PUBLISH = '[Organization] publish organization',
  ORGANIZATION_PUBLISH_SUCCESS = '[Organization] publish organization success',
  ORGANIZATION_PUBLISH_FAILURE = '[Organization] publish organization failure',

  ORGANIZATION_UPDATE_REQUISITES = '[Organization] update organization requisites',
  ORGANIZATION_UPDATE_REQUISITES_SUCCESS = '[Organization] update organization requisites success',
  ORGANIZATION_UPDATE_REQUISITES_FAILURE = '[Organization] update organization requisites failure',

  ORGANIZATION_DOCUMENT_UPLOAD = '[Organization] upload organization document',
  ORGANIZATION_DOCUMENT_UPLOAD_SUCCESS = '[Organization] upload organization document success',
  ORGANIZATION_DOCUMENT_UPLOAD_FAILURE = '[Organization] upload organization document failure',

  ORGANIZATION_DOCUMENT_DELETE = '[Organization] delete organization document',
  ORGANIZATION_DOCUMENT_DELETE_SUCCESS = '[Organization] delete organization document success',
  ORGANIZATION_DOCUMENT_DELETE_FAILURE = '[Organization] delete organization document failure',

  ORGANIZATION_RESET_BANK_INFO = '[Organization] reset organization bank info',
  ORGANIZATION_RESET_SHORT_INFO = '[Organization] reset organization short info',
  ORGANIZATION_RESET_STATE = '[Organization] reset organization state',
  ORGANIZATION_RESET_PUBLISHING_STATE = '[Organization] reset organization publishing state',
  ORGANIZATION_RESET_UPDATING_STATE = '[Organization] reset organization updating state',
}

export const checkOrganizationInn = createAction(eOrganizationActions.ORGANIZATION_CHECK_INN, props<{ inn: string }>());
export const checkOrganizationInnSuccess = createAction(
  eOrganizationActions.ORGANIZATION_CHECK_INN_SUCCESS,
  props<{ organizationShortInfo: IOrganizationGeneralInfo }>(),
);
export const checkOrganizationInnFailure = createAction(
  eOrganizationActions.ORGANIZATION_CHECK_INN_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const checkOrganizationBik = createAction(eOrganizationActions.ORGANIZATION_CHECK_BIK, props<{ bank_bik: string }>());
export const checkOrganizationBikSuccess = createAction(
  eOrganizationActions.ORGANIZATION_CHECK_BIK_SUCCESS,
  props<{ organizationBankInfo: IOrganizationBankInfo | IOrganizationBankInfo[] }>(),
);
export const checkOrganizationBikFailure = createAction(
  eOrganizationActions.ORGANIZATION_CHECK_BIK_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getCurrentOrganization = createAction(eOrganizationActions.ORGANIZATION_GET_CURRENT);
export const getCurrentOrganizationSuccess = createAction(
  eOrganizationActions.ORGANIZATION_GET_CURRENT_SUCCESS,
  props<{ organization: IOrganization }>(),
);
export const getCurrentOrganizationFailure = createAction(
  eOrganizationActions.ORGANIZATION_GET_CURRENT_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const setOrganizationShortInfo = createAction(
  eOrganizationActions.ORGANIZATION_SET_SHORT_INFO,
  props<{ organizationShortInfo: IOrganizationGeneralInfo | null }>(),
);

export const createOrganization = createAction(eOrganizationActions.ORGANIZATION_CREATE, props<{ organizationInfo: IOrganizationInfo }>());
export const createOrganizationSuccess = createAction(eOrganizationActions.ORGANIZATION_CREATE_SUCCESS);
export const createOrganizationFailure = createAction(
  eOrganizationActions.ORGANIZATION_CREATE_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const publishOrganization = createAction(eOrganizationActions.ORGANIZATION_PUBLISH);
export const publishOrganizationSuccess = createAction(eOrganizationActions.ORGANIZATION_PUBLISH_SUCCESS);
export const publishOrganizationFailure = createAction(
  eOrganizationActions.ORGANIZATION_PUBLISH_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const updateOrganizationRequisites = createAction(
  eOrganizationActions.ORGANIZATION_UPDATE_REQUISITES,
  props<{ organizationInfo: IOrganizationInfo }>(),
);
export const updateOrganizationRequisitesSuccess = createAction(eOrganizationActions.ORGANIZATION_UPDATE_REQUISITES_SUCCESS);
export const updateOrganizationRequisitesFailure = createAction(
  eOrganizationActions.ORGANIZATION_UPDATE_REQUISITES_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const uploadOrganizationDocument = createAction(
  eOrganizationActions.ORGANIZATION_DOCUMENT_UPLOAD,
  props<{ file: File; documentType: string }>(),
);
export const uploadOrganizationDocumentSuccess = createAction(eOrganizationActions.ORGANIZATION_DOCUMENT_UPLOAD_SUCCESS);
export const uploadOrganizationDocumentFailure = createAction(
  eOrganizationActions.ORGANIZATION_DOCUMENT_UPLOAD_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const deleteOrganizationDocument = createAction(
  eOrganizationActions.ORGANIZATION_DOCUMENT_DELETE,
  props<{ documentType: string }>(),
);
export const deleteOrganizationDocumentSuccess = createAction(eOrganizationActions.ORGANIZATION_DOCUMENT_DELETE_SUCCESS);
export const deleteOrganizationDocumentFailure = createAction(
  eOrganizationActions.ORGANIZATION_DOCUMENT_DELETE_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const resetOrganizationBankInfo = createAction(eOrganizationActions.ORGANIZATION_RESET_BANK_INFO);
export const resetOrganizationShortInfo = createAction(eOrganizationActions.ORGANIZATION_RESET_SHORT_INFO);
export const resetOrganizationState = createAction(eOrganizationActions.ORGANIZATION_RESET_STATE);
export const resetOrganizationPublishingState = createAction(eOrganizationActions.ORGANIZATION_RESET_PUBLISHING_STATE);
export const resetOrganizationUpdatingState = createAction(eOrganizationActions.ORGANIZATION_RESET_UPDATING_STATE);
