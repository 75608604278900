import { Component, forwardRef, Input, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, ValidationErrors, FormControl } from '@angular/forms';

@Component({
  selector: 'tlp-input-pass',
  templateUrl: './input-pass.component.html',
  styleUrls: ['./input-pass.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPassComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputPassComponent),
      multi: true,
    },
  ],
})
export class InputPassComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {
  @Input() public placeholder: string = '';
  @Input() public required: boolean = false;
  @Input() public confirmPassword: string | undefined = undefined;
  @Input() public autocomplete: string = 'off';

  public formControl!: FormControl | null;
  public isDisabled: boolean = false;
  public isPasswordVisible: boolean = false;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.formControl = new FormControl();
  }

  public ngOnDestroy(): void {
    this.formControl = null;
  }

  private onChange = (_: number | string) => {};
  private onTouched = () => {};

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  public writeValue(value: string): void {
    this.formControl?.setValue(value, { emitEvent: false });
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.formControl?.disable();
      this.isDisabled = true;
    } else {
      this.formControl?.enable();
      this.isDisabled = false;
    }

    this.cdr.detectChanges();
  }

  public onValueChange(event: Event | FocusEvent): void {
    const value = (event.target as HTMLInputElement).value;
    this.onTouched();
    this.onChange(value);
  }

  public validate(): ValidationErrors | null {
    if (this.formControl?.value === undefined) {
      return null;
    }

    if (this.confirmPassword && this.confirmPassword !== this.formControl.value) {
      return {
        passwordNotMatch: true,
      };
    }

    return this.formControl.errors;
  }
}
