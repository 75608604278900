import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IUser, IUsersApiParams, IUsers } from '../../models/user.model';

export enum eUsersActions {
  USERS_GET = '[Admin: Users] get users',
  USERS_GET_SUCCESS = '[Admin: Users] get users success',
  USERS_GET_FAILURE = '[Admin: Users] get users failure',

  USER_GET = '[Admin: Users] get user',
  USER_GET_SUCCESS = '[Admin: Users] get user success',
  USER_GET_FAILURE = '[Admin: Users] get user failure',

  USER_DELETE = '[Admin: Users] delete user',
  USER_DELETE_SUCCESS = '[Admin: Users] get delete item success',
  USER_DELETE_FAILURE = '[Admin: Users] get delete item failure',

  USER_CREATE = '[Admin: Users] create user',
  USER_CREATE_SUCCESS = '[Admin: Users] get create item success',
  USER_CREATE_FAILURE = '[Admin: Users] get create item failure',

  USER_UPDATE = '[Admin: Users] update user',
  USER_UPDATE_SUCCESS = '[Admin: Users] get update item success',
  USER_UPDATE_FAILURE = '[Admin: Users] get update item failure',

  USERS_RESET = '[Admin: Users] reset users',
  USER_RESET = '[Admin: Users] reset user',
  USERS_RESET_LOADING_STATE = '[Admin: Users] reset users loading state',
  USER_RESET_LOADING_STATE = '[Admin: Users] reset user loading state',
  USER_RESET_UPDATING_STATE = '[Admin: Users] reset user updating state',
  USERS_RESET_STATE = '[Admin: Users] reset users state',
}

export const getUsers = createAction(eUsersActions.USERS_GET, props<{ params: IUsersApiParams }>());
export const getUsersSuccess = createAction(eUsersActions.USERS_GET_SUCCESS, props<{ users: IUsers }>());
export const getUsersFailure = createAction(eUsersActions.USERS_GET_FAILURE, props<{ error: HttpErrorResponse }>());

export const getUser = createAction(eUsersActions.USER_GET, props<{ id: string }>());
export const getUserSuccess = createAction(eUsersActions.USER_GET_SUCCESS, props<{ user: IUser }>());
export const getUserFailure = createAction(eUsersActions.USER_GET_FAILURE, props<{ error: HttpErrorResponse }>());

export const deleteUser = createAction(eUsersActions.USER_DELETE, props<{ id: string }>());
export const deleteUserSuccess = createAction(eUsersActions.USER_DELETE_SUCCESS);
export const deleteUserFailure = createAction(eUsersActions.USER_DELETE_FAILURE, props<{ error: HttpErrorResponse }>());

export const createUser = createAction(eUsersActions.USER_CREATE, props<{ data: IUser }>());
export const createUserSuccess = createAction(eUsersActions.USER_CREATE_SUCCESS, props<{ id: string }>());
export const createUserFailure = createAction(eUsersActions.USER_CREATE_FAILURE, props<{ error: HttpErrorResponse }>());

export const updateUser = createAction(eUsersActions.USER_UPDATE, props<{ data: IUser; id: string }>());
export const updateUserSuccess = createAction(eUsersActions.USER_UPDATE_SUCCESS);
export const updateUserFailure = createAction(eUsersActions.USER_UPDATE_FAILURE, props<{ error: HttpErrorResponse }>());

export const resetUsers = createAction(eUsersActions.USERS_RESET);
export const resetUser = createAction(eUsersActions.USER_RESET);
export const resetUsersLoadingState = createAction(eUsersActions.USERS_RESET_LOADING_STATE);
export const resetUserLoadingState = createAction(eUsersActions.USER_RESET_LOADING_STATE);
export const resetUserUpdatingState = createAction(eUsersActions.USER_RESET_UPDATING_STATE);
export const resetUsersState = createAction(eUsersActions.USERS_RESET_STATE);
