<textarea
    class="textarea-field"
    [formControl]="formControl"
    [ngClass]="['textarea-field--' + theme]"
    [class.textarea-field--error]="formControl.touched && formControl.errors"
    [class.textarea-field--disabled]="isDisabled"
    [placeholder]="placeholder"
    [rows]="rows"
    [required]="required"
    (keyup)="onValueChange($event)"
    (blur)="onValueChange($event)"
></textarea>
