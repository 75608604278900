import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractApiMethodsService } from './abstract-api-methods.service';
import { IWarehousesCatalog, IWarehousesCatalogApiParams, IWarehousesCatalogItem } from '../models/warehouses-catalog.model';

@Injectable()
export class ApiWarehousesCatalogService extends AbstractApiMethodsService {
  public getWarehousesCatalog(params: IWarehousesCatalogApiParams): Observable<IWarehousesCatalog | null> {
    return this.httpPost(params, 'catalog/index');
  }

  public getWarehousesCatalogItem(id: string): Observable<IWarehousesCatalogItem | null> {
    return this.httpPost({}, `catalog/${id}/view`);
  }
}
