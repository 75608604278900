import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IHttpRequestState } from '../../models/http.model';
import { IOrganizationStoreState } from './organization.state';
import { IOrganizationGeneralInfo, IOrganizationInfo, IOrganization, IOrganizationBankInfo } from '../../models/organization.model';

import * as organizationActions from './organization.actions';
import * as organizationSelectors from './organization.selectors';

@Injectable()
export class OrganizationStoreService {
  constructor(private organizationStore: Store<IOrganizationStoreState>) {}

  get organization$(): Observable<IOrganization | null> {
    return this.organizationStore.select(organizationSelectors.organization);
  }

  get organizationBankInfo$(): Observable<IOrganizationBankInfo | IOrganizationBankInfo[] | null> {
    return this.organizationStore.select(organizationSelectors.organizationBankInfo);
  }

  get organizationShortInfo$(): Observable<IOrganizationGeneralInfo | null> {
    return this.organizationStore.select(organizationSelectors.organizationShortInfo);
  }

  get organizationStoreState$(): Observable<IOrganizationStoreState> {
    return this.organizationStore.select(organizationSelectors.getOrganizationStoreState);
  }

  get organizationBankInfoLoadingState$(): Observable<IHttpRequestState | null> {
    return this.organizationStore.select(organizationSelectors.getOrganizationBankInfoLoadingState);
  }

  get organizationBankInfoIsLoading$(): Observable<boolean | undefined> {
    return this.organizationStore.select(organizationSelectors.getOrganizationBankInfoIsLoading);
  }

  get organizationLoadingState$(): Observable<IHttpRequestState | null> {
    return this.organizationStore.select(organizationSelectors.getOrganizationLoadingState);
  }

  get organizationPublishingState$(): Observable<IHttpRequestState | null> {
    return this.organizationStore.select(organizationSelectors.getOrganizationPublishingState);
  }

  get organizationUpdatingState$(): Observable<IHttpRequestState | null> {
    return this.organizationStore.select(organizationSelectors.getOrganizationUpdatingState);
  }

  public checkOrganizationInn(inn: string): void {
    this.organizationStore.dispatch(organizationActions.checkOrganizationInn({ inn }));
  }

  public checkOrganizationBik(bank_bik: string): void {
    this.organizationStore.dispatch(organizationActions.checkOrganizationBik({ bank_bik }));
  }

  public getCurrentOrganization(): void {
    this.organizationStore.dispatch(organizationActions.getCurrentOrganization());
  }

  public createOrganization(organizationInfo: IOrganizationInfo): void {
    this.organizationStore.dispatch(organizationActions.createOrganization({ organizationInfo }));
  }

  public publishOrganization(): void {
    this.organizationStore.dispatch(organizationActions.publishOrganization());
  }

  public updateOrganizationRequisites(organizationInfo: IOrganizationInfo): void {
    this.organizationStore.dispatch(organizationActions.updateOrganizationRequisites({ organizationInfo }));
  }

  public uploadOrganizationDocument(file: File, documentType: string): void {
    this.organizationStore.dispatch(organizationActions.uploadOrganizationDocument({ file, documentType }));
  }

  public deleteOrganizationDocument(documentType: string): void {
    this.organizationStore.dispatch(organizationActions.deleteOrganizationDocument({ documentType }));
  }

  public setOrganizationShortInfo(organizationShortInfo: IOrganizationGeneralInfo | null): void {
    this.organizationStore.dispatch(organizationActions.setOrganizationShortInfo({ organizationShortInfo }));
  }

  public resetOrganizationBankInfo(): void {
    this.organizationStore.dispatch(organizationActions.resetOrganizationBankInfo());
  }

  public resetOrganizationShortInfo(): void {
    this.organizationStore.dispatch(organizationActions.resetOrganizationShortInfo());
  }

  public resetOrganizationState(): void {
    this.organizationStore.dispatch(organizationActions.resetOrganizationState());
  }

  public resetOrganizationPublishingState(): void {
    this.organizationStore.dispatch(organizationActions.resetOrganizationPublishingState());
  }

  public resetOrganizationUpdatingState(): void {
    this.organizationStore.dispatch(organizationActions.resetOrganizationUpdatingState());
  }
}
