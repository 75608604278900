import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ButtonModule } from '../_ui/button/button.module';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { NotificationsMenuModule } from '../notifications-menu/notifications-menu.module';
import { PageActionButtonsModule } from '../_ui/page-action-buttons/page-action-buttons.module';
import { MainHeaderComponent } from './component/main-header/main-header.component';

@NgModule({
  declarations: [MainHeaderComponent],
  imports: [CommonModule, RouterModule, ButtonModule, IconSpriteModule, NotificationsMenuModule, PageActionButtonsModule],
  exports: [MainHeaderComponent],
})
export class MainHeaderModule {}
