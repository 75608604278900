import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTimeComponent } from './component/input-time.component';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [InputTimeComponent],
  imports: [CommonModule, NgxMaskModule, ReactiveFormsModule, FormsModule],
  exports: [InputTimeComponent],
})
export class InputTimeModule {}
