import { Action, createReducer, on } from '@ngrx/store';
import { InitRegisterState, IRegisterStoreState } from './register.state';
import * as registerActions from './register.actions';

const registerReducer = createReducer(
  InitRegisterState,
  on(registerActions.register, state => ({
    ...state,
    registerLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(registerActions.registerSuccess, state => ({
    ...state,
    registerLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(registerActions.registerFailure, (state, { error }) => ({
    ...state,
    registerLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(registerActions.registerConfirm, state => ({
    ...state,
    registerLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(registerActions.registerConfirmSuccess, state => ({
    ...state,
    registerLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(registerActions.registerConfirmFailure, (state, { error }) => ({
    ...state,
    registerLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(registerActions.sendRegisterConfirmEmail, state => ({
    ...state,
    registerLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(registerActions.sendRegisterConfirmEmailSuccess, state => ({
    ...state,
    registerLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(registerActions.sendRegisterConfirmEmailFailure, (state, { error }) => ({
    ...state,
    registerLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(registerActions.resetRegisterState, () => ({ ...InitRegisterState })),
);

export const RegisterReducer = (state: IRegisterStoreState, action: Action) => {
  return registerReducer(state, action);
};
