import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { ApiStorageAreaService } from '../../api/admin/api-storage-area.service';
import { HttpErrorsService } from '../../api/http-errors.service';

import { IBookingDate, IBookingDatesFilters } from '../../models/booking.model';
import { IStorageArea, IStorageAreaUpsert, IStorageAreas, IStorageAreasApiParams } from '../../models/storage-area.model';
import { eStorageAreaActions } from './storage-area.actions';
import { HTTP_TOASTR_ERROR_FIELDS } from '../../const/http-error-codes.const';

@Injectable()
export class StorageAreaEffects {
  constructor(
    private actions$: Actions,
    private toastrService: ToastrService,
    private apiStorageAreaService: ApiStorageAreaService,
    private httpErrorsService: HttpErrorsService,
  ) {}

  public getStorageAreas$: Observable<
    { type: eStorageAreaActions; storageAreas: IStorageAreas | null } | { type: eStorageAreaActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eStorageAreaActions.STORAGE_AREAS_GET),
      switchMap((payload: { type: string; params: IStorageAreasApiParams }) =>
        this.apiStorageAreaService.getStorageAreas(payload.params).pipe(
          map(storageAreas => ({ type: eStorageAreaActions.STORAGE_AREAS_GET_SUCCESS, storageAreas })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eStorageAreaActions.STORAGE_AREAS_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getStorageArea$: Observable<
    { type: eStorageAreaActions; storageArea: IStorageArea | null } | { type: eStorageAreaActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eStorageAreaActions.STORAGE_AREA_GET),
      switchMap((payload: { type: string; id: string }) =>
        this.apiStorageAreaService.getStorageArea(payload.id).pipe(
          map(storageArea => ({ type: eStorageAreaActions.STORAGE_AREA_GET_SUCCESS, storageArea })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eStorageAreaActions.STORAGE_AREA_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getStorageAreaBookingDates$: Observable<
    { type: eStorageAreaActions; storageAreaBookingDates: IBookingDate[] | null } | { type: eStorageAreaActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eStorageAreaActions.STORAGE_AREA_BOOKING_DATES_GET),
      switchMap((payload: { type: string; bookingDatesFilters: IBookingDatesFilters; id: string }) =>
        this.apiStorageAreaService.getStorageAreaBookingDates(payload.bookingDatesFilters, payload.id).pipe(
          map(storageAreaBookingDates => ({ type: eStorageAreaActions.STORAGE_AREA_BOOKING_DATES_GET_SUCCESS, storageAreaBookingDates })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eStorageAreaActions.STORAGE_AREA_BOOKING_DATES_GET_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public createStorageArea$: Observable<
    { type: eStorageAreaActions } | { type: eStorageAreaActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eStorageAreaActions.STORAGE_AREA_CREATE),
      switchMap((payload: { type: string; data: IStorageAreaUpsert }) =>
        this.apiStorageAreaService.createStorageArea(payload.data).pipe(
          tap(() => {
            this.toastrService.success('Зона хранения успешно создана');
          }),
          map(() => ({ type: eStorageAreaActions.STORAGE_AREA_CREATE_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(err)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            }

            return of({ type: eStorageAreaActions.STORAGE_AREA_CREATE_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public updateStorageArea$: Observable<
    { type: eStorageAreaActions } | { type: eStorageAreaActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eStorageAreaActions.STORAGE_AREA_UPDATE),
      switchMap((payload: { type: string; data: IStorageAreaUpsert; id: string }) =>
        this.apiStorageAreaService.updateStorageArea(payload.data, payload.id).pipe(
          tap(() => {
            this.toastrService.success('Зона хранения успешно обновлена');
          }),
          map(() => ({ type: eStorageAreaActions.STORAGE_AREA_UPDATE_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            if (!this.httpErrorsService.isDataValidationError(err)) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            }

            return of({ type: eStorageAreaActions.STORAGE_AREA_UPDATE_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public deleteStorageArea$: Observable<
    { type: eStorageAreaActions } | { type: eStorageAreaActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eStorageAreaActions.STORAGE_AREA_DELETE),
      switchMap((payload: { type: string; id: string; params: IStorageAreasApiParams }) =>
        this.apiStorageAreaService.deleteStorageArea(payload.id).pipe(
          tap(() => {
            this.toastrService.success('Зона хранения успешно удалена');
          }),
          map(() => ({ type: eStorageAreaActions.STORAGE_AREA_DELETE_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            if (
              !this.httpErrorsService.isDataValidationError(err) ||
              this.httpErrorsService.isCertainError(err, HTTP_TOASTR_ERROR_FIELDS)
            ) {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            }

            return of({ type: eStorageAreaActions.STORAGE_AREA_DELETE_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public preloadStorageAreaPhotos$: Observable<
    { type: eStorageAreaActions; storageAreaPreloadedPhotos: string[] } | { type: eStorageAreaActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eStorageAreaActions.STORAGE_AREA_PHOTOS_PRELOAD),
      map((payload: { type: string; photos: File[] }) => {
        const formData = new FormData();
        payload.photos.forEach(photo => {
          formData.append('file[]', photo);
        });

        return { formData };
      }),
      switchMap((payload: { formData: FormData }) =>
        this.apiStorageAreaService.preloadStorageAreaPhotos(payload.formData).pipe(
          map(storageAreaPreloadedPhotos => ({
            type: eStorageAreaActions.STORAGE_AREA_PHOTOS_PRELOAD_SUCCESS,
            storageAreaPreloadedPhotos,
          })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eStorageAreaActions.STORAGE_AREA_PHOTOS_PRELOAD_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );
}
