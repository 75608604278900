import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { StatusNoteComponent } from './component/status-note/status-note.component';

@NgModule({
  declarations: [StatusNoteComponent],
  imports: [CommonModule, IconSpriteModule],
  exports: [StatusNoteComponent],
})
export class StatusNoteModule {}
