import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IDictionaryApiParams, IDictionaryItems } from '../../models/dictionary.model';
import { IDictionaryStoreState } from './dictionary.state';

import * as dictionaryActions from './dictionary.actions';
import * as dictionarySelectors from './dictionary.selectors';

@Injectable()
export class DictionaryStoreService {
  constructor(private dictionaryStore: Store<IDictionaryStoreState>) {}

  get accountingControlSystemsDictionary$(): Observable<IDictionaryItems | null> {
    return this.dictionaryStore.select(dictionarySelectors.getAccountingControlSystemsDictionary);
  }

  get accountingSystemTypeDictionary$(): Observable<IDictionaryItems | null> {
    return this.dictionaryStore.select(dictionarySelectors.getAccountingSystemTypeDictionary);
  }

  get classificationDictionary$(): Observable<IDictionaryItems | null> {
    return this.dictionaryStore.select(dictionarySelectors.getClassificationDictionary);
  }

  get cranageTypesDictionary$(): Observable<IDictionaryItems | null> {
    return this.dictionaryStore.select(dictionarySelectors.getCranageTypesDictionary);
  }

  get electricityGridTypeDictionary$(): Observable<IDictionaryItems | null> {
    return this.dictionaryStore.select(dictionarySelectors.getElectricityGridTypeDictionary);
  }

  get fireExtSystemTypeDictionary$(): Observable<IDictionaryItems | null> {
    return this.dictionaryStore.select(dictionarySelectors.getFireExtSystemTypeDictionary);
  }

  get floorCoveringDictionary$(): Observable<IDictionaryItems | null> {
    return this.dictionaryStore.select(dictionarySelectors.getFloorCoveringDictionary);
  }

  get heatingSystemTypeDictionary$(): Observable<IDictionaryItems | null> {
    return this.dictionaryStore.select(dictionarySelectors.getHeatingSystemTypeDictionary);
  }

  get loadingUnloadingMethodsDictionary$(): Observable<IDictionaryItems | null> {
    return this.dictionaryStore.select(dictionarySelectors.getLoadingUnloadingMethodsDictionary);
  }

  get loadingUnloadingStructTypesDictionary$(): Observable<IDictionaryItems | null> {
    return this.dictionaryStore.select(dictionarySelectors.getLoadingUnloadingStructTypesDictionary);
  }

  get loadingUnloadingTechTypesDictionary$(): Observable<IDictionaryItems | null> {
    return this.dictionaryStore.select(dictionarySelectors.getLoadingUnloadingTechTypesDictionary);
  }

  get officeFacilityTypesDictionary$(): Observable<IDictionaryItems | null> {
    return this.dictionaryStore.select(dictionarySelectors.getOfficeFacilityTypesDictionary);
  }

  get parkingTypeDictionary$(): Observable<IDictionaryItems | null> {
    return this.dictionaryStore.select(dictionarySelectors.getParkingTypeDictionary);
  }

  get storageFormatDictionary$(): Observable<IDictionaryItems | null> {
    return this.dictionaryStore.select(dictionarySelectors.getStorageFormatDictionary);
  }

  get storageSystemDictionary$(): Observable<IDictionaryItems | null> {
    return this.dictionaryStore.select(dictionarySelectors.getStorageSystemDictionary);
  }

  get taxationTypeDictionary$(): Observable<IDictionaryItems | null> {
    return this.dictionaryStore.select(dictionarySelectors.getTaxationTypeDictionary);
  }

  get temperatureModeDictionary$(): Observable<IDictionaryItems | null> {
    return this.dictionaryStore.select(dictionarySelectors.getTemperatureModeDictionary);
  }

  get unitDictionary$(): Observable<IDictionaryItems | null> {
    return this.dictionaryStore.select(dictionarySelectors.getUnitDictionary);
  }

  get unitDistanceDictionary$(): Observable<IDictionaryItems | null> {
    return this.dictionaryStore.select(dictionarySelectors.getUnitDistanceDictionary);
  }

  get unitMassDictionary$(): Observable<IDictionaryItems | null> {
    return this.dictionaryStore.select(dictionarySelectors.getUnitMassDictionary);
  }

  get unitSquareDictionary$(): Observable<IDictionaryItems | null> {
    return this.dictionaryStore.select(dictionarySelectors.getUnitSquareDictionary);
  }

  get unitTemperatureDictionary$(): Observable<IDictionaryItems | null> {
    return this.dictionaryStore.select(dictionarySelectors.getUnitTemperatureDictionary);
  }

  get legalTypeDictionary$(): Observable<IDictionaryItems | null> {
    return this.dictionaryStore.select(dictionarySelectors.getLegalTypeDictionary);
  }

  get partnerTypeDictionary$(): Observable<IDictionaryItems | null> {
    return this.dictionaryStore.select(dictionarySelectors.getPartnerTypeDictionary);
  }

  public getAccountingControlSystemsDictionary(params?: IDictionaryApiParams): void {
    this.dictionaryStore.dispatch(dictionaryActions.getAccountingControlSystemsDictionary({ params }));
  }

  public getAccountingSystemTypeDictionary(params?: IDictionaryApiParams): void {
    this.dictionaryStore.dispatch(dictionaryActions.getAccountingSystemTypeDictionary({ params }));
  }

  public getClassificationDictionary(params?: IDictionaryApiParams): void {
    this.dictionaryStore.dispatch(dictionaryActions.getClassificationDictionary({ params }));
  }

  public getCranageTypesDictionary(params?: IDictionaryApiParams): void {
    this.dictionaryStore.dispatch(dictionaryActions.getCranageTypesDictionary({ params }));
  }

  public getElectricityGridTypeDictionary(params?: IDictionaryApiParams): void {
    this.dictionaryStore.dispatch(dictionaryActions.getElectricityGridTypeDictionary({ params }));
  }

  public getFireExtSystemTypeDictionary(params?: IDictionaryApiParams): void {
    this.dictionaryStore.dispatch(dictionaryActions.getFireExtSystemTypeDictionary({ params }));
  }

  public getFloorCoveringDictionary(params?: IDictionaryApiParams): void {
    this.dictionaryStore.dispatch(dictionaryActions.getFloorCoveringDictionary({ params }));
  }

  public getHeatingSystemTypeDictionary(params?: IDictionaryApiParams): void {
    this.dictionaryStore.dispatch(dictionaryActions.getHeatingSystemTypeDictionary({ params }));
  }

  public getLoadingUnloadingMethodsDictionary(params?: IDictionaryApiParams): void {
    this.dictionaryStore.dispatch(dictionaryActions.getLoadingUnloadingMethodsDictionary({ params }));
  }

  public getLoadingUnloadingStructTypesDictionary(params?: IDictionaryApiParams): void {
    this.dictionaryStore.dispatch(dictionaryActions.getLoadingUnloadingStructTypesDictionary({ params }));
  }

  public getLoadingUnloadingTechTypesDictionary(params?: IDictionaryApiParams): void {
    this.dictionaryStore.dispatch(dictionaryActions.getLoadingUnloadingTechTypesDictionary({ params }));
  }

  public getOfficeFacilityTypesDictionary(params?: IDictionaryApiParams): void {
    this.dictionaryStore.dispatch(dictionaryActions.getOfficeFacilityTypesDictionary({ params }));
  }

  public getParkingTypeDictionary(params?: IDictionaryApiParams): void {
    this.dictionaryStore.dispatch(dictionaryActions.getParkingTypeDictionary({ params }));
  }

  public getStorageFormatDictionary(params?: IDictionaryApiParams): void {
    this.dictionaryStore.dispatch(dictionaryActions.getStorageFormatDictionary({ params }));
  }

  public getStorageSystemDictionary(params?: IDictionaryApiParams): void {
    this.dictionaryStore.dispatch(dictionaryActions.getStorageSystemDictionary({ params }));
  }

  public getStorageSystemDictionaryPublic(params?: IDictionaryApiParams): void {
    this.dictionaryStore.dispatch(dictionaryActions.getStorageSystemDictionaryPublic({ params }));
  }

  public getTaxationTypeDictionary(params?: IDictionaryApiParams): void {
    this.dictionaryStore.dispatch(dictionaryActions.getTaxationTypeDictionary({ params }));
  }

  public getTemperatureModeDictionary(params?: IDictionaryApiParams): void {
    this.dictionaryStore.dispatch(dictionaryActions.getTemperatureModeDictionary({ params }));
  }

  public getUnitDictionary(params?: IDictionaryApiParams): void {
    this.dictionaryStore.dispatch(dictionaryActions.getUnitDictionary({ params }));
  }

  public getUnitDistanceDictionary(params?: IDictionaryApiParams): void {
    this.dictionaryStore.dispatch(dictionaryActions.getUnitDistanceDictionary({ params }));
  }

  public getUnitMassDictionary(params?: IDictionaryApiParams): void {
    this.dictionaryStore.dispatch(dictionaryActions.getUnitMassDictionary({ params }));
  }

  public getUnitSquareDictionary(params?: IDictionaryApiParams): void {
    this.dictionaryStore.dispatch(dictionaryActions.getUnitSquareDictionary({ params }));
  }

  public getUnitTemperatureDictionary(params?: IDictionaryApiParams): void {
    this.dictionaryStore.dispatch(dictionaryActions.getUnitTemperatureDictionary({ params }));
  }

  public resetDictionaryStoreState(): void {
    this.dictionaryStore.dispatch(dictionaryActions.resetDictionaryState());
  }

  public resetDictionaryLoadingState(): void {
    this.dictionaryStore.dispatch(dictionaryActions.resetDictionaryLoadingState());
  }

  public getLegalTypeDictionary(params?: IDictionaryApiParams): void {
    this.dictionaryStore.dispatch(dictionaryActions.getLegalTypeDictionary({ params }));
  }

  public getPartnerTypeDictionary(params?: IDictionaryApiParams): void {
    this.dictionaryStore.dispatch(dictionaryActions.getPartnerTypeDictionary({ params }));
  }
}
