<div class="photo-carousel">
  <ng-container *ngIf="viewMode === 'extended' && activeItem">
    <div class="photo-carousel__active-photo-wrapper">
      <img class="photo-carousel__active-photo" [src]="activeItem.file.path" [alt]="activeItem.file.name || 'Главная фотография'" />
    </div>
    <div class="photo-carousel__extended-nav extended-nav">
      <div class="extended-nav__prev" (click)="switchPhoto('prev')">
        <svg-icon-sprite src="./assets/sprites/sprite.svg#ic-left" width="16px" height="16px"></svg-icon-sprite>
      </div>
      <div class="extended-nav__next" (click)="switchPhoto('next')">
        <svg-icon-sprite src="./assets/sprites/sprite.svg#ic-right" width="16px" height="16px"></svg-icon-sprite>
      </div>
    </div>
  </ng-container>

  <div class="photo-carousel__carousel-wrapper">
    <owl-carousel-o class="owl-carousel-o" [options]="config" (dragging)="isDragging = $event.dragging">
      <ng-container *ngFor="let item of items; let i = index">
        <ng-template carouselSlide [width]="slideWidth">
          <div
            class="slider-item"
            [ngClass]="{ 'slider-item--non-interactive': !actions?.length }"
            (click)="onSliderItemClicked('view', item)"
          >
            <img
              class="slider-item__photo"
              [@appear]
              [ngClass]="{ 'slider-item__photo--rounded': viewMode === 'compact' }"
              [style.height.px]="slideWidth"
              [style.width.px]="slideWidth"
              [src]="item.file.path"
              [alt]="item.file.name || 'Фотография ' + (i + 1)"
            />
            <div class="slider-item__actions" *ngIf="actions?.length">
              <svg-icon-sprite
                class="slider-item__action-icon slider-action-icon"
                width="16px"
                height="16px"
                *ngFor="let action of actions"
                [ngClass]="'slider-item__action-icon--' + action.type"
                [class.slider-item__action-icon--visible]="action.type === 'main' && item.id === mainItemId"
                [src]="'./assets/sprites/sprite.svg#ic-' + (action.icon || action.type)"
                (click)="onSliderItemClicked(action.type, item)"
              ></svg-icon-sprite>
            </div>
            <div class="slider-item__counter" *ngIf="viewMode === 'compact'">
              <span class="slider-item__counter-value">{{ i + 1 }} / {{ items?.length }}</span>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>
