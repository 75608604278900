import { Action, createReducer, on } from '@ngrx/store';
import { IForgotStoreState, InitForgotState } from './forgot.state';
import * as forgotActions from './forgot.actions';

const forgotReducer = createReducer(
  InitForgotState,
  on(forgotActions.resetPassword, state => ({
    ...state,
    forgotLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(forgotActions.resetPasswordSuccess, state => ({
    ...state,
    forgotLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(forgotActions.resetPasswordFailure, (state, { error }) => ({
    ...state,
    forgotLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(forgotActions.setPassword, state => ({
    ...state,
    forgotLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(forgotActions.setPasswordSuccess, state => ({
    ...state,
    forgotLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(forgotActions.setPasswordFailure, (state, { error }) => ({
    ...state,
    forgotLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(forgotActions.resetForgotState, () => ({ ...InitForgotState })),
);

export const ForgotReducer = (state: IForgotStoreState, action: Action) => {
  return forgotReducer(state, action);
};
