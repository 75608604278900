import { Action, createReducer, on } from '@ngrx/store';
import { InitStorageAreaState, IStorageAreaStoreState } from './storage-area.state';
import * as storageAreasActions from './storage-area.actions';

const storageAreaReducer = createReducer(
  InitStorageAreaState,
  on(storageAreasActions.getStorageAreas, state => ({
    ...state,
    storageAreasLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(storageAreasActions.getStorageAreasSuccess, (state, { storageAreas }) => ({
    ...state,
    storageAreas,
    storageAreasLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(storageAreasActions.getStorageAreasFailure, (state, { error }) => ({
    ...state,
    storageAreasLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(storageAreasActions.getStorageArea, state => ({
    ...state,
    storageAreaLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(storageAreasActions.getStorageAreaSuccess, (state, { storageArea }) => ({
    ...state,
    storageArea,
    storageAreaLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(storageAreasActions.getStorageAreaFailure, (state, { error }) => ({
    ...state,
    storageAreaLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(storageAreasActions.getStorageAreaBookingDates, state => ({
    ...state,
    storageAreaBookingDatesLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(storageAreasActions.getStorageAreaBookingDatesSuccess, (state, { storageAreaBookingDates }) => ({
    ...state,
    storageAreaBookingDates,
    storageAreaBookingDatesLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(storageAreasActions.getStorageAreaBookingDatesFailure, (state, { error }) => ({
    ...state,
    storageAreaBookingDatesLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(storageAreasActions.createStorageArea, state => ({
    ...state,
    storageAreaUpdatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(storageAreasActions.createStorageAreaSuccess, state => ({
    ...state,
    storageAreaUpdatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(storageAreasActions.createStorageAreaFailure, (state, { error }) => ({
    ...state,
    storageAreaUpdatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(storageAreasActions.updateStorageArea, state => ({
    ...state,
    storageAreaUpdatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(storageAreasActions.updateStorageAreaSuccess, state => ({
    ...state,
    storageAreaUpdatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(storageAreasActions.updateStorageAreaFailure, (state, { error }) => ({
    ...state,
    storageAreaUpdatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(storageAreasActions.deleteStorageArea, state => ({
    ...state,
    storageAreasUpdatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(storageAreasActions.deleteStorageAreaSuccess, state => ({
    ...state,
    storageAreasUpdatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(storageAreasActions.deleteStorageAreaFailure, (state, { error }) => ({
    ...state,
    storageAreasUpdatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(storageAreasActions.preloadStorageAreaPhotos, state => ({
    ...state,
    storageAreaPreloadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(storageAreasActions.preloadStorageAreaPhotosSuccess, (state, { storageAreaPreloadedPhotos }) => ({
    ...state,
    storageAreaPreloadedPhotos,
    storageAreaPreloadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(storageAreasActions.preloadStorageAreaPhotosFailure, (state, { error }) => ({
    ...state,
    storageAreaPreloadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(storageAreasActions.resetStorageAreas, state => ({
    ...state,
    storageAreas: null,
    storageAreasLoadingState: null,
  })),
  on(storageAreasActions.resetStorageArea, state => ({
    ...state,
    storageArea: null,
    storageAreaBookingDates: null,
    storageAreaPreloadedPhotos: null,
    storageAreaLoadingState: null,
    storageAreaBookingDatesLoadingState: null,
    storageAreaPreloadingState: null,
    storageAreaUpdatingState: null,
  })),
  on(storageAreasActions.resetStorageAreaBookingDates, state => ({
    ...state,
    storageAreaBookingDates: null,
    storageAreaBookingDatesLoadingState: null,
  })),
  on(storageAreasActions.resetStorageAreaPreloadedPhotos, state => ({
    ...state,
    storageAreaPreloadedPhotos: null,
  })),
  on(storageAreasActions.resetStorageAreasLoadingState, state => ({
    ...state,
    storageAreasLoadingState: null,
  })),
  on(storageAreasActions.resetStorageAreasUpdatingState, state => ({
    ...state,
    storageAreasUpdatingState: null,
  })),
  on(storageAreasActions.resetStorageAreaLoadingState, state => ({
    ...state,
    storageAreaLoadingState: null,
  })),
  on(storageAreasActions.resetStorageAreaBookingDatesLoadingState, state => ({
    ...state,
    storageAreaBookingDatesLoadingState: null,
  })),
  on(storageAreasActions.resetStorageAreaPreloadingState, state => ({
    ...state,
    storageAreaPreloadingState: null,
  })),
  on(storageAreasActions.resetStorageAreaUpdatingState, state => ({
    ...state,
    storageAreaUpdatingState: null,
  })),
  on(storageAreasActions.resetStorageAreaState, () => ({ ...InitStorageAreaState })),
);

export const StorageAreaReducer = (state: IStorageAreaStoreState, action: Action) => {
  return storageAreaReducer(state, action);
};
