import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ListingEffects } from './listing.effects';
import { ListingReducer } from './listing.reducer';
import { ListingStoreService } from './listing-store.service';
import { ApiListingUserService } from '../../api/api-listing-user.service';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('user-listing', ListingReducer), EffectsModule.forFeature([ListingEffects])],
  providers: [ListingStoreService, ApiListingUserService],
})
export class ListingStoreUserModule {}
