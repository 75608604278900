import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IUser, IUserPassword } from '../../models/user.model';

export enum eUserActions {
  USER_CURRENT_GET = '[User] get current user',
  USER_CURRENT_GET_SUCCESS = '[User] get current user success',
  USER_CURRENT_GET_FAILURE = '[User] get current user failure',

  USER_CURRENT_UPDATE_DATA = '[User] update current user data',
  USER_CURRENT_UPDATE_DATA_SUCCESS = '[User] update current user data success',
  USER_CURRENT_UPDATE_DATA_FAILURE = '[User] update current user data failure',

  USER_CURRENT_UPDATE_PASSWORD = '[User] update current user password',
  USER_CURRENT_UPDATE_PASSWORD_SUCCESS = '[User] update current user password success',
  USER_CURRENT_UPDATE_PASSWORD_FAILURE = '[User] update current user password failure',

  USER_CURRENT_SET_ORGANIZATION = '[User] set current user organization',

  USER_CURRENT_CURRENT_RESET = '[User] reset current user',
}

export const getCurrentUser = createAction(eUserActions.USER_CURRENT_GET);
export const getCurrentUserSuccess = createAction(eUserActions.USER_CURRENT_GET_SUCCESS, props<{ currentUser: IUser }>());
export const getCurrentUserFailure = createAction(eUserActions.USER_CURRENT_GET_FAILURE, props<{ error: HttpErrorResponse }>());

export const updateCurrentUserData = createAction(eUserActions.USER_CURRENT_UPDATE_DATA, props<{ user: IUser }>());
export const updateCurrentUserDataSuccess = createAction(eUserActions.USER_CURRENT_UPDATE_DATA_SUCCESS, props<{ user: IUser }>());
export const updateCurrentUserDataFailure = createAction(
  eUserActions.USER_CURRENT_UPDATE_DATA_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const updateCurrentUserPassword = createAction(eUserActions.USER_CURRENT_UPDATE_PASSWORD, props<{ password: IUserPassword }>());
export const updateCurrentUserPasswordSuccess = createAction(eUserActions.USER_CURRENT_UPDATE_PASSWORD_SUCCESS, props<{ user: IUser }>());
export const updateCurrentUserPasswordFailure = createAction(
  eUserActions.USER_CURRENT_UPDATE_PASSWORD_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const setCurrentUserOrganization = createAction(eUserActions.USER_CURRENT_SET_ORGANIZATION, props<{ organization_id?: string }>());

export const resetCurrentUser = createAction(eUserActions.USER_CURRENT_CURRENT_RESET);
