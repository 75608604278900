import { IHttpRequestState } from '../../models/http.model';
import { IUser } from '../../models/user.model';

export interface ICurrentUserStoreState {
  currentUser: IUser | null;
  currentUserLoadingState: IHttpRequestState | null;
  currentUserUpdatingState: IHttpRequestState | null;
}

export const InitUserState: ICurrentUserStoreState = {
  currentUser: null,
  currentUserLoadingState: null,
  currentUserUpdatingState: null,
};
