<div class="paginator-wrapper" *ngIf="totalCount && totalCount > paginatorMinSize && !loading">
  <div class="paginator" *ngIf="pages?.length && pages.length > 1">
    <div class="paginator__icon" *ngIf="pagesToShow[0].pageNumber >= pagesAmountToShow">
      <tlp-button size="small" (click)="onGoToPrev()">Назад</tlp-button>
      <svg-icon-sprite [src]="'./assets/sprites/sprite.svg#ic-ellipsis'" [width]="'27px'" [height]="'24px'"></svg-icon-sprite>
    </div>
    <div class="paginator__page"
      *ngFor="let page of pagesToShow"
      [class.paginator__page--current]="page.pageNumber == pages[currentPageIndex].pageNumber"
      (click)="onChangePages(page.pageNumber)"
    >
      {{ page.pageNumber }}
    </div>
    <div class="paginator__icon" *ngIf="pagesToShow[pagesToShow.length - 1].pageNumber < pages[pages.length - 1].pageNumber">
      <svg-icon-sprite [src]="'./assets/sprites/sprite.svg#ic-ellipsis'" [width]="'27px'" [height]="'24px'"></svg-icon-sprite>
      <tlp-button size="small" (click)="onGoToNext()">Вперед</tlp-button>
    </div>
  </div>

  <div class="paginator-size" *ngIf="paginatorSize">
    <p class="paginator-size__caption">Показывать по</p>
    <button
      class="paginator-size__button"
      *ngFor="let item of paginatorSize; let i = index"
      [class.paginator-size__button--active]="paginatorSizeActiveBtn === i"
      (click)="onChangeItemsPerPage(i)"
    >
      {{ item }}
    </button>
  </div>
</div>
