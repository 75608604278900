import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { inputTheme } from '../../../input/input/input.component';
import { nonZeroValidator } from '../../../input/input.validator';
import { toDecimal } from '../../../../_core/utils/string.utils';

export type inputLWH = {
  length: string | null;
  width: string | null;
  height: string | null;
};

@Component({
  selector: 'tlp-input-lwh',
  templateUrl: './input-lwh.component.html',
  styleUrls: ['./input-lwh.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputLwhComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputLwhComponent),
      multi: true,
    },
  ],
})
export class InputLwhComponent implements ControlValueAccessor, Validator, OnInit, OnChanges, OnDestroy {
  @Input() public theme: inputTheme = 'default';
  @Input() public nonZero: boolean = true;
  @Input() public required: boolean = true;
  @Input() public unit: string | undefined = 'м';
  @Input() public maxValue?: number = 99.999;
  @Input() public minValue?: number = 0.1;
  @Input() public maxValueHandling?: 'replace' | 'prevent' = 'prevent';
  @Input() public formControlTouched?: boolean = false;

  public value: inputLWH = {
    length: null,
    height: null,
    width: null,
  };
  public lengthControl: FormControl | null = new FormControl('');
  public widthControl: FormControl | null = new FormControl('');
  public heightControl: FormControl | null = new FormControl('');

  public mask: string = '0*.0';
  public symbolsAfterDot: number = 2;
  public isFocused: boolean = false;
  public isDisabled: boolean = false;

  constructor(private cdr: ChangeDetectorRef) {}

  private onChange = (_: inputLWH) => {};
  private onTouched = () => {};

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.formControlTouched?.previousValue !== changes.formControlTouched?.currentValue &&
      changes.formControlTouched?.currentValue === true
    ) {
      this.onTouched();
      this.lengthControl?.markAsTouched();
      this.widthControl?.markAsTouched();
      this.heightControl?.markAsTouched();
    }
  }

  public ngOnInit(): void {
    const validators: ValidatorFn[] = [];

    if (this.nonZero) {
      validators.push(nonZeroValidator);
    }

    if (this.required) {
      validators.push(Validators.required);
    }

    this.lengthControl?.setValidators(validators);
    this.widthControl?.setValidators(validators);
    this.heightControl?.setValidators(validators);
  }

  public ngOnDestroy(): void {
    this.lengthControl = null;
    this.widthControl = null;
    this.heightControl = null;
  }

  public writeValue(outsideValue: inputLWH): void {
    if (outsideValue) {
      this.value = outsideValue;
      this.setControlsValue();
      this.onTouched();
      this.onChange(this.value);
      this.cdr.markForCheck();
    }
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.lengthControl?.disable();
      this.widthControl?.disable();
      this.heightControl?.disable();
      this.isDisabled = true;
    } else {
      this.lengthControl?.enable();
      this.widthControl?.enable();
      this.heightControl?.enable();
      this.isDisabled = false;
    }

    this.cdr.markForCheck();
  }

  public onModelChange(): void {
    if (this.maxValue) {
      this.checkMax();
    }
  }

  public onInputTouched(): void {
    this.isFocused = false;
    setTimeout(() => {
      if (!this.isFocused) {
        this.onTouched();
        this.lengthControl?.markAsTouched();
        this.widthControl?.markAsTouched();
        this.heightControl?.markAsTouched();
        this.checkMin();
        this.onValueChange();
      }
    }, 0);
  }

  public onInputFocused(): void {
    this.isFocused = true;
  }

  public onValueChange(): void {
    this.value = {
      length:
        this.lengthControl?.value && (this.lengthControl?.value[0] === '.' || this.lengthControl?.value[0] === ',')
          ? toDecimal(`0${this.lengthControl?.value}` || '', this.symbolsAfterDot)
          : toDecimal(`${this.lengthControl?.value}` || '', this.symbolsAfterDot),
      height:
        this.heightControl?.value && (this.heightControl?.value[0] === '.' || this.heightControl?.value[0] === ',')
          ? toDecimal(`0${this.heightControl?.value}` || '', this.symbolsAfterDot)
          : toDecimal(`${this.heightControl?.value}` || '', this.symbolsAfterDot),
      width:
        this.widthControl?.value && (this.widthControl?.value[0] === '.' || this.widthControl?.value[0] === ',')
          ? toDecimal(`0${this.widthControl?.value}` || '', this.symbolsAfterDot)
          : toDecimal(`${this.widthControl?.value}` || '', this.symbolsAfterDot),
    };

    this.setControlsValue();
    this.onChange(this.value);
    this.cdr.markForCheck();
  }

  public validate(): ValidationErrors | null {
    return this.lengthControl?.errors || this.heightControl?.errors || this.widthControl?.errors || null;
  }

  public get isError(): boolean {
    return !!(
      (!!this.lengthControl?.errors || !!this.heightControl?.errors || !!this.widthControl?.errors) &&
      this.lengthControl?.touched &&
      this.widthControl?.touched &&
      this.heightControl?.touched
    );
  }

  private checkMax(): void {
    Object.entries(this.value).map(([key, value]) => {
      if (value && this.maxValue && Number(value) > this.maxValue) {
        if (this.maxValueHandling !== 'prevent') {
          this.value = {
            ...this.value,
            [key]: this.maxValue,
          };
        } else {
          this.value = {
            ...this.value,
            [key]: this.value[key as keyof inputLWH]?.slice(0, this.value[key as keyof inputLWH]!.length - 1),
          };
        }
        this.setControlsValue();
      }
    });
  }

  private checkMin(): void {
    Object.entries(this.value).map(([key, value]) => {
      if (value && this.minValue && Number(value) < this.minValue) {
        this.value = {
          ...this.value,
          [key]: this.minValue,
        };
        this.setControlsValue();
      }
    });
  }

  private setControlsValue(): void {
    this.lengthControl?.setValue(this.value.length);
    this.heightControl?.setValue(this.value.height);
    this.widthControl?.setValue(this.value.width);
  }
}
