import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StorageAreaEffects } from './storage-area.effects';
import { StorageAreaReducer } from './storage-area.reducer';
import { ApiStorageAreaService } from '../../api/api-storage-area.service';
import { StorageAreaStoreService } from './storage-area-store.service';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('storage-area', StorageAreaReducer), EffectsModule.forFeature([StorageAreaEffects])],
  providers: [ApiStorageAreaService, StorageAreaStoreService],
})
export class StorageAreaStoreModule {}
