<div class="mobile-header">
  <a href="/">
    <img src="./assets/images/logo-compact.svg" class="mobile-header__logo" width="95" alt="XPlanet - агрегатор складов" />
  </a>
  <span class="mobile-header__title">{{ pageTitle }}</span>
  <button class="mobile-header__burger" (click)="toggleMenu()">
    <svg-icon-sprite [src]="'./assets/sprites/sprite.svg#ic-burger'" [width]="'20px'" [height]="'20px'"></svg-icon-sprite>
  </button>
</div>
<aside class="left-sidebar" [class.left-sidebar--is-open]="isOpenMenu">
  <header class="left-sidebar__header">
    <div class="logo">
      <a href="/">
        <img src="./assets/images/logo.svg" width="95" alt="XPlanet - агрегатор складов" />
      </a>
    </div>
    <button class="left-sidebar__header-button" (click)="toggleMenu()">
      <svg-icon-sprite [src]="'./assets/sprites/sprite.svg#ic-close'" [width]="'20px'" [height]="'20px'"></svg-icon-sprite>
    </button>
  </header>
  <nav class="navigation">
    <tlp-navigation-list></tlp-navigation-list>
  </nav>
  <div class="faq">
    <a [routerLink]="'/#'" class="faq__link">
      <svg-icon-sprite src="./assets/sprites/sprite.svg#ic-faq" [width]="'20px'" [height]="'20px'" class="faq__link-icon"></svg-icon-sprite>
      <span class="faq__link-text">Вопросы и ответы</span>
    </a>
  </div>
  <footer class="footer">
    <div class="footer__section footer__section--info">
      <div class="footer__section-title">Служба поддержки</div>
      <div class="footer__section-info">help@xplanet.ru</div>
      <div class="footer__section-info">+7 (800) 555-71-41</div>
    </div>
  </footer>
</aside>
