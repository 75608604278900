<ng-select
  *ngIf="formControl"
  [formControl]="formControl"
  [class.required-error]="required && !value"
  [loading]="(delegate?.isLoad$ | async) || false"
  [placeholder]="placeholder"
  [notFoundText]="notFoundText"
  [loadingText]="loadingText"
  [tlpTooltip]="infoTip"
  [tooltipPlacement]="infoTipPlacement"

  [items]="items || (delegate?.searchResult$ | async) || []"
  [bindLabel]="'name'"
  [bindValue]="'value'"

  [multiple]="multiple"
  [required]="required"
  [readonly]="!selectable"
  [clearable]="clearable"
  [closeOnSelect]="closeOnSelect"
  [editableSearchTerm]="true"

  (change)="selectItem($event)"
  (search)="onSearch($event)"
  (close)="markAsTouched()"
>
  <ng-template ng-label-tmp let-item="item" let-clear="clear" *ngIf="!concatenatedSelections; else multipleLabel">
    {{ item.name }}
    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
  </ng-template>
  <ng-template #multipleLabel>
    <ng-template ng-multi-label-tmp>
      <div class="ng-value ng-value--concatenated">{{ this.concatenatedLabel }}</div>
    </ng-template>
  </ng-template>

  <ng-template ng-option-tmp let-item="item" *ngIf="isDropdownListTemplate">
    <div class="dropdown-list">
      <ng-container *ngIf="item.items?.length; else stringValue">
        <div class="dropdown-list__item" *ngFor="let item of item.items">
          <span *ngIf="item.value">
            <b>{{ item.name }}:</b>
            {{ item.value }}
          </span>
        </div>
      </ng-container>
    </div>
    <ng-template #stringValue>
      {{ item.name }}
    </ng-template>
  </ng-template>

  <ng-template ng-loadingspinner-tmp>
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </ng-template>
</ng-select>
