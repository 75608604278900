import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IBookingStoreState } from './booking.state';
import { IHttpRequestState } from '../../models/http.model';

export const getBookingStoreState = createFeatureSelector<IBookingStoreState>('admin-booking');

export const getBookings = createSelector(getBookingStoreState, (state: IBookingStoreState) => state.bookings);
export const getBooking = createSelector(getBookingStoreState, (state: IBookingStoreState) => state.booking);
export const getBookingCreatedId = createSelector(getBookingStoreState, (state: IBookingStoreState) => state.createdBookingId);
export const getBookingGeneral = createSelector(getBookingStoreState, (state: IBookingStoreState) => state.bookingGeneral);
export const getBookingStorageRequest = createSelector(getBookingStoreState, (state: IBookingStoreState) => state.bookingStorageRequest);
export const getBookingShipmentRequest = createSelector(getBookingStoreState, (state: IBookingStoreState) => state.bookingShipmentRequest);
export const getBookingDocuments = createSelector(getBookingStoreState, (state: IBookingStoreState) => state.bookingDocuments);

export const getBookingsLoadingState = createSelector(
  getBookingStoreState,
  (state: IBookingStoreState) =>
    ({
      ...state.bookingsLoadingState,
    } as IHttpRequestState),
);
export const getBookingLoadingState = createSelector(
  getBookingStoreState,
  (state: IBookingStoreState) =>
    ({
      ...state.bookingLoadingState,
    } as IHttpRequestState),
);
export const getBookingStorageRequestLoadingState = createSelector(getBookingStoreState, (state: IBookingStoreState) =>
  state.bookingRequestsLoadingState?.storageRequestState
    ? ({
        ...state.bookingRequestsLoadingState.storageRequestState,
      } as IHttpRequestState)
    : null,
);
export const getBookingShipmentRequestLoadingState = createSelector(getBookingStoreState, (state: IBookingStoreState) =>
  state.bookingRequestsLoadingState?.shipmentRequestState
    ? ({
        ...state.bookingRequestsLoadingState.shipmentRequestState,
      } as IHttpRequestState)
    : null,
);
export const getBookingUpdatingState = createSelector(
  getBookingStoreState,
  (state: IBookingStoreState) =>
    ({
      ...state.bookingUpdatingState,
    } as IHttpRequestState),
);
export const getBookingPublishingState = createSelector(
  getBookingStoreState,
  (state: IBookingStoreState) =>
    ({
      ...state.bookingPublishingState,
    } as IHttpRequestState),
);
