<div tlpOverflowHider class="drawer" [overflowElementSelector]="containerSelector" [class.drawer--opened]="isOpen">
  <div class="drawer__overlay" (click)="close()"></div>
  <div class="drawer__wrapper" #contentWrapper>
    <button class="drawer__close-button" [ngStyle]="{ 'right.px': drawerWidth + 20 }" (click)="close()">
      <svg-icon-sprite src="./assets/sprites/sprite.svg#ic-close" width="24px" height="24px"></svg-icon-sprite>
    </button>
    <div class="drawer__content" [ngStyle]="{ 'width.px': drawerWidth }">
      <ng-content></ng-content>
    </div>
  </div>
</div>
