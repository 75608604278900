import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipModule } from '../directives/tooltip/tooltip.module';

import { LiveSearchComponent } from './component/live-search.component';

@NgModule({
  declarations: [LiveSearchComponent],
  imports: [CommonModule, FormsModule, NgSelectModule, ReactiveFormsModule, TooltipModule],
  exports: [LiveSearchComponent],
})
export class LiveSearchModule {}
