import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { IDocument } from '../../models/document.model';
import { IRejectInfo } from '../../models/verification.model';
import {
  IWarehouses,
  IWarehouseApiParams,
  IWarehouseDocuments,
  IWarehouseDocumentsUpsert,
  IWarehouseExternal,
  IWarehouseExternalUpsert,
  IWarehouseGeneral,
  IWarehouseInternal,
  IWarehouseGeneralUpsert,
  IWarehouseInternalUpsert,
  IWarehouseTabState,
} from '../../models/warehouse.model';

export enum eWarehouseActions {
  WAREHOUSES_GET = '[Admin: Warehouse] get warehouses',
  WAREHOUSES_GET_SUCCESS = '[Admin: Warehouse] get warehouses success',
  WAREHOUSES_GET_FAILURE = '[Admin: Warehouse] get warehouses failure',

  WAREHOUSE_CREATE = '[Admin: Warehouse] create warehouse',
  WAREHOUSE_CREATE_SUCCESS = '[Admin: Warehouse] create warehouse success',
  WAREHOUSE_CREATE_FAILURE = '[Admin: Warehouse] create warehouse failure',

  WAREHOUSE_SET_CREATED_ID = '[Admin: Warehouse] set created warehouse id',

  WAREHOUSE_DELETE = '[Admin: Warehouse] delete warehouse',
  WAREHOUSE_DELETE_SUCCESS = '[Admin: Warehouse] delete warehouse success',
  WAREHOUSE_DELETE_FAILURE = '[Admin: Warehouse] delete warehouse failure',

  WAREHOUSE_PUBLISH = '[Admin: Warehouse] publish warehouse',
  WAREHOUSE_PUBLISH_SUCCESS = '[Admin: Warehouse] publish warehouse success',
  WAREHOUSE_PUBLISH_FAILURE = '[Admin: Warehouse] publish warehouse failure',

  WAREHOUSE_CERTIFY = '[Admin: Warehouse] certify warehouse',
  WAREHOUSE_CERTIFY_SUCCESS = '[Admin: Warehouse] certify warehouse success',
  WAREHOUSE_CERTIFY_FAILURE = '[Admin: Warehouse] certify warehouse failure',

  WAREHOUSE_VERIFY = '[Admin: Warehouse] verify warehouse',
  WAREHOUSE_VERIFY_SUCCESS = '[Admin: Warehouse] verify warehouse success',
  WAREHOUSE_VERIFY_FAILURE = '[Admin: Warehouse] verify warehouse failure',

  WAREHOUSE_REJECT_CERTIFY = '[Admin: Warehouse] reject certify warehouse',
  WAREHOUSE_REJECT_CERTIFY_SUCCESS = '[Admin: Warehouse] reject certify warehouse success',
  WAREHOUSE_REJECT_CERTIFY_FAILURE = '[Admin: Warehouse] reject certify warehouse failure',

  WAREHOUSE_REJECT_VERIFY = '[Admin: Warehouse] reject verify warehouse',
  WAREHOUSE_REJECT_VERIFY_SUCCESS = '[Admin: Warehouse] reject verify warehouse success',
  WAREHOUSE_REJECT_VERIFY_FAILURE = '[Admin: Warehouse] reject verify warehouse failure',

  WAREHOUSE_TAB_STATE_GET = '[Admin: Warehouse] get warehouse tab state info',
  WAREHOUSE_TAB_STATE_GET_SUCCESS = '[Admin: Warehouse] get warehouse tab state info success',
  WAREHOUSE_TAB_STATE_GET_FAILURE = '[Admin: Warehouse] get warehouse tab state info failure',

  WAREHOUSE_GENERAL_GET = '[Admin: Warehouse] get warehouse general info',
  WAREHOUSE_GENERAL_GET_SUCCESS = '[Admin: Warehouse] get warehouse general info success',
  WAREHOUSE_GENERAL_GET_FAILURE = '[Admin: Warehouse] get warehouse general info failure',

  WAREHOUSE_GENERAL_UPDATE = '[Admin: Warehouse] update warehouse general info',
  WAREHOUSE_GENERAL_UPDATE_SUCCESS = '[Admin: Warehouse] update warehouse general info success',
  WAREHOUSE_GENERAL_UPDATE_FAILURE = '[Admin: Warehouse] update warehouse general info failure',

  WAREHOUSE_EXTERNAL_GET = '[Admin: Warehouse] get warehouse external info',
  WAREHOUSE_EXTERNAL_GET_SUCCESS = '[Admin: Warehouse] get warehouse external info success',
  WAREHOUSE_EXTERNAL_GET_FAILURE = '[Admin: Warehouse] get warehouse external info failure',

  WAREHOUSE_EXTERNAL_UPDATE = '[Admin: Warehouse] update warehouse external info',
  WAREHOUSE_EXTERNAL_UPDATE_SUCCESS = '[Admin: Warehouse] update warehouse external info success',
  WAREHOUSE_EXTERNAL_UPDATE_FAILURE = '[Admin: Warehouse] update warehouse external info failure',

  WAREHOUSE_INTERNAL_GET = '[Admin: Warehouse] get warehouse internal info',
  WAREHOUSE_INTERNAL_GET_SUCCESS = '[Admin: Warehouse] get warehouse internal info success',
  WAREHOUSE_INTERNAL_GET_FAILURE = '[Admin: Warehouse] get warehouse internal info failure',

  WAREHOUSE_INTERNAL_UPDATE = '[Admin: Warehouse] update warehouse internal info',
  WAREHOUSE_INTERNAL_UPDATE_SUCCESS = '[Admin: Warehouse] update warehouse internal info success',
  WAREHOUSE_INTERNAL_UPDATE_FAILURE = '[Admin: Warehouse] update warehouse internal info failure',

  WAREHOUSE_DOCUMENTS_GET = '[Admin: Warehouse] get warehouse documents',
  WAREHOUSE_DOCUMENTS_GET_SUCCESS = '[Admin: Warehouse] get warehouse documents success',
  WAREHOUSE_DOCUMENTS_GET_FAILURE = '[Admin: Warehouse] get warehouse documents failure',

  WAREHOUSE_DOCUMENTS_ARCHIVE_GET = '[Admin: Warehouse] get warehouse documents archive',
  WAREHOUSE_DOCUMENTS_ARCHIVE_GET_SUCCESS = '[Admin: Warehouse] get warehouse documents archive success',
  WAREHOUSE_DOCUMENTS_ARCHIVE_GET_FAILURE = '[Admin: Warehouse] get warehouse documents archive failure',

  WAREHOUSE_DOCUMENTS_UPDATE = '[Admin: Warehouse] update warehouse documents',
  WAREHOUSE_DOCUMENTS_UPDATE_SUCCESS = '[Admin: Warehouse] update warehouse documents success',
  WAREHOUSE_DOCUMENTS_UPDATE_FAILURE = '[Admin: Warehouse] update warehouse documents failure',

  WAREHOUSE_DOCUMENT_UPLOAD = '[Admin: Warehouse] upload warehouse document',
  WAREHOUSE_DOCUMENT_UPLOAD_SUCCESS = '[Admin: Warehouse] upload warehouse document success',
  WAREHOUSE_DOCUMENT_UPLOAD_FAILURE = '[Admin: Warehouse] upload warehouse document failure',

  WAREHOUSE_DOCUMENT_DELETE = '[Admin: Warehouse] delete warehouse document',
  WAREHOUSE_DOCUMENT_DELETE_SUCCESS = '[Admin: Warehouse] delete warehouse document success',
  WAREHOUSE_DOCUMENT_DELETE_FAILURE = '[Admin: Warehouse] delete warehouse document failure',

  WAREHOUSE_PHOTOS_PRELOAD = '[Admin: Warehouse] preload warehouse photos',
  WAREHOUSE_PHOTOS_PRELOAD_SUCCESS = '[Admin: Warehouse] preload warehouse photos success',
  WAREHOUSE_PHOTOS_PRELOAD_FAILURE = '[Admin: Warehouse] preload warehouse photos failure',

  WAREHOUSE_REPORT_GET = '[Admin: Warehouse] get warehouses report',
  WAREHOUSE_REPORT_GET_SUCCESS = '[Admin: Warehouse] get warehouses report success',
  WAREHOUSE_REPORT_GET_FAILURE = '[Admin: Warehouse] get warehouses report failure',

  WAREHOUSES_RESET = '[Admin: Warehouse] reset warehouses',
  WAREHOUSE_RESET = '[Admin: Warehouse] reset warehouse',
  WAREHOUSE_PRELOADED_PHOTOS_RESET = '[Admin: Warehouse] reset preloaded warehouse photos',

  WAREHOUSES_LOADING_STATE_RESET = '[Admin: Warehouse] reset warehouses loading state',
  WAREHOUSE_PRELOADING_STATE_RESET = '[Admin: Warehouse] reset warehouse preloading state',
  WAREHOUSE_LOADING_STATE_RESET = '[Admin: Warehouse] reset warehouse loading state',
  WAREHOUSE_UPDATING_STATE_RESET = '[Admin: Warehouse] reset warehouse updating state',

  WAREHOUSE_STATE_RESET = '[Admin: Warehouse] reset warehouse state',
}

export const getWarehouses = createAction(eWarehouseActions.WAREHOUSES_GET, props<{ params: IWarehouseApiParams }>());
export const getWarehousesSuccess = createAction(eWarehouseActions.WAREHOUSES_GET_SUCCESS, props<{ warehouses: IWarehouses }>());
export const getWarehousesFailure = createAction(eWarehouseActions.WAREHOUSES_GET_FAILURE, props<{ error: HttpErrorResponse }>());

export const createWarehouse = createAction(eWarehouseActions.WAREHOUSE_CREATE, props<{ data: IWarehouseGeneralUpsert }>());
export const createWarehouseSuccess = createAction(eWarehouseActions.WAREHOUSE_CREATE_SUCCESS, props<{ id: string }>());
export const createWarehouseFailure = createAction(eWarehouseActions.WAREHOUSE_CREATE_FAILURE, props<{ error: HttpErrorResponse }>());

export const setCreatedWarehouseId = createAction(eWarehouseActions.WAREHOUSE_SET_CREATED_ID, props<{ id: string }>());

export const deleteWarehouse = createAction(eWarehouseActions.WAREHOUSE_DELETE, props<{ id: string; params: IWarehouseApiParams }>());
export const deleteWarehouseSuccess = createAction(eWarehouseActions.WAREHOUSE_DELETE_SUCCESS);
export const deleteWarehouseFailure = createAction(eWarehouseActions.WAREHOUSE_DELETE_FAILURE, props<{ error: HttpErrorResponse }>());

export const publishWarehouse = createAction(eWarehouseActions.WAREHOUSE_PUBLISH, props<{ id: string }>());
export const publishWarehouseSuccess = createAction(eWarehouseActions.WAREHOUSE_PUBLISH_SUCCESS);
export const publishWarehouseFailure = createAction(eWarehouseActions.WAREHOUSE_PUBLISH_FAILURE, props<{ error: HttpErrorResponse }>());

export const certifyWarehouse = createAction(eWarehouseActions.WAREHOUSE_CERTIFY, props<{ id: string }>());
export const certifyWarehouseSuccess = createAction(eWarehouseActions.WAREHOUSE_CERTIFY_SUCCESS);
export const certifyWarehouseFailure = createAction(eWarehouseActions.WAREHOUSE_CERTIFY_FAILURE, props<{ error: HttpErrorResponse }>());

export const verifyWarehouse = createAction(eWarehouseActions.WAREHOUSE_VERIFY, props<{ id: string }>());
export const verifyWarehouseSuccess = createAction(eWarehouseActions.WAREHOUSE_VERIFY_SUCCESS);
export const verifyWarehouseFailure = createAction(eWarehouseActions.WAREHOUSE_VERIFY_FAILURE, props<{ error: HttpErrorResponse }>());

export const rejectCertifyWarehouse = createAction(
  eWarehouseActions.WAREHOUSE_REJECT_CERTIFY,
  props<{ id: string; rejectInfo: IRejectInfo }>(),
);
export const rejectCertifyWarehouseSuccess = createAction(eWarehouseActions.WAREHOUSE_REJECT_CERTIFY_SUCCESS);
export const rejectCertifyWarehouseFailure = createAction(
  eWarehouseActions.WAREHOUSE_REJECT_CERTIFY_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const rejectVerifyWarehouse = createAction(
  eWarehouseActions.WAREHOUSE_REJECT_VERIFY,
  props<{ id: string; rejectInfo: IRejectInfo }>(),
);
export const rejectVerifyWarehouseSuccess = createAction(eWarehouseActions.WAREHOUSE_REJECT_VERIFY_SUCCESS);
export const rejectVerifyWarehouseFailure = createAction(
  eWarehouseActions.WAREHOUSE_REJECT_VERIFY_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getWarehouseTabState = createAction(eWarehouseActions.WAREHOUSE_TAB_STATE_GET, props<{ id: string }>());
export const getWarehouseTabStateSuccess = createAction(
  eWarehouseActions.WAREHOUSE_TAB_STATE_GET_SUCCESS,
  props<{ warehouseTabState: IWarehouseTabState }>(),
);
export const getWarehouseTabStateFailure = createAction(
  eWarehouseActions.WAREHOUSE_TAB_STATE_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getWarehouseGeneral = createAction(eWarehouseActions.WAREHOUSE_GENERAL_GET, props<{ id: string }>());
export const getWarehouseGeneralSuccess = createAction(
  eWarehouseActions.WAREHOUSE_GENERAL_GET_SUCCESS,
  props<{ warehouseGeneral: IWarehouseGeneral }>(),
);
export const getWarehouseGeneralFailure = createAction(
  eWarehouseActions.WAREHOUSE_GENERAL_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const updateWarehouseGeneral = createAction(
  eWarehouseActions.WAREHOUSE_GENERAL_UPDATE,
  props<{ data: IWarehouseGeneralUpsert; id: string }>(),
);
export const updateWarehouseGeneralSuccess = createAction(eWarehouseActions.WAREHOUSE_GENERAL_UPDATE_SUCCESS, props<{ id: string }>());
export const updateWarehouseGeneralFailure = createAction(
  eWarehouseActions.WAREHOUSE_GENERAL_UPDATE_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getWarehouseExternal = createAction(eWarehouseActions.WAREHOUSE_EXTERNAL_GET, props<{ id: string }>());
export const getWarehouseExternalSuccess = createAction(
  eWarehouseActions.WAREHOUSE_EXTERNAL_GET_SUCCESS,
  props<{ warehouseExternal: IWarehouseExternal }>(),
);
export const getWarehouseExternalFailure = createAction(
  eWarehouseActions.WAREHOUSE_EXTERNAL_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const updateWarehouseExternal = createAction(
  eWarehouseActions.WAREHOUSE_EXTERNAL_UPDATE,
  props<{ data: IWarehouseExternalUpsert; id: string }>(),
);
export const updateWarehouseExternalSuccess = createAction(eWarehouseActions.WAREHOUSE_EXTERNAL_UPDATE_SUCCESS, props<{ id: string }>());
export const updateWarehouseExternalFailure = createAction(
  eWarehouseActions.WAREHOUSE_EXTERNAL_UPDATE_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getWarehouseInternal = createAction(eWarehouseActions.WAREHOUSE_INTERNAL_GET, props<{ id: string }>());
export const getWarehouseInternalSuccess = createAction(
  eWarehouseActions.WAREHOUSE_INTERNAL_GET_SUCCESS,
  props<{ warehouseInternal: IWarehouseInternal }>(),
);
export const getWarehouseInternalFailure = createAction(
  eWarehouseActions.WAREHOUSE_INTERNAL_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const updateWarehouseInternal = createAction(
  eWarehouseActions.WAREHOUSE_INTERNAL_UPDATE,
  props<{ data: IWarehouseInternalUpsert; id: string }>(),
);
export const updateWarehouseInternalSuccess = createAction(eWarehouseActions.WAREHOUSE_INTERNAL_UPDATE_SUCCESS, props<{ id: string }>());
export const updateWarehouseInternalFailure = createAction(
  eWarehouseActions.WAREHOUSE_INTERNAL_UPDATE_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getWarehouseDocuments = createAction(eWarehouseActions.WAREHOUSE_DOCUMENTS_GET, props<{ id: string }>());
export const getWarehouseDocumentsSuccess = createAction(
  eWarehouseActions.WAREHOUSE_DOCUMENTS_GET_SUCCESS,
  props<{ warehouseDocuments: IWarehouseDocuments }>(),
);
export const getWarehouseDocumentsFailure = createAction(
  eWarehouseActions.WAREHOUSE_DOCUMENTS_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getWarehouseDocumentsArchive = createAction(eWarehouseActions.WAREHOUSE_DOCUMENTS_ARCHIVE_GET, props<{ id: string }>());
export const getWarehouseDocumentsArchiveSuccess = createAction(
  eWarehouseActions.WAREHOUSE_DOCUMENTS_ARCHIVE_GET_SUCCESS,
  props<{ warehouseDocumentsArchive: IDocument }>(),
);
export const getWarehouseDocumentsArchiveFailure = createAction(
  eWarehouseActions.WAREHOUSE_DOCUMENTS_ARCHIVE_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const updateWarehouseDocuments = createAction(
  eWarehouseActions.WAREHOUSE_DOCUMENTS_UPDATE,
  props<{ data: IWarehouseDocumentsUpsert; id: string }>(),
);
export const updateWarehouseDocumentsSuccess = createAction(eWarehouseActions.WAREHOUSE_DOCUMENTS_UPDATE_SUCCESS, props<{ id: string }>());
export const updateWarehouseDocumentsFailure = createAction(
  eWarehouseActions.WAREHOUSE_DOCUMENTS_UPDATE_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const uploadWarehouseDocument = createAction(
  eWarehouseActions.WAREHOUSE_DOCUMENT_UPLOAD,
  props<{ file: File; id: string; documentType: string; changesConfirmed?: boolean }>(),
);
export const uploadWarehouseDocumentSuccess = createAction(eWarehouseActions.WAREHOUSE_DOCUMENT_UPLOAD_SUCCESS);
export const uploadWarehouseDocumentFailure = createAction(
  eWarehouseActions.WAREHOUSE_DOCUMENT_UPLOAD_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const deleteWarehouseDocument = createAction(
  eWarehouseActions.WAREHOUSE_DOCUMENT_DELETE,
  props<{ id: string; documentType: string; changesConfirmed?: boolean }>(),
);
export const deleteWarehouseDocumentSuccess = createAction(eWarehouseActions.WAREHOUSE_DOCUMENT_DELETE_SUCCESS);
export const deleteWarehouseDocumentFailure = createAction(
  eWarehouseActions.WAREHOUSE_DOCUMENT_DELETE_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const preloadWarehousePhotos = createAction(eWarehouseActions.WAREHOUSE_PHOTOS_PRELOAD, props<{ photos: File[] }>());
export const preloadWarehousePhotosSuccess = createAction(
  eWarehouseActions.WAREHOUSE_PHOTOS_PRELOAD_SUCCESS,
  props<{ warehousePreloadedPhotos: string[] }>(),
);
export const preloadWarehousePhotosFailure = createAction(
  eWarehouseActions.WAREHOUSE_PHOTOS_PRELOAD_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getWarehouseReport = createAction(eWarehouseActions.WAREHOUSE_REPORT_GET, props<{ id: string }>());
export const getWarehouseReportSuccess = createAction(
  eWarehouseActions.WAREHOUSE_REPORT_GET_SUCCESS,
  props<{ response: { url: string } }>(),
);
export const getWarehouseReportFailure = createAction(
  eWarehouseActions.WAREHOUSE_REPORT_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const resetWarehouses = createAction(eWarehouseActions.WAREHOUSES_RESET);
export const resetWarehouse = createAction(eWarehouseActions.WAREHOUSE_RESET);
export const resetWarehousePreloadedPhotos = createAction(eWarehouseActions.WAREHOUSE_PRELOADED_PHOTOS_RESET);

export const resetWarehousesLoadingState = createAction(eWarehouseActions.WAREHOUSES_LOADING_STATE_RESET);
export const resetWarehousePreloadingState = createAction(eWarehouseActions.WAREHOUSE_PRELOADING_STATE_RESET);
export const resetWarehouseLoadingState = createAction(eWarehouseActions.WAREHOUSE_LOADING_STATE_RESET);
export const resetWarehouseUpdatingState = createAction(eWarehouseActions.WAREHOUSE_UPDATING_STATE_RESET);

export const resetWarehouseState = createAction(eWarehouseActions.WAREHOUSE_STATE_RESET);
