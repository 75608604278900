import { NgModule } from '@angular/core';
import { BookingStoreModule } from './booking/booking-store.module';
import { ListingStoreModule } from './listing/listing-store.module';
import { LogsStoreModule } from './logs/logs-store.module';
import { OrganizationStoreModule } from './organization/organization-store.module';
import { StorageAreaStoreModule } from './storage-area/storage-area-store.module';
import { TeamStoreModule } from './team/team-store.module';
import { UsersStoreModule } from './users/users-store.module';
import { WarehouseStoreModule } from './warehouse/warehouse-store.module';

@NgModule({
  imports: [
    BookingStoreModule,
    ListingStoreModule,
    LogsStoreModule,
    OrganizationStoreModule,
    StorageAreaStoreModule,
    TeamStoreModule,
    UsersStoreModule,
    WarehouseStoreModule,
  ],
})
export class AdminStoreModule {}
