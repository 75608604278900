import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IHttpRequestState } from '../../models/http.model';
import { ILoginStoreState } from './login.state';

export const loginState = (state: ILoginStoreState) => state;

export const getLoginStoreState = createFeatureSelector<ILoginStoreState>('login');

export const getLoginLoadingState = createSelector(
  getLoginStoreState,
  (state: ILoginStoreState) =>
    ({
      ...state.loginLoadingState,
    } as IHttpRequestState),
);
