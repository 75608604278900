import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IHttpRequestState } from '../../models/http.model';
import { IDictionaryStoreState } from './dictionary.state';

export const getDictionaryStoreState = createFeatureSelector<IDictionaryStoreState>('dictionary');

export const getAccountingControlSystemsDictionary = createSelector(
  getDictionaryStoreState,
  (state: IDictionaryStoreState) => state.accountingControlSystems,
);
export const getAccountingSystemTypeDictionary = createSelector(
  getDictionaryStoreState,
  (state: IDictionaryStoreState) => state.accountingSystemType,
);
export const getClassificationDictionary = createSelector(getDictionaryStoreState, (state: IDictionaryStoreState) => state.classification);
export const getCranageTypesDictionary = createSelector(getDictionaryStoreState, (state: IDictionaryStoreState) => state.cranageTypes);
export const getElectricityGridTypeDictionary = createSelector(
  getDictionaryStoreState,
  (state: IDictionaryStoreState) => state.electricityGridType,
);
export const getFireExtSystemTypeDictionary = createSelector(
  getDictionaryStoreState,
  (state: IDictionaryStoreState) => state.fireExtSystemType,
);
export const getFloorCoveringDictionary = createSelector(getDictionaryStoreState, (state: IDictionaryStoreState) => state.floorCovering);
export const getHeatingSystemTypeDictionary = createSelector(
  getDictionaryStoreState,
  (state: IDictionaryStoreState) => state.heatingSystemType,
);
export const getLoadingUnloadingMethodsDictionary = createSelector(
  getDictionaryStoreState,
  (state: IDictionaryStoreState) => state.loadingUnloadingMethods,
);
export const getLoadingUnloadingStructTypesDictionary = createSelector(
  getDictionaryStoreState,
  (state: IDictionaryStoreState) => state.loadingUnloadingStructTypes,
);
export const getLoadingUnloadingTechTypesDictionary = createSelector(
  getDictionaryStoreState,
  (state: IDictionaryStoreState) => state.loadingUnloadingTechTypes,
);
export const getOfficeFacilityTypesDictionary = createSelector(
  getDictionaryStoreState,
  (state: IDictionaryStoreState) => state.officeFacilityTypes,
);
export const getParkingTypeDictionary = createSelector(getDictionaryStoreState, (state: IDictionaryStoreState) => state.parkingType);
export const getStorageFormatDictionary = createSelector(getDictionaryStoreState, (state: IDictionaryStoreState) => state.storageFormat);
export const getStorageSystemDictionary = createSelector(getDictionaryStoreState, (state: IDictionaryStoreState) => state.storageSystem);
export const getTaxationTypeDictionary = createSelector(getDictionaryStoreState, (state: IDictionaryStoreState) => state.taxationType);
export const getTemperatureModeDictionary = createSelector(
  getDictionaryStoreState,
  (state: IDictionaryStoreState) => state.temperatureMode,
);
export const getUnitDictionary = createSelector(getDictionaryStoreState, (state: IDictionaryStoreState) => state.unit);
export const getUnitDistanceDictionary = createSelector(getDictionaryStoreState, (state: IDictionaryStoreState) => state.unitDistance);
export const getUnitMassDictionary = createSelector(getDictionaryStoreState, (state: IDictionaryStoreState) => state.unitMass);
export const getUnitSquareDictionary = createSelector(getDictionaryStoreState, (state: IDictionaryStoreState) => state.unitSquare);
export const getUnitTemperatureDictionary = createSelector(
  getDictionaryStoreState,
  (state: IDictionaryStoreState) => state.unitTemperature,
);
export const getLegalTypeDictionary = createSelector(getDictionaryStoreState, (state: IDictionaryStoreState) => state.legalType);
export const getPartnerTypeDictionary = createSelector(getDictionaryStoreState, (state: IDictionaryStoreState) => state.partnerType);

export const getDictionaryLoadingState = createSelector(
  getDictionaryStoreState,
  (state: IDictionaryStoreState) =>
    ({
      ...state.loadingState,
    } as IHttpRequestState),
);
