import { ICurrentUserStoreState } from './current-user';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IHttpRequestState } from '../../models/http.model';

export const getUserState = createFeatureSelector<ICurrentUserStoreState>('current-user');

export const getCurrentUser = createSelector(getUserState, (state: ICurrentUserStoreState) => state.currentUser);
export const getCurrentUserRole = createSelector(getUserState, (state: ICurrentUserStoreState) => state.currentUser?.role);

export const getUserLoadingState = createSelector(
  getUserState,
  (state: ICurrentUserStoreState) =>
    ({
      ...state.currentUserLoadingState,
    } as IHttpRequestState),
);

export const getUserUpdatingState = createSelector(
  getUserState,
  (state: ICurrentUserStoreState) =>
    ({
      ...state.currentUserUpdatingState,
    } as IHttpRequestState),
);
