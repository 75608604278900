import { Injectable } from '@angular/core';
import { BOOKING_PERCENTS, MIN_NON_ZERO_BOOKING_PERCENT } from '../const/booking-calendar.const';
import * as moment from 'moment';
import { parseFromUnixDate } from '../../../_core/utils/dates.utils';
import { IBookingDate } from '../../../_core/models/booking.model';
import { IBookingCalendarDate, tPeriodDirections } from '../model/booking-calendar.model';

@Injectable({
  providedIn: 'root',
})
export class BookingCalendarService {
  public getParsedLeftLimit(bookingDates: IBookingDate[] | undefined, leftLimitDate?: number | moment.Moment | null): moment.Moment {
    if (leftLimitDate) {
      return this.getMomentDate(leftLimitDate);
    }

    if (leftLimitDate === undefined && bookingDates?.length) {
      return this.getMomentDate(bookingDates[0].date);
    }

    return moment(new Date());
  }

  public getParsedRightLimit(
    bookingDates: IBookingDate[] | undefined,
    leftLimitDate?: number | moment.Moment | null,
    rightLimitDate?: number | moment.Moment | null,
  ): moment.Moment | null {
    let resultRightLimit: moment.Moment | null = null;

    if (rightLimitDate) {
      resultRightLimit = this.getMomentDate(rightLimitDate);
    } else if (bookingDates?.length) {
      resultRightLimit = this.getMomentDate(bookingDates[bookingDates.length - 1].date);
    } else if (leftLimitDate) {
      resultRightLimit = moment(leftLimitDate).add(1, 'month');
    }

    if (resultRightLimit && moment(resultRightLimit).isBefore(leftLimitDate)) {
      resultRightLimit = moment(leftLimitDate).add(1, 'month');
    } else if (resultRightLimit) {
      const limitPeriod = moment(resultRightLimit).diff(leftLimitDate, 'days');
      const daysInMonth = moment(leftLimitDate).daysInMonth();

      if (limitPeriod < daysInMonth) {
        resultRightLimit = moment(resultRightLimit).add(daysInMonth - limitPeriod, 'days');
      }
    } else {
      resultRightLimit = moment(new Date()).add(1, 'month');
    }

    return resultRightLimit;
  }

  public getParsedBookingDates(bookingDates: IBookingDate[] | undefined): IBookingDate[] | undefined {
    return bookingDates?.map(bookingDate => ({ ...bookingDate, date: this.getMomentDate(bookingDate.date) }));
  }

  public needToUpdateDates(
    bookingDates: IBookingDate[] | undefined,
    firstDateToDisplay: moment.Moment,
    lastDateToDisplay: moment.Moment,
    direction?: tPeriodDirections,
  ): boolean {
    return !!(
      bookingDates &&
      bookingDates[0]?.date &&
      (direction === 'left'
        ? moment(firstDateToDisplay).isBefore(this.getMomentDate(bookingDates[0]?.date))
        : moment(firstDateToDisplay).isSameOrAfter(this.getMomentDate(bookingDates[bookingDates.length - 1]?.date)) ||
          this.getMomentDate(bookingDates[bookingDates.length - 1]?.date).isBefore(lastDateToDisplay))
    );
  }

  public getBookingCalendar(
    totalStorageAmount: number | null,
    leftLimitDate: number | moment.Moment | null,
    rightLimitDate: number | moment.Moment | null,
    bookingDates: IBookingDate[] | undefined,
    updateBookingDates: boolean = false,
    displayedDaysCount?: number,
  ): IBookingCalendarDate[] {
    let bookingCalendar: IBookingCalendarDate[] = [];
    let rightLimitAdjustDaysCount: number = 1;

    if (displayedDaysCount) {
      rightLimitAdjustDaysCount = this.getMissedDisplayedDaysCount(leftLimitDate, rightLimitDate, displayedDaysCount) || 1;
    }

    const stopDate = moment(rightLimitDate).add(rightLimitAdjustDaysCount, 'days');
    let currentDate = leftLimitDate;

    while (moment(currentDate).isSameOrBefore(stopDate)) {
      const calendarDate = moment(currentDate).format('YYYY-MM-DD');
      const bookingAmount = bookingDates?.find(bookingDate => moment(bookingDate.date).isSame(calendarDate))?.amount;

      bookingCalendar.push({
        date: calendarDate,
        amount: bookingAmount ?? 0,
        bookingPercent: this.calculatePercentage(totalStorageAmount, bookingAmount ?? 0),
      });

      currentDate = moment(currentDate).add(1, 'day');
    }

    if (updateBookingDates) {
      bookingCalendar = this.getUpdatedBookingCalendar(totalStorageAmount, bookingCalendar, bookingDates) || [];
    }

    return bookingCalendar;
  }

  public getUpdatedBookingCalendar(
    totalStorageAmount: number | null,
    initialBookingCalendar: IBookingCalendarDate[] | null,
    bookingDates: IBookingDate[] | undefined,
  ): IBookingCalendarDate[] | undefined {
    const updatedBookingCalendar: IBookingCalendarDate[] | undefined = initialBookingCalendar ? [...initialBookingCalendar] : undefined;

    updatedBookingCalendar?.forEach((calendarItem, index) => {
      const sameBookingDate = bookingDates?.find(bookingDate =>
        moment(this.getMomentDate(bookingDate.date).format('YYYY-MM-DD')).isSame(calendarItem.date),
      );
      if (sameBookingDate) {
        updatedBookingCalendar![index] = {
          ...calendarItem,
          amount: sameBookingDate.amount ?? 0,
          bookingPercent: this.calculatePercentage(totalStorageAmount, sameBookingDate.amount ?? 0),
        };
      }
    });

    return updatedBookingCalendar;
  }

  public findSameBookingDate(calendarItem: IBookingCalendarDate, bookingDates: IBookingDate[] | undefined): IBookingDate | undefined {
    return bookingDates?.find(bookingDate => moment(this.getMomentDate(bookingDate.date).format('YYYY-MM-DD')).isSame(calendarItem.date));
  }

  public findSameCalendarDateIndex(date: moment.Moment | null, bookingCalendar: IBookingCalendarDate[] | undefined): number | undefined {
    return bookingCalendar?.findIndex(calendarItem => moment(calendarItem.date).isSame(moment(date).format('YYYY-MM-DD')));
  }

  public calculatePercentage(totalAmount: number | null, bookingAmount: number): number {
    if (!totalAmount || !bookingAmount) {
      return 0;
    }

    const bookingPercent: number = (bookingAmount / totalAmount) * 100;
    return bookingPercent < MIN_NON_ZERO_BOOKING_PERCENT
      ? MIN_NON_ZERO_BOOKING_PERCENT
      : BOOKING_PERCENTS.reduce((previousPercent, currentPercent) => {
          return Math.abs(currentPercent - bookingPercent) < Math.abs(previousPercent - bookingPercent) ? currentPercent : previousPercent;
        });
  }

  public getMissedDisplayedDaysCount(
    leftLimitDate: number | moment.Moment | null,
    rightLimitDate: number | moment.Moment | null,
    displayedDaysCount: number,
  ): number {
    const diffDaysCount: number = moment(rightLimitDate).diff(leftLimitDate, 'days');
    if (diffDaysCount % displayedDaysCount !== 0) {
      return Math.ceil(diffDaysCount / displayedDaysCount) * displayedDaysCount - diffDaysCount;
    }

    return 0;
  }

  public getMomentDate(date: number | string | moment.Moment | undefined): moment.Moment {
    return moment.isMoment(date) ? date : (parseFromUnixDate(typeof date === 'string' ? parseInt(date) : date) as moment.Moment);
  }

  public getDisplayedMainDate(date: string | null): string {
    return date ? moment(date).format('MMMM YYYY') : '';
  }

  public getFreeAmount(totalAmount: number | null, date: IBookingCalendarDate): number {
    if (!totalAmount) {
      return 0;
    }

    const freeAmount = totalAmount - date.amount;
    return !isNaN(freeAmount) && freeAmount > 0 ? freeAmount : 0;
  }
}
