import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ToastrService } from 'ngx-toastr';
import { ApiTeamService } from '../../api/api-team.service';
import { CurrentUserStoreService } from '../current-user/current-user-store.service';
import { HttpErrorsService } from '../../api/http-errors.service';
import { OrganizationStoreService } from '../organization/organization-store.service';
import { TeamStoreService } from './team-store.service';

import { ITeam, ICurrentTeamRequestResponse, ITeamApiParams } from '../../models/team.model';
import { eTeamActions } from './team.actions';

@Injectable()
export class TeamEffects {
  constructor(
    private actions$: Actions,
    private toastrService: ToastrService,
    private apiTeamService: ApiTeamService,
    private currentUserStoreService: CurrentUserStoreService,
    private httpErrorsService: HttpErrorsService,
    private organizationStoreService: OrganizationStoreService,
    private teamStoreService: TeamStoreService,
  ) {}

  public getTeam$: Observable<
    { type: eTeamActions; team: ITeam | null } | { type: eTeamActions; teamError: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eTeamActions.TEAM_GET),
      switchMap((payload: { type: string; params: ITeamApiParams }) =>
        this.apiTeamService.getTeam(payload.params).pipe(
          map(team => ({ type: eTeamActions.TEAM_GET_SUCCESS, team })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eTeamActions.TEAM_GET_FAILURE, teamError: err });
          }),
        ),
      ),
    ),
  );

  public createTeamRequest$: Observable<
    { type: eTeamActions } | { type: eTeamActions; teamCreateRequestError: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eTeamActions.TEAM_CREATE_REQUEST),
      switchMap((payload: { type: string; organizationId: string }) =>
        this.apiTeamService.createTeamRequest(payload.organizationId).pipe(
          tap(() => {
            this.toastrService.success('Заявка на присоединение успешно отправлена');
          }),
          map(() => ({ type: eTeamActions.TEAM_CREATE_REQUEST_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            return of({ type: eTeamActions.TEAM_CREATE_REQUEST_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public cancelCurrentTeamRequest$: Observable<{ type: eTeamActions } | { type: eTeamActions; error: HttpErrorResponse }> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(eTeamActions.TEAM_CANCEL_CURRENT_REQUEST),
        switchMap(() =>
          this.apiTeamService.cancelCurrentTeamRequest().pipe(
            tap(() => {
              this.organizationStoreService.resetOrganizationShortInfo();
              this.toastrService.success('Заявка на присоединение отменена');
            }),
            map(() => ({ type: eTeamActions.TEAM_CANCEL_CURRENT_REQUEST_SUCCESS })),
            catchError((err: HttpErrorResponse) => {
              this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
              this.currentUserStoreService.getCurrentUser();
              this.organizationStoreService.getCurrentOrganization();
              this.teamStoreService.getCurrentTeamRequest();
              return of({ type: eTeamActions.TEAM_CANCEL_CURRENT_REQUEST_FAILURE, error: err });
            }),
          ),
        ),
      ),
  );

  public getCurrentTeamRequest$: Observable<
    | { type: eTeamActions; currentTeamRequestResponse: ICurrentTeamRequestResponse | null }
    | { type: eTeamActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eTeamActions.TEAM_GET_CURRENT_REQUEST),
      switchMap(() =>
        this.apiTeamService.getCurrentTeamRequest().pipe(
          tap((result: ICurrentTeamRequestResponse | null) => {
            this.organizationStoreService.setOrganizationShortInfo(result ? result.organization : null);
          }),
          map(currentTeamRequestResponse => ({ type: eTeamActions.TEAM_GET_CURRENT_REQUEST_SUCCESS, currentTeamRequestResponse })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eTeamActions.TEAM_GET_CURRENT_REQUEST_FAILURE, error: err });
          }),
        ),
      ),
    ),
  );

  public getTeamRequests$: Observable<
    { type: eTeamActions; teamRequests: ITeam | null } | { type: eTeamActions; teamRequestsError: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eTeamActions.TEAM_REQUESTS_GET),
      switchMap((payload: { type: string; params: ITeamApiParams }) =>
        this.apiTeamService.getTeamRequests(payload.params).pipe(
          map(teamRequests => ({ type: eTeamActions.TEAM_REQUESTS_GET_SUCCESS, teamRequests })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eTeamActions.TEAM_REQUESTS_GET_FAILURE, teamRequestsError: err });
          }),
        ),
      ),
    ),
  );

  public approveTeamRequest$: Observable<
    { type: eTeamActions } | { type: eTeamActions; teamRequestsError: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eTeamActions.TEAM_APPROVE_REQUEST),
      switchMap((payload: { type: string; id: string; params: { teamApiParams: ITeamApiParams; teamRequestApiParams: ITeamApiParams } }) =>
        this.apiTeamService.approveTeamRequest(payload.id).pipe(
          tap(() => {
            this.teamStoreService.getTeamRequests(payload.params.teamRequestApiParams);
            this.teamStoreService.getTeam(payload.params.teamApiParams);
            this.toastrService.success('Запрос на доступ успешно принят');
          }),
          map(() => ({ type: eTeamActions.TEAM_APPROVE_REQUEST_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eTeamActions.TEAM_APPROVE_REQUEST_FAILURE, teamRequestsError: err });
          }),
        ),
      ),
    ),
  );

  public rejectTeamRequest$: Observable<
    { type: eTeamActions } | { type: eTeamActions; teamRequestsError: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eTeamActions.TEAM_REJECT_REQUEST),
      switchMap((payload: { type: string; id: string; params: { teamApiParams: ITeamApiParams; teamRequestApiParams: ITeamApiParams } }) =>
        this.apiTeamService.rejectTeamRequest(payload.id).pipe(
          tap(() => {
            this.teamStoreService.getTeamRequests(payload.params.teamRequestApiParams);
            this.toastrService.success('Запрос на доступ отклонен');
          }),
          map(() => ({ type: eTeamActions.TEAM_REJECT_REQUEST_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eTeamActions.TEAM_REJECT_REQUEST_FAILURE, teamRequestsError: err });
          }),
        ),
      ),
    ),
  );

  public deleteTeamMember$: Observable<{ type: eTeamActions } | { type: eTeamActions; teamError: HttpErrorResponse }> = createEffect(() =>
    this.actions$.pipe(
      ofType(eTeamActions.TEAM_DELETE_MEMBER),
      switchMap((payload: { type: string; id: string; params: ITeamApiParams }) =>
        this.apiTeamService.deleteTeamMember(payload.id).pipe(
          tap(() => {
            this.teamStoreService.getTeam(payload.params);
            this.toastrService.success('Пользователь был удален из команды');
          }),
          map(() => ({ type: eTeamActions.TEAM_DELETE_MEMBER_SUCCESS })),
          catchError((err: HttpErrorResponse) => {
            this.toastrService.error(this.httpErrorsService.getErrorMessage(err));
            return of({ type: eTeamActions.TEAM_DELETE_MEMBER_FAILURE, teamError: err });
          }),
        ),
      ),
    ),
  );
}
