import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ApiWarehousesCatalogService } from '../../api/api-warehouses-catalog.service';
import { IWarehousesCatalog, IWarehousesCatalogApiParams, IWarehousesCatalogItem } from '../../models/warehouses-catalog.model';
import { eWarehousesCatalogActions } from './warehouses-catalog.actions';

@Injectable()
export class WarehousesCatalogEffects {
  constructor(private actions$: Actions, private apiWarehousesCatalogService: ApiWarehousesCatalogService) {}

  public getWarehousesCatalog$: Observable<
    | { type: eWarehousesCatalogActions; warehousesCatalog: IWarehousesCatalog | null }
    | { type: eWarehousesCatalogActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eWarehousesCatalogActions.WAREHOUSES_CATALOG_GET),
      switchMap((payload: { type: string; params: IWarehousesCatalogApiParams }) =>
        this.apiWarehousesCatalogService.getWarehousesCatalog(payload.params).pipe(
          map(warehousesCatalog => {
            return { type: eWarehousesCatalogActions.WAREHOUSES_CATALOG_GET_SUCCESS, warehousesCatalog };
          }),
          catchError((error: HttpErrorResponse) => {
            return of({ type: eWarehousesCatalogActions.WAREHOUSES_CATALOG_GET_FAILURE, error });
          }),
        ),
      ),
    ),
  );

  public getWarehousesCatalogItem$: Observable<
    | { type: eWarehousesCatalogActions; warehousesCatalogItem: IWarehousesCatalogItem | null }
    | { type: eWarehousesCatalogActions; error: HttpErrorResponse }
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(eWarehousesCatalogActions.WAREHOUSES_CATALOG_ITEM_GET),
      switchMap((payload: { type: string; id: string }) =>
        this.apiWarehousesCatalogService.getWarehousesCatalogItem(payload.id).pipe(
          map(warehousesCatalogItem => ({ type: eWarehousesCatalogActions.WAREHOUSES_CATALOG_ITEM_GET_SUCCESS, warehousesCatalogItem })),
          catchError((error: HttpErrorResponse) => {
            return of({ type: eWarehousesCatalogActions.WAREHOUSES_CATALOG_ITEM_GET_FAILURE, error });
          }),
        ),
      ),
    ),
  );
}
