import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganizationStoreService } from '../../../../_core/stores/organization/organization-store.service';

import { ILiveSearchDelegate, ILiveSearchSelectableItem } from '../model/live-search-delegate.model';
import { IOrganizationBankInfo } from '../../../../_core/models/organization.model';

const BIK_MIN_LENGTH: number = 9;

@Injectable({
  providedIn: 'root',
})
export class LiveSearchDelegateOrganizationBankInfoService implements ILiveSearchDelegate {
  public isLoad$!: Observable<boolean | undefined>;
  public searchResult$!: Observable<ILiveSearchSelectableItem[] | null>;

  constructor(private organizationStoreService: OrganizationStoreService) {
    this.isLoad$ = this.organizationStoreService.organizationBankInfoIsLoading$;
    this.searchResult$ = this.organizationStoreService.organizationBankInfo$.pipe(
      map((data: IOrganizationBankInfo | IOrganizationBankInfo[] | null) => {
        if (Array.isArray(data)) {
          return data.map(bankInfoField => this.getBankInfoLiveSearchSelectableItem(bankInfoField));
        }

        if (data) {
          return [this.getBankInfoLiveSearchSelectableItem(data)];
        }

        return [];
      }),
    );
  }

  public search(searchableValue: string): void {
    if (searchableValue?.length === BIK_MIN_LENGTH) {
      this.organizationStoreService.checkOrganizationBik(searchableValue);
    }
  }

  public getBankInfoLiveSearchSelectableItem(bankInfoField: IOrganizationBankInfo): ILiveSearchSelectableItem {
    return {
      name: bankInfoField?.bank_bik,
      value: bankInfoField?.bank_bik,
      items:
        bankInfoField?.bank_title || bankInfoField?.bank_address || bankInfoField.bank_correspondent_account
          ? [
              { key: 'bank_bik', name: 'БИК', value: bankInfoField?.bank_bik },
              { key: 'bank_title', name: 'Наименование банка', value: bankInfoField.bank_title },
              { key: 'bank_address', name: 'Адрес', value: bankInfoField.bank_address },
              { key: 'bank_correspondent_account', name: 'Корреспондентский счет', value: bankInfoField.bank_correspondent_account },
            ]
          : [],
    };
  }

  public reset(): void {
    this.organizationStoreService.resetOrganizationBankInfo();
  }
}
