import { AbstractControl, ValidationErrors } from '@angular/forms';

/* tslint:disable */
const checkSumINN = {
  business(inn: number[]): boolean {
    return (
      inn.length === 10 &&
      inn[9] ===
        ((2 * inn[0] + 4 * inn[1] + 10 * inn[2] + 3 * inn[3] + 5 * inn[4] + 9 * inn[5] + 4 * inn[6] + 6 * inn[7] + 8 * inn[8]) % 11) % 10
    );
  },
  entrepreneur(inn: number[]): boolean {
    return (
      inn.length === 12 &&
      inn[10] ===
        ((7 * inn[0] +
          2 * inn[1] +
          4 * inn[2] +
          10 * inn[3] +
          3 * inn[4] +
          5 * inn[5] +
          9 * inn[6] +
          4 * inn[7] +
          6 * inn[8] +
          8 * inn[9]) %
          11) %
          10 &&
      inn[11] ===
        ((3 * inn[0] +
          7 * inn[1] +
          2 * inn[2] +
          4 * inn[3] +
          10 * inn[4] +
          3 * inn[5] +
          5 * inn[6] +
          9 * inn[7] +
          4 * inn[8] +
          6 * inn[9] +
          8 * inn[10]) %
          11) %
          10
    );
  },
};

const checkOGRN = {
  entrepreneur(ogrn: number[]): boolean {
    return ogrn.length === 13 && ogrn[12] === Math.floor((Number(ogrn.join('')) / 10) % 11) % 10;
  },
  business(ogrn: number[]): boolean {
    return ogrn.length === 15 && ogrn[14] === Math.floor((Number(ogrn.join('')) / 10) % 13) % 10;
  },
};

const checkKPP = (kpp: string): boolean => {
  return kpp.length === 9 && /^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(kpp);
};

const checkEmail = (email: string): boolean => {
  return /^[A-Za-zА-яЁё0-9._%-]+@[A-Za-zА-яЁё0-9._%-]+\.[A-Za-zА-яЁё]{2,3}$/.test(email);
};

export const bikValidator = (control: AbstractControl): ValidationErrors | null => {
  if (control.value === '' || control.value === null) {
    return null;
  }
  return control.value.length === 9 ? null : { invalidBik: 'error' };
};

export const innValidator = (control: AbstractControl): ValidationErrors | null => {
  if (control.value === '' || control.value === null) {
    return null;
  }
  const inn = String(control.value).replace(/\s/g, '').split('').map(Number);
  return checkSumINN.business(inn) || checkSumINN.entrepreneur(inn) ? null : { invalidInn: 'error' };
};

export const ogrnValidator = (control: AbstractControl): ValidationErrors | null => {
  if (control.value === '' || control.value === null) {
    return null;
  }
  const ogrn = String(control.value).split('').map(Number);
  return checkOGRN.entrepreneur(ogrn) ? null : { invalidOgrn: 'error' };
};

export const ogrnIPValidator = (control: AbstractControl): ValidationErrors | null => {
  if (control.value === '' || control.value === null) {
    return null;
  }
  const ogrn = String(control.value).split('').map(Number);
  return checkOGRN.business(ogrn) ? null : { invalidOgrnIP: 'error' };
};

export const okpoValidator = (control: AbstractControl): ValidationErrors | null => {
  if (control.value === '' || control.value === null) {
    return null;
  }

  return control.value.length === 8 || control.value.length === 10 ? null : { invalidOkpo: 'error' };
};

export const kppValidator = (control: AbstractControl): ValidationErrors | null => {
  if (control.value === '' || control.value === null) {
    return null;
  }
  return checkKPP(control.value) ? null : { invalidKpp: 'error' };
};

export const rusEmailValidator = (control: AbstractControl): ValidationErrors | null => {
  if (control.value === '' || control.value === null) {
    return null;
  }
  return checkEmail(control.value) ? null : { email: 'error' };
};

export const nonZeroValidator = (control: AbstractControl): ValidationErrors | null => {
  if (control.value === '0' || control.value === null) {
    return { nonZero: 'error' };
  }
  return null;
};
/* tslint:enable */
