import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
import { pairwise } from 'rxjs/operators';

import { IHttpError } from '../../../_core/models/http.model';
import { INPUT_VALIDATION_MESSAGE } from '../../../_core/const/input-validation.const';
import { tooltipPlacements } from '../../directives/tooltip/tooltip.directive';

export type validationIconNames = 'done' | 'undone' | 'error';
export type infoTipTypes = 'attention' | 'danger' | 'tip';
export type labelThemes = 'default' | 'enlarged';
export type errorMessagePositions = 'beforeContent' | 'afterContent';

@Component({
  selector: 'tlp-input-holder',
  templateUrl: './input-holder.component.html',
  styleUrls: ['./input-holder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputHolderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public control: AbstractControl | null = null;
  @Input() public required: boolean = false;
  @Input() public errors?: ValidationErrors | null;
  @Input() public backendError?: IHttpError | string | null;

  @Input() public label: string = '';
  @Input() public labelTheme: labelThemes = 'default';
  @Input() public infoTip: string | null = null;
  @Input() public infoTipType: infoTipTypes | string = '';
  @Input() public infoTipPlacement: tooltipPlacements = 'bottom';
  @Input() public errorMessagePosition: errorMessagePositions = 'afterContent';
  @Input() public validationIconAtStart: boolean = false;
  @Input() public showValidationIcon: boolean = true;

  public validationIcon: string | undefined;
  public validationIconSize: string = '10px';
  public errorMessage: string | undefined;

  private controlSub: Subscription | undefined = undefined;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    const errors = changes.errors?.currentValue;
    const backendError = changes.backendError?.currentValue;

    if (changes.required?.currentValue && !this.validationIcon) {
      this.setValidationIcon(this.control?.value ? 'done' : 'undone');
    }

    if (errors) {
      if (this.control?.touched && this.control?.dirty) {
        this.setValidationIcon('undone');
        this.showErrorMessage(errors);
      }
    } else {
      setTimeout(() => {
        if ((!this.control?.disabled || (this.control?.disabled && this.control?.value)) && this.validationIcon) {
          this.setValidationIcon('done');
        }
      }, 0);

      this.setErrorMessage(undefined);
    }

    if (backendError) {
      this.setValidationIcon('error');
      const errorMessage: string = typeof backendError === 'string' ? backendError : backendError.message;
      this.setErrorMessage(errorMessage);
    }
  }

  public ngOnInit(): void {
    // tslint:disable-next-line: no-any
    this.controlSub = this.control?.valueChanges.pipe(pairwise()).subscribe(([prevValue, nextValue]: [any, any]) => {
      if (prevValue !== nextValue && this.backendError) {
        this.backendError = null;
        if (this.control && !this.control.errors) {
          this.setValidationIcon('done');
          this.setErrorMessage(undefined);
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.controlSub?.unsubscribe();

    this.control = null;
    this.errors = null;
    this.backendError = null;
  }

  public showErrorMessage(validationErrors: ValidationErrors): void {
    let message: string;
    const activeError: string = Object.keys(validationErrors).sort()[0];
    if (INPUT_VALIDATION_MESSAGE[activeError as keyof typeof INPUT_VALIDATION_MESSAGE]) {
      if (activeError === 'minlength') {
        message = `${INPUT_VALIDATION_MESSAGE.minlength} ${validationErrors.minlength.requiredLength}`;
      } else {
        message = INPUT_VALIDATION_MESSAGE[activeError as keyof typeof INPUT_VALIDATION_MESSAGE];
      }
    } else {
      message = typeof validationErrors[activeError] === 'string' ? validationErrors[activeError] : '';
    }
    this.setErrorMessage(message);
  }

  private setValidationIcon(name: validationIconNames): void {
    if (this.control?.validator?.length) {
      this.validationIcon = name;
      this.cdr.markForCheck();
    }
  }

  private setErrorMessage(msg: string | undefined): void {
    this.errorMessage = msg;
    this.cdr.detectChanges();
  }
}
