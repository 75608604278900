import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainNavigationComponent } from './component/main-navigation/main-navigation.component';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { RouterModule } from '@angular/router';
import { NavigationListModule } from '../navigation-list/navigation-list.module';

@NgModule({
  declarations: [MainNavigationComponent],
  imports: [CommonModule, IconSpriteModule, RouterModule, NavigationListModule],
  exports: [MainNavigationComponent],
})
export class MainNavigationModule {}
