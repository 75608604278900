import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnInit } from '@angular/core';
import { MainNavigationService } from '../service/main-navigation.service';

@Component({
  selector: 'tlp-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainNavigationComponent implements OnInit {
  @Input() public pageTitle: string = 'Профиль';

  public isOpenMenu: boolean = false;

  constructor(private cdr: ChangeDetectorRef, private mainNavigationService: MainNavigationService) {}

  public ngOnInit(): void {
    this.mainNavigationService.pageTitle.subscribe((title: string | null) => {
      this.pageTitle = title || '';
      this.cdr.detectChanges();
    });
  }

  public toggleMenu(): void {
    this.isOpenMenu = !this.isOpenMenu;
  }
}
