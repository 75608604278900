import { Directive, Output, EventEmitter, HostListener, Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[tlpFileDnd]',
})
export class FileDndDirective {
  @Input() public cssFileOverClass: string = 'file-over';
  @Input() public draggable: boolean = true;
  @Output() public fileDropped: EventEmitter<FileList> = new EventEmitter<FileList>();

  @HostListener('dragover', ['$event']) public onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.elementRef.nativeElement.classList.add(this.cssFileOverClass);
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.elementRef.nativeElement.classList.remove(this.cssFileOverClass);
  }

  @HostListener('drop', ['$event']) public ondrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (!this.draggable) {
      return;
    }

    this.elementRef.nativeElement.classList.remove(this.cssFileOverClass);

    const files: FileList | undefined = event.dataTransfer?.files;
    if (files && files.length > 0) {
      this.fileDropped.emit(files);
    }
  }

  constructor(private elementRef: ElementRef) {}
}
