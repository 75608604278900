import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { transition, trigger } from '@angular/animations';
import { OwlOptions } from 'ngx-owl-carousel-o';

import { IDocument } from '../../../../_core/models/document.model';
import { IPhotoAction, IPhotoActionOutput, photoActionTypes, photoViewModes } from '../../model/photo-carousel.model';
import { APPEAR_ANIMATION, DISAPPEAR_ANIMATION } from '../../../animation/animation.const';

@Component({
  selector: 'tlp-photo-carousel',
  templateUrl: './photo-carousel.component.html',
  styleUrls: ['./photo-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [trigger('appear', [transition(':enter', APPEAR_ANIMATION), transition(':leave', DISAPPEAR_ANIMATION)])],
})
export class PhotoCarouselComponent implements OnInit, OnChanges {
  @Input() public items: IDocument[] | undefined = [];
  @Input() public activeItemId?: string | null;
  @Input() public mainItemId?: string | null;
  @Input() public slideWidth: number = 110;
  @Input() public actions: IPhotoAction[] = [];
  @Input() public viewMode: photoViewModes = 'default';
  @Output() public sliderItemClicked: EventEmitter<IPhotoActionOutput> = new EventEmitter<IPhotoActionOutput>();

  public config!: OwlOptions;
  public isDragging: boolean = false;

  public activeItem!: IDocument | undefined;
  public mainItem!: IDocument | undefined;

  public ngOnInit(): void {
    if (!this.config) {
      this.initConfig();
    }

    if (this.viewMode === 'extended' && this.activeItemId) {
      this.activeItem = this.findItem(this.activeItemId);
    } else if (this.viewMode === 'compact' && this.mainItemId) {
      this.mainItem = this.findItem(this.mainItemId);
      if (this.mainItem) {
        this.items = this.items?.filter(item => item.id !== this.mainItem?.id);
        this.items?.unshift(this.mainItem);
      }
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeItemId) {
      this.activeItem = this.findItem(changes.activeItemId.currentValue);
    }

    if (changes.viewMode && changes.viewMode.currentValue === 'compact') {
      this.initConfig();
      this.config = {
        ...this.config,
        center: true,
        responsive: {
          0: { items: 1 },
          480: { items: 1 },
          678: { items: 1 },
        },
      };
    }
  }

  public handlePhotoLoaded(loader: HTMLElement): void {
    console.log(loader);
    if (loader) {
      console.log('remove');
      loader?.remove();
    }
  }

  public onSliderItemClicked(actionType: photoActionTypes, item: IDocument): void {
    switch (actionType) {
      case 'delete':
      case 'main':
        this.sliderItemClicked.emit({
          itemId: item.id,
          actionType,
        });
        break;
      case 'view':
        if (this.viewMode === 'extended') {
          this.activeItem = item;
        } else {
          this.sliderItemClicked.emit({
            itemId: item.id,
          });
        }
        break;
      default:
        return;
    }
  }

  public switchPhoto(direction: 'next' | 'prev'): void {
    if (!this.items) {
      return;
    }

    const currentItemIndex: number = this.items.findIndex(item => item.id === this.activeItem!.id);
    const switchedPhotoIndex: number = direction === 'next' ? currentItemIndex + 1 : currentItemIndex - 1;

    if (switchedPhotoIndex < 0) {
      this.activeItem = this.items[this.items.length - 1];
    } else if (switchedPhotoIndex >= this.items.length) {
      this.activeItem = this.items[0];
    } else {
      this.activeItem = this.items[switchedPhotoIndex];
    }
  }

  private initConfig(): void {
    this.config = {
      loop: false,
      autoHeight: false,
      autoWidth: true,
      margin: 10,
      mouseDrag: false,
      touchDrag: false,
      pullDrag: false,
      dots: false,
      nav: true,
      navText: [
        `<img class="nav-icon" src="/assets/icons/ic-arrow-left.svg">`,
        `<img class="nav-icon" src="/assets/icons/ic-arrow-right.svg">`,
      ],
      responsive: {
        0: { items: 1 },
        480: { items: 4 },
        678: { items: 6 },
      },
      center: false,
    };
  }

  private findItem(itemId: string): IDocument | undefined {
    if (!this.items) {
      return undefined;
    }

    return this.items!.find(item => item.id === itemId) || this.items[0];
  }
}
