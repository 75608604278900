import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { IPasswordCheckItem, validationRules } from '../../model/password-checker.model';
import { DEFAULT_PASSWORD_VALIDATION, PASSWORD_RULES_REGEXP } from '../../const/password-checker.const';

@Component({
  selector: 'tlp-password-checker',
  templateUrl: './password-checker.component.html',
  styleUrls: ['./password-checker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordCheckerComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public inputValue?: string;
  @Input() public control: AbstractControl | null = null;
  public validationList: IPasswordCheckItem[] = DEFAULT_PASSWORD_VALIDATION;

  private controlSub: Subscription | undefined = undefined;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.control?.currentValue && !this.controlSub) {
      this.updateValidation(changes.control?.currentValue);
      this.cdr.detectChanges();
    }
  }

  public ngOnInit(): void {
    this.controlSub = this.control?.valueChanges.subscribe(() => {
      this.updateValidation(this.control);
      this.cdr.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.controlSub?.unsubscribe();
  }

  public getItemIcon(isValid: boolean | undefined): string {
    if (isValid === undefined) {
      return 'initial';
    }

    return isValid ? 'valid' : 'invalid';
  }

  private updateValidation(inputControl: AbstractControl | null): void {
    if (!inputControl || (!inputControl.dirty && inputControl.value)) {
      return;
    }

    const passedValidation: validationRules[] = [];

    if (PASSWORD_RULES_REGEXP.latinLengthRegexp.test(inputControl.value) && PASSWORD_RULES_REGEXP.latinRegexp.test(inputControl.value)) {
      passedValidation.push('validLatinLengthAndLatin');
    }
    if (PASSWORD_RULES_REGEXP.uppercaseRegexp.test(inputControl.value)) {
      passedValidation.push('hasUppercase');
    }
    if (PASSWORD_RULES_REGEXP.digitRegexp.test(inputControl.value)) {
      passedValidation.push('hasDigit');
    }

    this.validationList.forEach((validationItem, index) => {
      this.validationList[index].isValid = passedValidation.includes(validationItem.rule) ? true : false;
    });
  }
}
