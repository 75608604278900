import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListingStoreService } from '../../../../_core/stores/listing/listing-store.service';
import { MapLiveSearchDelegateHelperService } from './map-live-search-delegate-helper.service';

import { ILiveSearchDelegate, ILiveSearchSelectableItem } from '../model/live-search-delegate.model';
import { IListingLoadingState } from '../../../../_core/stores/listing/listing.state';
import { IListing, IListingApiParams } from '../../../../_core/models/listing.model';

@Injectable({
  providedIn: 'root',
})
export class ListingLiveSearchDelegateWarehousesUserService implements ILiveSearchDelegate {
  public isLoad$!: Observable<boolean | undefined>;
  public searchResult$!: Observable<ILiveSearchSelectableItem[] | null>;

  constructor(
    private listingStoreService: ListingStoreService,
    private mapLiveSearchDelegateHelperService: MapLiveSearchDelegateHelperService,
  ) {
    this.isLoad$ = this.listingStoreService.listingLoadingState$.pipe(
      map((state: IListingLoadingState | null) => state?.warehousesLoadingState?.isLoading),
    );

    this.searchResult$ = this.listingStoreService.warehouses$.pipe(
      map((listing: IListing | null) => this.mapLiveSearchDelegateHelperService.mapListingItems(listing)),
    );
  }

  public search(searchQuery?: string, searchParams?: object, searchId?: string[]): void {
    let apiParams: IListingApiParams = {};
    if (searchId) {
      apiParams = { ...apiParams, id: searchId };
    }
    if (searchQuery) {
      apiParams = { query: searchQuery };
    }
    if (searchParams) {
      apiParams = { ...apiParams, ...searchParams };
    }

    this.listingStoreService.getWarehousesListing(apiParams);
  }

  public reset(): void {}
}
