import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { INewPassword } from '../../models/auth.model';

export enum eForgotActions {
  FORGOT_RESET_PASSWORD = '[Forgot] reset password action',
  FORGOT_RESET_PASSWORD_SUCCESS = '[Forgot] reset password success',
  FORGOT_RESET_PASSWORD_FAILURE = '[Forgot] reset password failure',

  FORGOT_SET_PASSWORD = '[Forgot] set password action',
  FORGOT_SET_PASSWORD_SUCCESS = '[Forgot] set password success',
  FORGOT_SET_PASSWORD_FAILURE = '[Forgot] set password failure',

  FORGOT_STATE_RESET = '[Forgot] forgot state reset',
}

export const resetPassword = createAction(eForgotActions.FORGOT_RESET_PASSWORD, props<{ email: string }>());
export const resetPasswordSuccess = createAction(eForgotActions.FORGOT_RESET_PASSWORD_SUCCESS);
export const resetPasswordFailure = createAction(eForgotActions.FORGOT_RESET_PASSWORD_FAILURE, props<{ error: HttpErrorResponse }>());

export const setPassword = createAction(eForgotActions.FORGOT_SET_PASSWORD, props<{ newPassword: INewPassword }>());
export const setPasswordSuccess = createAction(eForgotActions.FORGOT_SET_PASSWORD_SUCCESS);
export const setPasswordFailure = createAction(eForgotActions.FORGOT_SET_PASSWORD_FAILURE, props<{ error: HttpErrorResponse }>());

export const resetForgotState = createAction(eForgotActions.FORGOT_STATE_RESET);
