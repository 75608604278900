import { Injectable } from '@angular/core';
import { ILiveSearchSelectableItem } from '../model/live-search-delegate.model';
import { IListing, IListingItem } from '../../../../_core/models/listing.model';
import { ISelectableItem } from '../../../delegate/model/delegate.model';

@Injectable({
  providedIn: 'root',
})
export class MapLiveSearchDelegateHelperService {
  public mapValues(items?: string[]): ISelectableItem[] | [] {
    if (items) {
      return items.map((item: string) => ({
        name: item || '',
        value: item || '',
      }));
    }
    return [];
  }

  public mapListingItems(listing: IListing | null): ILiveSearchSelectableItem[] {
    if (listing) {
      return listing.items?.map((item: IListingItem) => ({
        name: item?.title || '',
        value: item?.id?.toString() || '',
      }));
    }
    return [];
  }
}
