<div tlpFileDnd
     class="file-upload"
     [draggable]="!readonly"
     [cssFileOverClass]="!readonly ? 'file-upload--file-over' : ''"
     [ngClass]="'file-upload--' + interactionMode"
     [class.file-upload--uploaded]="interactionMode === 'upload' && (file || fileDocument)"
     [class.file-upload--disabled]="interactionMode === 'upload' && readonly"
     [class.file-upload--error]="!validFileExtension"
     (fileDropped)="onFileDropped($event)"
>
  <div class="file-upload__general">
    <tlp-change-note
        changedNoteMode="documentChanges"
        [showChanges]="showChanges && interactionMode === 'view'"
        [changedFieldName]="changedFieldName"
        [changedFields]="changedFields"
        [changedNoteMarginTop]="5"
    >
      <div class="file-upload__file-info">
        <svg-icon-sprite
            class="file-upload__icon"
            width="16px" height="16px"
            *ngIf="icon && interactionMode !== 'view'"
            [ngClass]="'file-upload__icon--' + icon"
            [src]="'./assets/sprites/sprite.svg#ic-' + icon"
        ></svg-icon-sprite>
        <ng-container *ngIf="validFileExtension && !backendError; else errorHeading">
          <div class="file-upload__heading">
            <h4 class="file-upload__title">{{ fileTitle }}</h4>
            <p class="file-upload__text" *ngIf="fileDescription">{{ fileDescription }}</p>
            <p class="file-upload__text" *ngIf="file || fileDocument">
              {{ currentFileName }}
              <span *ngIf="!validFilesCount && validFileExtension">
                (Вы пытались загрузить более одного файла. Был загружен первый удовлетворяющий требованиям файл)
              </span>
            </p>
          </div>
        </ng-container>
        <ng-template #errorHeading>
          <div class="file-upload__heading">
            <h4 class="file-upload__title">Ошибка при загрузке документа</h4>
            <p class="file-upload__text">
              Попробуйте загрузить повторно. Убедитесь что формат файла соответствует требованиям
              {{ (this.fileDocument || this.file) && '(сохранен загруженный ранее файл ' + (this.fileDocument?.file?.name || this.file?.name) + ')' }}
            </p>
          </div>
        </ng-template>
      </div>
    </tlp-change-note>
  </div>
  <ng-container *ngIf="interactionMode === 'view'; else editMode">
    <div class="file-upload__actions">
      <ng-container *ngIf="fileDocument; else noDocument">
        <ng-container *ngTemplateOutlet="fileUploadActionsButton"></ng-container>
      </ng-container>
      <ng-template #noDocument>
        <span class="file-upload__text file-upload__text--unspecified">Не прикреплен</span>
      </ng-template>
    </div>
  </ng-container>

  <ng-template #editMode>
    <input #fileInput type="file" hidden [accept]="currentFileTypes"/>
    <div class="file-upload__actions">
      <svg-icon-sprite
          class="file-upload__icon file-upload__icon--tip"
          width="16px" height="16px"
          [src]="'./assets/sprites/sprite.svg#ic-' + (readonly || hasDependencies ? 'attention' : 'tip')"
          *ngIf="readonly || hasDependencies || infoTooltipMessage"
          [tlpTooltip]="infoTooltipMessage"
          [class.file-upload__icon--attention]="hasDependencies && hasChanges"
      >
      </svg-icon-sprite>
      <ng-container *ngTemplateOutlet="fileUploadActionsButton"></ng-container>
    </div>
  </ng-template>

  <ng-template #fileUploadActionsButton>
    <tlp-button
        class="file-upload__action"
        theme="link"
        size="small"
        [isIconButton]="true"
        (click)="toggleActionMenu()"
    >
      <svg-icon-sprite
          class="file-upload__icon file-upload__icon--more"
          src="./assets/sprites/sprite.svg#ic-more"
          width="16px" height="16px"
      >
      </svg-icon-sprite>
    </tlp-button>
  </ng-template>
</div>

<tlp-content-block *ngIf="fileUploadActionsVisible" class="file-upload-menu" minWidth="170px">
  <ul class="file-upload-menu__actions" *ngIf="fileUploadActions && fileUploadActions.length">
    <li class="file-upload-menu__action" *ngFor="let action of fileUploadActions">
      <tlp-button
          theme="link-dark"
          [isIconButton]="true"
          [iconLeft]="action.icon"
          [disabled]="action.disabled || false"
          (click)="action.callback()"
      >
        <span class="file-upload-menu__text" *ngIf="action.name">{{ action.name }}</span>
      </tlp-button>
    </li>
  </ul>
</tlp-content-block>


