import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DictionaryEffects } from './dictionary.effects';
import { DictionaryReducer } from './dictionary.reducer';
import { ApiDictionaryService } from '../../api/api-dictionary.service';
import { DictionaryStoreService } from './dictionary-store.service';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('dictionary', DictionaryReducer), EffectsModule.forFeature([DictionaryEffects])],
  providers: [ApiDictionaryService, DictionaryStoreService],
})
export class DictionaryStoreModule {}
