import { Action, createReducer, on } from '@ngrx/store';
import { InitOrganizationState, IOrganizationStoreState } from './organization.state';
import * as organizationActions from './organization.actions';

const organizationReducer = createReducer(
  InitOrganizationState,
  on(organizationActions.checkOrganizationInn, state => ({
    ...state,
    organizationLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(organizationActions.checkOrganizationInnSuccess, (state, { organizationShortInfo }) => ({
    ...state,
    organizationShortInfo,
    organizationLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(organizationActions.checkOrganizationInnFailure, (state, { error }) => ({
    ...state,
    organizationLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(organizationActions.checkOrganizationBik, state => ({
    ...state,
    organizationBankInfoLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(organizationActions.checkOrganizationBikSuccess, (state, { organizationBankInfo }) => ({
    ...state,
    organizationBankInfo,
    organizationBankInfoLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(organizationActions.checkOrganizationBikFailure, (state, { error }) => ({
    ...state,
    organizationBankInfoLoadingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(organizationActions.getCurrentOrganization, state => ({
    ...state,
    organizationLoadingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(organizationActions.getCurrentOrganizationSuccess, (state, { organization }) => ({
    ...state,
    organization,
    organizationLoadingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(organizationActions.getCurrentOrganizationFailure, (state, { error }) => ({
    ...state,
    organizationLoadingState: { isLoading: false, isDidLoad: false, error },
  })),
  on(organizationActions.setOrganizationShortInfo, (state, { organizationShortInfo }) => ({ ...state, organizationShortInfo })),

  on(organizationActions.createOrganization, state => ({
    ...state,
    organizationUpdatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(organizationActions.createOrganizationSuccess, state => ({
    ...state,
    organizationUpdatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(organizationActions.createOrganizationFailure, (state, { error }) => ({
    ...state,
    organizationUpdatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(organizationActions.publishOrganization, state => ({
    ...state,
    organizationPublishingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(organizationActions.publishOrganizationSuccess, state => ({
    ...state,
    organizationPublishingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(organizationActions.publishOrganizationFailure, (state, { error }) => ({
    ...state,
    organizationPublishingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(organizationActions.updateOrganizationRequisites, state => ({
    ...state,
    organizationUpdatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(organizationActions.updateOrganizationRequisitesSuccess, state => ({
    ...state,
    organizationUpdatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(organizationActions.updateOrganizationRequisitesFailure, (state, { error }) => ({
    ...state,
    organizationUpdatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(organizationActions.uploadOrganizationDocument, state => ({
    ...state,
    organizationUpdatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(organizationActions.uploadOrganizationDocumentSuccess, state => ({
    ...state,
    organizationUpdatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(organizationActions.uploadOrganizationDocumentFailure, (state, { error }) => ({
    ...state,
    organizationUpdatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(organizationActions.deleteOrganizationDocument, state => ({
    ...state,
    organizationUpdatingState: { isLoading: true, isDidLoad: false, error: null },
  })),
  on(organizationActions.deleteOrganizationDocumentSuccess, state => ({
    ...state,
    organizationUpdatingState: { isLoading: false, isDidLoad: true, error: null },
  })),
  on(organizationActions.deleteOrganizationDocumentFailure, (state, { error }) => ({
    ...state,
    organizationUpdatingState: { isLoading: false, isDidLoad: false, error },
  })),

  on(organizationActions.resetOrganizationBankInfo, state => ({ ...state, organizationBankInfo: null })),
  on(organizationActions.resetOrganizationShortInfo, state => ({ ...state, organizationShortInfo: null })),
  on(organizationActions.resetOrganizationState, () => ({ ...InitOrganizationState })),
  on(organizationActions.resetOrganizationPublishingState, state => ({
    ...state,
    organizationPublishingState: null,
  })),
  on(organizationActions.resetOrganizationUpdatingState, state => ({
    ...state,
    organizationUpdatingState: null,
  })),
);

export const OrganizationReducer = (state: IOrganizationStoreState, action: Action) => {
  return organizationReducer(state, action);
};
