import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';

enum apiMethods {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

@Injectable()
export abstract class AbstractApiMethodsService {
  constructor(protected httpService: HttpService) {}

  // - GET - //

  protected httpGetById<T>(id: string, url: string = ''): Observable<T> {
    return this.httpGet(`${url}/${id}`);
  }

  protected httpGet<T>(url: string = ''): Observable<T> {
    return this.httpService.httpRequest(apiMethods.GET, url);
  }

  // - DELETE - //

  protected httpDeleteById<T>(id: string, url: string = ''): Observable<T> {
    return this.httpGet(`${url}/${id}`);
  }

  protected httpDelete<T>(url: string = ''): Observable<T> {
    return this.httpService.httpRequest(apiMethods.DELETE, url);
  }

  // - POST - //

  protected httpPostById<T>(id: string, obj: object, url: string = ''): Observable<T> {
    return this.httpPost(obj, `${url}/${id}`);
  }

  protected httpPost<T>(obj: object, url: string = '', multipart: boolean = false): Observable<T> {
    return this.httpService.httpRequest(apiMethods.POST, url, obj, multipart);
  }

  // - PUT - //

  protected httpPutById<T>(id: string, obj: object, url: string = '', lastUrl: string = ''): Observable<T> {
    return this.httpPut(obj, `${url}/${id}${lastUrl}`);
  }

  protected httpPut<T>(obj: object, url: string = ''): Observable<T> {
    return this.httpService.httpRequest(apiMethods.PUT, url, obj);
  }
}
