import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LogoutEffects } from './logout.effects';
import { LogoutReducer } from './logout.reducer';
import { ApiLogoutService } from '../../api/api-logout.service';
import { LogoutStoreService } from './logout-store.service';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('logout', LogoutReducer), EffectsModule.forFeature([LogoutEffects])],
  providers: [ApiLogoutService, LogoutStoreService],
})
export class LogoutStoreModule {}
