import { Injectable } from '@angular/core';
import { IAuth, IAccessToken, IRefreshToken, ITokens } from '../models/auth.model';

@Injectable()
export class AuthService {
  public setAuthData(data: IAuth): void {
    localStorage.setItem('user_id', data.user_id);
    localStorage.setItem('access_token', data.access_token);
    localStorage.setItem('access_token_expired_at', String(data.access_token_expired_at));
    localStorage.setItem('refresh_token', data.refresh_token);
    localStorage.setItem('refresh_token_expired_at', String(data.refresh_token_expired_at));
  }

  public setTokens(data: ITokens): void {
    localStorage.setItem('access_token', data.access_token);
    localStorage.setItem('access_token_expired_at', String(data.access_token_expired_at));
    localStorage.setItem('refresh_token', data.refresh_token);
    localStorage.setItem('refresh_token_expired_at', String(data.refresh_token_expired_at));
  }

  public removeAuthData(): void {
    localStorage.removeItem('user_id');
    localStorage.removeItem('access_token');
    localStorage.removeItem('access_token_expired_at');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('refresh_token_expired_at');
  }

  public getAccessToken(): IAccessToken {
    return {
      access_token: String(localStorage.getItem('access_token')),
      access_token_expired_at: Number(localStorage.getItem('access_token_expired_at')),
    };
  }

  public getRefreshToken(): IRefreshToken {
    return {
      refresh_token: String(localStorage.getItem('refresh_token')),
      refresh_token_expired_at: Number(localStorage.getItem('refresh_token_expired_at')),
    };
  }

  public getUserId(): string | null {
    return localStorage.getItem('user_id');
  }
}
