import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '../_ui/button/button.module';
import { ContentBlockModule } from '../_ui/content-block/content-block.module';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LoadingModule } from '../_ui/directives/loading/loading.module';
import { NotificationsMenuComponent } from './component/notifications-menu.component';

@NgModule({
  declarations: [NotificationsMenuComponent],
  imports: [CommonModule, ButtonModule, ContentBlockModule, IconSpriteModule, InfiniteScrollModule, LoadingModule],
  exports: [NotificationsMenuComponent],
})
export class NotificationsMenuModule {}
