import { HttpErrorResponse } from '@angular/common/http';
import { INotifications } from '../../models/notifications.model';

export interface INotificationsState {
  isLoading: boolean;
  isDidLoad: boolean;
  error: HttpErrorResponse | null;
  unreadCount: number;
  notifications: INotifications | null;
}

// TODO переделать
export interface INotificationsLoadingState {
  error: HttpErrorResponse | null;
  isLoading: boolean;
  isDidLoad: boolean;
}

export const InitNotificationsState: INotificationsState = {
  isLoading: false,
  isDidLoad: false,
  error: null,
  unreadCount: 0,
  notifications: null,
};
