export const toDecimal = (str: string, symbolsAfterDot: number = 2): string => {
  if (!str) {
    return str;
  }

  const valueWithDot: string = str
    .toString()
    .replace(/[^0-9.,]/g, '')
    .replace(/,/g, '.')
    .replace(/^([^.]*\.)(.*)$/, (_a, b, c) => {
      return b + c.replace(/\./g, '');
    });

  let decimal: string;
  const valueArr = valueWithDot.split('.');
  if (!valueArr[1] || valueArr[1].length < symbolsAfterDot + 1) {
    decimal = valueWithDot;
  } else {
    decimal = `${valueArr[0]}.${valueArr[1].slice(0, symbolsAfterDot)}`;
  }
  return decimal;
};
