import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DEFAULT_FILE_REQUIREMENT_DESCRIPTION, DEFAULT_FILE_REQUIREMENT_LIST } from '../../../../_core/const/requirements.const';

export type hintListDirections = 'horizontal' | 'vertical';

@Component({
  selector: 'tlp-hint-list',
  templateUrl: './hint-list.component.html',
  styleUrls: ['./hint-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HintListComponent {
  @Input() public hintDescription: string | null = DEFAULT_FILE_REQUIREMENT_DESCRIPTION;
  @Input() public hintList: string[] | null = DEFAULT_FILE_REQUIREMENT_LIST;
  @Input() public hintListDirection: hintListDirections = 'horizontal';
}
