import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IAcceptanceDocument } from '../../models/acceptance-document.model';
import {
  IBooking,
  IBookingDocuments,
  IBookingGeneral,
  IBookings,
  IBookingsApiParams,
  IBookingGeneralUpsert,
} from '../../models/booking.model';
import { IDocument } from '../../models/document.model';

export enum eBookingActions {
  BOOKINGS_GET = '[Admin: Booking] get bookings',
  BOOKINGS_GET_SUCCESS = '[Admin: Booking] get bookings success',
  BOOKINGS_GET_FAILURE = '[Admin: Booking] get bookings failure',

  BOOKING_GET = '[Admin: Booking] get booking',
  BOOKING_GET_SUCCESS = '[Admin: Booking] get booking success',
  BOOKING_GET_FAILURE = '[Admin: Booking] get booking failure',

  BOOKING_DOCUMENTS_GET = '[Admin: Booking] get booking documents',
  BOOKING_DOCUMENTS_GET_SUCCESS = '[Admin: Booking] get booking documents success',
  BOOKING_DOCUMENTS_GET_FAILURE = '[Admin: Booking] get booking documents failure',

  BOOKING_DOCUMENTS_ARCHIVE_GET = '[Admin: Booking] get booking documents archive',
  BOOKING_DOCUMENTS_ARCHIVE_GET_SUCCESS = '[Admin: Booking] get booking documents archive success',
  BOOKING_DOCUMENTS_ARCHIVE_GET_FAILURE = '[Admin: Booking] get booking documents archive failure',

  BOOKING_GENERAL_GET = '[Admin: Booking] get booking general',
  BOOKING_GENERAL_GET_SUCCESS = '[Admin: Booking] get booking general success',
  BOOKING_GENERAL_GET_FAILURE = '[Admin: Booking] get booking general failure',

  BOOKING_STORAGE_REQUEST_GET = '[Admin: Booking] get booking storage request',
  BOOKING_STORAGE_REQUEST_GET_SUCCESS = '[Admin: Booking] get booking storage request success',
  BOOKING_STORAGE_REQUEST_GET_FAILURE = '[Admin: Booking] get booking storage request failure',

  BOOKING_SHIPMENT_REQUEST_GET = '[Admin: Booking] get booking shipment request',
  BOOKING_SHIPMENT_REQUEST_GET_SUCCESS = '[Admin: Booking] get booking shipment request success',
  BOOKING_SHIPMENT_REQUEST_GET_FAILURE = '[Admin: Booking] get booking shipment request failure',

  BOOKING_CREATE = '[Admin: Booking] create booking',
  BOOKING_CREATE_SUCCESS = '[Admin: Booking] create booking success',
  BOOKING_CREATE_FAILURE = '[Admin: Booking] create booking failure',

  BOOKING_SET_CREATED_ID = '[Admin: Booking] set created booking id',

  BOOKING_PUBLISH = '[Admin: Booking] publish booking',
  BOOKING_PUBLISH_SUCCESS = '[Admin: Booking] publish booking success',
  BOOKING_PUBLISH_FAILURE = '[Admin: Booking] publish booking failure',

  BOOKING_CANCEL = '[Admin: Booking] cancel booking',
  BOOKING_CANCEL_SUCCESS = '[Admin: Booking] cancel booking success',
  BOOKING_CANCEL_FAILURE = '[Admin: Booking] cancel booking failure',

  BOOKING_COMPLETE = '[Admin: Booking] complete booking',
  BOOKING_COMPLETE_SUCCESS = '[Admin: Booking] complete booking success',
  BOOKING_COMPLETE_FAILURE = '[Admin: Booking] complete booking failure',

  BOOKING_UPDATE = '[Admin: Booking] update booking',
  BOOKING_UPDATE_SUCCESS = '[Admin: Booking] update booking success',
  BOOKING_UPDATE_FAILURE = '[Admin: Booking] update booking failure',

  BOOKING_DOCUMENT_UPLOAD = '[Admin: Booking] upload booking document',
  BOOKING_DOCUMENT_UPLOAD_SUCCESS = '[Admin: Booking] upload booking document success',
  BOOKING_DOCUMENT_UPLOAD_FAILURE = '[Admin: Booking] upload booking document failure',

  BOOKING_DOCUMENT_DELETE = '[Admin: Booking] delete warehouse document',
  BOOKING_DOCUMENT_DELETE_SUCCESS = '[Admin: Booking] delete booking document success',
  BOOKING_DOCUMENT_DELETE_FAILURE = '[Admin: Booking] delete booking document failure',

  BOOKINGS_RESET = '[Admin: Booking] reset bookings',
  BOOKING_RESET = '[Admin: Booking] reset booking',
  BOOKINGS_RESET_LOADING_STATE = '[Admin: Booking] reset bookings loading state',
  BOOKING_RESET_LOADING_STATE = '[Admin: Booking] reset booking loading state',
  BOOKING_RESET_PUBLISHING_STATE = '[Admin: Booking] reset booking publishing state',
  BOOKING_RESET_UPDATING_STATE = '[Admin: Booking] reset booking updating state',
  BOOKING_RESET_STATE = '[Admin: Booking] reset booking state',
}

export const getBookings = createAction(eBookingActions.BOOKINGS_GET, props<{ params: IBookingsApiParams }>());
export const getBookingsSuccess = createAction(eBookingActions.BOOKINGS_GET_SUCCESS, props<{ bookings: IBookings }>());
export const getBookingsFailure = createAction(eBookingActions.BOOKINGS_GET_FAILURE, props<{ error: HttpErrorResponse }>());

export const getBooking = createAction(eBookingActions.BOOKING_GET, props<{ id: string }>());
export const getBookingSuccess = createAction(eBookingActions.BOOKING_GET_SUCCESS, props<{ booking: IBooking }>());
export const getBookingFailure = createAction(eBookingActions.BOOKING_GET_FAILURE, props<{ error: HttpErrorResponse }>());

export const getBookingGeneral = createAction(eBookingActions.BOOKING_GENERAL_GET, props<{ id: string }>());
export const getBookingGeneralSuccess = createAction(
  eBookingActions.BOOKING_GENERAL_GET_SUCCESS,
  props<{ bookingGeneral: IBookingGeneral }>(),
);
export const getBookingGeneralFailure = createAction(eBookingActions.BOOKING_GENERAL_GET_FAILURE, props<{ error: HttpErrorResponse }>());

export const getBookingStorageRequest = createAction(eBookingActions.BOOKING_STORAGE_REQUEST_GET, props<{ id: string }>());
export const getBookingStorageRequestSuccess = createAction(
  eBookingActions.BOOKING_STORAGE_REQUEST_GET_SUCCESS,
  props<{ bookingStorageRequest: IAcceptanceDocument }>(),
);
export const getBookingStorageRequestFailure = createAction(
  eBookingActions.BOOKING_STORAGE_REQUEST_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getBookingShipmentRequest = createAction(eBookingActions.BOOKING_SHIPMENT_REQUEST_GET, props<{ id: string }>());
export const getBookingShipmentRequestSuccess = createAction(
  eBookingActions.BOOKING_SHIPMENT_REQUEST_GET_SUCCESS,
  props<{ bookingShipmentRequest: IAcceptanceDocument }>(),
);
export const getBookingShipmentRequestFailure = createAction(
  eBookingActions.BOOKING_SHIPMENT_REQUEST_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getBookingDocuments = createAction(eBookingActions.BOOKING_DOCUMENTS_GET, props<{ id: string }>());
export const getBookingDocumentsSuccess = createAction(
  eBookingActions.BOOKING_DOCUMENTS_GET_SUCCESS,
  props<{ bookingDocuments: IBookingDocuments }>(),
);
export const getBookingDocumentsFailure = createAction(
  eBookingActions.BOOKING_DOCUMENTS_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const getBookingDocumentsArchive = createAction(eBookingActions.BOOKING_DOCUMENTS_ARCHIVE_GET, props<{ id: string }>());
export const getBookingDocumentsArchiveSuccess = createAction(
  eBookingActions.BOOKING_DOCUMENTS_ARCHIVE_GET_SUCCESS,
  props<{ bookingDocumentsArchive: IDocument }>(),
);
export const getBookingDocumentsArchiveFailure = createAction(
  eBookingActions.BOOKING_DOCUMENTS_ARCHIVE_GET_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const createBooking = createAction(eBookingActions.BOOKING_CREATE, props<{ data: IBookingGeneralUpsert }>());
export const createBookingSuccess = createAction(eBookingActions.BOOKING_CREATE_SUCCESS, props<{ id: string }>());
export const createBookingFailure = createAction(eBookingActions.BOOKING_CREATE_FAILURE, props<{ error: HttpErrorResponse }>());

export const setCreatedBookingId = createAction(eBookingActions.BOOKING_SET_CREATED_ID, props<{ id: string }>());

export const publishBooking = createAction(eBookingActions.BOOKING_PUBLISH, props<{ id: string }>());
export const publishBookingSuccess = createAction(eBookingActions.BOOKING_PUBLISH_SUCCESS);
export const publishBookingFailure = createAction(eBookingActions.BOOKING_PUBLISH_FAILURE, props<{ error: HttpErrorResponse }>());

export const cancelBooking = createAction(eBookingActions.BOOKING_CANCEL, props<{ id: string; params: IBookingsApiParams }>());
export const cancelBookingSuccess = createAction(eBookingActions.BOOKING_CANCEL_SUCCESS);
export const cancelBookingFailure = createAction(eBookingActions.BOOKING_CANCEL_FAILURE, props<{ error: HttpErrorResponse }>());

export const completeBooking = createAction(eBookingActions.BOOKING_COMPLETE, props<{ id: string; params: IBookingsApiParams }>());
export const completeBookingSuccess = createAction(eBookingActions.BOOKING_COMPLETE_SUCCESS);
export const completeBookingFailure = createAction(eBookingActions.BOOKING_COMPLETE_FAILURE, props<{ error: HttpErrorResponse }>());

export const updateBooking = createAction(eBookingActions.BOOKING_UPDATE, props<{ data: IBookingGeneralUpsert; id: string }>());
export const updateBookingSuccess = createAction(eBookingActions.BOOKING_UPDATE_SUCCESS, props());
export const updateBookingFailure = createAction(eBookingActions.BOOKING_UPDATE_FAILURE, props<{ error: HttpErrorResponse }>());

export const uploadBookingDocument = createAction(
  eBookingActions.BOOKING_DOCUMENT_UPLOAD,
  props<{ file: File; id: string; documentType: string }>(),
);
export const uploadBookingDocumentSuccess = createAction(eBookingActions.BOOKING_DOCUMENT_UPLOAD_SUCCESS);
export const uploadBookingDocumentFailure = createAction(
  eBookingActions.BOOKING_DOCUMENT_UPLOAD_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const deleteBookingDocument = createAction(eBookingActions.BOOKING_DOCUMENT_DELETE, props<{ id: string; documentType: string }>());
export const deleteBookingDocumentSuccess = createAction(eBookingActions.BOOKING_DOCUMENT_DELETE_SUCCESS);
export const deleteBookingDocumentFailure = createAction(
  eBookingActions.BOOKING_DOCUMENT_DELETE_FAILURE,
  props<{ error: HttpErrorResponse }>(),
);

export const resetBookings = createAction(eBookingActions.BOOKINGS_RESET);
export const resetBooking = createAction(eBookingActions.BOOKING_RESET);
export const resetBookingsLoadingState = createAction(eBookingActions.BOOKINGS_RESET_LOADING_STATE);
export const resetBookingLoadingState = createAction(eBookingActions.BOOKING_RESET_LOADING_STATE);
export const resetBookingPublishingState = createAction(eBookingActions.BOOKING_RESET_PUBLISHING_STATE);
export const resetBookingUpdatingState = createAction(eBookingActions.BOOKING_RESET_UPDATING_STATE);
export const resetBookingState = createAction(eBookingActions.BOOKING_RESET_STATE);
