import { NgModule } from '@angular/core';
import { BookingStoreModule } from './booking/booking-store.module';
import { CityStoreModule } from './city/city-store.module';
import { CurrentUserStoreModule } from './current-user/current-user-store.module';
import { DictionaryStoreModule } from './dictionary/dictionary-store.module';
import { ForgotStoreModule } from './forgot/forgot-store.module';
import { LoginStoreModule } from './login/login-store.module';
import { LogoutStoreModule } from './logout/logout-store.module';
import { NotificationsStoreModule } from './notifications/notifications-store.module';
import { OrganizationStoreModule } from './organization/organization-store.module';
import { StorageAreaStoreModule } from './storage-area/storage-area-store.module';
import { RegisterStoreModule } from './register/register-store.module';
import { TeamStoreModule } from './team/team-store.module';
import { WarehouseStoreModule } from './warehouse/warehouse-store.module';
import { WarehousesCatalogStoreModule } from './warehouses-catalog/warehouses-catalog-store.module';
import { ListingStoreUserModule } from './listing/listing-store.module';

@NgModule({
  imports: [
    BookingStoreModule,
    CityStoreModule,
    CurrentUserStoreModule,
    DictionaryStoreModule,
    ForgotStoreModule,
    LoginStoreModule,
    LogoutStoreModule,
    NotificationsStoreModule,
    OrganizationStoreModule,
    StorageAreaStoreModule,
    RegisterStoreModule,
    TeamStoreModule,
    WarehouseStoreModule,
    WarehousesCatalogStoreModule,
    ListingStoreUserModule,
  ],
})
export class StoresModule {}
